import { TimerStatus } from '../../enums';
import { domain } from '../domain';

export const setTimer = domain.createEvent<number | null>('set timer');
export const continueTimer = domain.createEvent<void>('continue timer');
export const tickTimer = domain.createEvent<void>('tickTimer');
export const startGame = domain.createEvent<void>('set timer');
export const setActivePlayerNum = domain.createEvent<number>('set timer');
export const stopTimer = domain.createEvent<void>('stopTimer');
export const changeTimerStatus =
  domain.createEvent<TimerStatus>('set timer status');
export const setNextPlayerId = domain.createEvent<number | null>('set timer');
export const changeTimeForStep = domain.createEvent<number>(
  'change time for step'
);

export const setAudioTickTimer =
  domain.createEvent<HTMLAudioElement>('setAudioTickTimer');

export const setAudioEndTimer =
  domain.createEvent<HTMLAudioElement>('setAudioEndTimer');
