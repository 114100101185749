import { subscribeChatApi } from './api'

export function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        const vapidKey = process.env.REACT_APP_VAPID_KEY || ''
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapidKey),
        }

        return registration.pushManager.subscribe(subscribeOptions)
      })
      .then(function (pushSubscription) {
        subscribeChatApi({ info: pushSubscription })
      })
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then(function (registration) {
        const newSW = registration.installing
        if (newSW) {
          newSW.addEventListener('statechange', () => {
            if (newSW.state === 'installed' && navigator.serviceWorker.controller) {
              // Новый SW установлен и существует старый SW, обновляем страницу или уведомляем пользователя
              console.log('Новый Service Worker готов к активации.')
              newSW.postMessage({ action: 'skipWaiting' }) // Это если в SW обрабатываются сообщения для skipWaiting
            }
          })
        }
        subscribeUser()
      })
      .catch(function (err) {
        console.log('ServiceWorker registration failed: ', err)
      })
    let refreshing = false
    // Обрабатываем событие, когда новый SW активируется
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) return // предотвращаем повторную перезагрузку страницы
      window.location.reload()
      refreshing = true
    })
  }
}
