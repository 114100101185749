import { forward, guard } from 'effector'

import { setToken } from '@/services/api'

import * as api from '../../api'
import { getUserFx } from '../user/events'
import { signIn, signInFx } from './events'
import { loginForm } from './forms'

signInFx.use(api.signInApi)

signInFx.doneData.watch(({ data }: any) => {
  setToken(data.authToken)
})

guard({
  source: signIn,
  filter: signInFx.pending.map((state) => !state),
  target: signInFx,
})

forward({
  from: signInFx.doneData,
  to: getUserFx,
})

forward({
  from: loginForm.formValidated,
  to: signIn,
})

forward({
  from: signInFx.doneData,
  to: loginForm.reset,
})
