// ./model/index.ts
import { createStore } from 'effector'

import { decryptMessageFx, encryptMessageFx } from './effects'

export const $encryptedData = createStore<{
  encryptedMessage: string
  qrCode: string
} | null>(null)
  .on(encryptMessageFx.doneData, (_, response) => ({
    encryptedMessage: response.data.encryptedMessage,
    qrCode: response.data.qrCode,
  }))
  .reset(encryptMessageFx.fail)

export const $encryptPending = encryptMessageFx.pending

encryptMessageFx.failData.watch((error) => {
  console.error('Error encrypting message:', error)
})

export const $decryptedData = createStore<string | null>(null)
  .on(decryptMessageFx.doneData, (_, response) => response.data.decryptedMessage)
  .reset(decryptMessageFx.fail)

export const $decryptPending = decryptMessageFx.pending

decryptMessageFx.failData.watch((error) => {
  console.error('Error decrypting message:', error)
})
