import { Avatar, Box, Link, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { getAppPath } from '@/shared/utils/getPath'

import { $user } from '../../../../../auth'
import { User } from '../../../../../auth/types'

export const UserInfo = () => {
  const user = useStore($user) as User
  return (
    <Link underline="none" href={getAppPath('/user/profile')}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Avatar src={user.avatar || ''} />
        <Typography variant="h6" sx={{ my: 2, ml: 1, color: 'common.black' }}>
          {user?.username}
        </Typography>
      </Box>
    </Link>
  )
}
