import { AlertProps, CircularProgress, Collapse, Typography, TypographyProps } from '@mui/material'
import { useEffect, useState } from 'react'

import { AlertSuccess } from '../success'
import * as Styled from './styled'

type Props = AlertProps & {
  text?: string | null
  TypographyProps?: TypographyProps
  pending?: boolean
  successText?: string
  showSuccess?: boolean
  size?: number
}
export const AlertLoading = ({
  text = 'Данные обновляются',
  successText = 'Успешно обновлено',
  pending = false,
  size = 16,
  showSuccess = false,
  TypographyProps = {},
  ...otherProps
}: Props) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (!pending && showSuccess) {
      setTimeout(() => setShow(false), 2000)
    } else {
      setShow(true)
    }
  }, [pending, showSuccess])

  if (!pending && showSuccess) {
    return (
      <Collapse in={show} timeout={show ? 0 : 1000}>
        <AlertSuccess text={successText} />
      </Collapse>
    )
  }

  return (
    <Styled.Root severity="info" {...otherProps}>
      <Typography
        variant="h6"
        whiteSpace="break-spaces"
        textAlign="left"
        fontSize={size}
        {...TypographyProps}
      >
        {text}
      </Typography>
      <Styled.CircularWrapper>
        <CircularProgress size={size} sx={{ ml: 2 }} />
      </Styled.CircularWrapper>
    </Styled.Root>
  )
}
