import { Replay as ReplayIcon } from '@mui/icons-material'
import { AlertProps, Fade, IconButton, Tooltip, Typography, TypographyProps } from '@mui/material'

import { If } from '@/shared/utils/if'

import * as Styled from './styled'

type Props = AlertProps & {
  text?: string | null
  TypographyProps?: TypographyProps
  size?: number
  onReload?: () => void
}
export const AlertError = ({
  text = 'Произошла ошибка',
  size = 16,
  onReload,
  TypographyProps = {},
  ...otherProps
}: Props) => {
  return (
    <Styled.Root severity="error" {...otherProps}>
      <Typography
        variant="body2"
        whiteSpace="break-spaces"
        textAlign="left"
        fontSize={size}
        {...TypographyProps}
      >
        {text}
      </Typography>
      <If condition={onReload}>
        <Tooltip
          arrow
          title="Повторить запрос"
          placement="left"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <IconButton size="small" onClick={onReload}>
            <ReplayIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </If>
    </Styled.Root>
  )
}
