import { List } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ChatLists = styled(List)`
  flex-grow: 1;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* Стилизация скроллбара для всего документа */
  ::-webkit-scrollbar {
    width: 4px; /* ширина скроллбара */
    height: 4px; /* высота горизонтального скроллбара */
  }

  /* Обработка трека (дорожки) скроллбара */
  ::-webkit-scrollbar-track {
    background: transparent; /* фоновый цвет дорожки */
  }

  /* Обработка ползунка скроллбара */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3); /* цвет ползунка */
    border-radius: 20px; /* скругление углов ползунка */
  }

  /* Обработка ползунка скроллбара при наведении */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.7); /* темнее при наведении */
  }
`
