import { Box, PaginationItem, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertApi } from '@/shared/components/alert'

import { If } from '../../../../shared/utils/if'
import { $user } from '../../../auth'
import { $levelsCombine } from '../../model/admin'
import { getLevels } from '../../model/admin/events'
import { $profileCombined } from '../../model/game-snake'
import { MainLevelGate } from '../../model/level/gate'
import { GameWrapper } from '../game-wrapper'
import { getLevelColor } from './utils'

export const GameSnakeLevels = () => {
  useGate(MainLevelGate)
  const navigator = useNavigate()
  const user = useStore($user)
  const { profile, profileError, profilePending } = useStore($profileCombined)
  const { levelsData, levelsError, levelsPending } = useStore($levelsCombine)
  const availableLevels = useMemo(
    () => (user?.isSuperuser ? levelsData : levelsData.filter(({ isPublished }) => isPublished)),
    [levelsData, user]
  )
  const isAllGameDone =
    profile &&
    profile.snakeParams.level - 1 === availableLevels.length &&
    availableLevels.length > 0

  return (
    <GameWrapper title="Режим кампании">
      {profile && Boolean(levelsData.length) && (
        <Box>
          <Typography variant="h5">Ваш уровень: {profile?.snakeParams.level - 1}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              widht: '100%',
              justifyContent: 'flex-start',
              my: 2,
            }}
          >
            {availableLevels.map(({ id, number, isPublished }) => (
              <PaginationItem
                sx={{
                  mb: 1,
                  minWidth: 25,
                  height: 25,
                  ...getLevelColor(profile, number, isPublished),
                }}
                key={id}
                disabled={
                  (!profile || profile.snakeParams.level !== number || profile.gameCount === 0) &&
                  !user?.isSuperuser
                }
                page={number}
                variant="outlined"
                onClick={() => navigator(`${ROUTE_PATH.GAME_SNAKE_LEVEL_NUMBER}/${number}`)}
              />
            ))}
          </Box>
          <If condition={profile.gameCount === 0 && !isAllGameDone}>
            <Typography variant="h6">У вас нет доступных игр</Typography>
            <Typography variant="body2">Их можно купить в змейкашопе</Typography>
          </If>
          <If condition={isAllGameDone}>
            <Typography variant="h6">Вы прошли все уровни!</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Пухля усердно трудится над новыми!
            </Typography>
            <img
              src={'https://b.avodom.ru/media/files/waddles/waddles_pc.jpg'}
              width={300}
              alt="Пухля"
            />
          </If>
        </Box>
      )}
      <AlertApi
        sx={{ mt: 2 }}
        errorText={levelsError || profileError}
        pending={levelsPending || profilePending}
        loadingText={'Загружается'}
        onReload={() => getLevels()}
      />
    </GameWrapper>
  )
}
