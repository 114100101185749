import { guard } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import * as api from '../../api'
import { getHoliday, getHolidayFx } from './events'

getHolidayFx.use(api.getHolidayApi)

guard({
  source: getHoliday,
  filter: getHolidayFx.pending.map((state) => !state),
  target: getHolidayFx,
})

getHolidayFx.doneData.watch(({ data }) => {
  Storage.set(STORAGE_NAMES.HOLIDAY, data)
})
