import {
  ArrowBack as ArrowBackIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  PauseCircleSharp,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { If } from '@/shared/utils/if'

import { GameSnakeLevel } from '../level-number/data'
import { GameSnakeSingle } from '../single-game/data'
import { GAME_STATUS, SIDE } from '../single-game/types'

type Props = {
  gameSnake: GameSnakeSingle | GameSnakeLevel
  gameStatus: GAME_STATUS
}

export const ControlButtons = ({ gameSnake, gameStatus }: Props) => {
  return (
    <Box>
      <If condition={[GAME_STATUS.PAUSE, GAME_STATUS.PROGRESS].includes(gameStatus)}>
        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{ width: 90 }}
            disabled={gameStatus === GAME_STATUS.PAUSE}
            onMouseDown={() => gameSnake.changeSide(SIDE.LEFT)}
            disableRipple
            onTouchStart={() => gameSnake.changeSide(SIDE.LEFT)}
          >
            <ArrowBackIcon />
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              variant="contained"
              sx={{ width: 110, height: 70 }}
              disabled={gameStatus === GAME_STATUS.PAUSE}
              disableRipple
              onMouseDown={() => gameSnake.changeSide(SIDE.UP)}
              onTouchStart={() => gameSnake.changeSide(SIDE.UP)}
            >
              <ArrowUpwardIcon />
            </Button>
            <Button
              variant="contained"
              sx={{ width: 110, height: 70 }}
              disabled={gameStatus === GAME_STATUS.PAUSE}
              disableRipple
              onMouseDown={() => gameSnake.changeSide(SIDE.DOWN)}
              onTouchStart={() => gameSnake.changeSide(SIDE.DOWN)}
            >
              <ArrowDownwardIcon />
            </Button>
          </Box>
          <Button
            variant="contained"
            sx={{ width: 90 }}
            disabled={gameStatus === GAME_STATUS.PAUSE}
            disableRipple
            onMouseDown={() => gameSnake.changeSide(SIDE.RIGHT)}
            onTouchStart={() => gameSnake.changeSide(SIDE.RIGHT)}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      </If>
      <Button
        variant="contained"
        size="large"
        id="btn-status"
        sx={{ width: 300, my: 2 }}
        onClick={() =>
          [GAME_STATUS.PAUSE, GAME_STATUS.START].includes(gameStatus)
            ? gameSnake.runGame()
            : gameSnake.pauseGame()
        }
      >
        {gameStatus === GAME_STATUS.PROGRESS ? <PauseCircleSharp /> : <PlayArrowIcon />}
      </Button>
    </Box>
  )
}
