import { styled } from '@mui/material/styles'

import { FIELD_WIDTH } from '../../consts'

export const Root = styled('div')`
  width: ${FIELD_WIDTH}px;
  height: ${FIELD_WIDTH}px;
  display: flex;
  flex-wrap: wrap;
`
