import { guard } from 'effector'

import * as api from '../../api'
import { getTimers, getTimersFx } from './events'

getTimersFx.use(api.getTimersApi)

guard({
  source: getTimers,
  filter: getTimersFx.pending.map((state) => !state),
  target: getTimersFx,
})
