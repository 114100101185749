import { Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useStore } from 'effector-react'

import { $openConfirmDialog } from '../../model/dialog'
import { toggleConfirmDialog } from '../../model/dialog/events'
import { $battle, $battleProfiles } from '../../model/game'
import { WsAction } from '../../model/types'
import { chatSocket } from '../../model/ws'
import * as Styled from './styled'

const reject = (battleId?: number, inviterUsername?: string) => {
  if (!chatSocket || !battleId || !inviterUsername) return

  toggleConfirmDialog(false)

  chatSocket.send(
    JSON.stringify({
      type: WsAction.REJECT,
      inviter_username: inviterUsername,
      battle_id: battleId,
    })
  )
}

const accept = (battleId?: number) => {
  if (!chatSocket || !battleId) return

  chatSocket.send(
    JSON.stringify({
      type: WsAction.ACCEPT,
      battle_id: battleId,
    })
  )
}

export const BattleConfirmDialog = () => {
  const modalOpen = useStore($openConfirmDialog)
  const battle = useStore($battle)
  const battleProfiles = useStore($battleProfiles)
  return (
    <Dialog
      open={modalOpen}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        toggleConfirmDialog(false)
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Приготовьтесь!</Typography>
          <IconButton onClick={() => toggleConfirmDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Styled.Root>
          <Box fontSize="18px">
            Пользователь {battleProfiles?.user.profile.username} вызывает вас на дуэль!
          </Box>
        </Styled.Root>
      </DialogContent>
      <Styled.Actions>
        <Button
          onClick={() => reject(battle?.id, battleProfiles?.enemy.profile.username)}
          color="error"
          tabIndex={4}
          variant="contained"
        >
          Отклонить
        </Button>
        <LoadingButton
          color="success"
          tabIndex={3}
          variant="contained"
          onClick={() => accept(battle?.id)}
        >
          Принять
        </LoadingButton>
      </Styled.Actions>
    </Dialog>
  )
}
