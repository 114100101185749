import { combine } from 'effector'

import { ChatRoom } from '../../type'
import { updateChatRoomForStore } from '../chat-settings/events'
import { domain } from '../domain'
import {
  changeModalCreateChat,
  changeOpenChatsMenu,
  createChatRoomFx,
  getUserChatRoomFx,
  resetActiveChatId,
  setActiveChatId,
  updateUserChatInStore,
} from './events'

export const $userChats = domain
  .createStore<ChatRoom[]>([])
  .on(getUserChatRoomFx.doneData, (_, { data }) => data)
  .on(updateUserChatInStore, (_, data) => data)
  .on(updateChatRoomForStore, (store, data) =>
    store.map((chat) => (chat.id === data.id ? { ...chat, ...data } : chat))
  )

export const $openChatMenu = domain
  .createStore<boolean>(false)
  .on(changeOpenChatsMenu, (_, open) => open)

export const $activeChatId = domain
  .createStore<number | null>(null)
  .on(setActiveChatId, (_, chatId) => chatId)
  .reset(resetActiveChatId)

export const $activeChat = combine({ activeChatId: $activeChatId, userChats: $userChats }).map(
  ({ activeChatId, userChats }) => userChats.find((item) => item.id === activeChatId) || null
)

export const $userChatsPending = getUserChatRoomFx.pending

export const $showModalCreateChat = domain
  .createStore<boolean>(false)
  .on(changeModalCreateChat, (_, open) => open)

export const $createChatPending = createChatRoomFx.pending
