import '../../model/notifications/init'
import '../../model/theme/init'

import { useGate, useStore } from 'effector-react'

import { MainGate } from '../../model/gate'
import { $notifications } from '../../model/notifications'
import { CenterMain } from '../center'
import { LeftMain } from '../left'
import { RightMain } from '../right'
import { TopMain } from '../top'
import * as Styled from './styled'

export const Main = () => {
  useGate(MainGate)

  const notifications = useStore($notifications)

  return (
    <Styled.Root>
      <TopMain />
      <Styled.CenterBlock>
        <LeftMain updatedProductsCount={notifications.updatedProductsCount} />
        <CenterMain />
        <RightMain
          newTaskCount={notifications.newTasksCount}
          newPostsCount={notifications.newPostsCount}
        />
      </Styled.CenterBlock>
    </Styled.Root>
  )
}
