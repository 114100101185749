import { Box, Typography } from '@mui/material'

import { If } from '@/shared/utils/if'

import { CoinsHeader } from '../coins-header'
import { HomeButton } from '../home-button'
import * as Styled from './styled'

type Props = {
  children: React.ReactNode
  title?: string
  withHeader?: Boolean
  withHomeButton?: Boolean
}

export const GameWrapper = ({
  children,
  title,
  withHeader = true,
  withHomeButton = true,
}: Props) => {
  return (
    <Styled.Root>
      <Styled.MainWrapper>
        <If condition={title}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {title}
          </Typography>
        </If>
        <If condition={withHeader}>
          <Box sx={{ mb: 1, width: '100%' }}>
            <CoinsHeader />
          </Box>
        </If>
        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
        <If condition={withHomeButton}>
          <HomeButton sx={{ mt: 1 }} />
        </If>
      </Styled.MainWrapper>
    </Styled.Root>
  )
}
