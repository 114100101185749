import { range } from 'lodash'

import { getXYbyId } from '../../model/admin/utils'

export const createDefaultField = (size: number) =>
  range(0, size ** 2).map((id) => ({
    id,
    ...getXYbyId(id, size),
    order: null,
    fieldItem: null,
  }))
