import { styled } from '@mui/system'

export const Root = styled('div')({
  width: '100%',
  padding: '10px',
  boxSizing: 'border-box',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const HouseContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 auto',
  margin: '20px 0',
  width: '100%',
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
  animation: 'bounce 5s infinite',
})

export const ProgressContainer = styled('div')({
  width: '100%',
  maxWidth: '600px',
  marginBottom: '20px',
})

export const MotivationalText = styled('div')({
  marginTop: '20px',
  textAlign: 'center',
  color: '#333',
  marginBottom: '20px',
})
