import { forward, guard, sample } from 'effector'

import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'

import { $activeChat } from '../chat/store'
import * as api from './api'
import {
  joinChat,
  joinChatFx,
  redirectToListChat,
  updateChatRoom,
  updateChatRoomFx,
} from './events'
import { updateChatForm } from './forms'

updateChatRoomFx.use(api.updateChatApi)

// update chat
guard({
  source: updateChatRoom,
  filter: updateChatRoomFx.pending.map((state) => !state),
  target: updateChatRoomFx,
})

sample({
  source: updateChatForm.formValidated,
  fn: (data) => ({
    chatId: $activeChat.getState()?.id || -1,
    title: data.title,
    avatar: data.avatar,
  }),
  target: updateChatRoom,
})

// join to chat
joinChatFx.use(api.joinChatApi)

guard({
  source: joinChat,
  filter: joinChatFx.pending.map((state) => !state),
  target: joinChatFx,
})

guard({
  source: joinChatFx.failData,
  filter: ({ response }) => Boolean(response?.data.isMember),
  target: redirectToListChat,
})

forward({
  from: joinChatFx.done,
  to: redirectToListChat,
})

redirectToListChat.watch((item) => {
  router.navigate(ROUTE_PATH.CHAT)
})
