import { api } from '@/services/api'

import {
  ChangeActiveChatParams,
  ChangeActiveChatResponse,
  CreateChatParams,
  CreateChatResponse,
  GetUserChatsResponse,
} from './types'

export const getUserChatsApi = async (): Promise<GetUserChatsResponse> => {
  return api.get('/chat/get_user_chats/')
}

export const createChatApi = async (params: CreateChatParams): Promise<CreateChatResponse> => {
  return api.post('/chat/create_chat/', params)
}

export const changeActiveChatApi = async (
  params: ChangeActiveChatParams
): Promise<ChangeActiveChatResponse> => {
  const { chatId, ...otherParams } = params
  return api.put(`/chat/${chatId}/change_active/`, otherParams)
}
