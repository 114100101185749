import { Box } from '@mui/material'

import { ImageWrapper } from '@/shared/components/image-wrapper'

import { changeDialogImage } from '../../../../../../model/messages/events'
import { MessageImage } from '../../../../../../type'

type Props = {
  images: MessageImage[]
}

export const ImageItems = ({ images }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {images.map((image) => (
        <Box
          key={image.id}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            changeDialogImage(image.image)
          }}
        >
          <ImageWrapper
            src={image.image}
            alt="Картинка пользователя"
            sx={{ maxWidth: '260px', minHeight: '200px' }}
            skeletonProps={{ height: '300px', width: '260px' }}
          />
        </Box>
      ))}
    </Box>
  )
}
