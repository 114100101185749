import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`

export const TabWrapper = styled(Box)`
  margin-bottom: 16px;
`

export const Bold = styled('span')`
  font-weight: bold;
`
