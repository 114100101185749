import { CircularProgress, SxProps, Typography, TypographyProps } from '@mui/material'

import { waddlesCoinSrc } from '@/shared/assets'

import { If } from '../../utils/if'
import * as Styled from './styled'

type WaddlesCoinProps = {
  count?: number
  text?: string
  sx?: SxProps
  hasError?: boolean
  pending?: boolean
  TypographyProps?: TypographyProps
}

export const WaddlesCoin = ({
  count = 0,
  text,
  hasError = false,
  pending = false,
  sx,
  TypographyProps = {},
}: WaddlesCoinProps) => {
  return (
    <Styled.Root sx={sx}>
      <Typography variant="body1" {...TypographyProps}>
        {text} {hasError ? '-' : count}
      </Typography>
      <If condition={pending}>
        <CircularProgress sx={{ color: 'common.white', ml: 1 }} size={20} />
      </If>
      <If condition={!pending}>
        <img src={waddlesCoinSrc} alt="Пухлякоины" />
      </If>
    </Styled.Root>
  )
}
