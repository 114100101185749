import { ThemeProvider } from '@mui/system'

import { Footer } from '@/features/footer'
import { Header } from '@/features/header'
import { theme } from '@/theme/default'

import { useUpdateVersion } from './hook'
import * as Styled from './styled'

type AppProps = {
  children: React.ReactNode
  withFooter?: boolean
  withHeader?: boolean
  backgroundUrl?: string
  hasPadding?: boolean
  hasContainer?: boolean
}

export const Template = ({
  children,
  withFooter = true,
  withHeader = true,
  backgroundUrl,
  hasPadding = true,
  hasContainer = true,
}: AppProps) => {
  useUpdateVersion()

  return (
    <ThemeProvider theme={theme}>
      <Styled.Root $backgroundUrl={backgroundUrl}>
        {withHeader && <Header />}
        {hasContainer ? (
          <Styled.Container
            disableGutters={!hasPadding}
            maxWidth="lg"
            sx={{ mt: hasPadding ? 1 : 0, mb: hasPadding ? 1 : 0 }}
          >
            {children}
          </Styled.Container>
        ) : (
          children
        )}
        {withFooter && <Footer />}
      </Styled.Root>
    </ThemeProvider>
  )
}
