import { AxiosResponse } from 'axios'

import { SIDE } from './ui/single-game/types'

export type SnakeParams = {
  canTurnAround: boolean
  hasAccelerometer: boolean
  headImage: string | null
  singleGameLevel: number
  speed: number
  maxSpeed: number
  snakeColor: string
  level: number
}

export type Profile = {
  gameCount: number
  foodCount: number
  lastGameDate: string | null
  snakeParams: SnakeParams
}

export type GetProfileResponse = AxiosResponse<Profile>

export type FinishSingleGameParams = {
  result: number
}

export type SingleGameResult = {
  waddlescoin: number
  fortuneCount: number
  foodCount: number
}

export type FinishSingleGameResponse = AxiosResponse<SingleGameResult>

export enum ItemType {
  FORTUNE = 'FORTUNE',
  WADDLESCOIN = 'WADDLESCOIN',
  FOOD_1 = 'FOOD_1',
  OBSTACLE = 'OBSTACLE',
}

export type Item = {
  id: number
  name: string
  itemType: ItemType
  audio: HTMLAudioElement
  image: HTMLImageElement
}

export type ItemData = {
  x: number
  y: number
  item: Item
}

export type ItemPosition = {
  x: number
  y: number
  id: string
  isActive: boolean
  isEaten: boolean
  item: Item
}

export type Game = {
  id: number
  items: ItemData[]
}

export type GameParams = {
  defaultStep: number
  fieldColor: string
  fieldSize: number
  foodCount: number
  obstacleCount: number
  roundTime: number
  snakeHead: string
  snakeSpeed: number
  snakeColor: string
  startSide: SIDE
  startX: number
  startY: number
  brokenMyselfAccuracy: number
  obstacleAccuracy: number
  prizeAccuracy: number
  audioBg: string
  audioEndLose: string
  audioEndTime: string
  audioEndWin: string
}

export type GetSnakeSingleGameParams = { difficulty: number }

export type ItemDataType = {
  id: number
  name: string
  itemType: ItemType
  audio: string | null
  image: string
}

export type GetSnakeSingleGameResponse = AxiosResponse<{
  game: {
    id: number
    difficulty: number
    items: Array<{
      x: number
      y: number
      item: ItemDataType
    }>
  }
  gameParams: GameParams
  record: number
  difficulty: number
}>

export type SingleGame = {
  id: number
  params: GameParams
  difficulty: number
  record: number
  prizes: ItemPosition[]
  obstacles: ItemPosition[]
}

export type BuyGameResponse = AxiosResponse<Profile>

export type UpgradeParamRequest = {
  param: string
  value?: string
}

export type UpgradeParamResponse = AxiosResponse<Profile>

export type StatsInfo = {
  difficulty: number
  record: number
  playerRecord: number
}

export type StatsResponse = AxiosResponse<StatsInfo[]>

export type UpdateParamRequest = {
  param: string
  value: number
}

export type UpdateParamResponse = AxiosResponse<Profile>
