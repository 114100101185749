import { Close as CloseIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { AlertError } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import {
  $activeEditNoteId,
  $anchorDelete,
  $deleteNoteFxPending,
  $editNoteError,
  $editNoteFxPending,
  $tagList,
} from '../../model/note'
import { deleteNote, setActiveNoteId, setAnchorDelete } from '../../model/note/events'
import { editNoteForm } from '../../model/note/forms'
import * as Styled from './styled'

export const EditNoteModal = () => {
  const tagList = useStore($tagList)
  const error = useStore($editNoteError)
  const editPending = useStore($editNoteFxPending)
  const deletePending = useStore($deleteNoteFxPending)
  const editedNoteId = useStore($activeEditNoteId)
  const anchorDelete = useStore($anchorDelete)

  const { fields, submit, errorText } = useForm(editNoteForm)

  const pending = editPending || deletePending

  return (
    <Dialog
      fullWidth
      open={editedNoteId !== null}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        setActiveNoteId(null)
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Редактирование</Typography>
          <IconButton onClick={() => setActiveNoteId(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Styled.Root>
          <Autocomplete
            sx={{ mb: 1 }}
            disablePortal
            value={fields.tagName.value}
            onChange={(e, newValue) => fields.tagName.onChange(newValue || '')}
            disabled={pending}
            freeSolo
            size="small"
            options={tagList}
            renderInput={(params) => (
              <TextField
                {...params}
                label="тег"
                variant="standard"
                placeholder="тег"
                onChange={(e) => fields.tagName.onChange(e.target.value)}
              />
            )}
          />
          <TextField
            sx={{ mb: 1 }}
            multiline
            disabled={pending}
            value={fields.text.value}
            error={fields.text.hasError()}
            helperText={errorText('text')}
            variant="standard"
            label="Описание"
            onChange={(e) => fields.text.onChange(e.target.value)}
          />
          <If condition={error}>
            <AlertError text={error} />
          </If>
        </Styled.Root>
      </DialogContent>
      <Styled.Actions>
        <LoadingButton
          onClick={(event) => setAnchorDelete(event.currentTarget)}
          disabled={pending}
          color="error"
          tabIndex={4}
          variant="contained"
          loading={deletePending}
        >
          Удалить
        </LoadingButton>
        <LoadingButton
          loading={editPending}
          disabled={pending}
          tabIndex={3}
          variant="contained"
          onClick={() => submit()}
        >
          Изменить
        </LoadingButton>
        <Menu
          open={Boolean(anchorDelete)}
          onClose={() => setAnchorDelete(null)}
          anchorEl={anchorDelete}
        >
          <MenuItem
            onClick={() => {
              setAnchorDelete(null)
              deleteNote(editedNoteId as number)
            }}
            color="error"
          >
            <DeleteForeverIcon color="error" sx={{ mr: 1 }} />
            <Typography color="error" variant="h6">
              Подтвердить удаление
            </Typography>
          </MenuItem>
        </Menu>
      </Styled.Actions>
    </Dialog>
  )
}
