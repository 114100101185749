import { FortuneSector, ProductCode } from '../../types'

export const getResultText = (sector: FortuneSector | null): string => {
  if (!sector) return ''

  if (sector.productCode === ProductCode.WADDLES_COIN) {
    if (sector.count === 0 || sector.count === -1) {
      return 'Ничего страшного!\nПриходите завтра и вам обязательно повезёт!'
    } else {
      return `Вы выиграли пухлякоины: ${sector.count}!`
    }
  }
  let resultText = `Ура!\nВы выиграли "${sector.productName}"!`
  if (sector.count > 1) {
    resultText = `${resultText} ${sector.count} шт.`
  }
  return resultText
}
