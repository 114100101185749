import { ShopCards } from '../shop-cards'
import * as Styled from './styled'

export const Shop = () => {
  return (
    <Styled.Root>
      <ShopCards />
    </Styled.Root>
  )
}
