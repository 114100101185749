import { useEffect } from 'react'

import { getAppPath } from '@/shared/utils/getPath'

export const AdminPage = () => {
  useEffect(() => {
    window.location.href = getAppPath('/admin')
  }, [])

  return null
}
