import '../../model/timer/init'

import { useStore } from 'effector-react'

import { Choose, When } from '@/shared/utils/choose'

import { GameStatus } from '../../enums'
import { $gameStatus } from '../../model/single-game-status'
import { Finish } from '../single-finish'
import { Settings } from '../single-settings'
import { TimerProcess } from '../single-timer-process'
import * as Styled from './styled'

export const Timer = () => {
  const gameStatus = useStore($gameStatus)

  return (
    <Styled.Root>
      <Choose>
        <When condition={gameStatus === GameStatus.SETTINGS}>
          <Settings />
        </When>
        <When condition={gameStatus === GameStatus.PROCESS}>
          <TimerProcess />
        </When>
        <When condition={gameStatus === GameStatus.FINISH}>
          <Finish />
        </When>
      </Choose>
    </Styled.Root>
  )
}
