import { forward, guard, sample } from 'effector'

import { getMessagesRoom, sendMessageFx } from '../messages/events'
import * as api from './api'
import {
  changeActiveChat,
  changeActiveChatFx,
  changeModalCreateChat,
  changeOpenChatsMenu,
  createChatRoom,
  createChatRoomFx,
  getUserChatRoomFx,
  getUserChatsRoom,
} from './events'
import { createChatForm } from './forms'

//get
getUserChatRoomFx.use(api.getUserChatsApi)

guard({
  source: getUserChatsRoom,
  filter: getUserChatRoomFx.pending.map((state) => !state),
  target: getUserChatRoomFx,
})

// create
createChatRoomFx.use(api.createChatApi)
guard({
  source: createChatRoom,
  filter: createChatRoomFx.pending.map((state) => !state),
  target: createChatRoomFx,
})

sample({
  source: createChatForm.formValidated,
  fn: (data) => data,
  target: createChatRoom,
})

forward({
  from: createChatRoomFx.doneData,
  to: [
    createChatForm.reset,
    changeModalCreateChat.prepend(() => false),
    changeOpenChatsMenu.prepend(() => false),
    getUserChatsRoom,
  ],
})

sample({
  source: createChatRoomFx.doneData,
  fn: ({ data }) => ({ chatId: data.id, offset: 0 }),
  target: getMessagesRoom,
})

sample({
  source: createChatRoomFx.doneData,
  fn: ({ data }) => data.id,
  target: getUserChatsRoom,
})

forward({
  from: sendMessageFx.doneData,
  to: getUserChatsRoom,
})

// change active
changeActiveChatFx.use(api.changeActiveChatApi)

guard({
  source: changeActiveChat,
  filter: changeActiveChatFx.pending.map((state) => !state),
  target: changeActiveChatFx,
})

changeActiveChatFx.done.watch(() => {
  getUserChatsRoom()
})
