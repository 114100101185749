import { forward, guard, sample } from 'effector'

import * as api from '../../api'
import { Note, Tag } from '../../types'
import { $activeEditNoteId, $activeTagTab, $noteTags, $tags } from '.'
import {
  createNote,
  createNoteFx,
  deleteNote,
  deleteNoteFx,
  editNote,
  editNoteFx,
  getNotes,
  getNotesFx,
  setActiveNoteId,
} from './events'
import { editNoteForm, newNoteForm } from './forms'

getNotesFx.use(api.getNotesApi)
createNoteFx.use(api.createNoteApi)
editNoteFx.use(api.editNoteApi)
deleteNoteFx.use(api.deleteNoteApi)

guard({
  source: getNotes,
  filter: getNotesFx.pending.map((state) => !state),
  target: getNotesFx,
})

guard({
  source: createNote,
  filter: createNoteFx.pending.map((state) => !state),
  target: createNoteFx,
})

guard({
  source: editNote,
  filter: editNoteFx.pending.map((state) => !state),
  target: editNoteFx,
})
guard({
  source: deleteNote,
  filter: deleteNoteFx.pending.map((state) => !state),
  target: deleteNoteFx,
})

sample({
  source: $noteTags,
  clock: setActiveNoteId,
  fn: (tagList, id) =>
    tagList
      .map((item) => item.notes)
      .flat()
      .find((item) => item.id === id),
  target: editNoteForm.setForm.prepend((item?: Note) => ({
    tagName: item?.tag.name || '',
    text: item?.text || '',
  })),
})

// New note form
forward({
  from: newNoteForm.formValidated,
  to: createNote,
})

forward({
  from: createNoteFx.done,
  to: newNoteForm.reset,
})

// Edit not form
sample({
  source: $activeEditNoteId,
  clock: editNoteForm.formValidated,
  filter: (noteId) => noteId !== null,
  fn: (id, editNote) => ({ id: id as number, ...editNote }),
  target: editNote,
})

forward({
  from: [editNoteFx.done, deleteNoteFx.done],
  to: editNoteForm.reset,
})

sample({
  source: $tags,
  clock: $activeTagTab,
  fn: (tags, id): Tag | undefined => tags.find((item) => item.id === id),
  target: newNoteForm.fields.tagName.onChange.prepend<Tag | undefined>((item) => item?.name || ''),
})
