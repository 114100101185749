import { PhotoDayError, PhotoDayParams, PhotoDayResponse, PhotoDayTabName } from '../../types'
import { domain } from '../domain'

export const getPhotoDay = domain.event<PhotoDayParams>('attempt to get photo day')

export const getPhotoDayFx = domain.effect<PhotoDayParams, PhotoDayResponse, PhotoDayError>(
  'get photo day fx'
)

export const changeModalPhotoDayVisible = domain.event<boolean>('change modal visible')

export const changeActiveTab = domain.event<PhotoDayTabName>('change active tab')

export const setLoadedImage = domain.event<string>('set loaded image')
