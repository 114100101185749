import { blue, orange } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-bottom: 20px;
  }
`

export const WaddlesImage = styled('img')`
  width: 240px;
  height: 240px;
  user-select: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 180px;
    height: 180px;
  }
`

export const Time = styled('div')`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    top: 0;
  }
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -60px;
  display: flex;
  justify-content: center;
`

export const Message = styled('div')`
  position: relative;
  text-align: center;
  max-width: 400px;
  border-radius: 20px;
  padding: 16px;
  background: ${blue[500]};
  color: ${({ theme }) => theme.palette.common.white};
  opacity: 0.9;
  margin-bottom: 12px;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top: 20px solid ${blue[500]};
    bottom: -40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 32px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 200px;
    p {
      font-size: 20px;
    }
  }
`

export const WaddlesWrapper = styled('div')`
  position: relative;
`

export const Gift = styled('img')`
  cursor: pointer;
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 120px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    right: -12px;
    width: 88px;
  }
`

export const BottomMessage = styled('div')`
  position: relative;
  text-align: center;
  border-radius: 20px;
  padding: 8px 16px;
  background: ${orange[900]};
  color: ${({ theme }) => theme.palette.common.white};
  opacity: 0.9;

  p {
    font-size: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    p {
      font-size: 14px;
    }
  }
`
