import { Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'

import { MainGameSnakeGate, StatsGate } from '../../gate'
import { $getStatsError, $getStatsPending, $stats } from '../../model/game-snake'
import { StatsInfo } from '../../types'
import { GameWrapper } from '../game-wrapper'
import { StatsSingleGame } from '../stats-single-game'

export const GameSnakeStats = () => {
  useGate(MainGameSnakeGate)
  useGate(StatsGate)
  const stats = useStore($stats)
  const statsPending = useStore($getStatsPending)
  const statsError = useStore($getStatsError)

  return (
    <GameWrapper title="Статистика">
      <Typography sx={{ mb: 2 }} variant="h5">
        Одиночная игра
      </Typography>
      <Choose>
        <When condition={statsError}>
          <AlertError text={statsError} />
        </When>
        <When condition={statsPending}>
          <AlertLoading text="Статистика загружается" />
        </When>
        <When condition={stats && stats.length === 0}>
          <Typography variant="body1">Сыграйте в одиночную игру и статистика появятся!</Typography>
        </When>
        <When condition={stats}>
          <StatsSingleGame stats={stats as StatsInfo[]} />
        </When>
      </Choose>
    </GameWrapper>
  )
}
