export const ROUTE_PATH = {
  HOME: '/',
  TIMER: '/timer',
  NOTES: '/notes',
  PWA: '/pwa',
  ADMIN: '/admin',
  TIMER_SINGLE: '/timer/single',
  TIMER_MULTI: '/timer/:id',
  SIGN_IN: '/login',
  GAME: '/game/',
  APPS: '/apps/',
  // engame
  GAME_ENGAME: '/game/engame',
  GAME_BATTLE: '/game/engame/battle',
  GAME_NUMBER: '/game/number',
  // snake
  GAME_SNAKE: '/game/snake',
  GAME_SNAKE_SINGLE_GAME: '/game/snake/single-game',
  GAME_SNAKE_SHOP: '/game/snake/shop',
  GAME_SNAKE_PARAMS: '/game/snake/params',
  GAME_SNAKE_STATS: '/game/snake/stats',
  GAME_SNAKE_ADMIN: '/game/snake/admin',
  GAME_SNAKE_LEVELS: '/game/snake/levels',
  GAME_SNAKE_LEVEL_NUMBER: '/game/snake/levels',
  BLOG_POST: '/blog/posts/:id/',
  // shop
  SHOP: '/shop',
  SHOP_PACK: '/shop/pack',
  SHOP_TRANSACTIONS: '/shop/transactions',
  // report
  DAILY_NOTES: '/daily-notes',
  DAILY_NOTES_LIST: '/daily-notes/list',
  DAILY_NOTES_NEW: '/daily-notes/new',
  DAILY_NOTES_EDIT: '/daily-notes/edit',
  DAILY_NOTES_EDIT_WITH_ID: '/daily-notes/edit/:id',
  DAILY_NOTES_CALENDAR: '/daily-notes/calendar',
  // chat
  CHAT: '/chat',
  CHAT_ROOM: '/chat/:chatId',
  CHAT_JOIN: '/chat/:chatId/join',
  // chat
  ROAD_TO_HOUSE: '/road-to-house',
  // shift
  CRUTILS: '/crutils/',
  // shop
  BOARDGAMES: '/boardgames',
  BOARDGAMES_NEW: '/boardgames/new',
  BOARDGAMES_REVIEW: '/boardgames/review',
  BOARDGAMES_EDIT: '/boardgames/edit',
  BOARDGAMES_LIST: '/boardgames/list',
  // sounds
  SOUNDS: '/sounds/',
}
