// ./ui/main/styled.ts
import { Box, Container as ContainerMui, Tab as TabMui, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('section')`
  width: 100%;
`

export const TopRow = styled('div')`
  display: flex;
  a:not(:last-child) {
    margin-right: 20px;
  }
`

export const UtilsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
`

export const Container = styled(ContainerMui)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TabsContainer = styled(Tabs)`
  display: flex;
  width: max-content;
  background-color: #f0f0f0; // Светло-серый фон
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Легкая тень
  min-height: 48px;
  .MuiTabs-indicator {
    display: none; // Убираем нижнюю полоску
  }
`

export const Tab = styled(TabMui)`
  text-transform: none; // Отключаем uppercase для текста
  font-size: 16px;
  font-weight: 500;
  color: #333; // Более темный текст
  padding: 12px 16px;
  min-width: 120px;
  &:hover {
    color: #1976d2; // Цвет текста при наведении
  }
  &.Mui-selected {
    color: #fff; // Белый текст для активного таба
    background-color: #1976d2; // Синий фон для активного таба
    border-radius: 8px; // Радиус закругления активного таба
  }
`

export const FixedBox = styled(Box)`
  width: 100%;
  max-width: 500px;
  min-height: 300px; /* Минимальная высота, чтобы предотвратить «прыжки» */
`
