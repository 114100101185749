import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RouterLink } from '@/shared/components/router-link'

import { ROUTE_PATH } from '../../../../packages/paths'
import { $ratingCreating } from '../../model'
import { createRatingFx } from '../../model/events'
import { ratingForm } from '../../model/forms'

export const ReviewGameSession = () => {
  const ratingCreating = useStore($ratingCreating)
  const { fields, submit, error } = useForm(ratingForm)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const { id: sessionId } = useParams<{ id: string }>()

  useEffect(() => {
    fields.session.onChange(sessionId || '')
    fields.comment.onChange('')
    fields.score.onChange(5)

    const unsubscribeCreate = createRatingFx.doneData.watch(() => {
      setIsSubmitted(true)
      setSubmitError(null)
    })

    const unsubscribeCreateError = createRatingFx.failData.watch((error) => {
      setSubmitError(error?.response?.data[0] || 'Произошла ошибка при добавлении отзыва')
    })

    return () => {
      unsubscribeCreate()
      unsubscribeCreateError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Container>
      <Box sx={{ mt: 3, width: '100%' }}>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 700 }}>
          Оставить отзыв о сессии
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <RouterLink to={ROUTE_PATH.BOARDGAMES}>
            <Button variant="outlined" color="primary">
              На главную
            </Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.BOARDGAMES_LIST}>
            <Button variant="outlined" color="primary">
              К списку сессий
            </Button>
          </RouterLink>
        </Box>
        {isSubmitted ? (
          <Box>
            <Alert severity="success" sx={{ mb: 2 }}>
              Отзыв успешно добавлен!
            </Alert>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Комментарий"
              name="comment"
              value={fields.comment.value}
              onChange={(e) => fields.comment.onChange(e.target.value)}
              error={!!error('comment')}
              helperText={error('comment')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Оценка"
              name="score"
              type="number"
              inputProps={{ min: 1, max: 10 }}
              value={fields.score.value}
              onChange={(e) => fields.score.onChange(Number(e.target.value))}
              error={!!error('score')}
              helperText={error('score')?.errorText}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%' }}
              color="primary"
              disabled={ratingCreating}
              startIcon={ratingCreating ? <CircularProgress size={20} /> : null}
            >
              {ratingCreating ? 'Отправка...' : 'Добавить отзыв'}
            </Button>
          </form>
        )}
      </Box>
    </Container>
  )
}
