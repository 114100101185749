import AttachFileIcon from '@mui/icons-material/AttachFile'
import SendIcon from '@mui/icons-material/Send'
import { LoadingButton } from '@mui/lab'
import { Box, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useRef } from 'react'

import { If } from '@/shared/utils/if'
import { isMobileDevice } from '@/shared/utils/media/isMobileDevice'

import { $user } from '../../../../../../../auth'
import { User } from '../../../../../../../auth/types'
import { $activeChatId } from '../../../../../../model/chat/store'
import {
  fileChanged,
  setEditMessage,
  setSendInput,
  setSendInputFocus,
} from '../../../../../../model/messages/events'
import { sendMessageForm } from '../../../../../../model/messages/forms'
import {
  sendUserIsTyping,
  throttleUserTyping,
} from '../../../../../../model/messages/socket/events'
import {
  $imageFile,
  $messages,
  $sendMessagePending,
  $socketsIsOnline,
} from '../../../../../../model/messages/store'
import { EmojiPicker } from '../emoji'
import { ImageFile } from '../image-file'

export const Send = () => {
  const { fields, submit, errorText } = useForm(sendMessageForm)
  const allMessages = useStore($messages)
  const user = useStore($user) as User
  const activeChatId = useStore($activeChatId)
  const chatMessages = activeChatId ? allMessages[activeChatId] : null
  const pending = useStore($sendMessagePending)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const imageFile = useStore($imageFile)
  const hasMessage = fields.text.value !== '' || imageFile !== null
  const chatSocketIsOnline = useStore($socketsIsOnline)

  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0]
    // Обрабатывайте файл здесь
    if (fileUploaded) {
      fileChanged(fileUploaded)
    }
  }
  const onPaste = (e: React.ClipboardEvent) => {
    const items = e.clipboardData.items
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.type.indexOf('image') === 0) {
        const file = item.getAsFile()
        if (file) {
          fileChanged(file)
        }
      }
    }
  }

  return (
    <Box>
      <ImageFile />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <EmojiPicker
          onClose={() => {
            setSendInputFocus()
          }}
          onEmojiSelect={(emoji) => {
            fields.text.onChange(fields.text.value + emoji.emoji)
            sendUserIsTyping()
            setSendInputFocus()
          }}
        />
        <TextField
          inputRef={(input) => {
            setSendInput(input)
          }}
          sx={{ width: '100%', overflowY: 'auto', maxHeight: 300 }}
          InputProps={{ sx: { fontSize: 14, lineHeight: 1.2 } }}
          error={fields.text.hasError()}
          helperText={errorText('text')}
          multiline
          disabled={!chatSocketIsOnline}
          value={fields.text.value}
          placeholder="Введите сообщение..."
          onPaste={onPaste}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp' && chatMessages && fields.text.value === '') {
              const filteredMessage = chatMessages.filter(
                (message) => message.author.id === user.id
              )
              const lastUserMessage = filteredMessage[filteredMessage.length - 1]
              setEditMessage(lastUserMessage)
            }
            const isSendMobile = e.key === 'Enter' && !e.shiftKey && !isMobileDevice()
            const isSendPc = e.key === 'Enter' && e.ctrlKey
            if (isSendMobile || isSendPc) {
              e.preventDefault()
              submit()
              setSendInputFocus()
            }
          }}
          onChange={(e) => {
            if (e.target.value !== '') throttleUserTyping()
            fields.text.onChange(e.target.value)
          }}
        />
        <Box sx={{ ml: 1 }}>
          <If condition={!hasMessage}>
            <input
              type="file"
              ref={hiddenFileInput}
              disabled={!chatSocketIsOnline}
              onChange={handleChange}
              style={{ display: 'none' }} // скрыть input
            />
            <LoadingButton
              sx={{ minWidth: 40, height: 50 }}
              loading={pending}
              disabled={!chatSocketIsOnline}
              variant="contained"
              size="small"
              onClick={() => handleClick()}
            >
              <AttachFileIcon />
            </LoadingButton>
          </If>
          <If condition={hasMessage}>
            <LoadingButton
              sx={{ minWidth: 40, height: 50 }}
              loading={pending}
              disabled={!chatSocketIsOnline}
              variant="contained"
              size="small"
              onClick={() => submit()}
            >
              <SendIcon />
            </LoadingButton>
          </If>
        </Box>
      </Box>
    </Box>
  )
}
