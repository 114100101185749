import { RoadToHouse } from '@/features/road-to-house'

import { Template } from '../templates/template'

type Props = {}

export const RoadToHousePage = (props: Props) => {
  return (
    <Template hasPadding={false}>
      <RoadToHouse />
    </Template>
  )
}
