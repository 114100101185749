import { forward, guard } from 'effector'

import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'

import { changeWaddlesCoin } from '../../../auth/models/user/events'
import { changeFortuneCount } from '../../../main/model/fortune/events'
import * as api from '../../api'
import { FinishSingleGameResponse } from '../../types'
import {
  buyGame,
  buyGameFx,
  changeSingleGameModalVisible,
  finishSingleGame,
  finishSingleGameFx,
  getProfile,
  getProfileFx,
  getSingleGame,
  getSingleGameFx,
  getStats,
  getStatsFx,
  updateParam,
  updateParamFx,
  upgradeParam,
  upgradeParamFx,
} from './events'
import { $singleGameForm } from './forms'

getProfileFx.use(api.getProfileApi)
finishSingleGameFx.use(api.finishSingleGameApi)
getSingleGameFx.use(api.getSnakeSingeGameApi)
buyGameFx.use(api.buyGameApi)
upgradeParamFx.use(api.upgradeParamApi)
getStatsFx.use(api.getStatsApi)
updateParamFx.use(api.updateParamApi)

guard({
  source: getProfile,
  filter: getProfileFx.pending.map((state) => !state),
  target: getProfileFx,
})

guard({
  source: getStats,
  filter: getStatsFx.pending.map((state) => !state),
  target: getStatsFx,
})

guard({
  source: buyGame,
  filter: buyGameFx.pending.map((state) => !state),
  target: buyGameFx,
})

guard({
  source: upgradeParam,
  filter: upgradeParamFx.pending.map((state) => !state),
  target: upgradeParamFx,
})

guard({
  source: updateParam,
  filter: updateParamFx.pending.map((state) => !state),
  target: updateParamFx,
})

guard({
  source: finishSingleGame,
  filter: finishSingleGameFx.pending.map((state) => !state),
  target: finishSingleGameFx,
})

guard({
  source: getSingleGame,
  filter: getSingleGameFx.pending.map((state) => !state),
  target: getSingleGameFx,
})

forward({
  from: finishSingleGameFx.doneData,
  to: [
    changeWaddlesCoin.prepend<FinishSingleGameResponse>(({ data }) => data.waddlescoin),
    changeFortuneCount.prepend<FinishSingleGameResponse>(({ data }) => data.fortuneCount),
  ],
})

forward({
  from: $singleGameForm.formValidated,
  to: getSingleGame,
})

getSingleGameFx.doneData.watch(() => {
  changeSingleGameModalVisible(false)
  router.navigate(ROUTE_PATH.GAME_SNAKE_SINGLE_GAME)
})

upgradeParamFx.done.watch(({ params }) => {
  if (params.param === 'snake_color') {
    router.navigate(ROUTE_PATH.GAME_SNAKE)
  }
})
