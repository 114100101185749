import { Box, Button, Typography } from '@mui/material'

import { GameStatus } from '../../enums'
import { changeGameStatus } from '../../model/single-game-status/events'

export const Finish = () => {
  return (
    <Box mt={2}>
      <Typography sx={{ textAlign: 'center' }} variant="h3" color="initial">
        Игра окончена
      </Typography>
      <Button
        variant="contained"
        onClick={() => changeGameStatus(GameStatus.SETTINGS)}
        sx={{ width: '100%', mt: 3 }}
      >
        Начать сначала
      </Button>
    </Box>
  )
}
