import { combine } from 'effector'

import { Profile, SingleGame, SingleGameResult, StatsInfo } from '../../types'
import { GameSnakeSingle } from '../../ui/single-game/data'
import { END_REASON, GAME_STATUS } from '../../ui/single-game/types'
import { domain } from '../domain'
import {
  buyGameFx,
  changeGameSnakeCount,
  changeGameStatus,
  changeSingleGameModalVisible,
  changeSnakeColor,
  changeVisibleColorModal,
  finishSingleGameFx,
  getProfileFx,
  getSingleGameFx,
  getStatsFx,
  hideSnackbarShop,
  resetGame,
  setEndReason,
  setGameRecord,
  setGameSnakeSingle,
  setScore,
  setSnackbarSingleGame,
  updateParamFx,
  upgradeParamFx,
} from './events'
import { mapSingleGameResponse } from './utils'

export const $score = domain
  .createStore<number>(0)
  .on(setScore, (_, score) => score)
  .reset(resetGame)

export const $singleGameResult = domain
  .createStore<SingleGameResult | null>(null)
  .on(finishSingleGameFx.doneData, (_, { data }) => data)
  .reset(resetGame)

export const $endReason = domain
  .createStore<END_REASON | null>(null)
  .on(setEndReason, (_, reason) => reason)
  .reset(resetGame)

export const $gameStatus = domain
  .createStore<GAME_STATUS>(GAME_STATUS.START)
  .on(changeGameStatus, (_, status) => status)
  .reset(resetGame)

export const $gameSnakeProfile = domain
  .createStore<Profile | null>(null)
  .on(setGameRecord, (store, record) => (store ? { ...store, record } : null))
  .on(changeGameSnakeCount, (store, count) =>
    store ? { ...store, gameCount: store.gameCount + count } : null
  )
  .on(
    [getProfileFx.doneData, buyGameFx.doneData, upgradeParamFx.doneData, updateParamFx.doneData],
    (_, { data }) => data
  )

export const $isShowSnackbarShop = domain
  .createStore<boolean>(false)
  .on(buyGameFx.doneData, () => true)
  .on(upgradeParamFx.doneData, () => true)
  .reset(hideSnackbarShop)

export const $isShowColorModal = domain
  .createStore<boolean>(false)
  .on(changeVisibleColorModal, (data, isShow) => isShow)
  .reset(upgradeParamFx.doneData)

export const $snakeColorChange = domain
  .createStore<string | null>(null)
  .on(changeSnakeColor, (data, color) => color)

export const $singleGame = domain
  .createStore<SingleGame | null>(null)
  .on(getSingleGameFx.doneData, (_, data) => mapSingleGameResponse(data))
  .reset(resetGame)

export const $stats = domain
  .createStore<StatsInfo[] | null>(null)
  .on(getStatsFx.doneData, (_, { data }) => data)

export const $gameSnakeSingle = domain
  .createStore<GameSnakeSingle | null>(null)
  .on(setGameSnakeSingle, (_, data) => data)
  .reset(resetGame)

export const $singleGameModalVisible = domain
  .createStore<boolean>(false)
  .on(changeSingleGameModalVisible, (_, data) => data)
  .reset(resetGame)

export const $snackbarSingleGameVisible = domain
  .createStore<boolean>(false)
  .on(setSnackbarSingleGame, (_, data) => data)
  .reset(resetGame)

export const $getProfilePending = getProfileFx.pending
export const $getSingleGamePending = getSingleGameFx.pending
export const $finishSingleGamePending = finishSingleGameFx.pending
export const $buyGamePending = buyGameFx.pending
export const $upgradeParamPending = upgradeParamFx.pending
export const $getStatsPending = getStatsFx.pending
export const $updateParamPending = updateParamFx.pending

export const $getProfileError = domain
  .createStore<string | null>(null)
  .on(getProfileFx.failData, (_, error) => 'Ошибка при загрузке профиля игры')
  .reset([resetGame, getProfileFx.done])

export const $getStatsError = domain
  .createStore<string | null>(null)
  .on(getStatsFx.failData, (_, error) => 'Ошибка при загрузке статистики')
  .reset([resetGame, getStatsFx.done])

export const $getSnakeSingleGameError = domain
  .createStore<string | null>(null)
  .on(getSingleGameFx.failData, (_, error) => 'Ошибка при загрузке игры')
  .reset([resetGame, getSingleGameFx.done])

export const $finishSingleGameError = domain
  .createStore<string | null>(null)
  .on(finishSingleGameFx.failData, (_, error) => 'Ошибка при сохранении результата игры')
  .reset([resetGame, finishSingleGameFx.done])

export const $buyGameError = domain
  .createStore<string | null>(null)
  .on(buyGameFx.failData, (_, error) => 'Ошибка при покупке игры')
  .reset([resetGame, buyGameFx.done])

export const $updateParamError = domain
  .createStore<string | null>(null)
  .on(updateParamFx.failData, (_, error) => 'Ошибка при обновлении параметра')
  .reset([resetGame, updateParamFx.done])

export const $profileCombined = combine({
  profile: $gameSnakeProfile,
  profilePending: $getProfilePending,
  profileError: $getProfileError,
})

export const $updateParamCombined = combine({
  updateParamPending: $updateParamPending,
  updateParamError: $updateParamError,
})
