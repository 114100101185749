import { ChatRoom } from '../../type'
import { domain } from '../domain'
import {
  JoinChatError,
  JoinChatParams,
  JoinChatResponse,
  UpdateChatRoomError,
  UpdateChatRoomParams,
  UpdateChatRoomResponse,
} from './types'

export const changeChatSettingsModal = domain.event<boolean>('changeChatSettingsModal')

export const updateChatRoom = domain.event<UpdateChatRoomParams>('updateChatRoom')
export const updateChatRoomFx = domain.effect<
  UpdateChatRoomParams,
  UpdateChatRoomResponse,
  UpdateChatRoomError
>('updateChatRoomFx')

export const resetSettingsUpdate = domain.event<void>('resetSettingsUpdate')

export const joinChat = domain.event<JoinChatParams>('joinChat')
export const joinChatFx = domain.effect<JoinChatParams, JoinChatResponse, JoinChatError>(
  'joinChatFx'
)

export const redirectToListChat = domain.event<void>('redirectToListChat')

export const updateChatRoomForStore = domain.event<ChatRoom>('updateChatRoomForStore')
