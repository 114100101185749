import { forward, guard, sample } from 'effector'

import { setActiveChatId } from '../chat/events'
import { initiateWebSocketChatConnection } from '../chat/socket'
import { $chatSocketOnline } from '../chat/socket/init'
import { $activeChatId } from '../chat/store'
import * as api from './api'
import {
  deleteMessage,
  deleteMessageFx,
  editMessage,
  editMessageFx,
  formSubmitted,
  getMessagesRoom,
  getMessagesRoomFx,
  initializeSockets,
  resetMenuMessage,
  scrollChatMessages,
  sendMessage,
  sendMessageFx,
  setEditMessage,
  setSendInputFocus,
} from './events'
import { editMessageForm, sendMessageForm } from './forms'
import { initiateWebSocketMessagesConnection } from './socket'
import { $messagesSocketOnline } from './socket/init'
import { $editMessage, $endChatElement, $imageFile, $inputSend } from './store'

// get
getMessagesRoomFx.use(api.getMessagesApi)

guard({
  source: getMessagesRoom,
  filter: getMessagesRoomFx.pending.map((state) => !state),
  target: getMessagesRoomFx,
})

sample({
  clock: setActiveChatId,
  filter: (id) => id !== null,
  fn: (id) => ({ chatId: id }),
  target: getMessagesRoom,
})

scrollChatMessages.watch((options) => {
  setTimeout(() => {
    $endChatElement.getState()?.scrollIntoView(options || undefined)
  }, 0)
})

// send

sendMessageFx.use(api.sendMessageApi)

guard({
  source: sendMessage,
  filter: sendMessageFx.pending.map((state) => !state),
  target: sendMessageFx,
})

formSubmitted.watch((data) => {
  const { text } = sendMessageForm.$values.getState()
  const file = $imageFile.getState()

  if (!text && !file) {
    console.error('Both text and file cannot be empty')
    return
  }

  // Обрабатываем отправку данных формы
  const formData = new FormData()
  if (text) formData.append('text', text)
  if (file) formData.append('file', file)
  const chatId = $activeChatId.getState() || -1

  // Здесь ваша логика отправки формы, например:
  // api.sendMessage(formData);
  sendMessage({ chatId, formData })
})

// Связываем отправку формы с событием
sendMessageForm.formValidated.watch((formIsValid) => {
  if (formIsValid) formSubmitted()
})

setSendInputFocus.watch(() => {
  const inputSend = $inputSend.getState()
  setTimeout(() => {
    if (inputSend) {
      const length = inputSend.value.length
      inputSend.focus()
      inputSend.setSelectionRange(length, length)
    }
  })
})

// delete

deleteMessageFx.use(api.deleteMessageApi)

guard({
  source: deleteMessage,
  filter: deleteMessageFx.pending.map((state) => !state),
  target: deleteMessageFx,
})

forward({
  from: deleteMessageFx.doneData,
  to: [resetMenuMessage, editMessageForm.reset],
})

// edit

editMessageFx.use(api.editMessageApi)

guard({
  source: editMessage,
  filter: editMessageFx.pending.map((state) => !state),
  target: editMessageFx,
})

sample({
  source: editMessageForm.formValidated,
  fn: (data) => ({
    messageId: $editMessage.getState()?.id || -1,
    text: data.text.trim(),
  }),
  target: editMessage,
})

forward({
  from: editMessageFx.doneData,
  to: [resetMenuMessage, editMessageForm.reset],
})

// scrolling chat down
forward({
  from: [setEditMessage, sendMessageFx.done, getMessagesRoomFx.done],
  to: scrollChatMessages.prepend(() => undefined),
})

initializeSockets.watch(() => {
  const isChatOnline = $chatSocketOnline.getState()
  if (!isChatOnline) initiateWebSocketChatConnection()

  const isMessagesOnline = $messagesSocketOnline.getState()
  const chatId = $activeChatId.getState()
  if (!isMessagesOnline && chatId) initiateWebSocketMessagesConnection(chatId)
})
