import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-top: 4px;
    margin-bottom: 16px;
  }
`

export const XpWrapper = styled('div')``

export const WaddlesCoinWrapper = styled('div')`
  max-width: 320px;
  p {
    font-size: 24px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      max-width: 200px;
      font-size: 16px;
    }
  }
`
