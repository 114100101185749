import '../../model/multi-timer/init'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertLoading, AlertSuccess } from '@/shared/components/alert'
import { RouterLink } from '@/shared/components/router-link'
import { Choose, Otherwise, When } from '@/shared/utils/choose'

import { $isShowCreateMulitDialog, $titleNewTimer } from '../../model/dialogs'
import { changeCreateMultiDialog, changeTitleNewTimer } from '../../model/dialogs/events'
import { $createTimerIsPending, $multiTimer } from '../../model/multi-timer'
import { createTimer, resetMultiTimer } from '../../model/multi-timer/events'

export const DialogMultiUser = () => {
  const isOpen = useStore($isShowCreateMulitDialog)
  const titleTimer = useStore($titleNewTimer)
  const createTimerIsPending = useStore($createTimerIsPending)
  const multiTimer = useStore($multiTimer)

  useEffect(() => {
    if (!isOpen) {
      resetMultiTimer()
      changeTitleNewTimer('')
    }
  })

  return (
    <Dialog open={isOpen} onClose={() => changeCreateMultiDialog(false)}>
      <DialogTitle>Создать таймер</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <TextField
          autoFocus
          margin="dense"
          label="Название нового таймера"
          fullWidth
          disabled={!!multiTimer}
          variant="standard"
          value={titleTimer}
          onChange={(e) => changeTitleNewTimer(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Choose>
          <When condition={!multiTimer}>
            <Button onClick={() => changeCreateMultiDialog(false)}>Отменить</Button>
            <Button disabled={!titleTimer} variant="contained" onClick={() => createTimer()}>
              Создать
            </Button>
          </When>
          <When condition={createTimerIsPending}>
            <AlertLoading text="Таймер создаётся" />
          </When>
          <Otherwise>
            <Box sx={{ width: '100%' }}>
              <AlertSuccess text="Таймер успешно создан" TypographyProps={{ variant: 'body1' }} />

              <RouterLink to={`${ROUTE_PATH.TIMER}/${multiTimer?.id}`}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ width: '100%' }}
                  onClick={() => {
                    changeTitleNewTimer('')
                    changeCreateMultiDialog(true)
                  }}
                >
                  К таймеру
                </Button>
              </RouterLink>
            </Box>
          </Otherwise>
        </Choose>
      </DialogActions>
    </Dialog>
  )
}
