import { EmptyParams } from '@/shared/types/api'

import {
  BuyFortuneError,
  BuyFortuneParams,
  BuyFortuneResponse,
  GetFortuneError,
  GetFortuneResponse,
  GetFortuneStatsParams,
  GetFortuneStatsResponse,
  ModalMode,
  ProductCode,
  SaveFortuneError,
  SaveFortuneParams,
  SaveFortuneResponse,
  StartFortuneResponse,
} from '../../types'
import { domain } from '../domain'

export const getFortune = domain.event<EmptyParams>('getFortune')
export const getFortuneFx = domain.effect<EmptyParams, GetFortuneResponse, GetFortuneError>(
  'getFortuneFx'
)

export const updateFortune = domain.event<EmptyParams>('updateFortune')
export const updateFortuneFx = domain.effect<EmptyParams, GetFortuneResponse, GetFortuneError>(
  'updateFortuneFx'
)

export const expiredFortune = domain.event<EmptyParams>('expiredFortune')
export const expiredFortuneFx = domain.effect<EmptyParams, GetFortuneResponse, GetFortuneError>(
  'expiredFortuneFx'
)

export const startFortune = domain.event<EmptyParams>('startFortune')
export const startFortuneFx = domain.effect<EmptyParams, StartFortuneResponse, GetFortuneError>(
  'startFortuneFx'
)

export const saveFortune = domain.event<SaveFortuneParams>('saveFortune')
export const saveFortuneFx = domain.effect<
  SaveFortuneParams,
  SaveFortuneResponse,
  SaveFortuneError
>('saveFortuneFx')

export const buyFortune = domain.event<BuyFortuneParams>('buyFortune')
export const buyFortuneFx = domain.effect<BuyFortuneParams, BuyFortuneResponse, BuyFortuneError>(
  'buyFortuneFx'
)

export const getFortuneStats = domain.event<BuyFortuneParams>('getFotuneStats')
export const getFortuneStatsFx = domain.effect<
  GetFortuneStatsParams,
  GetFortuneStatsResponse,
  GetFortuneStatsParams
>('getFotuneStatsFx')

export const reloadFortune = domain.event<EmptyParams>('reloadFortune')

export const changeFortuneCount = domain.event<number>('changeFortuneCount')

export const changeModalFortuneVisible = domain.event<boolean>('changeModalFortuneVisible')

export const setActivePrizeCount = domain.event<number | null>('setActivePrizeCount')

export const changeModalMode = domain.event<ModalMode>('changeModalMode')

export const changeFortuneStatsTab = domain.event<ProductCode>('changeFortuneStatsTab')
