// ./model/store.ts
import { createStore } from 'effector'

import { decryptMessageFx, encryptMessageFx } from './effects'
import { resetDecryptedData, resetEncryptedData } from './events'

// Store for encrypted data
export const $encryptedData = createStore<{
  encryptedMessage: string
  qrCode: string
} | null>(null)
  .on(encryptMessageFx.doneData, (_, response) => ({
    encryptedMessage: response.data.encryptedMessage,
    qrCode: response.data.qrCode,
  }))
  .reset(encryptMessageFx.fail)
  .reset(resetEncryptedData) // Reset data on event

export const $encryptPending = encryptMessageFx.pending

encryptMessageFx.failData.watch((error) => {
  console.error('Error encrypting message:', error)
})

// Store for decrypted data
export const $decryptedData = createStore<string | null>(null)
  .on(decryptMessageFx.doneData, (_, response) => response.data.decryptedMessage)
  .reset(decryptMessageFx.fail)
  .reset(resetDecryptedData) // Reset data on event

export const $decryptPending = decryptMessageFx.pending

decryptMessageFx.failData.watch((error) => {
  console.error('Error decrypting message:', error)
})

// New stores for error messages
export const $encryptError = createStore<string | null>(null)
  .on(encryptMessageFx.failData, (_, error) => {
    console.error('Error from API:', error)
    return 'Произошла ошибка при шифровании. Пожалуйста, проверьте данные и попробуйте снова.'
  })
  .reset(encryptMessageFx.done)

export const $decryptError = createStore<string | null>(null)
  .on(decryptMessageFx.failData, (_, error) => {
    console.error('Error from API:', error)
    return 'Произошла ошибка при дешифровании. Пожалуйста, проверьте данные и попробуйте снова.'
  })
  .reset(decryptMessageFx.done)
