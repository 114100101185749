import { fortuneSrc, rubleSrc, snakeSrc, waddlesCoinSrc } from '@/shared/assets'

import { PackPrize } from './model/shop/types'

export const prizeItems = {
  waddlecoin: { src: waddlesCoinSrc },
  snake: { src: snakeSrc },
  ruble: { src: rubleSrc },
  fortune: { src: fortuneSrc },
}
export const getCountInfo = (packPrize: PackPrize) => {
  return {
    image: prizeItems[packPrize.type].src,
    numbers: packPrize.value,
  }
}

export function countLeadingZeros<T>(array: T[]): number {
  let count = 0
  for (let i = 0; i < array.length; i++) {
    if (array[i] === '0') {
      count++
    } else {
      break
    }
  }
  return count
}
