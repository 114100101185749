import {
  Dialog as DialogUi,
  DialogContent as DialogContentUi,
  DialogTitle as DialogTitleUi,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

import { FORTUNE_SIZE } from '../modal-fortune-body/consts'

export const Dialog = styled(DialogUi)`
  min-width: 260px;
  [role='dialog'] {
    margin: 0;
    max-width: 580px;
  }
`

export const BoxCenter = styled(Box)`
  height: ${FORTUNE_SIZE}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DialogTitle = styled(DialogTitleUi)`
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  font-weight: bold;
  margin-bottom: 16px;
`

export const DialogContent = styled(DialogContentUi)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 500px;
  min-height: 400px;
  max-height: 500px;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    min-width: 372px;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${({ theme }) => theme.palette.grey[500]};
`
