import { api } from '@/services/api'

import {
  CreateTimerParams,
  DeleteTimerResponse,
  DeleteUserParams,
  StartTimerParams,
  UpdateParticipantsParams,
  UpdateRoundParams,
  UpdateStatusParams,
} from './model/multi-timer/types'
import { TimerPaginatorResponse, TimerResponse } from './types'

export const getTimersApi = (): Promise<TimerPaginatorResponse> => {
  return api.get('/timer/')
}

export const getTimerApi = (id: number): Promise<TimerResponse> => {
  return api.get(`/timer/${id}/get_with_updated_time/`)
}

export const createTimerApi = (params: CreateTimerParams): Promise<TimerResponse> => {
  return api.post('/timer/new/', params)
}

export const deleteTimerApi = (id: number): Promise<DeleteTimerResponse> => {
  return api.delete(`/timer/${id}/`)
}

export const addUserApi = (id: number): Promise<TimerResponse> => {
  return api.post(`/timer/${id}/add_user/`)
}

export const deleteUserApi = ({ timerId, userId }: DeleteUserParams): Promise<TimerResponse> => {
  return api.post(`/timer/${timerId}/delete_user/`, { user_id: userId })
}

export const startTimerApi = ({ id, roundSeconds }: StartTimerParams): Promise<TimerResponse> => {
  return api.put(`/timer/${id}/start/`, { roundSeconds: roundSeconds })
}

export const updateRoundApi = ({ id, roundSeconds }: UpdateRoundParams): Promise<TimerResponse> => {
  return api.put(`/timer/${id}/update_round/`, { roundSeconds: roundSeconds })
}

export const updateStatusApi = ({
  id,
  status,
  activeUserId,
}: UpdateStatusParams): Promise<TimerResponse> => {
  return api.put(`/timer/${id}/update_status/`, {
    activeUser_id: activeUserId,
    status,
  })
}

export const updateParticipantsApi = ({
  participants,
  id,
}: UpdateParticipantsParams): Promise<TimerResponse> => {
  return api.put(`/timer/${id}/update_participants/`, {
    participants,
  })
}
