import { Box, Link, ListItem, ListItemButton, ListItemText, Menu } from '@mui/material'
import { grey } from '@mui/material/colors'

import { logout } from '@/features/auth/models/user/events'
import { getAppPath } from '@/shared/utils/getPath'

import { setAnchorDesktopMenu } from '../../model/xp/events'

interface Props {
  anchor: HTMLElement | null
}

export const DesktopMenu = ({ anchor }: Props) => {
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={() => setAnchorDesktopMenu(null)}
    >
      <Link
        underline="none"
        href={getAppPath('/user/profile')}
        sx={{ color: grey[900] }}
        onClick={() => setAnchorDesktopMenu(null)}
      >
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Профиль'} />
          </ListItemButton>
        </ListItem>
      </Link>
      <Box
        color={grey[900]}
        onClick={() => {
          setAnchorDesktopMenu(null)
          logout()
        }}
      >
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Выйти'} />
          </ListItemButton>
        </ListItem>
      </Box>
    </Menu>
  )
}
