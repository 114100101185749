import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  count: number
}

export const Timer = ({ count = 5 }: Props): JSX.Element | null => {
  const [time, setTime] = useState<number>(count)

  useEffect(() => {
    setTimeout(() => {
      if (time === 0) {
        return
      }
      setTime(time - 1)
    }, 1000)
  }, [time])

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h3">{time}</Typography>
    </Box>
  )
}
