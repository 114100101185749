import 'moment/locale/ru'

import { Box, CircularProgress, Typography } from '@mui/material'
import {
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useStore } from 'effector-react'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths' // Замените на ваш путь

import { Media } from '../../../../shared/utils/media'
import { $calendarData, $calendarLoading } from '../../model'
import { getCalendarData } from '../../model/events'
import { getBackgroundColor } from './utils'

interface CalendarDay {
  day: number
  filled: boolean
  id: number | null
}

// Устанавливаем локаль для moment
moment.locale('ru')

export const DailyNotesCalendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<moment.Moment | null>(moment())
  const calendarData = useStore($calendarData)
  const loading = useStore($calendarLoading)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentDate) {
      const month = currentDate.month() + 1 // месяцы в moment начинаются с 0
      const year = currentDate.year()
      getCalendarData({ month, year })
    }
  }, [currentDate])

  const handleMonthChange = (newDate: Moment | null) => {
    if (newDate) {
      setCurrentDate(newDate) // Обновляем текущую дату
    }
  }

  const handleDayClick = (day: Moment) => {
    const dayData = calendarData?.days.find((d: CalendarDay) => d.day === day.date())

    if (dayData?.filled && dayData.id) {
      navigate(`${ROUTE_PATH.DAILY_NOTES_EDIT_WITH_ID.replace(':id', dayData.id.toString())}`)
    } else {
      navigate(ROUTE_PATH.DAILY_NOTES_NEW, { state: { selectedDate: day.format('YYYY-MM-DD') } })
    }
  }

  const renderCustomDay = (day: Moment, DayComponentProps: PickersDayProps<Moment>) => {
    const isFilled =
      calendarData?.days.find((d: CalendarDay) => d.day === day.date())?.filled || false
    const isFuture = day.isAfter(moment(), 'day')
    const isToday = day.isSame(moment(), 'day')
    const isPast = day.isBefore(moment(), 'day')

    const handleClick = () => {
      if (isPast || isToday) {
        handleDayClick(day)
      }
    }

    const bgColor = getBackgroundColor({ isFuture, isToday, isFilled, loading })

    return (
      <PickersDay
        {...DayComponentProps}
        day={day}
        onClick={handleClick}
        disabled={isFuture}
        sx={{
          borderRadius: '50%',
          color: isFuture ? 'gray' : 'white',
          backgroundColor: bgColor,
          pointerEvents: isFuture ? 'none' : 'auto',
          cursor: isFuture ? 'default' : 'pointer',
          '&:hover': {
            opacity: isFuture ? 1 : 0.5,
            backgroundColor: bgColor,
          },
          '&.Mui-selected': {
            backgroundColor: bgColor,
          },
        }}
      >
        {loading ? (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : (
          day.date()
        )}
      </PickersDay>
    )
  }

  return (
    <Box sx={{ mt: 1, maxWidth: 350, width: '100%', textAlign: 'center' }}>
      <Media desktop tablet>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Календарь
        </Typography>
      </Media>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDatePicker<Moment>
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={currentDate}
          onChange={(date) => setCurrentDate(date)}
          onMonthChange={handleMonthChange}
          slots={{
            day: (props) => renderCustomDay(props.day, props),
          }}
          slotProps={{
            layout: {
              sx: {
                background: 'transparent',
              },
            },
            day: {
              sx: {
                backgroundColor: 'inherit',
                '&.Mui-selected': {
                  backgroundColor: 'inherit',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}
