import { forward, guard } from 'effector'

import {
  createRatingApi,
  createSessionApi,
  deleteSessionApi,
  getRatingsApi,
  getSessionsApi,
  updateSessionApi,
} from './api'
import {
  createRating,
  createRatingFx,
  createSession,
  createSessionFx,
  deleteSession,
  deleteSessionFx,
  getRatings,
  getRatingsFx,
  getSessions,
  getSessionsFx,
  updateSession,
  updateSessionFx,
} from './events'

getSessionsFx.use(getSessionsApi)
createSessionFx.use(createSessionApi)
createRatingFx.use(createRatingApi)

guard({
  source: getSessions,
  filter: getSessionsFx.pending.map((state) => !state),
  target: getSessionsFx,
})

forward({
  from: createSession,
  to: createSessionFx,
})

forward({
  from: createSessionFx.done,
  to: getSessions,
})

updateSessionFx.use(updateSessionApi)
deleteSessionFx.use(deleteSessionApi)

forward({
  from: updateSession,
  to: updateSessionFx,
})

forward({
  from: deleteSession,
  to: deleteSessionFx,
})

forward({
  from: updateSessionFx.done,
  to: getSessions,
})

forward({
  from: deleteSessionFx.done,
  to: getSessions,
})

getRatingsFx.use(getRatingsApi)

forward({
  from: createRating,
  to: createRatingFx,
})

forward({
  from: getRatings,
  to: getRatingsFx,
})
