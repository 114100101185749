import { Box, Typography } from '@mui/material'

import { ItemDataType } from '../../../../model/admin/types'
import { FieldItem } from '../field-item'
import * as Styled from './styled'

type Props = {
  items: Array<ItemDataType>
  title: string
}

export const ListItems = ({ items, title }: Props) => {
  return (
    <Styled.Root>
      <Typography sx={{ mr: 1 }} variant="body2">
        {title}
      </Typography>
      {items.map((item, index) => (
        <Box key={index} sx={{ width: 30, height: 30 }}>
          <FieldItem fieldItem={item} />
        </Box>
      ))}
    </Styled.Root>
  )
}
