import { useEffect } from 'react'

import { EnGameBatte, EnGameMain } from '@/features/engame'
import { resetProfile } from '@/features/engame/model/game/events'
import { Choose, When } from '@/shared/utils/choose'

import { Template } from '../templates/template'
import { EnGamePageType } from './types'

type Props = {
  type: EnGamePageType
}

export const EnGamePage = ({ type }: Props) => {
  useEffect(() => {
    return () => {
      resetProfile()
    }
  }, [])
  return (
    <Template hasPadding={false}>
      <Choose>
        <When condition={type === EnGamePageType.MAIN}>
          <EnGameMain />
        </When>
        <When condition={type === EnGamePageType.BATTLE}>
          <EnGameBatte />
        </When>
      </Choose>
    </Template>
  )
}
