import { guard } from 'effector'

import * as api from './api'
import { hasUnreadMessage, hasUnreadMessageFx } from './events'

hasUnreadMessageFx.use(api.getHasUnreadMessageApi)

guard({
  source: hasUnreadMessage,
  filter: hasUnreadMessageFx.pending.map((state) => !state),
  target: hasUnreadMessageFx,
})
