import { AppBar, Avatar, Box, Container, Toolbar, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'
import { Media, useMedia } from '@/shared/utils/media'

import { $user } from '../../../auth'
import { $headerColor, $logoUrl } from '../../../main/model/theme'
import { $anchorDesktopMenu, $openDrawerMenu } from '../../model/xp'
import { changeDrawerMenu, setAnchorDesktopMenu } from '../../model/xp/events'
import { DesktopMenu } from '../desktop-menu'
import { MobileMenu } from '../mobile-menu'
import { PcMenu } from '../pc-menu'
import * as Styled from './styled'
import { getNavItems } from './utils'

interface Props {}

export const Header = (props: Props) => {
  const user = useStore($user)
  const menuIsOpen = useStore($openDrawerMenu)
  const headerColor = useStore($headerColor)
  const logoUrl = useStore($logoUrl)
  const anchorDesktopMenu = useStore($anchorDesktopMenu)
  const { isMobile } = useMedia()

  if (!user) return null

  const navItems = getNavItems()

  return (
    <Styled.Header>
      <AppBar component="nav" sx={{ background: headerColor }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ width: '100%', justifyContent: 'space-between' }}>
            <RouterLink to="/">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={1} width={32}>
                  <img src={logoUrl} alt="Avodom" width="100%" />
                </Box>
                <Typography fontSize={24} component="div" color="white">
                  AvoDom
                </Typography>
              </Box>
            </RouterLink>
            <Box display={'flex'}>
              <PcMenu navItems={navItems} />
              <Avatar
                sx={{ cursor: 'pointer', mr: 1 }}
                alt={user.username}
                src={getAppPath(user.avatar)}
                onClick={(event) =>
                  isMobile ? changeDrawerMenu() : setAnchorDesktopMenu(event.currentTarget)
                }
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Media mobile>
        <MobileMenu isOpen={menuIsOpen} navItems={navItems} username={user.username || ''} />
      </Media>
      <Media desktop>
        <DesktopMenu anchor={anchorDesktopMenu} />
      </Media>
    </Styled.Header>
  )
}
