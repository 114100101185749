import { ROUTE_PATH } from '@/packages/paths'
import { getAppPath } from '@/shared/utils/getPath'

import { NavItem } from '../../types'

export const getNavItems = (): NavItem[] => {
  return [
    { name: 'Заметки', link: ROUTE_PATH.NOTES },
    { name: 'Таймер', link: ROUTE_PATH.TIMER },
    { name: 'Приложения', link: ROUTE_PATH.APPS },
    { name: 'Рандомайзер', link: getAppPath('/random') },
  ]
}
