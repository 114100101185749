import { Box, Button, Card, CardActions, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useGate, useStore } from 'effector-react'
import { motion } from 'framer-motion'
import { useState } from 'react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { getUser } from '../../../auth/models/user/events'
import { ShopPackGate } from '../../model/gate'
import { $activeCard, $packPrize } from '../../model/shop'
import { clearActiveCard } from '../../model/shop/events'
import { countLeadingZeros, getCountInfo } from '../../utils'
import { animCard } from './animate'
import * as Styled from './styled'

export const ShopPack = () => {
  const activePack = useStore($activeCard)
  const packPrize = useStore($packPrize)
  const [isAwardsReceived, setIsAwardsReceived] = useState<boolean>(false)

  useGate(ShopPackGate)

  if (!activePack || !packPrize)
    return (
      <Styled.Root sx={{ my: 4 }}>
        <RouterLink to={ROUTE_PATH.SHOP} onClick={() => clearActiveCard()}>
          <Button variant="contained" color="secondary" sx={{ width: 300 }}>
            На главную
          </Button>
        </RouterLink>
      </Styled.Root>
    )

  const infoPack = getCountInfo(packPrize)
  const numbersLength = infoPack.numbers.length

  const num1 = numbersLength >= 4 ? infoPack.numbers[3] : 0
  const num2 = numbersLength >= 3 ? infoPack.numbers[2] : 0
  const num3 = numbersLength >= 2 ? infoPack.numbers[1] : 0
  const num4 = numbersLength >= 1 ? infoPack.numbers[0] : 0
  const zeroesCount = countLeadingZeros(infoPack.numbers)
  const buttonDelay = 10 + (numbersLength - zeroesCount) * 5 + 2

  return (
    <Styled.Root>
      <Typography sx={{ fontSize: 28, fontWeight: 700, mb: 2 }}>{activePack.name}</Typography>
      <motion.div {...animCard.container} initial="start" animate="end" style={{ width: '100%' }}>
        <Card sx={{ width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', ...activePack }}>
          <Styled.CardConent sx={{ width: '100%' }}>
            <motion.span {...animCard.imagePrizeAnim} initial="start" animate="end">
              <Styled.ImagePrizeWrapper>
                <Box
                  component="img"
                  src={infoPack.image}
                  alt="Приз"
                  sx={{ width: 140, height: 140 }}
                />
              </Styled.ImagePrizeWrapper>
            </motion.span>
            <Styled.MultipliersWrapper
              {...animCard.prizeAnim}
              transition={{
                ...animCard.prizeAnim,
                delay: buttonDelay,
              }}
              initial="start"
              animate="end"
            >
              <If condition={zeroesCount < 1}>
                <motion.span {...animCard.anim4} initial="start" animate="end">
                  <Styled.MultiplierWrapper>
                    <Typography sx={{ fontSize: 48, color: grey[50] }} component="div">
                      {num4}
                    </Typography>
                  </Styled.MultiplierWrapper>
                </motion.span>
              </If>
              <If condition={zeroesCount < 2}>
                <motion.span {...animCard.anim3} initial="start" animate="end">
                  <Styled.MultiplierWrapper>
                    <Typography sx={{ fontSize: 48, color: grey[50] }} component="div">
                      {num3}
                    </Typography>
                  </Styled.MultiplierWrapper>
                </motion.span>
              </If>
              <If condition={zeroesCount < 3}>
                <motion.span {...animCard.anim2} initial="start" animate="end">
                  <Styled.MultiplierWrapper>
                    <Typography sx={{ fontSize: 48, color: grey[50] }} component="div">
                      {num2}
                    </Typography>
                  </Styled.MultiplierWrapper>
                </motion.span>
              </If>
              <If condition={num1 !== null}>
                <motion.span {...animCard.anim1} initial="start" animate="end">
                  <Styled.MultiplierWrapper>
                    <Typography sx={{ fontSize: 48, color: grey[50] }} component="div">
                      {num1}
                    </Typography>
                  </Styled.MultiplierWrapper>
                </motion.span>
              </If>
            </Styled.MultipliersWrapper>
          </Styled.CardConent>
          <Box sx={{ heigth: 2 }}>
            <motion.span
              {...animCard.buttonAnim}
              transition={{
                ...animCard.anim4,
                delay: buttonDelay,
              }}
            >
              <Box component="hr" sx={{ mx: 1 }} />
            </motion.span>
          </Box>
          <CardActions sx={{ display: 'flex', justifyContent: 'center', height: 48, mb: 1 }}>
            <motion.span
              {...animCard.buttonAnim}
              transition={{
                ...animCard.anim4,
                delay: buttonDelay,
              }}
            >
              <Choose>
                <When condition={isAwardsReceived}>
                  <RouterLink to={ROUTE_PATH.SHOP} onClick={() => clearActiveCard()}>
                    <Button size="small">
                      <Typography color={activePack.textColor}>Награда получена</Typography>
                    </Button>
                  </RouterLink>
                </When>
                <Otherwise>
                  <Button
                    variant="contained"
                    color={activePack.buttonColor}
                    size="small"
                    onClick={() => {
                      setIsAwardsReceived(true)
                      if (packPrize.type === 'ruble') {
                        getUser()
                      }
                    }}
                  >
                    <Typography>Забрать награду</Typography>
                  </Button>
                </Otherwise>
              </Choose>
            </motion.span>
          </CardActions>
        </Card>
      </motion.div>
    </Styled.Root>
  )
}
