import '../../model/xp/init'

import { Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { ProgressBar } from '@/shared/ui/progress-bar'
import { If } from '@/shared/utils/if'

import { $userXp, $userXpError } from '../../model/xp'
import * as Styled from './styled'

export const TopXpMain = () => {
  const userXp = useStore($userXp)
  const userXpError = useStore($userXpError)

  if (!userXp) {
    return (
      <Styled.EmptyRoot>
        <If condition={userXpError}>
          <Typography variant="body2" color="error.light">
            {userXpError}
          </Typography>
        </If>
        <If condition={!userXpError}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </If>
      </Styled.EmptyRoot>
    )
  }

  return (
    <Styled.Root>
      <ProgressBar
        progress={userXp.progress}
        text={userXp.name}
        value={userXp.xp}
        minValue={userXp.minXp}
        maxValue={userXp.maxXp}
      />
    </Styled.Root>
  )
}
