// reports/model/init.ts

import { forward, guard } from 'effector'

import {
  createReportApi,
  deleteReportApi,
  getCalendarDataApi,
  getHappinessLevelApi,
  getLastDaysReportApi,
  getReportByIdApi,
  getReportsApi,
  updateReportApi,
} from './api'
import {
  createReport,
  createReportFx,
  deleteReport,
  deleteReportFx,
  getCalendarData,
  getCalendarDataFx,
  getHappinessLevel,
  getHappinessLevelFx,
  getLastDaysReport,
  getLastDaysReportFx,
  getReportById,
  getReportByIdFx,
  getReports,
  getReportsFx,
  updateReport,
  updateReportFx,
} from './events'

// get Reports
getReportsFx.use(getReportsApi)

guard({
  source: getReports,
  filter: getReportsFx.pending.map((state) => !state),
  target: getReportsFx,
})

// create reports
createReportFx.use(createReportApi)
forward({
  from: createReport,
  to: createReportFx,
})

forward({
  from: createReportFx.done,
  to: getReports,
})

// update reports
updateReportFx.use(updateReportApi)

forward({
  from: updateReport,
  to: updateReportFx,
})

forward({
  from: updateReportFx.done,
  to: getReports,
})

// delete reports
deleteReportFx.use(deleteReportApi)

forward({
  from: deleteReport,
  to: deleteReportFx,
})

forward({
  from: deleteReportFx.done,
  to: getReports,
})

// getHappinessLevelFx

getHappinessLevelFx.use(getHappinessLevelApi)

forward({
  from: getHappinessLevel,
  to: getHappinessLevelFx,
})

getCalendarDataFx.use(getCalendarDataApi)

forward({
  from: getCalendarData,
  to: getCalendarDataFx,
})

// get report by id

getReportByIdFx.use(getReportByIdApi)

forward({
  from: getReportById,
  to: getReportByIdFx,
})

// get last reports
getLastDaysReportFx.use(getLastDaysReportApi)

forward({
  from: getLastDaysReport,
  to: getLastDaysReportFx,
})
