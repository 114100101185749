import { api } from '@/services/api'

import { GetUserResponse, SignInParams, SignInResponse } from './types'

export const signInApi = async (params: SignInParams): Promise<SignInResponse> => {
  return api.post('/auth/token/login/', params)
}

export const getUserApi = async (): Promise<GetUserResponse> => {
  return api.get('/user/get_user/')
}
