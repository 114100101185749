import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { PhotoDay, PhotoDayItem, PhotoDayTabName } from '../../types'
import { domain } from '../domain'
import { getTextByPhotoDayKey } from '../utils'
import {
  changeActiveTab,
  changeModalPhotoDayVisible,
  getPhotoDayFx,
  setLoadedImage,
} from './events'

export const initPhotoDay = Storage.get<PhotoDay>(STORAGE_NAMES.PHOTO_DAY)

// Фото дня
export const $photoDay = domain
  .createStore<PhotoDay | null>(initPhotoDay)
  .on(getPhotoDayFx.doneData, (_, { data }) => data)

// Ошибка при загрузке фото дня
export const $photoDayError = domain
  .createStore<string | null>(null)
  .on(getPhotoDayFx.failData, (_, error) => 'Ошибка при загрузке фото дня')

// Подгрузка информации о фото дня
export const $getPhotoDayPending = getPhotoDayFx.pending

// Отображение модального окна
export const $modalVisible = domain
  .createStore<boolean>(false)
  .on(changeModalPhotoDayVisible, (_, isVisible) => isVisible)

// Информация об активном табе
export const $activeTab = domain
  .createStore<PhotoDayTabName>(PhotoDayTabName.WEEK)
  .on(changeActiveTab, (_, activeTab) => activeTab)

// Проверка что есть хотя бы одно фото дня за периодны
export const $noPhotoDay = $photoDay.map((data) => {
  if (!data) return false

  return Object.values(data).filter((item) => item).length === 0
})

// Список фото для для отрисовки табов
export const $photoDayList = $photoDay.map<PhotoDayItem[]>((data) => {
  if (!data) return []
  return Object.entries(data).map(([key, item]) => ({
    key,
    url: item?.photo || '',
    text: getTextByPhotoDayKey(key),
    about: item?.about || '',
  }))
})

// Отрфильтрованный список фото дня, что бы отрисовать табы с информацией
export const $filteredPhotoDayList = $photoDayList.map((data) => data.filter((item) => item.url))

// Информация о полной загрузке картинок
export const $loadedImages = domain
  .createStore<{ [key: string]: boolean }>({})
  .on(setLoadedImage, (images, key) => ({ ...images, [key]: true }))
