import { round } from 'lodash'

import { NumberItem } from '../../types'

export const getChanceSuccess = (numbers: NumberItem[]) => {
  const availableNumbers = numbers.filter((item) => !item.hideNumber)
  const successCount = availableNumbers.filter((item) => item.isWin).length
  return round((successCount / availableNumbers.length) * 100, 1)
}

export const getSpeedByItems = (count: number): number => {
  if (count >= 50) return 2000
  else if (count >= 30) return 6000
  else if (count >= 20) return 10000
  else if (count >= 10) return 15000
  else if (count >= 4) return 20000
  else if (count === 3) return 23000
  else if (count === 2) return 26000
  else if (count === 1) return 30000
  return 35000
}

const minVolume = 20
export const getVolumeByChance = (chance: number | null) => {
  if (chance === 0) return 5
  return Math.round(chance && chance > minVolume ? chance : minVolume)
}

export const getWinCount = (numbers: NumberItem[]) => {
  return numbers.filter((item) => item.isWin).length
}

export const getRemovedCount = (numbers: NumberItem[]) => {
  const count = numbers.length
  const winCount = getWinCount(numbers)
  if (winCount === 0) return count - 1

  if (count <= 5) return 1

  return Math.floor(count / 3)
}
