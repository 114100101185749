import { domain } from './domain'
import {
  CreateRatingError,
  CreateRatingResponse,
  CreateSessionError,
  CreateSessionResponse,
  GetRatingsError,
  GetRatingsResponse,
  GetSessionsError,
  GetSessionsResponse,
  Session,
} from './types'

export const getSessions = domain.event<void>('getSessions')
export const getSessionsFx = domain.effect<void, GetSessionsResponse, GetSessionsError>(
  'getSessionsFx'
)

export const createSession = domain.event<any>('createSession')
export const createSessionFx = domain.effect<any, CreateSessionResponse, CreateSessionError>(
  'createSessionFx'
)

export const updateSession = domain.event<{ id: number; data: any }>('updateSession')
export const updateSessionFx = domain.effect<{ id: number; data: any }, Session, Error>(
  'updateSessionFx'
)

export const deleteSession = domain.event<number>('deleteSession')
export const deleteSessionFx = domain.effect<number, void, Error>('deleteSessionFx')

export const getRatings = domain.event<void>('getRatings')
export const getRatingsFx = domain.effect<void, GetRatingsResponse, GetRatingsError>('getRatingsFx')

export const createRating = domain.event<any>('createRating')
export const createRatingFx = domain.effect<any, CreateRatingResponse, CreateRatingError>(
  'createRatingFx'
)
