import { LinearProgress, LinearProgressProps, Typography } from '@mui/material'

import { If } from '@/shared/utils/if'

import * as Styled from './styled'

type LinearProgressWithLabelProps = LinearProgressProps & {
  text: string
  progress: number
  minValue?: number
  maxValue?: number
}

export const ProgressBar = (props: LinearProgressWithLabelProps) => {
  const { minValue, maxValue, progress, text, value, ...otherProps } = props
  return (
    <Styled.Root>
      <Styled.CurrentValue>
        <Typography fontSize={12}>{text}</Typography>
      </Styled.CurrentValue>
      <Styled.LinearWrapper>
        <LinearProgress color="primary" variant="determinate" value={progress} {...otherProps} />
        <Typography variant="body1">{value}</Typography>
      </Styled.LinearWrapper>
      <If condition={Boolean(minValue) && Boolean(maxValue)}>
        <Styled.MinMaxWrapper>
          <Typography variant="body2">{minValue}</Typography>
          <Typography variant="body2">{maxValue}</Typography>
        </Styled.MinMaxWrapper>
      </If>
    </Styled.Root>
  )
}
