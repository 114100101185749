import { keyframes } from '@emotion/react'
import { styled } from '@mui/material/styles'

const hideSnake = keyframes`
  0% {
    transform: translateX(90px);
  }
  100% {
    transform: translateX(-110px) translateY(-36px) rotate(18deg);
  }
`

const ANIMATION_TIME = 300
export const SnakeWrapper = styled('div')`
  animation: ${hideSnake} ${ANIMATION_TIME}s linear forwards;
`
