export enum GameStatus {
  SETTINGS = 'SETTINGS',
  PROCESS = 'PROCESS',
  FINISH = 'FINISH',
}

export enum TimerStatus {
  NONE = 'NONE',
  SETTINGS = 'SETTINGS',
  READY = 'READY',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
  RUN = 'RUN',
  RUNNING = 'RUNNING',
  WAIT = 'WAIT',
}
