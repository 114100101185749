import { Box, Button, SxProps } from '@mui/material'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'

import { resetGame } from '../../model/game-snake/events'

type Props = {
  onClick?: () => void
  sx?: SxProps
}

export const HomeButton = ({ onClick, sx }: Props) => {
  const onHandleClick = () => {
    resetGame()
    onClick && onClick()
  }
  return (
    <Box sx={sx}>
      <RouterLink to={ROUTE_PATH.GAME_SNAKE} onClick={onHandleClick}>
        <Button color="secondary" sx={{ width: 300 }}>
          На главную
        </Button>
      </RouterLink>
    </Box>
  )
}
