import { guard, sample } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import * as api from '../../api'
import { PhotoDayTabName } from '../../types'
import { $photoDay } from '.'
import { changeActiveTab, getPhotoDay, getPhotoDayFx } from './events'

getPhotoDayFx.use(api.getPhotoDayApi)

guard({
  source: getPhotoDay,
  filter: getPhotoDayFx.pending.map((state) => !state),
  target: getPhotoDayFx,
})

sample({
  clock: $photoDay,
  fn: (data) => {
    if (!data) return PhotoDayTabName.WEEK
    return data.week
      ? PhotoDayTabName.WEEK
      : data.month
      ? PhotoDayTabName.MONTH
      : data.quarter
      ? PhotoDayTabName.QUARTER
      : data.year
      ? PhotoDayTabName.YEAR
      : data.year2
      ? PhotoDayTabName.YEAR2
      : PhotoDayTabName.WEEK
  },
  target: changeActiveTab,
})

getPhotoDayFx.doneData.watch(({ data }) => {
  Storage.set(STORAGE_NAMES.PHOTO_DAY, data)
})
