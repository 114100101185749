import { createGate } from 'effector-react'

import { getProfile, getStats, setGameSnakeSingle } from './model/game-snake/events'
import { Profile, SingleGame } from './types'
import { GameSnakeSingle } from './ui/single-game/data'
import { preventArrows } from './utils'

export type GameSnakeGateArgs = {
  canvas?: React.MutableRefObject<HTMLCanvasElement | null>
  gameSnake: GameSnakeSingle | null
  singleGame?: SingleGame | null
  profile: Profile | null
}

export const SingleGameSnakeGate = createGate<GameSnakeGateArgs>('SingleGameSnakeGate')
export const StatsGate = createGate('StatsGate')
export const MainGameSnakeGate = createGate('MainGameSnakeGate')

export let keyHandler: (e: KeyboardEvent) => void

SingleGameSnakeGate.state.watch(({ gameSnake, singleGame, canvas, profile }) => {
  if (canvas && canvas.current && !gameSnake) {
    const ctx = canvas.current.getContext(`2d`)
    if (ctx && singleGame && profile) {
      const initSnakeGame = new GameSnakeSingle(ctx, singleGame.params, profile)
      setGameSnakeSingle(initSnakeGame)
      keyHandler = (event: KeyboardEvent) => preventArrows(event, initSnakeGame, false)
      document.addEventListener('keydown', keyHandler, false)
    }
  }
  if (singleGame && gameSnake) {
    gameSnake.drawNav()
    gameSnake.setFood(singleGame.prizes)
    gameSnake.setObstacle(singleGame.obstacles)

    // deviceMotionHandler = (e: DeviceMotionEvent) => changeSnakeSideByMotion(e, gameSnake)
    // Акселерометр, временно отключен, возможно будет сделан как отдельная платная фича
    // window.addEventListener('devicemotion', deviceMotionHandler, true)
  }
})

MainGameSnakeGate.open.watch(() => {
  getProfile()
})

StatsGate.open.watch(() => {
  getStats()
})

SingleGameSnakeGate.open.watch(({ gameSnake, canvas, singleGame }) => {
  getProfile()

  if (canvas && canvas.current && gameSnake) {
    gameSnake.game()
  }
})

SingleGameSnakeGate.close.watch(({ gameSnake }) => {
  gameSnake && gameSnake.pauseGame()
  if (Boolean(keyHandler)) {
    document.removeEventListener('keydown', keyHandler)
  }
  // if (Boolean(deviceMotionHandler)) {
  //   window.removeEventListener('devicemotion', deviceMotionHandler)
  // }
})
