import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NumberItem = styled('span')<{ $isHide: boolean; $isWin: boolean }>`
  width: 28px;
  height: 28px;
  font-size: 16px;
  border-radius: 50px;
  background: ${({ $isWin }) => ($isWin ? 'green' : 'brown')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 2s;
  opacity: ${({ $isHide }) => ($isHide ? 0 : 1)};
`
