import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { Holiday } from '../../types'
import { domain } from '../domain'
import { getHolidayFx } from './events'

export const initHoliday = Storage.get<Holiday>(STORAGE_NAMES.HOLIDAY)

export const $holiday = domain
  .createStore<Holiday | null>(initHoliday)
  .on(getHolidayFx.doneData, (_, { data }) => data)

export const $holidayError = domain
  .createStore<string | null>(null)
  .on(getHolidayFx.failData, (_, error) => 'Ошибка при загрузке праздника')

export const $getHolidayPending = getHolidayFx.pending
