import { $user } from '../../../auth'
import { TimerStatus } from '../../enums'
import { Timer } from '../../types'
import { domain } from '../domain'
import {
  addUserFx,
  changeIsEditRoundTime,
  createTimerFx,
  deleteUserFx,
  getTimerFx,
  resetMultiTimer,
  setAudioEndTimer,
  setAudioTickTimer,
  setRoundTime,
  setTimerData,
  startTimerFx,
  updateParticipantsFx,
  updateStatusFx,
} from './events'

export const $multiTimer = domain
  .createStore<Timer | null>(null)
  .on(setTimerData, (store, timer) => ({ ...store, ...timer }))
  .on(addUserFx.doneData, (_, { data }) => data)
  .on(deleteUserFx.doneData, (_, { data }) => data)
  .on(startTimerFx.doneData, (_, { data }) => data)
  .on(updateStatusFx.doneData, (_, { data }) => data)
  .on(updateParticipantsFx.doneData, (_, { data }) => data)
  .on(getTimerFx.failData, () => null)

  .reset(resetMultiTimer)

export const $audioTickTimer = domain
  .createStore<HTMLAudioElement | null>(null)
  .on(setAudioTickTimer, (_, timer) => timer)

export const $audioEndTimer = domain
  .createStore<HTMLAudioElement | null>(null)
  .on(setAudioEndTimer, (_, timer) => timer)

export const $isEditRoundTime = domain.createStore<boolean>(false).on(changeIsEditRoundTime, (_, isEdit) => isEdit)

export const $roundTime = domain.createStore<number | null>(60).on(setRoundTime, (_, time) => time || null)

export const $isGetTimerPanding = getTimerFx.pending

export const $createTimerIsPending = createTimerFx.pending

export const setIsFinishPlayed = domain.event<boolean>('setIsFinishPlayed')
export const $IsfinishPlayed = domain.createStore(false).on(setIsFinishPlayed, (_, isFinish) => isFinish)

$multiTimer.watch((timer) => {
  const user = $user.getState()
  if (!user || !timer || user.id !== timer.activeUser?.id) return

  const isFinishPlayed = $IsfinishPlayed.getState()

  const audioTick = $audioTickTimer.getState()
  const audioEnd = $audioEndTimer.getState()

  const secondsLeft = timer.roundSecondsLeft

  if (secondsLeft && secondsLeft > 1 && isFinishPlayed) {
    setIsFinishPlayed(false)
  }

  if (audioTick && secondsLeft && secondsLeft <= 10 && timer.status !== TimerStatus.PAUSE) {
    audioTick.play()
  } else if (audioEnd && secondsLeft === 0 && !isFinishPlayed) {
    audioEnd.play()
    setIsFinishPlayed(true)
  }
})
