import { Auth } from '@/features/auth'

import { Template } from '../templates/template'

export const AuthPage = () => {
  return (
    <Template withFooter={false} withHeader={false}>
      <Auth />
    </Template>
  )
}
