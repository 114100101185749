import {
  Done as DoneIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  TimerTwoTone as TimerTwoToneIcon,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { TimerStatus } from '../../enums'
import { $activePlayerNum, $activeTimer, $timerStatus } from '../../model/single-timer-process'
import { changeTimerStatus, setNextPlayerId } from '../../model/single-timer-process/events'
import { Player } from '../../types'
import * as Styled from './styled'

type PlayerCardProps = {
  player: Player
}

export const PlayerCard = ({ player }: PlayerCardProps) => {
  const activeTimer = useStore($activeTimer)
  const timerStatus = useStore($timerStatus)
  const activePlayerNum = useStore($activePlayerNum)
  const isActive = Boolean(activeTimer != null && player.number === activePlayerNum)

  const isExpired = activeTimer === 0

  return (
    <Styled.PlayerCard $isActive={isActive} $activeTimer={activeTimer}>
      <Typography gutterBottom variant="h5" component="div">
        {player.name}{' '}
        {isActive &&
          (timerStatus === TimerStatus.PAUSE ? '(пауза)' : isExpired ? '(время вышло)' : '')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Choose>
          <When condition={isActive}>
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              style={{ marginBottom: 0, marginRight: 0, fontWeight: 600 }}
            >
              {isActive && isExpired ? '0' : activeTimer}
            </Typography>
          </When>
          <Otherwise>
            <TimerTwoToneIcon style={{ fontSize: 40, color: 'orange' }} />
          </Otherwise>
        </Choose>
        <Box sx={{ display: 'flex' }}>
          <If condition={isActive}>
            <Styled.IconButton
              $isActive
              disabled={isExpired}
              size="large"
              onClick={() => setNextPlayerId(null)}
            >
              <DoneIcon />
            </Styled.IconButton>
          </If>
          <Choose>
            <When condition={isActive && timerStatus === TimerStatus.PAUSE}>
              <Styled.IconButton
                $isActive
                size="large"
                onClick={() => changeTimerStatus(TimerStatus.RUN)}
              >
                <PlayArrowIcon />
              </Styled.IconButton>
            </When>
            <When condition={isActive}>
              <Styled.IconButton
                $isActive
                disabled={isExpired}
                size="large"
                onClick={() => changeTimerStatus(TimerStatus.PAUSE)}
              >
                <PauseIcon />
              </Styled.IconButton>
            </When>
            <Otherwise>
              <Styled.IconButton
                $isActive={isActive}
                size="large"
                onClick={() => setNextPlayerId(isActive ? null : player.number)}
              >
                <PlayArrowIcon />
              </Styled.IconButton>
            </Otherwise>
          </Choose>
        </Box>
      </Box>
    </Styled.PlayerCard>
  )
}
