import '../../model/note/init'

import { Divider } from '@mui/material'
import { useGate } from 'effector-react'

import { NoteGate } from '../../model/gate'
import { NewNote } from '../new-note'
import { NoteTabs } from '../tabs'
import * as Styled from './styled'

export const Note = () => {
  useGate(NoteGate)
  return (
    <Styled.Root>
      <NewNote />
      <Divider sx={{ my: 1 }} />
      <NoteTabs />
    </Styled.Root>
  )
}
