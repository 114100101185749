import { Box, Skeleton, SkeletonProps, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  src: string
  sx?: SxProps
  alt?: string
  skeletonProps?: SkeletonProps
}
export const ImageWrapper = ({ src, sx = {}, alt = 'Изображение', skeletonProps = {} }: Props) => {
  const [isLoaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setLoaded(true)
    }
    img.src = src
  }, [src])

  // Пока размеры неизвестны, можно отображать заглушку или лоадер
  if (!isLoaded) {
    return <Skeleton variant="rectangular" width={200} height={120} {...skeletonProps} />
  }

  // Размеры установлены, можно рендерить компонент
  return <Box component="img" src={src} alt={alt} sx={{ ...sx }} />
}
