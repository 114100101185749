import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonWrapper = styled('div')`
  cursor: pointer;
  margin-bottom: 16px;
`

export const IconImage = styled('img')`
  object-fit: contain;
  width: 100%;
  height: 100%;
`
