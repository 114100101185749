export enum GameSnakePageType {
  MAIN = 'MAIN',
  SINGLE_GAME = 'SINGLE',
  SHOP = 'SHOP',
  PARAMS = 'PARAMS',
  STATS = 'STATS',
  ADMIN = 'ADMIN',
  ADMIN_NUMBER = 'ADMIN_NUMBER',
  LEVELS = 'LEVELS',
  LEVEL_NUMBER = 'LEVEL_NUMBER',
}
