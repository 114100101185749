import { Alert, Button, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'

import { $user } from '@/features/auth'
import { AlertError } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { ROUTE_PATH } from '../../paths'
import { Template } from '../templates/template'
import * as Styled from './styled'

export const ErrorPage = () => {
  const error: any = useRouteError()
  const navigate = useNavigate()
  const user = useStore($user)

  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <Template withFooter={Boolean(user)} withHeader={Boolean(user)}>
      <Styled.Root>
        <If condition={!user}>
          <AlertError text="Вы не авторизованы" sx={{ my: 2 }} />
          <Button
            sx={{ width: 200 }}
            variant="contained"
            onClick={() => navigate(ROUTE_PATH.SIGN_IN)}
          >
            Войти
          </Button>
        </If>
        <If condition={user}>
          <Typography mt={2} variant="h5" fontWeight="bold">
            Произошла ошибка
          </Typography>
          <Alert sx={{ mt: 2, textAlign: 'left' }} severity="error">
            <Typography variant="body1">Статус: {error?.status || 'Неизвестный статус'}</Typography>
            <Typography variant="body1" mt={1}>
              Ошибка: {error?.statusText || error?.message || 'Что-то пошло не так'}
            </Typography>
          </Alert>
        </If>
      </Styled.Root>
    </Template>
  )
}
