import { styled } from '@mui/material/styles'

export const Image = styled('img')`
  max-width: 700px;
  max-height: 500px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 500px;
    max-height: 400px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-height: 400px;
    max-width: 260px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    max-height: 300px;
    max-width: 200px;
  }
`

export const TabWrapper = styled('div')`
  margin-bottom: 8px;
`
