import { Box, Button, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { $multiTimer } from '../../model/multi-timer'
import { deleteTimer } from '../../model/multi-timer/events'

export const Finish = () => {
  const timer = useStore($multiTimer)
  const user = useStore($user)
  if (!user || !timer) throw new Error('Not found user or timer')
  const isAdmin = user.id === timer.admin.id

  return (
    <Box mt={2}>
      <Typography sx={{ textAlign: 'center' }} variant="h3" color="initial">
        {`${timer?.activeUser?.username || 'Автор'} завершил таймер`}
      </Typography>
      <RouterLink to={ROUTE_PATH.TIMER}>
        <Button variant="contained" color="primary" sx={{ width: '100%', mt: 3 }}>
          К списку таймеров
        </Button>
      </RouterLink>
      <If condition={isAdmin}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%', mt: 3 }}
          onClick={() => deleteTimer(timer.id)}
        >
          Удалить таймер
        </Button>
      </If>
    </Box>
  )
}
