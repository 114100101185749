import { api } from '@/services/api'

import { ActivateNumber, GetNumbers } from './types'

export const getNumbersApi = async (): Promise<GetNumbers> => {
  return api.get('/game_number/get/')
}

export const activateNumberApi = async (params: { number: number }): Promise<ActivateNumber> => {
  return api.post('/game_number/activate/', params)
}
