import { domain } from '../domain'
import { Battle, BattleStatus, Profile } from './types'

export const setProfile = domain.event<Profile>('setProfile')
export const resetProfile = domain.event<void>('resetProfile')

export const setPlayers = domain.event<Profile[]>('setPlayers')
export const resetPlayers = domain.event<void>('resetPlayers')

export const setBattle = domain.event<Battle>('setBattle')
export const setBattleStatus = domain.event<BattleStatus>('setBattleStatus')
export const resetQuestion = domain.event<void>('resetQuestion')
export const resetLastDamage = domain.event<void>('resetLastDamage')
export const resetBattle = domain.event<void>('resetBattle')

export const setBattleError = domain.event<string>('setBattleError')
export const resetBattleError = domain.event<void>('resetBattleError')

export const setEnemyUsername = domain.event<string>('setEnemyUsername')
export const resetEnemyUsername = domain.event<void>('resetEnemyUsername')

export const setAnswerId = domain.event<number>('setAnswerId')
export const resetAnswerId = domain.event<void>('resetAnswerId')
