import {
  GetNotificationsError,
  GetNotificationsParams,
  GetNotificationsResponse,
} from '../../types'
import { domain } from '../domain'

export const getNotifications = domain.event<GetNotificationsParams>('getNotifications')

export const getNotificationsFx = domain.effect<
  GetNotificationsParams,
  GetNotificationsResponse,
  GetNotificationsError
>('getNotificationsFx')
