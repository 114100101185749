import { Tab, Tabs } from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import {
  $fortuneStats,
  $fortuneStatsTab,
  $getFortuneStatsError,
  $getFortuneStatsPending,
} from '../../model/fortune'
import { changeFortuneStatsTab, getFortuneStats } from '../../model/fortune/events'
import { FortuneStatsGate } from '../../model/gate'
import { FortuneStatSection, ProductCode } from '../../types'
import { ModalFortuneStatsItem } from '../modal-fortune-stats-item'
import * as Styled from './styled'

export const ModalFortuneStats = () => {
  useGate(FortuneStatsGate)
  const fortuneStats = useStore($fortuneStats)
  const isPending = useStore($getFortuneStatsPending)
  const error = useStore($getFortuneStatsError)
  const tabName = useStore($fortuneStatsTab)

  return (
    <Styled.Root>
      <If condition={isPending && fortuneStats}>
        <AlertLoading size={20} text="Статистика обновляется" sx={{ width: '100%' }} />
      </If>
      <Choose>
        <When condition={error}>
          <AlertError
            text={error}
            TypographyProps={{ variant: 'h6' }}
            onReload={() => getFortuneStats()}
          />
        </When>
        <When condition={isPending && !fortuneStats}>
          <AlertLoading size={20} text="Статистика загружается" sx={{ width: '100%' }} />
        </When>
        <When condition={fortuneStats}>
          <Styled.TabWrapper sx={{ borderColor: 'divider' }}>
            <Tabs
              variant="scrollable"
              value={tabName}
              scrollButtons={false}
              onChange={(_, newValue) => changeFortuneStatsTab(newValue)}
            >
              <Tab label="Пухлякоины" value={ProductCode.WADDLES_COIN} />
              <Tab label="Опыт" value={ProductCode.XP} />
              <Tab label="Колёса фортуны" value={ProductCode.FORTUNE_NAME} />
              <Tab label="Товары" value={ProductCode.PRODUCT} />
            </Tabs>
          </Styled.TabWrapper>
          <If condition={tabName === ProductCode.WADDLES_COIN}>
            <ModalFortuneStatsItem item={fortuneStats?.waddlecoins as FortuneStatSection} />
          </If>
          <If condition={tabName === ProductCode.XP}>
            <ModalFortuneStatsItem item={fortuneStats?.xps as FortuneStatSection} />
          </If>
          <If condition={tabName === ProductCode.FORTUNE_NAME}>
            <ModalFortuneStatsItem item={fortuneStats?.fortunes as FortuneStatSection} />
          </If>
          <If condition={tabName === ProductCode.PRODUCT}>
            <ModalFortuneStatsItem item={fortuneStats?.products as FortuneStatSection} />
          </If>
        </When>
      </Choose>
    </Styled.Root>
  )
}
