import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { StatsInfo } from '../../types'

type Props = {
  stats: StatsInfo[]
}

export const StatsSingleGame = ({ stats }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }} variant="head" align="center">
              Сложность
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} variant="head" align="center">
              Твой рекорд
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} variant="head" align="center">
              Общий рекорд
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((row, i) => (
            <TableRow key={i}>
              <TableCell align="center">{row.difficulty}</TableCell>
              <TableCell align="center">{row.playerRecord}</TableCell>
              <TableCell align="center">{row.record}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
