import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const CurrentValue = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 4px;
`

export const MinMaxWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  width: 100%;
`

export const LinearValue = styled(Typography)``

export const LinearWrapper = styled('div')`
  position: relative;
  width: 100%;
  & > span {
    height: 16px;
    border-radius: 4px;
  }
  & > p {
    font-size: 12px;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
