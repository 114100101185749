import { Message } from '../../type'
import { domain } from '../domain'
import {
  DeleteMessageError,
  DeleteMessageParams,
  DeleteMessageResponse,
  EditMessageError,
  EditMessageParams,
  EditMessageResponse,
  GetMessagesError,
  GetMessagesParams,
  GetMessagesResponse,
  MenuMessageType,
  SendMessageError,
  SendMessageParams,
  SendMessageResponse,
} from './types'

export const getMessagesRoom = domain.event<GetMessagesParams>('getMessagesRoom')
export const getMessagesRoomFx = domain.effect<
  GetMessagesParams,
  GetMessagesResponse,
  GetMessagesError
>('getMessagesRoomFx')

export const sendMessage = domain.event<SendMessageParams>('sendMessage')
export const sendMessageFx = domain.effect<
  SendMessageParams,
  SendMessageResponse,
  SendMessageError
>('sendMessageFx')

export const editMessage = domain.event<EditMessageParams>('editMessageRoom')
export const editMessageFx = domain.effect<
  EditMessageParams,
  EditMessageResponse,
  EditMessageError
>('editMessagemFx')

export const deleteMessage = domain.event<DeleteMessageParams>('deleteMessageRoom')
export const deleteMessageFx = domain.effect<
  DeleteMessageParams,
  DeleteMessageResponse,
  DeleteMessageError
>('editMessagemFx')

export const addMessageToChat = domain.event<{ chatId: number; message: Message }>(
  'changeOpenChatsMenu'
)

export const setMenuMessage = domain.event<MenuMessageType>('setMenuMessage')
export const resetMenuMessage = domain.event<void>('resetMenuMessage')

export const setEditMessage = domain.event<Message>('setMenuMessage')
export const resetEditMessage = domain.event<void>('resetEditMessage')

export const setDeleteMessageId = domain.event<number>('setDeleteMessageId')
export const resetDeleteMessageId = domain.event<void>('resetDeleteMessageId')

export const setEndChatElement = domain.event<HTMLDivElement | null>('setEndChatElement')
export const setScrollChatContainer = domain.event<HTMLDivElement | null>('setScrollChatContainer')
export const resetScrollChatConteiner = domain.event<void>('resetScrollChatConteiner')
export const scrollChatMessages = domain.event<void | ScrollIntoViewOptions>('scrollChatMessages')

export const deleteMessageInStore = domain.event<{ chatId: number; messageId: number }>(
  'deleteMesageInStore'
)

export const getMessageForStore = domain.event<{ chatId: number; messages: Message[] }>(
  'getMessageForStore'
)
export const addMessageInStore = domain.event<{ chatId: number; message: Message }>(
  'addMessageInStore'
)

export const editMessageInStore = domain.event<{ chatId: number; messageId: number; text: string }>(
  'editMessageInStore'
)

export const setScrollHeightMessageContainer = domain.event<number>(
  'setScrollHeightMessageContainer'
)
export const resetScrollHeightMessageContainer = domain.event<void>(
  'resetScrollHeightMessageContainer'
)

// Обработчик отправки формы
export const fileChanged = domain.createEvent<File | null>('fileChanged')
export const changeDialogImage = domain.createEvent<string | null>('changeDialogImage')
export const formSubmitted = domain.createEvent('formSubmitted')

export const setSendInput = domain.createEvent<HTMLInputElement | null>('setSendInput')
export const setSendInputFocus = domain.createEvent<void>('setSendInputFocus')

export const initializeSockets = domain.createEvent<void>('initializeSockets')
