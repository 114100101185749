import { combine } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { User } from '../../types'
import { $signInPending } from '../auth'
import { domain } from '../domain'
import { changeRuble, changeWaddlesCoin, getUserFx, resetUser, setUserError } from './events'

export const initUser = Storage.get<User>(STORAGE_NAMES.USER)
export const initWaddlesCoin = Storage.get<number>(STORAGE_NAMES.WADDLES_COIN)
export const initRubles = Storage.get<number>(STORAGE_NAMES.RUBLES)

export const $user = domain
  .createStore<User | null>(initUser)
  .on(getUserFx.doneData, (_, { data: { waddlesCoin, ...user } }) => user)
  .on(resetUser, () => null)

export const $waddlesCoins = domain
  .createStore<number | null>(initWaddlesCoin)
  .on(getUserFx.doneData, (_, { data }) => data.waddlesCoin)
  .on(changeWaddlesCoin, (data, waddlesCoin) => {
    if (!data) return null
    return data + waddlesCoin
  })

export const $rubles = domain
  .createStore<number | null>(initRubles)
  .on(getUserFx.doneData, (_, { data }) => data.rubles)
  .on(changeRuble, (data, ruble) => {
    if (!data) return null
    return data + ruble
  })

export const $getUserPending = getUserFx.pending

export const $userAuthPending = combine({
  getUserPending: $getUserPending,
  signInPending: $signInPending,
}).map(({ getUserPending, signInPending }) => getUserPending || signInPending)

export const $userError = domain
  .createStore<string | null>(null)
  .on(
    setUserError,
    (_, data) =>
      `Кажется что-то сломалось! Ошибка: ${data.message}. ` +
      `Обратитесь к разработчику, он всё исправит!`
  )
  .reset(resetUser)
