import { domain } from '../domain';
import { changeCreateMultiDialog, changeTitleNewTimer } from './events';

export const $isShowCreateMulitDialog = domain
  .createStore<boolean>(false)
  .on(changeCreateMultiDialog, (_, isOpen) => isOpen);

export const $titleNewTimer = domain
  .createStore<string>('')
  .on(changeTitleNewTimer, (_, title) => title);
