import { api } from '@/services/api'

import {
  DeleteMessageParams,
  DeleteMessageResponse,
  EditMessageParams,
  EditMessageResponse,
  GetMessagesParams,
  GetMessagesResponse,
  SendMessageParams,
  SendMessageResponse,
} from './types'

export const getMessagesApi = async (params: GetMessagesParams): Promise<GetMessagesResponse> => {
  return api.get(`chat/message/${params.chatId}/get/?offset=${params.offset || 0}`)
}

export const sendMessageApi = async (params: SendMessageParams): Promise<SendMessageResponse> => {
  const { chatId, formData } = params
  return api.post(`chat/message/${chatId}/send/`, formData)
}

export const editMessageApi = async (params: EditMessageParams): Promise<EditMessageResponse> => {
  const { messageId, ...otherParams } = params
  return api.put(`chat/message/${messageId}/edit/`, otherParams)
}

export const deleteMessageApi = async (
  params: DeleteMessageParams
): Promise<DeleteMessageResponse> => {
  return api.delete(`chat/message/${params.messageId}/delete/`)
}
