import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import { foodSrc, fortuneSrc, snakeSrc, waddlesCoinSrc } from '@/shared/assets'

import { LevelData } from '../../../../model/admin/types'

type Props = {
  levelGame: LevelData
  title?: string
}

export const RewardBlock = ({ levelGame, title = 'Награды' }: Props) => {
  return (
    <TableContainer>
      <Table sx={{ width: '100%' }}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} component="th" scope="row" align="center">
              <Typography variant="h5">{title}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">{levelGame.rewardFood}</Typography>
                <Box component="img" sx={{ ml: 1 }} src={foodSrc} alt="Еда" width={24} />
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">{levelGame.rewardWaddlescoin}</Typography>
                <Box
                  component="img"
                  sx={{ ml: 1 }}
                  src={waddlesCoinSrc}
                  alt="Пухлякоины"
                  width={24}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">{levelGame.rewardFortune}</Typography>
                <Box
                  component="img"
                  sx={{ ml: 1 }}
                  src={fortuneSrc}
                  alt="Колеса фортуны"
                  width={24}
                />
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">{levelGame.rewardGameCount}</Typography>
                <Box component="img" sx={{ ml: 1 }} src={snakeSrc} alt="Змейки" width={24} />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
