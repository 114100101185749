import { AxiosError, AxiosResponse } from 'axios'

export type UserXpResponse = {
  xp: number
  name: string
  minXp: number
  maxXp: number
  progress: number
}

export type GetUserXpParams = void

export type GetUserXpResponse = AxiosResponse<UserXpResponse>

export type GetUserXpError = AxiosError<string[]>

export type PhotoDayElement = {
  photo: string
  about: string
}

export type PhotoDay = {
  week: PhotoDayElement | null
  month: PhotoDayElement | null
  quarter: PhotoDayElement | null
  year: PhotoDayElement | null
  year2: PhotoDayElement | null
}

export type PhotoDayParams = void

export type PhotoDayResponse = AxiosResponse<PhotoDay>

export type PhotoDayError = AxiosError<string[]>

export type LastReportParams = void

export type LastReport = {
  needReport: boolean
}

export type LastReportResponse = AxiosResponse<LastReport>

export type LastReportError = AxiosError<string[]>

export enum PhotoDayTabName {
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  YEAR2 = 'year2',
}

export type PhotoDayItem = {
  key: string
  about: string
  url: string | null
  text: string
}

export type Notifications = {
  newPostsCount: number
  newTasksCount: number
  updatedProductsCount: number
}

export type GetNotificationsParams = void

export type GetNotificationsResponse = AxiosResponse<Notifications>

export type GetNotificationsError = AxiosError<string[]>

export enum HolidayType {
  OUR = 'OUR',
  OUTER = 'OUTER',
}

export type Holiday = {
  title: string
  day: number
  month: number
  description: string
  mainText: string
  holidayType: HolidayType
}

export type GetHolidayParams = void

export type GetHolidayResponse = AxiosResponse<Holiday>

export type GetHolidayError = AxiosError<string[]>

export enum ProductCode {
  FORTUNE_NAME = 'fortune',
  WADDLES_COIN = 'waddles_coin',
  DETAIL_BOARD_GAME = 'detail_board_game',
  GAME_SNAKE = 'game-snake',
  PRODUCT = 'product',
  XP = 'xp',
}

export enum FortunePrizeType {
  NEGATIVE = 'NEGATIVE',
  ZERO = 'ZERO',
  FUNNY = 'FUNNY',
  NORMAL = 'NORMAL',
  GOOD = 'GOOD',
  VERY_GOOD = 'VERY_GOOD',
}

export type FortuneSector = {
  id: number
  order: number
  color: string
  count: number
  prizeType: FortunePrizeType
  productCode: ProductCode
  productName: string
  productId: number
}

export type FortuneParams = {
  accelerate: number
  friction: number
  angleStart: number
  angleEnd: number
  angleMax: number
}

export type FortuneInfo = FortuneParams & {
  id: number
  expired: string | null
  isStarted: boolean
  priceUpdated: number
  price: number | null
}

export type GetFortuneResponse = AxiosResponse<
  FortuneInfo & { sectors: FortuneSector[]; fortuneCount: number }
>

export type GetFortuneError = AxiosError<string[]>

export type SaveFortuneParams = { sectorId: number }

export type SaveFortuneResponse = AxiosResponse<FortuneSector>

export type SaveFortuneError = AxiosError<string[]>

export type BuyFortuneParams = void

export type BuyFortune = {
  spent: number
}

export type StartFortuneResponse = AxiosResponse<FortuneParams>

export type BuyFortuneResponse = AxiosResponse<BuyFortune>

export type BuyFortuneError = AxiosError<string[]>

export type FortuneStatItem = {
  count: number
  data: number
}

export type FortuneStatSection = {
  count: number
  stats: FortuneStatItem[]
  sumWin?: number
  max?: number
  avg?: number
}

export type FortuneStats = {
  allCount: number
  waddlecoins: FortuneStatSection
  xps: FortuneStatSection
  fortunes: FortuneStatSection
  products: FortuneStatSection
}

export type GetFortuneStatsParams = void

export type GetFortuneStatsResponse = AxiosResponse<FortuneStats>

export type GetFortuneStatsError = AxiosError<string[]>

export enum ModalMode {
  MAIN = 'MAIN',
  STATS = 'STATS',
}

export type ReadableProduct = {
  id: number
  title: string
  code: string
  image: string
}

export enum GiftType {
  FORTUNE = 'fortune',
  RUBBLE = 'RUBBLE',
  GAME_SNAKE = 'game-snake',
  WADDLES_COIN = 'WADDLES_COIN',
  XP = 'XP',
  DETAIL_BOARD_GAME = 'DETAIL_BOARD_GAME',
}

export type Gift = {
  id: number
  title: string
  description: string
  code: string
  giftText: string
  giftType: GiftType
  isUsed: boolean
  count: number
  product: ReadableProduct
}

export type GetGiftsResponse = AxiosResponse<Gift[]>

export type ActivateGiftParams = { code: string }
export type ActivateGiftResponse = AxiosResponse<{ id: number }>

export type Theme = {
  headerColor: string
  logoUrl: string
  backgroundUrl: string
  backgroundMobileUrl: string
  waddlesUrl: string
}

export type GetThemeResponse = AxiosResponse<Theme>
