export const STORAGE_NAMES = {
  USER: 'user',
  WADDLES_COIN: 'waddles_coin',
  RUBLES: 'RUBLES',
  USER_XP: 'user_xp',
  HOLIDAY: 'holiday',
  PHOTO_DAY: 'photo_day',
  FORTUNE: 'fortune',
  THEME: 'theme',
  BACKGROUND_URL: 'background_url',
  LOGO_URL: 'logo_url',
  HEADER_COLOR: 'header_color',
  WADDLES_URL: 'waddles_url',
  ACTIVE_VERSION: 'ACTIVE_VERSION',
  MESSAGES: 'MESSAGES',
}
