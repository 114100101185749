import { openBluePackAudio, openGreenPackAudio, openWhitePackAudio } from '../assets'
import { $activeCard } from './shop'

export const stopPackAudio = () => {
  openWhitePackAudio.currentTime = 0
  openWhitePackAudio.pause()
  openBluePackAudio.currentTime = 0
  openBluePackAudio.pause()
  openGreenPackAudio.currentTime = 0
  openGreenPackAudio.pause()
}

export const startPackAudio = () => {
  const type = $activeCard.getState()?.type
  if (!type) return

  if (type === 'BLUE' || 'PURPLE') {
    openBluePackAudio.play()
  } else if (type === 'GREEN') {
    openGreenPackAudio.play()
  } else {
    openWhitePackAudio.play()
  }
}
