import { Box, Button } from '@mui/material'

import { Template } from '../templates/template'

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }
}

export let deferredPrompt: BeforeInstallPromptEvent | null = null

window.addEventListener('beforeinstallprompt', async (e) => {
  deferredPrompt = e
})

export const PWAPage = () => {
  return (
    <Template>
      <Box sx={{ w: '100%', textAlign: 'center', mt: 5 }}>
        <Button
          variant="contained"
          size="large"
          color="info"
          onClick={async () => {
            if (deferredPrompt !== null) {
              deferredPrompt.prompt()
              const { outcome } = await deferredPrompt.userChoice
              if (outcome === 'accepted') {
                deferredPrompt = null
              }
            }
          }}
        >
          Скачать PWA
        </Button>
      </Box>
    </Template>
  )
}
