import { saveFortune, setActivePrizeCount } from '../../model/fortune/events'
import { FortuneInfo, FortuneSector, ProductCode } from '../../types'
import { backgroundAudio, playAudioResult } from './audio'
import { FORTUNE_SIZE, PI, TAU } from './consts'
import { DrawSectorArgs, EngineArgs, FrameArgs } from './types'

export class FortuneData {
  angleSpeed: number
  angle: number
  isSpinning: boolean
  isAccelerating: boolean
  isFinish: boolean
  fortuneInfo: FortuneInfo | null
  ctx: CanvasRenderingContext2D | null
  fortuneSectors: FortuneSector | null

  constructor(
    angleSpeed: number = 0,
    angle: number = 0,
    isSpinning: boolean = false,
    isAccelerating: boolean = false,
    isFinish: boolean = false
  ) {
    this.angleSpeed = angleSpeed
    this.angle = angle
    this.isSpinning = isSpinning
    this.isAccelerating = isAccelerating
    this.isFinish = isFinish
    this.fortuneSectors = null
    this.fortuneInfo = null
    this.ctx = null
  }

  setAngleSpeed(value: number) {
    this.angleSpeed = value
  }

  setAngle(value: number) {
    this.angle = value
  }

  setIsSpinning(value: boolean) {
    this.isSpinning = value
  }

  setIsAccelerating(value: boolean) {
    this.isAccelerating = value
  }

  setIsFinish(value: boolean) {
    this.isFinish = value
  }

  getSectorIndex = (sectorsCount: number, angle: number) => {
    return Math.floor(sectorsCount - (angle / TAU) * sectorsCount) % sectorsCount
  }

  arr: number[] = []

  changeAngleSpeed(fortuneInfo: FortuneInfo) {
    if (this.isAccelerating) {
      this.setAngleSpeed((this.angleSpeed || fortuneInfo.angleStart) * fortuneInfo.accelerate)
    } else {
      this.setIsAccelerating(false)
      this.setAngleSpeed(this.angleSpeed * fortuneInfo.friction)
    }
    this.arr.push(this.angleSpeed)
  }

  frame = ({ fortuneSectors, fortuneInfo, ctx }: FrameArgs): boolean => {
    if (!this.isSpinning) return false

    if (this.angleSpeed >= fortuneInfo.angleMax) {
      this.setIsAccelerating(false)
    }

    this.changeAngleSpeed(fortuneInfo)

    this.setAngle((this.angle + this.angleSpeed) % TAU)

    const currentSector = fortuneSectors[this.getSectorIndex(fortuneSectors.length, this.angle)]

    this.drawCircle(ctx, currentSector.color)
    setActivePrizeCount(currentSector.count)

    if (!this.isAccelerating && this.angleSpeed < fortuneInfo.angleEnd) {
      this.setIsAccelerating(false)

      this.setAngleSpeed(0)

      this.setIsFinish(true)

      backgroundAudio.pause()
      backgroundAudio.currentTime = 0

      saveFortune({ sectorId: currentSector.id })
      playAudioResult(currentSector)
      return true
    }

    this.rotate(ctx, this.angle) // CSS rotate!

    return false
  }

  // Отрисовака срекотора и ипризы на них
  drawSector = ({ ctx, arc, radius, sector, i }: DrawSectorArgs) => {
    const innerAng = arc * i
    ctx.save()
    // COLOR
    ctx.beginPath()
    ctx.fillStyle = sector.color
    ctx.moveTo(radius, radius)
    ctx.arc(radius, radius, radius, innerAng, innerAng + arc)
    ctx.lineTo(radius, radius)
    ctx.fill()
    // TEXT
    ctx.translate(radius, radius)
    ctx.rotate(innerAng + arc / 2)
    ctx.textAlign = 'right'
    ctx.fillStyle = '#fff'
    if (sector.productCode === ProductCode.WADDLES_COIN) {
      ctx.font = 'bold 16px Roboto'
      ctx.fillText(String(sector.count), radius - 12, 4)
    } else if (sector.productCode === ProductCode.FORTUNE_NAME) {
      ctx.font = 'bold 12px Roboto'
      ctx.fillText(`Колёса: ${sector.count}`, radius - 10, 5)
    } else if (sector.productCode === ProductCode.XP) {
      ctx.font = 'bold 12px Roboto'
      ctx.fillText(`Опыт: ${sector.count}`, radius - 10, 5)
    } else if (sector.productCode === ProductCode.GAME_SNAKE) {
      ctx.font = 'bold 12px Roboto'
      ctx.fillText(`Змейка: ${sector.count}`, radius - 10, 5)
    } else {
      ctx.font = 'bold 10px Roboto'
      ctx.fillText(sector.productName, radius - 10, 3)
    }
    ctx.restore()
  }

  drawCircle(ctx: CanvasRenderingContext2D, color: string) {
    const centerX = FORTUNE_SIZE / 2
    const centerY = FORTUNE_SIZE / 2
    const radius = 32

    ctx.fillStyle = color
    ctx.beginPath()
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    ctx.fill()
    ctx.stroke()
  }

  // Отрисовка всех секторов
  drawSectors = (ctx: CanvasRenderingContext2D, fortuneSectors: FortuneSector[]) => {
    const fortuneSectorsCount = fortuneSectors.length

    const diameter = ctx.canvas.width
    const radius = diameter / 2
    const PI = Math.PI
    const TAU = 2 * PI
    const arc = TAU / fortuneSectorsCount

    // Отрисовка колеса
    fortuneSectors.forEach((sector, i) => {
      this.drawSector({ ctx: ctx, arc: arc, radius, sector: sector, i: i })
    })
  }

  rotate = (ctx: CanvasRenderingContext2D, angle: number) => {
    ctx.canvas.style.transform = `rotate(${angle - PI / 2}rad)`
  }
  engine = ({ fortuneInfo, fortuneSectors, ctx }: EngineArgs) => {
    if (this.isFinish) return

    this.frame({ fortuneInfo, fortuneSectors, ctx })

    requestAnimationFrame(() => this.engine({ fortuneInfo, fortuneSectors, ctx }))
  }

  clear() {
    this.angleSpeed = 0
    this.angle = 0
    this.isSpinning = false
    this.isAccelerating = false
    this.isFinish = false
  }
}
