import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useStore } from 'effector-react'

import { If } from '@/shared/utils/if'

import { useMedia } from '../../../../../../shared/utils/media'
import { $usernameTyping } from '../../../../model/messages/socket/store'
import { Send } from './ui/send'

export const BottomBlock = () => {
  const usernameTyping = useStore($usernameTyping)
  const { isDesktop } = useMedia()
  return (
    <Box
      sx={{
        p: 1,
        pl: 0,
        mb: isDesktop ? 0 : 1,
        flexGrow: 1,
        borderTop: `1px solid ${grey[400]}`,
      }}
    >
      <If condition={usernameTyping}>
        <Typography fontSize={12}>{`${usernameTyping} печатает...`}</Typography>
      </If>
      <Send />
    </Box>
  )
}
