import { Timer, TimerList } from '@/features/timer'
import { MultiUser } from '@/features/timer/ui/multi-user'
import { Choose, When } from '@/shared/utils/choose'

import { Template } from '../templates/template'
import { TimerPageType } from './types'

type TimerPageProps = {
  type: TimerPageType
}

export const TimerPage = ({ type }: TimerPageProps) => {
  return (
    <Template>
      <Choose>
        <When condition={type === TimerPageType.LIST}>
          <TimerList />
        </When>
        <When condition={type === TimerPageType.SINGLE}>
          <Timer />
        </When>
        <When condition={type === TimerPageType.MULTI_USER}>
          <MultiUser />
        </When>
      </Choose>
    </Template>
  )
}
