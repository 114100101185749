import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import { IconButton, Popover } from '@mui/material'
import { useStore } from 'effector-react'
import Picker, { EmojiClickData } from 'emoji-picker-react'
import { useState } from 'react'

import { $socketsIsOnline } from '../../../../../../model/messages/store'

type Props = {
  onEmojiSelect: (emoji: EmojiClickData) => void
  onClose: () => void
}

export const EmojiPicker = ({ onEmojiSelect, onClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const chatSocketIsOnline = useStore($socketsIsOnline)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        disabled={!chatSocketIsOnline}
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <InsertEmoticonIcon sx={{ fontSize: 30 }} />
      </IconButton>
      <Popover
        id={open ? 'emoji-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Picker
          previewConfig={{ showPreview: false }}
          autoFocusSearch={false}
          lazyLoadEmojis
          skinTonesDisabled
          onEmojiClick={(emoji) => {
            onEmojiSelect(emoji)
            handleClose()
          }}
        />
      </Popover>
    </>
  )
}
