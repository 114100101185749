import { Done as DoneIcon } from '@mui/icons-material'
import { Box, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'

import { MainGameSnakeGate } from '../../gate'
import { $profileCombined, $updateParamCombined } from '../../model/game-snake'
import { updateParam } from '../../model/game-snake/events'
import { GameWrapper } from '../game-wrapper'

export const GameSnakeParams = () => {
  useGate(MainGameSnakeGate)
  const { profile, profileError, profilePending } = useStore($profileCombined)
  const { updateParamPending } = useStore($updateParamCombined)
  const [speed, setSpeed] = useState<string>(profile?.snakeParams.speed.toString() || '')

  useEffect(() => setSpeed(profile?.snakeParams.speed.toString() || ''), [profile])

  const snakeParams = profile?.snakeParams

  return (
    <GameWrapper title="Параметры змейки">
      <Choose>
        <When condition={profile?.snakeParams}>
          <List component="nav" aria-label="secondary mailbox folder" sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary={<Typography fontWeight={'bold'}>Сложность в одиночной игре:</Typography>}
                sx={{ flex: '0 0 auto', mr: 1 }}
              />
              <Typography variant="body1" fontStyle="italic">
                {snakeParams?.singleGameLevel}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography fontWeight={'bold'}>Текущий уровень:</Typography>}
                sx={{ flex: '0 0 auto', mr: 1 }}
              />
              <Typography variant="body1" fontStyle="italic">
                {snakeParams?.level}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography fontWeight={'bold'}>Уровень скорости: </Typography>}
                sx={{ flex: '0 0 auto', mr: 1 }}
              />
              <TextField
                sx={{ mr: 1 }}
                label={`от 0 до ${profile?.snakeParams.maxSpeed}`}
                type="number"
                InputProps={{ inputProps: { min: 0, max: profile?.snakeParams.maxSpeed } }}
                size="small"
                value={speed}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  if (profile && value >= 0 && value <= profile.snakeParams.maxSpeed) {
                    setSpeed(e.target.value)
                  }
                }}
              />
              <IconButton
                disabled={updateParamPending}
                onClick={() => updateParam({ param: 'speed', value: +speed })}
              >
                <DoneIcon />
              </IconButton>
            </ListItem>
            <ListItem>
              <ListItemText
                sx={{ flex: '0 0 auto', mr: 1 }}
                primary={<Typography fontWeight={'bold'}>Цвет змейки: </Typography>}
              />
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  background: snakeParams?.snakeColor,
                  borderRadius: 1,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography fontWeight={'bold'}>Аксилерометр: </Typography>}
                sx={{ flex: '0 0 auto', mr: 1 }}
              />
              <Typography variant="body1" fontStyle="italic">
                {snakeParams?.hasAccelerometer ? 'Доступен' : 'Недоступен'}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography fontWeight={'bold'}>Супероборот:</Typography>}
                sx={{ flex: '0 0 auto', mr: 1 }}
              />
              <Typography variant="body1" fontStyle="italic">
                {snakeParams?.canTurnAround ? 'Доступен' : 'Недоступен'}
              </Typography>
            </ListItem>
          </List>
        </When>
        <When condition={profileError}>
          <AlertError text={profileError} />
        </When>
        <When condition={profilePending}>
          <AlertLoading text={'Данные загружаются'} />
        </When>
      </Choose>
    </GameWrapper>
  )
}
