import { Box, Skeleton, Tab } from '@mui/material'
import { useStore } from 'effector-react'

import { AlertApi } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { $activeTagTab, $getNotesError, $getNotesFxPending, $noteTags } from '../../model/note'
import { changeTagTab, getNotes } from '../../model/note/events'
import { EditNoteModal } from '../edit-note-modal'
import { NoteTabItem } from '../tabs-item'
import * as Styled from './styled'

export const NoteTabs = () => {
  const noteTags = useStore($noteTags)
  const activeTab = useStore($activeTagTab)
  const pending = useStore($getNotesFxPending)
  const error = useStore($getNotesError)

  return (
    <Styled.Root>
      <Box display="flex" justifyContent="center">
        <Styled.Tabs
          orientation="vertical"
          variant="scrollable"
          value={activeTab}
          onChange={(_, id: number) => changeTagTab(id)}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {noteTags.map((item) => (
            <Tab key={item.tag.id} label={item.tag.name} value={item.tag.id} />
          ))}
        </Styled.Tabs>
        <If condition={pending}>
          <Box sx={{ m: 1 }}>
            <Skeleton variant="rounded" width="100%" height={40} />
          </Box>
        </If>
        <Styled.Content>
          <AlertApi
            errorText={error}
            pending={pending}
            loadedEmtpy={!pending && noteTags.length === 0}
            loadingText={!noteTags.length ? 'Заметки загружаются' : 'Заметки обновляются'}
            emptyText={'Пока у вас нету заметок'}
            onReload={() => getNotes()}
          />
          {noteTags.map((item) => (
            <If key={item.tag.id} condition={item.tag.id === activeTab}>
              {item.notes.map((note) => (
                <NoteTabItem key={note.id} note={note} />
              ))}
            </If>
          ))}
        </Styled.Content>
        <EditNoteModal />
      </Box>
    </Styled.Root>
  )
}
