import { Box } from '@mui/material'

import { If } from '../../../../shared/utils/if'
import { useMedia } from '../../../../shared/utils/media'
import { ChatList } from '../chat-list'
import { ChatWindow } from '../chat-window'

type Props = {
  isChatPage?: boolean
}

export const ChatWrapper = ({ isChatPage = false }: Props) => {
  const { isDesktop } = useMedia()
  return (
    <Box sx={{ display: 'flex' }}>
      <If condition={isDesktop || !isChatPage}>
        <ChatList />
      </If>
      <If condition={isDesktop || isChatPage}>
        <ChatWindow />
      </If>
    </Box>
  )
}
