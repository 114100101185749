import { forward, guard, sample } from 'effector'

import * as api from '../../api'
import { $numbers, audioFail, audioWin } from '.'
import {
  activateNumber,
  activateNumberFx,
  getNumbers,
  getNumbersFx,
  resetActiveNumber,
} from './events'

getNumbersFx.use(api.getNumbersApi)
activateNumberFx.use(api.activateNumberApi)

guard({
  source: getNumbers,
  filter: getNumbersFx.pending.map((state) => !state),
  target: getNumbersFx,
})

guard({
  source: activateNumber,
  filter: activateNumberFx.pending.map((state) => !state),
  target: activateNumberFx.prepend((number: number) => ({ number })),
})

forward({
  from: resetActiveNumber,
  to: getNumbers,
})

sample({
  source: $numbers,
  clock: activateNumber,
  fn: (numbers, activeNumber) => {
    const num = numbers.find((item) => item.number === activeNumber)
    return num ? num.isWin : false
  },
}).watch((isWin) => {
  setTimeout(() => {
    if (isWin) audioWin.play()
    else audioFail.play()
  }, 600)
})
