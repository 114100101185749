import { guard } from 'effector'

import * as api from '../../api'
import { finishLevelGame, finishLevelGameFx } from './events'

finishLevelGameFx.use(api.finishLevelGameApi)

guard({
  source: finishLevelGame,
  filter: finishLevelGameFx.pending.map((state) => !state),
  target: finishLevelGameFx,
})
