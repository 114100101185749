import { useLayoutEffect } from 'react'

export const useLockBodyScroll = () => {
  // @ts-ignore
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    // Получаем оригинальное значение overflow у body
    const originalStyle = window.getComputedStyle(document.body).overflow

    // Запрещаем скролл у body
    document.body.style.overflow = 'hidden'

    // Возвращаем оригинальное значение overflow при размонтировании
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Пустой массив зависимостей гарантирует, что эффект запустится только при монтировании и размонтировании
}
