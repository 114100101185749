import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  height: max-content;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.white};

  img {
    width: 20px;
    margin-left: 8px;
  }
`
