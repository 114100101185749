// ./model/effects.ts
import { createEffect } from 'effector'

import { DecryptRequest, DecryptResponse, EncryptRequest, EncryptResponse } from '../types'
import { decryptMessageApi, encryptMessageApi } from './api'

// Encrypt events and effects
export const encryptMessageFx = createEffect<EncryptRequest, EncryptResponse>(encryptMessageApi)

// Decrypt events and effects
export const decryptMessageFx = createEffect<DecryptRequest, DecryptResponse>(decryptMessageApi)
