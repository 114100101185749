import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { logout } from '@/features/auth/models/user/events'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'

import { changeDrawerMenu } from '../../model/xp/events'
import { NavItem } from '../../types'

interface Props {
  window?: () => Window
  isOpen: boolean
  navItems: NavItem[] | null
  username: string
}

export const MobileMenu = ({ window, isOpen, navItems, username }: Props) => {
  const container = typeof window !== 'undefined' ? () => window().document.body : undefined

  return (
    <Box component="nav">
      <Drawer
        container={container}
        anchor="right"
        variant="temporary"
        open={isOpen}
        onClose={() => changeDrawerMenu()}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box onClick={() => changeDrawerMenu()} sx={{ textAlign: 'center', width: 240 }}>
          <Link underline="none" href={getAppPath('/user/profile')}>
            <Typography variant="h6" sx={{ my: 2, color: 'common.black' }}>
              {username}
            </Typography>
          </Link>
          <Divider />
          <List>
            {navItems ? (
              <>
                {navItems.map((item) => (
                  <RouterLink key={item.link} color={grey[900]} to={item.link}>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  </RouterLink>
                ))}
                <ListItem disablePadding onClick={() => logout()}>
                  <ListItemButton sx={{ textAlign: 'center' }}>
                    <ListItemText primary={'Выйти'} />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: 180, height: 60 }} />
              </Stack>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}
