import { LoadingButton } from '@mui/lab'
import { Autocomplete, TextField } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useRef } from 'react'

import { AlertError } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'
import { useMedia } from '@/shared/utils/media'

import { $createNoteError, $createNoteFxPending, $tagList } from '../../model/note'
import { newNoteForm } from '../../model/note/forms'
import * as Styled from './styled'

export const NewNote = () => {
  const tagList = useStore($tagList)
  const { isDesktop } = useMedia()
  const error = useStore($createNoteError)
  const pending = useStore($createNoteFxPending)
  const inputDescription = useRef<HTMLInputElement | null>(null)

  const { fields, submit, errorText } = useForm(newNoteForm)

  return (
    <Styled.Root>
      <Styled.Title variant="h6">Новая заметка</Styled.Title>
      <Autocomplete
        sx={{ mb: 1 }}
        disablePortal
        value={fields.tagName.value}
        onChange={(e, newValue) => {
          fields.tagName.onChange(newValue || '')
          inputDescription.current?.focus()
        }}
        freeSolo
        disabled={pending}
        size="small"
        options={tagList}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="тег"
            onChange={(e) => fields.tagName.onChange(e.target.value)}
          />
        )}
      />
      <TextField
        sx={{ mb: 1 }}
        autoFocus={isDesktop}
        disabled={pending}
        error={fields.text.hasError()}
        helperText={errorText('text')}
        multiline
        value={fields.text.value}
        inputRef={inputDescription}
        variant="standard"
        placeholder="Описание"
        onChange={(e) => fields.text.onChange(e.target.value)}
      />
      <If condition={error}>
        <AlertError text={error} sx={{ mb: 1 }} />
      </If>
      <LoadingButton loading={pending} variant="contained" size="small" onClick={() => submit()}>
        Создать
      </LoadingButton>
    </Styled.Root>
  )
}
