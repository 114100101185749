import { Badge, Box, Link, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { engSrc, numberSrc, snakeSrc } from '@/shared/assets'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { $gameSnakeProfile } from '../../../game-snake/model/game-snake'
import { MenuItem } from '../../ui/menu-item'
import crutilsSrc from './assets/crutils.png'
import puzzleSrc from './assets/puzzle_pig.svg'
import soundsSrc from './assets/sounds.png'
import timerSrc from './assets/timer.png'
import * as Styled from './styled'

export const Apps = () => {
  const profile = useStore($gameSnakeProfile)
  const user = useStore($user)
  return (
    <Styled.Root>
      <Styled.Container>
        <Typography sx={{ my: 3 }} fontSize={30} fontWeight="bold" lineHeight={1.1}>
          Приложения
        </Typography>
        <Styled.AppWrapper>
          <Link underline="none" href={getAppPath('/puzzle')} mb={2}>
            <MenuItem text="Пазлики">
              <Styled.ImageIcon src={puzzleSrc} alt="Пазлики" />
            </MenuItem>
          </Link>
          <Box mb={2}>
            <RouterLink to={ROUTE_PATH.TIMER}>
              <MenuItem text="Таймер">
                <Styled.ImageIcon src={timerSrc} alt="Таймер" />
              </MenuItem>
            </RouterLink>
          </Box>
          <Box mb={2}>
            <RouterLink to={ROUTE_PATH.CRUTILS}>
              <MenuItem text="crutils">
                <Styled.ImageIcon src={crutilsSrc} alt="crutils" />
              </MenuItem>
            </RouterLink>
          </Box>
          <Box sx={{ mb: 2 }}>
            <RouterLink to={ROUTE_PATH.GAME_SNAKE}>
              <Badge badgeContent={profile?.gameCount || 0} color="primary">
                <MenuItem text="Змейка">
                  <Styled.ImageIcon src={snakeSrc} alt="Игра в змейку" />
                </MenuItem>
              </Badge>
            </RouterLink>
          </Box>
          <Box sx={{ mb: 2 }}>
            <RouterLink to={ROUTE_PATH.GAME_ENGAME}>
              <MenuItem text="EnGame">
                <Styled.ImageIcon src={engSrc} alt="EnGame" />
              </MenuItem>
            </RouterLink>
          </Box>
          <Box sx={{ mb: 2 }}>
            <RouterLink to={ROUTE_PATH.GAME_NUMBER}>
              <MenuItem text="Числа">
                <Styled.ImageIcon src={numberSrc} alt="Number" />
              </MenuItem>
            </RouterLink>
          </Box>
          <If condition={user?.isSuperuser}>
            <Box sx={{ mb: 2 }}>
              <RouterLink to={ROUTE_PATH.SOUNDS}>
                <MenuItem text="Песни">
                  <Styled.ImageIcon src={soundsSrc} alt="Sounds" />
                </MenuItem>
              </RouterLink>
            </Box>
          </If>
        </Styled.AppWrapper>
      </Styled.Container>
    </Styled.Root>
  )
}
