import { Box, Button, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { Delayed } from '@/shared/components/delayed'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $endReason, $score, $singleGameResult } from '../../model/game-snake'
import { changeSingleGameModalVisible, resetGame } from '../../model/game-snake/events'
import { Profile, SingleGame } from '../../types'
import { HomeButton } from '../home-button'
import { GameSnakeSingle } from '../single-game/data'
import { END_REASON } from '../single-game/types'

type Props = {
  gameSnake: GameSnakeSingle
  profile: Profile
  singleGame: SingleGame | null
}

export const ResultSingleBlock = ({ gameSnake, profile, singleGame }: Props) => {
  const score = useStore($score)
  const endReason = useStore($endReason)
  const singleGameResult = useStore($singleGameResult)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 1,
        width: 300,
      }}
    >
      <If condition={singleGameResult}>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          <Choose>
            <When condition={endReason === END_REASON.FOOD_COLLECTED}>Вы собрали всю еду!</When>
            <When
              condition={singleGame && score > singleGame.record}
            >{`Новый рекорд на сложности ${singleGame?.difficulty}!`}</When>
            <When condition={endReason === END_REASON.BROKEN}>Игра окончена!</When>
            <When condition={endReason === END_REASON.TIME_OVER}>Время вышло!</When>
          </Choose>
        </Typography>
        <Typography variant="body1" textAlign="left" fontWeight="bold">
          Всего собрано: {score}
        </Typography>
        <If condition={singleGameResult?.foodCount}>
          <Typography variant="body1" textAlign="left" fontWeight="bold">
            Еда: {singleGameResult?.foodCount}
          </Typography>
        </If>
        <If condition={singleGameResult?.waddlescoin}>
          <Typography variant="body1" textAlign="left" fontWeight="bold">
            Пухлякоины: {singleGameResult?.waddlescoin}
          </Typography>
        </If>
        <If condition={singleGameResult?.fortuneCount}>
          <Typography variant="body1" textAlign="left" fontWeight="bold">
            Колёса фортуны: {singleGameResult?.fortuneCount}
          </Typography>
        </If>
      </If>

      <Delayed time={2000}>
        <>
          {profile.gameCount > 1 && (
            <Button
              variant="contained"
              id="btn-reload"
              sx={{ width: 300, my: 2 }}
              onClick={() => {
                gameSnake.clear()
                resetGame()
                changeSingleGameModalVisible(true)
              }}
            >
              Играть ещё
            </Button>
          )}
          <HomeButton
            onClick={() => {
              resetGame()
              gameSnake.clear()
            }}
          />
        </>
      </Delayed>
    </Box>
  )
}
