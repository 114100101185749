import { GetUserError, GetUserParams, GetUserResponse } from '../../types'
import { domain } from '../domain'

export const getUser = domain.event<GetUserParams>('getUser')

export const getUserFx = domain.effect<GetUserParams, GetUserResponse, GetUserError>('getUserFx')

export const setUserError = domain.event<GetUserError>('setUserError')

export const resetUser = domain.event<void>('resetUser')

export const logout = domain.event<void>('logout')

export const changeWaddlesCoin = domain.event<number>('changeWaddlesCoin')

export const changeRuble = domain.event<number>('changeRuble')
