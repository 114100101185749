import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { range } from 'lodash'
import { useMemo } from 'react'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { Choose, When } from '../../../../shared/utils/choose'
import {
  $gameSnakeProfile,
  $getProfileError,
  $getProfilePending,
  $getSingleGamePending,
  $getSnakeSingleGameError,
  $singleGameModalVisible,
} from '../../model/game-snake'
import { changeSingleGameModalVisible } from '../../model/game-snake/events'
import { $singleGameForm } from '../../model/game-snake/forms'

const handleClose = () => changeSingleGameModalVisible(false)

export const ModalSingleGame = () => {
  const modalVisible = useStore($singleGameModalVisible)
  const singleGameError = useStore($getSnakeSingleGameError)
  const singleGamePending = useStore($getSingleGamePending)
  const getProfilePending = useStore($getProfilePending)
  const getProfileError = useStore($getProfileError)
  const profile = useStore($gameSnakeProfile)

  const singleGameLevels = useMemo(
    () => range((profile?.snakeParams.singleGameLevel || 0) + 1),
    [profile]
  )

  const { fields, submit, errorText } = useForm($singleGameForm)

  return (
    <Dialog maxWidth="sm" open={modalVisible} keepMounted onClose={handleClose}>
      <DialogTitle>Одиночная игра</DialogTitle>
      <DialogContent>
        <Choose>
          <When condition={profile}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="single-game-difficulty">Сложность</InputLabel>
              <Select
                label="Сложность"
                labelId="single-game-difficulty"
                variant="standard"
                disabled={singleGamePending}
                error={fields.difficulty.hasError()}
                value={fields.difficulty.value}
                onChange={(e) => fields.difficulty.onChange(Number(e.target.value))}
                MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
              >
                {singleGameLevels.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <If condition={errorText('difficulty')}>
                <FormHelperText error>{errorText('difficulty')}</FormHelperText>
              </If>
            </FormControl>
          </When>
          <When condition={singleGameError || getProfileError}>
            <AlertError text={singleGameError || getProfileError} variant="filled" sx={{ mt: 2 }} />
          </When>
          <When condition={getProfilePending}>
            <AlertLoading text="Данные профиля загружаются" />
          </When>
          <When condition={singleGamePending}>
            <AlertLoading text="Данные об игре загружаются" />
          </When>
        </Choose>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={singleGamePending || getProfilePending}
          color="primary"
          variant="contained"
          onClick={() => submit()}
        >
          Начать игру
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
