import { api } from '@/services/api'
import { EmptyResponse, NumberParam } from '@/shared/types/api'

import {
  CreateEditNotesResponse,
  CreateNoteParams,
  EditNoteParams,
  GetNotesResponse,
} from './types'

export const getNotesApi = async (): Promise<GetNotesResponse> => {
  return api.get('/note/')
}

export const createNoteApi = async (params: CreateNoteParams): Promise<CreateEditNotesResponse> => {
  return api.post('/note/', params)
}
export const editNoteApi = async ({
  id,
  ...params
}: EditNoteParams): Promise<CreateEditNotesResponse> => {
  return api.put(`/note/${id}/`, params)
}

export const deleteNoteApi = async (id: NumberParam): Promise<EmptyResponse> => {
  return api.delete(`/note/${id}/`)
}
