import '../../models/auth/init'

import { Card, CardActions, CardContent, Container, TextField, Typography } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { AlertError } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { $signInError } from '../../models/auth'
import { loginForm } from '../../models/auth/forms'
import { $userAuthPending, $userError } from '../../models/user'
import * as Styled from './styled'

export const Auth = (): any => {
  const signInError = useStore($signInError)
  const userError = useStore($userError)
  const userAuthPending = useStore($userAuthPending)

  const { fields, submit, errorText } = useForm(loginForm)

  return (
    <Container maxWidth="sm" sx={{ mt: '10%' }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
            Войти в аккаунт
          </Typography>
          <Styled.Fields>
            <TextField
              label="Логин"
              autoFocus
              variant="standard"
              error={fields.username.hasError()}
              helperText={errorText('username')}
              value={fields.username.value}
              onKeyDown={(e) => e.key === 'Enter' && submit()}
              onChange={(e) => fields.username.onChange(e.target.value)}
            />
            <TextField
              label="Пароль"
              type="password"
              error={fields.password.hasError()}
              variant="standard"
              helperText={errorText('password')}
              onKeyDown={(e) => e.key === 'Enter' && submit()}
              value={fields.password.value}
              onChange={(e) => fields.password.onChange(e.target.value)}
            />
          </Styled.Fields>
        </CardContent>
        <CardActions>
          <Styled.ButtonSend
            sx={{ width: '100%' }}
            variant="contained"
            loading={userAuthPending}
            onClick={() => submit()}
          >
            Войти
          </Styled.ButtonSend>
        </CardActions>
        <Styled.Errors>
          <If condition={signInError}>
            <AlertError text={signInError} variant="filled" sx={{ mt: 2 }} />
          </If>
          <If condition={userError}>
            <AlertError text={userError} variant="filled" sx={{ mt: 2 }} />
          </If>
        </Styled.Errors>
      </Card>
    </Container>
  )
}
