import { DialogActions, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Title = styled(Typography)`
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
`

export const Actions = styled(DialogActions)`
  display: flex;
`
