import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
