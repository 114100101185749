import { ChatRoom } from '../../type'
import { domain } from '../domain'
import {
  ChangeActiveChatError,
  ChangeActiveChatParams,
  ChangeActiveChatResponse,
  CreateChatError,
  CreateChatParams,
  CreateChatResponse,
  GetUserChatsError,
  GetUserChatsParams,
  GetUserChatsResponse,
} from './types'

export const getUserChatsRoom = domain.event<GetUserChatsParams>('getUserChatsRoom')
export const getUserChatRoomFx = domain.effect<
  GetUserChatsParams,
  GetUserChatsResponse,
  GetUserChatsError
>('getUserChatRoomFx')

export const createChatRoom = domain.event<CreateChatParams>('createChatRoom')
export const createChatRoomFx = domain.effect<
  CreateChatParams,
  CreateChatResponse,
  CreateChatError
>('createChatRoomFx')

export const changeActiveChat = domain.event<ChangeActiveChatParams>('changeActiveChat')
export const changeActiveChatFx = domain.effect<
  ChangeActiveChatParams,
  ChangeActiveChatResponse,
  ChangeActiveChatError
>('changeActiveChatFx')

export const changeOpenChatsMenu = domain.event<boolean>('changeOpenChatsMenu')

export const setActiveChatId = domain.event<number>('setActiveChatId')
export const resetActiveChatId = domain.event<void>('resetActiveChatId')

export const changeModalCreateChat = domain.event<boolean>('changeModalCreateChat')

export const updateUserChatInStore = domain.event<ChatRoom[]>('updateUserChatInStore')
