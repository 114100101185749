import { forward, guard } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { changeWaddlesCoin } from '../../../auth/models/user/events'
import { changeGameSnakeCount } from '../../../game-snake/model/game-snake/events'
import * as api from '../../api'
import { GetFortuneResponse, ModalMode, ProductCode } from '../../types'
import { fortuneData } from '../../ui/modal-fortune-body'
import { getUserXp } from '../xp/events'
import {
  buyFortune,
  buyFortuneFx,
  changeFortuneCount,
  changeFortuneStatsTab,
  changeModalFortuneVisible,
  changeModalMode,
  expiredFortune,
  expiredFortuneFx,
  getFortune,
  getFortuneFx,
  getFortuneStats,
  getFortuneStatsFx,
  reloadFortune,
  saveFortune,
  saveFortuneFx,
  startFortune,
  startFortuneFx,
  updateFortune,
  updateFortuneFx,
} from './events'

getFortuneFx.use(api.getFortuneApi)
updateFortuneFx.use(api.updateFortuneApi)
expiredFortuneFx.use(api.expiredFortuneApi)
saveFortuneFx.use(api.saveFortuneApi)
buyFortuneFx.use(api.buyFortuneApi)
startFortuneFx.use(api.startFortuneApi)
getFortuneStatsFx.use(api.getFortuneStatsApi)

guard({
  source: getFortune,
  filter: getFortuneFx.pending.map((state) => !state),
  target: getFortuneFx,
})

guard({
  source: updateFortune,
  filter: updateFortuneFx.pending.map((state) => !state),
  target: updateFortuneFx,
})

guard({
  source: expiredFortune,
  filter: expiredFortuneFx.pending.map((state) => !state),
  target: expiredFortuneFx,
})

guard({
  source: startFortune,
  filter: startFortuneFx.pending.map((state) => !state),
  target: startFortuneFx,
})

guard({
  source: saveFortune,
  filter: saveFortuneFx.pending.map((state) => !state),
  target: saveFortuneFx,
})

guard({
  source: buyFortune,
  filter: buyFortuneFx.pending.map((state) => !state),
  target: buyFortuneFx,
})

guard({
  source: getFortuneStats,
  filter: getFortuneStatsFx.pending.map((state) => !state),
  target: getFortuneStatsFx,
})

guard({
  source: changeModalFortuneVisible,
  filter: (isVisibale) => isVisibale,
  target: [
    changeFortuneStatsTab.prepend(() => ProductCode.WADDLES_COIN),
    changeModalMode.prepend(() => ModalMode.MAIN),
  ],
})

forward({
  from: saveFortuneFx.doneData,
  to: changeFortuneCount.prepend(() => -1),
})

forward({
  from: buyFortuneFx.doneData,
  to: changeWaddlesCoin.prepend(({ data }) => data.spent),
})

saveFortuneFx.doneData.watch(({ data }) => {
  Storage.remove(STORAGE_NAMES.FORTUNE)

  if (data.productCode === ProductCode.WADDLES_COIN) {
    changeWaddlesCoin(data.count)
  } else if (data.productCode === ProductCode.FORTUNE_NAME) {
    changeFortuneCount(data.count)
  } else if (data.productCode === ProductCode.XP) {
    getUserXp()
  } else if (data.productCode === ProductCode.GAME_SNAKE) {
    changeGameSnakeCount(data.count)
  }
})

guard({
  source: updateFortuneFx.doneData,
  filter: ({ data }) => data.priceUpdated !== 0,
  target: changeWaddlesCoin.prepend((data: GetFortuneResponse) => -data.data.priceUpdated + 1),
})

reloadFortune.watch(() => {
  fortuneData.clear()
  getFortune()
})
