export class AudioService {
  audio: HTMLAudioElement
  MIN_VOLUME = 0
  MAX_VOLUME = 100

  constructor(src: string, volume: number = 100) {
    this.audio = new Audio(src)
    if (volume) {
      this.audio.volume = volume / 100
    }
  }

  get(): HTMLAudioElement {
    return this.audio
  }

  play(): void {
    this.audio.play()
  }
  setVolume(volume: number): void {
    if (volume < this.MIN_VOLUME || volume > this.MAX_VOLUME) {
      throw new Error('Incorrect number')
    }
    this.audio.volume = volume / 100
  }

  pause(): void {
    this.audio.pause()
  }

  stop(): void {
    this.audio.pause()
    this.audio.currentTime = 0
  }

  restart(): void {
    this.audio.pause()
    this.audio.currentTime = 0
    this.audio.play()
  }
}
