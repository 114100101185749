export const getBackgroundColor = ({
  isFuture,
  isToday,
  isFilled,
  loading,
}: {
  isFuture: boolean
  isToday: boolean
  isFilled: boolean
  loading: boolean
}): string => {
  if (loading || isFuture) return 'gray'
  else if (isToday && !isFilled) return 'blue'
  else if (!isFilled) return 'red'
  else if (isFilled) return 'green'

  return 'gray'
}
