import { Tabs as TabsUi } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Tabs = styled(TabsUi)`
  width: 128px;
  button {
    font-size: 12px;
    padding: 8px;
    min-height: 20px;
    min-width: auto;
  }
`

export const Content = styled('div')`
  margin-left: 12px;
  width: 100%;
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`
