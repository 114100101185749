import { guard } from 'effector'

import * as api from '../../api'
import { getNotifications, getNotificationsFx } from './events'

getNotificationsFx.use(api.getNotificationsApi)

guard({
  source: getNotifications,
  filter: getNotificationsFx.pending.map((state) => !state),
  target: getNotificationsFx,
})
