import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material'
import { green, orange } from '@mui/material/colors'
import { useStore } from 'effector-react'
import { useRef } from 'react'

import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $user } from '../../../../../../../auth'
import { User } from '../../../../../../../auth/types'
import { setMenuMessage } from '../../../../../../model/messages/events'
import { Message } from '../../../../../../type'
import { ImageItems } from '../image-items'
import { MessageTextField } from '../message-text-field'

type Props = {
  message: Message
}

export const MessageItem = ({ message }: Props) => {
  const user = useStore($user) as User
  const isAuthor = user.username === message.author.username
  const menuAnchor = useRef<HTMLButtonElement>(null)

  const openMenuMessage = () => {
    if (!menuAnchor.current) return
    setMenuMessage({ message, anchor: menuAnchor.current })
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          justifyContent: isAuthor ? 'right' : 'left',
          flexDirection: isAuthor ? 'row-reverse' : 'row',
        }}
      >
        <Avatar
          sx={{ mr: isAuthor ? 0 : 1, ml: isAuthor ? 1 : 0, width: 20, height: 20 }}
          alt={message.author.username}
          src={getAppPath(message.author.avatar)}
        />
        <Box
          sx={{
            border: `1px solid ${isAuthor ? green[200] : orange[400]}`,
            maxWidth: '80%',
            minWidth: 100,
            p: 1,
            background: isAuthor ? green[100] : orange[200],
            borderRadius: 3,
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <If condition={message.images.length > 0}>
            <Box sx={{ mb: '4px' }}>
              <ImageItems images={message.images} />
            </Box>
          </If>
          <MessageTextField message={message} />

          <Box
            ref={menuAnchor}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            onClick={openMenuMessage}
          >
            <Typography sx={{}} fontSize={10}>
              {message.createdDate}
            </Typography>
            <If condition={message.updatedDate !== message.createdDate}>
              <Tooltip title={message.updatedDate} placement="top">
                <IconButton sx={{ width: 14, height: 14 }}>
                  <EditIcon sx={{ fontSize: 12, ml: 1 }} />
                </IconButton>
              </Tooltip>
            </If>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
