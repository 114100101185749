import { domain } from '../domain'
import {
  clearBackgroundUrl,
  clearHeaderColor,
  clearLogoUrl,
  clearWaddlesUrl,
  getThemeFx,
} from './events'
import { mapBackgroundUrl, mapHeaderColor, mapLogoUrl, mapWaddlesUrl } from './utils'

export const $backgroundUrl = domain
  .createStore<string>(mapBackgroundUrl())
  .on(getThemeFx.doneData, (_, { data }) => mapBackgroundUrl(data.backgroundUrl))
  .on(clearBackgroundUrl, () => mapBackgroundUrl())

export const $backgroundMobileUrl = domain
  .createStore<string>(mapBackgroundUrl())
  .on(getThemeFx.doneData, (_, { data }) => mapBackgroundUrl(data.backgroundMobileUrl))
  .on(clearBackgroundUrl, () => mapBackgroundUrl())

export const $headerColor = domain
  .createStore<string>(mapHeaderColor())
  .on(getThemeFx.doneData, (_, { data }) => mapHeaderColor(data.headerColor))
  .on(clearHeaderColor, () => mapHeaderColor())

export const $waddlesUrl = domain
  .createStore<string>(mapWaddlesUrl())
  .on(getThemeFx.doneData, (_, { data }) => mapWaddlesUrl(data.waddlesUrl))
  .on(clearWaddlesUrl, () => mapWaddlesUrl())

export const $logoUrl = domain
  .createStore<string>(mapLogoUrl())
  .on(getThemeFx.doneData, (_, { data }) => mapLogoUrl(data.logoUrl))
  .on(clearLogoUrl, () => mapLogoUrl())

export const $themeError = domain
  .createStore<string | null>(null)
  .on(getThemeFx.failData, (_, error) => 'Ошибка при загрузке темы')

export const $getThemePending = getThemeFx.pending
