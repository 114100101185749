import { PhotoCamera } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'

import { $reportCreating } from '../../model'
import { createReportFx } from '../../model/events'
import { reportForm } from '../../model/forms'

export const NewDailyNote: React.FC = () => {
  const reportCreating = useStore($reportCreating)
  const { fields, submit, error } = useForm(reportForm)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  const location = useLocation()
  const selectedDate = location.state?.selectedDate || moment().format('YYYY-MM-DD')

  useEffect(() => {
    fields.date.onChange(selectedDate)
    fields.about.onChange('')
    fields.photoOfDay.onChange(null)
    setImagePreview(null)

    const unsubscribeCreate = createReportFx.doneData.watch(() => {
      setIsSubmitted(true)
      setSubmitError(null)
    })

    const unsubscribeCreateError = createReportFx.failData.watch((error) => {
      setSubmitError(error?.response?.data[0] || 'Произошла ошибка при создании записи')
    })

    return () => {
      unsubscribeCreate()
      unsubscribeCreateError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const handleNewReport = () => {
    fields.date.onChange(moment().format('YYYY-MM-DD'))
    fields.about.onChange('')
    fields.photoOfDay.onChange(null)
    setIsSubmitted(false)
    setSubmitError(null)
    setImagePreview(null)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitError(null)
    const target = e.target as HTMLInputElement
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      fields.photoOfDay.onChange(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Box>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 700 }}>
          Новая заметка
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <RouterLink to={ROUTE_PATH.DAILY_NOTES}>
            <Button variant="outlined" color="primary">
              На главную
            </Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.DAILY_NOTES_LIST}>
            <Button variant="outlined" color="primary">
              К списку
            </Button>
          </RouterLink>
        </Box>
      </Box>
      {isSubmitted ? (
        <Box>
          <Alert severity="success" sx={{ mb: 2 }}>
            Запись успешно создана!
          </Alert>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            color="primary"
            onClick={handleNewReport}
          >
            Создать еще заметку
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          {submitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Дата"
            name="date"
            type="date"
            value={fields.date.value}
            onChange={(e) => fields.date.onChange(e.target.value)}
            error={!!error('date')}
            helperText={error('date')?.errorText}
            sx={{ mb: 2 }}
            InputProps={{
              inputProps: {
                max: moment().format('YYYY-MM-DD'), // Ограничение на выбор будущих дат
              },
            }}
          />
          <TextField
            fullWidth
            label="Как прошел день?)"
            name="about"
            multiline
            minRows={1}
            maxRows={6}
            value={fields.about.value}
            onChange={(e) => {
              setSubmitError(null)
              fields.about.onChange(e.target.value)
            }}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mr: 2, height: 60 }}
            >
              Выбрать фото
              <input type="file" accept="image/*" hidden onChange={handleImageChange} />
            </Button>
            {imagePreview && (
              <Box
                component="img"
                src={imagePreview}
                alt="Предпросмотр"
                sx={{ maxWidth: 60, maxHeight: 60, ml: 2, borderRadius: 1 }}
              />
            )}
          </Box>
          {error('photoOfDay') && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error('photoOfDay')?.errorText}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ width: '100%' }}
            color="primary"
            disabled={reportCreating}
            startIcon={reportCreating ? <CircularProgress size={20} /> : null}
          >
            {reportCreating ? 'Отправка...' : 'Создать заметку'}
          </Button>
        </form>
      )}
    </Box>
  )
}
