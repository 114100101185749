import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  width: 100%;
`

export const CenterBlock = styled('div')`
  display: flex;
  justify-content: space-between;
`
