import { LoadingButton } from '@mui/lab'
import { Alert, Box, Snackbar } from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { foodSrc } from '@/shared/assets'
import { AlertError } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { MainGameSnakeGate } from '../../gate'
import {
  $buyGameError,
  $buyGamePending,
  $gameSnakeProfile,
  $getProfilePending,
  $isShowSnackbarShop,
  $upgradeParamPending,
} from '../../model/game-snake'
import {
  buyGame,
  changeVisibleColorModal,
  hideSnackbarShop,
  upgradeParam,
} from '../../model/game-snake/events'
import { GameWrapper } from '../game-wrapper'
import { DialogChangeColor } from './ui/dialog-change-color'

export const GameSnakeShop = () => {
  useGate(MainGameSnakeGate)
  const upgradeParamPending = useStore($upgradeParamPending)
  const buyGamePending = useStore($buyGamePending)
  const profilePending = useStore($getProfilePending)
  const profile = useStore($gameSnakeProfile)
  const buyGameError = useStore($buyGameError)
  const isShowSnackbarShop = useStore($isShowSnackbarShop)

  const singleGameLevel = profile?.snakeParams.singleGameLevel || 0
  const maxSpeed = profile?.snakeParams.maxSpeed || 0
  const singleGamePrice = (singleGameLevel + 1) * 50
  const maxSpeedPrice = (maxSpeed + 1) * 5

  return (
    <GameWrapper title="Змейкашоп">
      <LoadingButton
        loading={buyGamePending}
        disabled={Number(profile?.foodCount) < 30 || profilePending || isShowSnackbarShop}
        variant="contained"
        sx={{ width: '100%', my: 1 }}
        onClick={() => buyGame()}
      >
        <If condition={!profile}>Купить игру</If>
        <If condition={profile}>
          <Box sx={{ mr: 1 }}>Купить игру за 30</Box>
          <img src={foodSrc} alt="Яблоки" width={20} />
        </If>
      </LoadingButton>
      <LoadingButton
        loading={upgradeParamPending}
        disabled={
          Number(profile?.foodCount) < maxSpeedPrice ||
          profilePending ||
          isShowSnackbarShop ||
          maxSpeed === 230
        }
        variant="contained"
        sx={{ width: '100%', my: 1 }}
        onClick={() => upgradeParam({ param: 'max_speed' })}
      >
        <If condition={maxSpeed === 230}>Скорость вкачена максимально</If>
        <If condition={!profile}>Улучшить скорость</If>
        <If condition={profile && maxSpeed !== 230}>
          <Box sx={{ mr: 1 }}>
            ↑ Скорость до {maxSpeed + 1} за {maxSpeedPrice}
          </Box>
          <img src={foodSrc} alt="Яблоки" width={20} />
        </If>
      </LoadingButton>
      <LoadingButton
        loading={upgradeParamPending}
        disabled={
          Number(profile?.foodCount) < singleGamePrice ||
          profilePending ||
          isShowSnackbarShop ||
          singleGameLevel === 20
        }
        variant="contained"
        sx={{ width: '100%', my: 1 }}
        onClick={() => upgradeParam({ param: 'single_game_level' })}
      >
        <If condition={singleGameLevel === 20}>Сложность прокачена по максимуму!</If>
        <If condition={!profile}>Улучшить сложность</If>
        <If condition={profile && singleGameLevel !== 20}>
          <Box sx={{ mr: 1 }}>
            Улучшить сложность до {singleGameLevel + 1} за {singleGamePrice}
          </Box>
          <img src={foodSrc} alt="Яблоки" width={20} />
        </If>
      </LoadingButton>
      <LoadingButton
        loading={upgradeParamPending}
        disabled={profilePending || isShowSnackbarShop}
        variant="contained"
        sx={{ width: '100%', my: 1 }}
        onClick={() => changeVisibleColorModal(true)}
      >
        <Box sx={{ mr: 1 }}>Изменить цвет</Box>
      </LoadingButton>
      <If condition={buyGameError}>
        <AlertError text={buyGameError} />
      </If>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isShowSnackbarShop}
        autoHideDuration={5000}
        onClose={() => hideSnackbarShop()}
      >
        <Alert sx={{ mt: 20 }} severity="success" variant="filled">
          Успешно куплено
        </Alert>
      </Snackbar>
      <DialogChangeColor foodCount={profile?.foodCount} />
    </GameWrapper>
  )
}
