import { api } from '@/services/api'

import {
  ActivateGiftParams,
  ActivateGiftResponse,
  BuyFortuneResponse,
  GetFortuneResponse,
  GetFortuneStatsResponse,
  GetGiftsResponse,
  GetHolidayResponse,
  GetNotificationsResponse,
  GetThemeResponse,
  GetUserXpResponse,
  LastReportResponse,
  PhotoDayResponse,
  SaveFortuneParams,
  SaveFortuneResponse,
  StartFortuneResponse,
} from './types'

export const getUserXpApi = async (): Promise<GetUserXpResponse> => {
  return api.get('/xp/get_user_xp/')
}

export const getPhotoDayApi = async (): Promise<PhotoDayResponse> => {
  return api.get('/daily-notes/photo-days/')
}

export const getLastReportApi = async (): Promise<LastReportResponse> => {
  return api.get('/daily-notes/last/')
}

export const getNotificationsApi = async (): Promise<GetNotificationsResponse> => {
  return api.get('/user/get_notifications/')
}

export const getHolidayApi = async (): Promise<GetHolidayResponse> => {
  return api.get('/calendar/get_holiday_today/')
}

export const getFortuneApi = async (): Promise<GetFortuneResponse> => {
  return api.post('/fortune/get_fortune/')
}

export const updateFortuneApi = async (): Promise<GetFortuneResponse> => {
  return api.post('/fortune/update_fortune/')
}

export const expiredFortuneApi = async (): Promise<GetFortuneResponse> => {
  return api.post('/fortune/expired_fortune/')
}

export const saveFortuneApi = async (params: SaveFortuneParams): Promise<SaveFortuneResponse> => {
  return api.post('/fortune/save_fortune_result/', params)
}

export const buyFortuneApi = async (): Promise<BuyFortuneResponse> => {
  return api.post('/fortune/buy_fortune/')
}

export const startFortuneApi = async (): Promise<StartFortuneResponse> => {
  return api.post('/fortune/start_fortune/')
}

export const getFortuneStatsApi = async (): Promise<GetFortuneStatsResponse> => {
  return api.get('/fortune/get_fortune_stats/')
}

export const getGiftsApi = async (): Promise<GetGiftsResponse> => {
  return api.get('/gift/get_gifts/')
}

export const activateGiftApi = async (
  params: ActivateGiftParams
): Promise<ActivateGiftResponse> => {
  return api.post('/gift/activate/', params)
}

export const getThemeApi = async (): Promise<GetThemeResponse> => {
  return api.get('/theme/get_current_theme/')
}
