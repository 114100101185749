import { orange } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

import { CANVAS_HEIGHT } from '../single-game/consts'

export const Root = styled('div')`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-top: -10px;
    margin-bottom: -6px;
  }
`

export const MainWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  min-height: ${CANVAS_HEIGHT + 230}px;
  width: 375px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  background: ${orange[100]};
`

export const ChildrenWrapper = styled('div')`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
`
