import '../../model/game-snake/init'

import { Box, Button, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { ROUTE_PATH } from '../../../../packages/paths'
import { SingleGameSnakeGate } from '../../gate'
import {
  $finishSingleGameError,
  $finishSingleGamePending,
  $gameSnakeProfile,
  $gameSnakeSingle,
  $gameStatus,
  $getProfileError,
  $getProfilePending,
  $getSingleGamePending,
  $getSnakeSingleGameError,
  $singleGame,
} from '../../model/game-snake'
import { changeSingleGameModalVisible } from '../../model/game-snake/events'
import { Profile } from '../../types'
import { GameWrapper } from '../game-wrapper'
import { HomeButton } from '../home-button'
import { ControlButtons } from '../result-block'
import { ResultSingleBlock } from '../result-single-block'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './consts'
import { GameSnakeSingle } from './data'
import { GAME_STATUS } from './types'

export const GameSnakeSingleGame = () => {
  const canvas = useRef<HTMLCanvasElement | null>(null)
  const gameStatus = useStore($gameStatus)
  const profile = useStore($gameSnakeProfile)
  const singleGame = useStore($singleGame)
  const profilePending = useStore($getProfilePending)
  const singleGamePending = useStore($getSingleGamePending)
  const finishSingleGamePending = useStore($finishSingleGamePending)
  const profileError = useStore($getProfileError)
  const singleGameError = useStore($getSnakeSingleGameError)
  const finishSingleGameError = useStore($finishSingleGameError)
  const gameSnake = useStore($gameSnakeSingle)
  const navigate = useNavigate()

  useGate(SingleGameSnakeGate, { gameSnake, canvas, singleGame, profile })

  useEffect(() => {
    if (!singleGame) navigate(ROUTE_PATH.GAME_SNAKE)
  }, [navigate, singleGame])

  return (
    <GameWrapper withHomeButton={false} withHeader={false}>
      <canvas
        ref={canvas}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        style={{ borderRadius: '4px' }}
      ></canvas>
      <Choose>
        <When condition={profileError || singleGameError || finishSingleGameError}>
          <AlertError
            text={profileError || singleGameError || finishSingleGameError}
            sx={{ mt: 2 }}
          />
        </When>
        <When condition={singleGamePending || profilePending || finishSingleGamePending}>
          <AlertLoading
            text={finishSingleGamePending ? 'Сохраняем результат игры!' : 'Загружаем игру!'}
            sx={{ mt: 2 }}
          />
        </When>
        <When condition={gameStatus === GAME_STATUS.END && profile && gameSnake}>
          <ResultSingleBlock
            singleGame={singleGame}
            gameSnake={gameSnake as GameSnakeSingle}
            profile={profile as Profile}
          />
        </When>
        <When condition={profile}>
          <If condition={gameStatus === GAME_STATUS.START}>
            <Box sx={{ my: 1 }}>
              <If condition={singleGame && singleGame.difficulty !== undefined}>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                  {`Уровень сложности: ${singleGame?.difficulty || 0}`}
                </Typography>
              </If>
              <If condition={singleGame && singleGame.record !== undefined}>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                  {`Ваш рекорд: ${singleGame?.record || 0}`}
                </Typography>
              </If>
              <If condition={profile}>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                  {profile?.gameCount === 0
                    ? 'У вас нет доступных игры'
                    : `Вам доступно игр: ${profile?.gameCount || 0}`}
                </Typography>
              </If>
            </Box>
            <If condition={!singleGame && Number(profile?.gameCount) > 0}>
              <Button
                variant="contained"
                sx={{ width: 300, my: 2 }}
                onClick={() => {
                  changeSingleGameModalVisible(true)
                }}
              >
                Играть
              </Button>
            </If>
          </If>
          <If condition={gameSnake && profile && profile.gameCount > 0}>
            <ControlButtons gameSnake={gameSnake as GameSnakeSingle} gameStatus={gameStatus} />
          </If>
        </When>
      </Choose>
      <If condition={[GAME_STATUS.START].includes(gameStatus)}>
        <Box sx={{ my: 1 }}>
          <HomeButton />
        </Box>
      </If>
    </GameWrapper>
  )
}
