import './init'

import { domain } from './domain'
import { createRatingFx, createSessionFx, getRatingsFx, getSessionsFx } from './events'
import { Rating, Session } from './types'

export const $sessions = domain
  .createStore<Session[]>([])
  .on(getSessionsFx.doneData, (_, { data }) => data)

export const $sessionsLoading = getSessionsFx.pending
export const $sessionCreating = createSessionFx.pending

export const $sessionError = domain
  .createStore<string | null>(null)
  .on(createSessionFx.failData, (_, error) => error.message || 'Ошибка при создании сессии')
  .reset(createSessionFx.done)

export const $ratings = domain
  .createStore<Rating[]>([])
  .on(getRatingsFx.doneData, (_, { data }) => data)

export const $ratingCreating = createRatingFx.pending
