import createMixins from '@mui/material/styles/createMixins'
import createPalette, { Palette } from '@mui/material/styles/createPalette'
import createTransitions from '@mui/material/styles/createTransitions'
import createTypography from '@mui/material/styles/createTypography'
import shadows from '@mui/material/styles/shadows'
import { createTheme } from '@mui/system'

const systemTheme = createTheme()
const basePallette = createPalette({})
const palette: Palette = { ...basePallette }

/**
 * Исходный код:
 * https://github.com/mui/material-ui/blob/master/packages/mui-material/src/styles/createTheme.js
 * colors - https://mui.com/material-ui/customization/color/
 */
export const theme = createTheme({
  breakpoints: systemTheme.breakpoints,
  direction: 'ltr',
  mixins: createMixins(systemTheme.breakpoints, {}),
  // @ts-ignore
  transitions: createTransitions({}),
  palette: palette,
  typography: createTypography(palette, {}),
  shadows: shadows.slice(),
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
})

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.theme = theme
}
