import { Alert } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Alert)`
  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }
  .MuiAlert-message {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
