import { Apps } from '@/features/apps'

import { Template } from '../templates/template'

export const AppsPage = () => {
  return (
    <Template>
      <Apps />
    </Template>
  )
}
