export type Position = {
  x: number
  y: number
}

export enum SIDE {
  UP = 'UP',
  RIGHT = 'RIGHT',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
}
export enum GAME_STATUS {
  START = 'START',
  PAUSE = 'PAUSE',
  PROGRESS = 'PROGRESS',
  END = 'END',
}

export enum END_REASON {
  FOOD_COLLECTED = 'FOOD_COLLECTED',
  BROKEN = 'BROKEN',
  TIME_OVER = 'TIME_OVER',
}

export type GameAudios = {
  bg: HTMLAudioElement
  endLose: HTMLAudioElement
  endTime: HTMLAudioElement
  endWin: HTMLAudioElement
}
