import { createForm } from 'effector-forms'

import { createRating, createSession } from './events'

export const sessionForm = createForm({
  fields: {
    game: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Название игры обязательно',
        },
      ],
    },
    date: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Дата обязательна',
        },
      ],
    },
    duration: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => parseInt(value) > 0,
          errorText: 'Длительность должна быть больше 0',
        },
      ],
    },
    participants: {
      init: [] as string[],
      rules: [
        {
          name: 'required',
          validator: (value: string[]) => value.length > 0,
          errorText: 'Необходимо указать участников',
        },
      ],
    },
    winner: {
      init: '',
      rules: [],
    },
  },
  validateOn: ['submit'],
})

sessionForm.formValidated.watch((formValues) => {
  createSession({
    game: formValues.game,
    date: formValues.date,
    duration_minutes: parseInt(formValues.duration),
    participants: formValues.participants,
    winner: formValues.winner || null,
  })
})

export const ratingForm = createForm({
  fields: {
    session: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Необходимо указать сессию',
        },
      ],
    },
    comment: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Комментарий обязателен',
        },
      ],
    },
    score: {
      init: 5,
      rules: [
        {
          name: 'required',
          validator: (value: number) => value >= 1 && value <= 10,
          errorText: 'Оценка должна быть от 1 до 10',
        },
      ],
    },
  },
  validateOn: ['submit'],
})

ratingForm.formValidated.watch((formValues) => {
  createRating({
    session: formValues.session,
    comment: formValues.comment,
    score: formValues.score,
  })
})
