// ./model/forms.ts
import { createForm } from 'effector-forms'

import { decryptMessageFx, encryptMessageFx } from './effects'

// Encrypt form
export const encryptForm = createForm({
  fields: {
    secretPhrase: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Секретная фраза обязательна',
        },
      ],
    },
    message: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Сообщение обязательно',
        },
      ],
    },
  },
  validateOn: ['submit'],
})

encryptForm.formValidated.watch((values) => {
  encryptMessageFx(values)
})

// Decrypt form by text
export const decryptTextForm = createForm({
  fields: {
    secretPhrase: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Секретная фраза обязательна',
        },
      ],
    },
    encryptedMessage: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Зашифрованное сообщение обязательно',
        },
      ],
    },
  },
  validateOn: ['submit'],
})

decryptTextForm.formValidated.watch((values) => {
  decryptMessageFx({ ...values, qrCode: undefined })
})

// Decrypt form by QR
export const decryptQRForm = createForm({
  fields: {
    secretPhrase: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Секретная фраза обязательна',
        },
      ],
    },
    qrCode: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'QR код обязателен',
        },
      ],
    },
  },
  validateOn: ['submit'],
})

decryptQRForm.formValidated.watch((values) => {
  decryptMessageFx({ ...values, encryptedMessage: undefined })
})
