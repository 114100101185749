import { guard } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { resetUser } from '../../../auth/models/user/events'
import * as api from '../../api'
import { getUserXp, getUserXpFx } from './events'

getUserXpFx.use(api.getUserXpApi)

guard({
  source: getUserXp,
  filter: getUserXpFx.pending.map((state) => !state),
  target: getUserXpFx,
})

getUserXpFx.doneData.watch(({ data }) => {
  Storage.set(STORAGE_NAMES.USER_XP, data)
})

resetUser.watch(() => {
  Storage.clear()
})
