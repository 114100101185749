import { Rule } from 'effector-forms'
import isEmail from 'validator/lib/isEmail'

export const RULES = {
  required: (): Rule<string> => ({
    name: 'required',
    validator: (value) => Boolean(value),
    errorText: 'Поле обязательно для заполнения',
  }),
  email: (): Rule<string> => ({
    name: 'email',
    validator: (value) => isEmail(value),
    errorText: 'Неправильный формат email',
  }),
  minLength: (min: number): Rule<string> => ({
    name: 'minLength',
    validator: (value) => value.length >= min,
    errorText: 'Недостаточно символов',
  }),
  maxLength: (max: number): Rule<string> => ({
    name: 'maxLength',
    validator: (value) => value.length <= max,
    errorText: 'Слишком длинный текст',
  }),
  minValue: (min: number): Rule<number> => ({
    name: 'minValue',
    validator: (value) => Number(value) >= min,
    errorText: 'Значение ниже минимума',
  }),
  maxValue: (max: number): Rule<number> => ({
    name: 'maxValue',
    validator: (value) => Number(value) <= max,
    errorText: 'Значение выше максимума',
  }),
}
