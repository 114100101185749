import { Box, CircularProgress, Divider, Drawer } from '@mui/material'
import { useStore } from 'effector-react'

import { If } from '@/shared/utils/if'

import { changeOpenChatsMenu } from '../../model/chat/events'
import { $openChatMenu, $userChatsPending } from '../../model/chat/store'
import { CreateChat } from './ui/create-chat'
import { UserInfo } from './ui/user-info'

export const ChatRoomsMenu = () => {
  const pending = useStore($userChatsPending)
  const isOpen = useStore($openChatMenu)

  const container = typeof window !== 'undefined' ? () => window.document.body : undefined

  return (
    <Box component="nav">
      <Drawer
        container={container}
        anchor="left"
        variant="temporary"
        open={isOpen}
        onClose={() => changeOpenChatsMenu(false)}
        ModalProps={{ keepMounted: true }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 240,
          }}
        >
          <UserInfo />
          <Divider sx={{ width: '100%' }} />
          <If condition={pending}>
            <Box sx={{ mt: 2 }}>
              <CircularProgress />
            </Box>
          </If>
          <Box flexGrow={1}></Box>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ my: 2 }}>
            <CreateChat />
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}
