import { Crutils } from '@/features/crutils'

import { Template } from '../templates/template'

export const CrutilsPage = () => {
  return (
    <Template>
      <Crutils />
    </Template>
  )
}
