import { AlertProps, Typography, TypographyProps } from '@mui/material'

import * as Styled from './styled'

type Props = AlertProps & {
  text: string
  size?: number
  TypographyProps?: TypographyProps
}
export const AlertInfo = ({ text, TypographyProps = {}, size = 16, ...otherProps }: Props) => {
  return (
    <Styled.Root severity="info" {...otherProps}>
      <Typography
        variant="body2"
        whiteSpace="break-spaces"
        textAlign="left"
        fontSize={size}
        {...TypographyProps}
      >
        {text}
      </Typography>
    </Styled.Root>
  )
}
