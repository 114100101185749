import { combine } from 'effector'

import { domain } from '../domain'
import { chatSocket } from '../ws'
import {
  resetAnswerId,
  resetBattle,
  resetBattleError,
  resetEnemyUsername,
  resetLastDamage,
  resetPlayers,
  resetProfile,
  resetQuestion,
  setAnswerId,
  setBattle,
  setBattleError,
  setBattleStatus,
  setEnemyUsername,
  setPlayers,
  setProfile,
} from './events'
import { Battle, Profile } from './types'

export const $userProfile = domain
  .createStore<Profile | null>(null)
  .on(setProfile, (_, data) => data)
  .reset(resetProfile)

export const $players = domain
  .createStore<Profile[]>([])
  .on(setPlayers, (_, data) => data)
  .reset(resetPlayers)

export const $battle = domain
  .createStore<Battle | null>(null)
  .on(setBattleStatus, (data, status) => (data ? { ...data, status } : null))
  .on(setBattle, (_, data) => data)
  .on(resetQuestion, (data) => (data ? { ...data, activeQuestion: null } : null))
  .on(resetLastDamage, (data) =>
    data
      ? {
          ...data,
          teams: data.teams.map((team) => ({
            ...team,
            players: team.players.map((player) => ({ ...player, lastDamage: null })),
          })),
        }
      : null
  )
  .reset(resetBattle)

export const $battleError = domain
  .createStore<string | null>(null)
  .on(setBattleError, (_, error) => error)
  .reset(resetBattleError)

export const $enemyUsername = domain
  .createStore<string>('')
  .on(setEnemyUsername, (_, username) => username)
  .reset(resetEnemyUsername)

resetProfile.watch(() => {
  chatSocket && chatSocket.close()
})

export const $answerId = domain
  .createStore<number | null>(null)
  .on(setAnswerId, (_, id) => id)
  .reset(resetAnswerId)

export const $battleProfiles = combine({
  profile: $userProfile,
  battle: $battle,
}).map(({ profile, battle }) => {
  const team1 = battle?.teams[0]
  const team2 = battle?.teams[1]
  if (!profile || !team1 || !team2) return null

  const isTeam1 = team1.players.find((item) => item.profile.id === profile.id)
  let userBattleProfile = team1.players[0]
  let enemyBattleProfile = team2.players[0]

  if (!isTeam1) {
    userBattleProfile = team2.players[0]
    enemyBattleProfile = team1.players[0]
  }

  return {
    user: userBattleProfile,
    enemy: enemyBattleProfile,
  }
})
