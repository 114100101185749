import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { WaddlesCoin } from '@/shared/components/waddles-coin'
import { If } from '@/shared/utils/if'

import { $waddlesCoins } from '../../../auth/models/user'
import { MainShopGate } from '../../model/gate'
import { $activeBuyModal, $packs, $packsPending } from '../../model/shop'
import { openBuyModal } from '../../model/shop/events'
import { ConfrimBuy } from './ui/confirm-buy'

export const ShopCards = () => {
  const activeModal = useStore($activeBuyModal)
  const waddleCoins = useStore($waddlesCoins)
  useGate(MainShopGate)

  const pendingPacks = useStore($packsPending)
  const packs = useStore($packs)
  const activePack = packs.find((pack) => pack.type === activeModal) || null

  return (
    <Box sx={{ textAlign: 'center' }}>
      <If condition={pendingPacks}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <CircularProgress />
        </Box>
      </If>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
        {packs.map((item) => (
          <Card
            key={item.id}
            sx={{
              minWidth: 275,
              background: item.background,
              color: item.textColor,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
            }}
          >
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontSize: 20, fontWeight: 700 }} gutterBottom>
                {item.name}
              </Typography>
              <Typography sx={{ fontSize: 16 }} gutterBottom>
                {`В продаже ${item.info.availableCount} шт.`}
              </Typography>
              <WaddlesCoin
                sx={{ color: item.textColor, fontSize: 16 }}
                text="Строимость: "
                count={item.price}
              />
            </CardContent>
            <CardActions>
              <Button
                color={item.buttonColor}
                sx={{ width: '100%' }}
                disabled={item.info.availableCount <= 0 || Number(waddleCoins) < item.price}
                variant="contained"
                onClick={() => openBuyModal(item.type)}
                size="small"
              >
                Купить
              </Button>
            </CardActions>
          </Card>
        ))}
        <ConfrimBuy pack={activePack} />
      </Box>
      <If condition={packs.length > 0}>
        <Box sx={{ my: 3 }}>
          <RouterLink to={ROUTE_PATH.SHOP_TRANSACTIONS}>
            <Button variant="contained">
              <Typography>История</Typography>
            </Button>
          </RouterLink>
        </Box>
      </If>
    </Box>
  )
}
