import { useMediaQuery, useTheme } from '@mui/material'

export const useMedia = (): { isDesktop: boolean; isMobile: boolean; isTablet: boolean } => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isDesktop = !isMobile && !isTablet

  return { isDesktop, isMobile, isTablet }
}
