import { combine, guard, sample } from 'effector'

import * as api from '../../api'
import { $fieldCells, $items, $level } from '.'
import {
  clearFieldCell,
  clearItemsFx,
  createLevelItem,
  createLevelItemFx,
  createNewLevel,
  createNewLevelFx,
  deleteLevelItem,
  deleteLevelItemFx,
  editLevelItem,
  editLevelItemFx,
  fillFieldCell,
  fillManyItemsFx,
  getLevel,
  getLevelFx,
  getLevels,
  getLevelsFx,
} from './events'
import { $fillManyItemsForm } from './forms'
import { calculateManyNewItems, getLevelId } from './utils'

getLevelFx.use(api.getLevelApi)
getLevelsFx.use(api.getLevelsApi)
createNewLevelFx.use(api.createNewLevelApi)
createLevelItemFx.use(api.createLevelItemApi)
deleteLevelItemFx.use(api.deleteLevelItemApi)
editLevelItemFx.use(api.editLevelItemApi)
clearItemsFx.use(api.clearItemsApi)
fillManyItemsFx.use(api.fillManyItemsApi)

guard({
  source: getLevel,
  filter: getLevelFx.pending.map((state) => !state),
  target: getLevelFx,
})

guard({
  source: getLevels,
  filter: getLevelsFx.pending.map((state) => !state),
  target: getLevelsFx,
})

guard({
  source: createLevelItem,
  filter: createLevelItemFx.pending.map((state) => !state),
  target: createLevelItemFx,
})
guard({
  source: deleteLevelItem,
  filter: deleteLevelItemFx.pending.map((state) => !state),
  target: deleteLevelItemFx,
})

guard({
  source: editLevelItem,
  filter: editLevelItemFx.pending.map((state) => !state),
  target: editLevelItemFx,
})

guard({
  source: createNewLevel,
  filter: createNewLevelFx.pending.map((state) => !state),
  target: createNewLevelFx,
})

guard({
  source: fillFieldCell,
  filter: () => Boolean(getLevelId()),
  // @ts-ignore
  target: createLevelItem.prepend(({ x, y, fieldItem, order }) => {
    return {
      x,
      y,
      number: getLevelId(),
      order,
      itemId: fieldItem.id,
    }
  }),
})

guard({
  source: clearFieldCell,
  filter: (item) => Boolean(getLevelId()),
  // @ts-ignore
  target: deleteLevelItem.prepend(({ x, y }) => {
    return { x, y, number: getLevelId() }
  }),
})

sample({
  // @ts-ignore
  source: combine({ level: $level, items: $items, fieldCells: $fieldCells }),
  clock: $fillManyItemsForm.formValidated,
  fn: ({ level, items, fieldCells }, data) => {
    const item = items.find((item) => item.id === data.itemId)
    return {
      number: level?.number,
      items: calculateManyNewItems(fieldCells, data.count, data.hasOrder, item),
    }
  },
  target: fillManyItemsFx,
})
