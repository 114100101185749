import { Note } from '@/features/note'

import { Template } from '../templates/template'

export const NotePage = () => {
  return (
    <Template>
      <Note />
    </Template>
  )
}
