import { Dialog } from '@mui/material'
import { orange } from '@mui/material/colors'
import { useEffect, useState } from 'react'

import * as Styled from './styled'

export const Time = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString())
  const [date, setDate] = useState(new Date().toLocaleDateString())
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const closeDialog = () => {
    setIsOpenDialog(false)
  }

  useEffect(() => {
    setInterval(() => {
      const newTime = new Date().toLocaleTimeString()
      setTime(newTime)

      if (newTime === '00:00:00') {
        setDate(new Date().toLocaleDateString())
      }
    }, 1000)
  }, [])

  return (
    <>
      <Styled.Root onClick={() => setIsOpenDialog(true)}>
        <Styled.Timer>
          <Styled.DateText>{date}</Styled.DateText>
          <Styled.TimerText>{time}</Styled.TimerText>
        </Styled.Timer>
      </Styled.Root>
      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={closeDialog}
        PaperProps={{
          sx: { justifyContent: 'center', backgroundColor: orange[100], cursor: 'pointer' },
        }}
      >
        <Styled.Close onClick={closeDialog} />
        <Styled.DialogWrapper onClick={closeDialog}>
          <Styled.TimerBig>
            <Styled.TimerTextBig>{time}</Styled.TimerTextBig>
            <Styled.DateTextBig>{date}</Styled.DateTextBig>
          </Styled.TimerBig>
        </Styled.DialogWrapper>
      </Dialog>
    </>
  )
}
