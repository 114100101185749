import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { If } from '@/shared/utils/if'

import { $activeChatId } from '../../../../model/chat/store'
import { $messages } from '../../../../model/messages'
import {
  scrollChatMessages,
  setEndChatElement,
  setScrollChatContainer,
} from '../../../../model/messages/events'
import { initiateWebSocketMessagesConnection } from '../../../../model/messages/socket'
import { $messagesPending, $scrollChatContainer } from '../../../../model/messages/store'
import { DeleteMessageModal } from '../delete-message'
import { MessageMenu } from '../message-menu'
import * as Styled from './styled'
import { MessageItem } from './ui/message-item'

export const MessageList = () => {
  const allMessages = useStore($messages)
  const messagesPending = useStore($messagesPending)
  const activeChatId = useStore($activeChatId)
  const chatMessages = activeChatId ? allMessages[activeChatId] : null
  const scrollChatContainer = useStore($scrollChatContainer)
  const [showScrollButton, setShowScrollButton] = useState(false)

  const handleScroll = () => {
    if (scrollChatContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollChatContainer
      if (scrollHeight - scrollTop > clientHeight + 10) {
        setShowScrollButton(true)
      } else {
        setShowScrollButton(false)
      }
    }
  }

  useEffect(() => {
    if (activeChatId) {
      initiateWebSocketMessagesConnection(activeChatId)
    }
  }, [activeChatId])

  useEffect(() => {
    // Добавляем обработчик прокрутки
    if (scrollChatContainer) {
      scrollChatContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollChatContainer) {
        scrollChatContainer.removeEventListener('scroll', handleScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollChatContainer])

  useEffect(() => {
    scrollChatMessages()
  }, [])

  return (
    <Styled.Root
      onScroll={handleScroll}
      ref={(element: HTMLDivElement) => {
        setScrollChatContainer(element)
        scrollChatMessages()
      }}
    >
      <If condition={!messagesPending && chatMessages?.length === 0}>
        <Box sx={{ width: '100%', textAlign: 'center', mt: 5 }}>
          <Typography fontSize={18} fontWeight={700}>
            Сообщений пока что нет
          </Typography>
        </Box>
      </If>

      <Box sx={{ marginTop: 'auto' }}>
        {chatMessages?.map((message) => (
          <MessageItem message={message} key={message.id} />
        ))}
      </Box>
      <If condition={messagesPending}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress size={20} />
        </Box>
      </If>
      <div
        ref={(element) => {
          setEndChatElement(element)
          scrollChatMessages()
        }}
      />
      <If condition={showScrollButton}>
        <IconButton
          onClick={() => scrollChatMessages({ behavior: 'smooth' })}
          size="small"
          color="info"
          sx={{
            width: 24,
            height: 24,
            position: 'fixed',
            bottom: '80px',
            right: 0,
            left: 0,
            margin: '0 auto',
            zIndex: 1,
            backgroundColor: 'primary.main', // Добавляет фоновый цвет кнопке
            '&:hover': {
              backgroundColor: 'primary.dark', // Цвет при наведении
            },
          }}
        >
          <ArrowDownwardIcon sx={{ color: grey[50], fontSize: 22 }} />
        </IconButton>
      </If>
      <MessageMenu />
      <DeleteMessageModal />
    </Styled.Root>
  )
}
