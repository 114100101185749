import {
  DailyNotes,
  DailyNotesCalendar,
  DailyNotesList,
  EditDailyNotes,
  NewDailyNote,
} from '@/features/daily-notes'

import { Template } from '../templates/template'

export const DailyNotesMainPage = () => {
  return (
    <Template>
      <DailyNotes />
    </Template>
  )
}

export const DailyNotesListPage = () => {
  return (
    <Template>
      <DailyNotesList />
    </Template>
  )
}

export const NewDailyNotesPage = () => {
  return (
    <Template>
      <NewDailyNote />
    </Template>
  )
}

export const EditDailyNotesPage = () => {
  return (
    <Template>
      <EditDailyNotes />
    </Template>
  )
}

export const DailyNotesCalendarPage = () => {
  return (
    <Template>
      <DailyNotesCalendar />
    </Template>
  )
}
