import { Edit as EditIcon } from '@mui/icons-material'
import { Button, IconButton, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { memo } from 'react'
import { DndProvider } from 'react-dnd-multi-backend'
import { useParams } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertApi } from '@/shared/components/alert'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $fieldCells, $levelCombine } from '../../../../model/admin'
import { getLevel } from '../../../../model/admin/events'
import { LevelEditGate } from '../../../../model/admin/gate'
import { GameWrapper } from '../../../game-wrapper'
import { FieldCells } from '../field-cells'
import { FieldMenu } from '../field-menu'
import { FieldSettings } from '../field-settings'
import { ListItems } from '../list-items'
import * as Styled from './styled'

export const LevelEditor = memo(() => {
  const { items, level, levelError, levelPending } = useStore($levelCombine)
  const params = useParams()
  const fieldCells = useStore($fieldCells)
  const levelNumber = Number(params.number)

  useGate(LevelEditGate, { activeLevelNumber: levelNumber })

  return (
    <GameWrapper withHeader={false}>
      <Styled.Root>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Редактирование уровня {levelNumber}
          {level && (
            <IconButton
              target="__blank"
              href={getAppPath(`/admin/game_snake/level/${level.id}/change/`)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Typography>
        <If condition={items.length}>
          <DndProvider options={HTML5toTouch}>
            <FieldCells fieldCells={fieldCells} />
            <ListItems title={'Элементы:'} items={items} />
            <FieldMenu />
            <FieldSettings />
          </DndProvider>
        </If>
        <AlertApi
          sx={{ mt: 1 }}
          errorText={levelError}
          pending={levelPending && !items.length}
          loadingText={'Загружается'}
          onReload={() => levelNumber && getLevel(levelNumber)}
        />
        <RouterLink sx={{ my: 2 }} to={`${ROUTE_PATH.GAME_SNAKE_LEVEL_NUMBER}/${levelNumber}`}>
          <Button variant="contained">К уровню</Button>
        </RouterLink>
        <RouterLink to={ROUTE_PATH.GAME_SNAKE_ADMIN}>
          <Button>На главную админки</Button>
        </RouterLink>
      </Styled.Root>
    </GameWrapper>
  )
})
