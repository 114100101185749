import { HelpOutline } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { $happinessLevel, $happinessLoading } from '../../model'
import { getHappinessLevel } from '../../model/events'
import wadddleSrc from './assets/waddles.jpg'

export const HappinessLevel = () => {
  const happinessLevel = useStore($happinessLevel)
  const loading = useStore($happinessLoading)

  useEffect(() => {
    getHappinessLevel()
  }, [])

  const getGradientColor = (value: number) => {
    const red = Math.min(255, (1 - value / 100) * 255)
    const green = Math.min(255, (value / 100) * 255)
    return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, 0)`
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 410,
        maxWidth: 350,
        textAlign: 'center',
        mt: 3,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Более заметная тень
        p: 2,
        borderRadius: 2,
        backgroundColor: 'white', // Добавление белого фона для контраста
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 700, mr: 2 }}>
          Уровень счастья Пухли
        </Typography>
        <Tooltip title="Уровень счастья рассчитывается на основе заполненных дневников за последний год.">
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      ) : happinessLevel !== null ? (
        <>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontSize: 30,
              fontWeight: 'bold',
              color: getGradientColor(happinessLevel),
            }}
          >
            {`${happinessLevel.toFixed(2)}%`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={happinessLevel}
            sx={{
              height: 12,
              mb: 2,
              borderRadius: 5,
              backgroundColor: '#d0d0d0',
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                background: getGradientColor(happinessLevel),
              },
            }}
          />
          <Box component="img" sx={{ width: 240, mb: 2 }} src={wadddleSrc} alt="Waddles" />
        </>
      ) : (
        <Typography color="error">Не удалось загрузить уровень счастья</Typography>
      )}
    </Box>
  )
}
