import { api } from '@/services/api'

import { BuyPackParams, BuyPackResponse, GetPacksResponse } from './types'

export const getPacksApi = async (): Promise<GetPacksResponse> => {
  return api.get('/new_shop/get_packs/')
}

export const buyPackApi = async (params: BuyPackParams): Promise<BuyPackResponse> => {
  return api.post('/new_shop/buy/', params)
}
