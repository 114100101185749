import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
export const ImageHead = styled('img')`
  position: absolute;
  left: 0px;
  top: 0px;
  transform: rotate(270deg);
`

export const SnakeHead = styled(Box)`
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
`

export const Speed = styled(Box)`
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: ${({ theme }) => theme.palette.common.white};
`
