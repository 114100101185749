import { combine } from 'effector'

import { getAppPath } from '@/shared/utils/getPath'

import { FieldCellType } from '../../ui/admin/types'
import { domain } from '../domain'
import {
  clearFieldCell,
  clearItemsFx,
  createNewLevelFx,
  editLevelItem,
  fillFieldCell,
  fillManyItemsFx,
  getLevelFx,
  getLevelsFx,
  resetLevel,
  setMenuAnchor,
} from './events'
import { ItemDataType, LevelData, SimpleLevel } from './types'
import { mapFieldCell } from './utils'

export const $level = domain
  .createStore<LevelData | null>(null)
  .on(getLevelFx.doneData, (_, { data }) => data.level)
  .reset(resetLevel)

export const $items = domain
  .createStore<ItemDataType[]>([])
  .on(getLevelFx.doneData, (_, { data }) =>
    data.items.map((item) => ({ ...item, image: getAppPath(item.image) }))
  )
  .reset(resetLevel)

export const $fieldCells = domain
  .createStore<FieldCellType[]>([])
  .on(getLevelFx.doneData, (store, { data }) => mapFieldCell(store, data.level))
  .on(clearItemsFx.doneData, (store) => store.map((item) => ({ ...item, fieldItem: null })))
  .on(fillManyItemsFx.doneData, (store, { data }) => mapFieldCell(store, data))
  .on(fillFieldCell, (store, { x, y, fieldItem }) =>
    store.map((item) =>
      item.x === x && item.y === y ? { ...item, fieldItem, order: fieldItem?.order || null } : item
    )
  )
  .on(clearFieldCell, (store, { x, y }) =>
    store.map((item) =>
      item.x === x && item.y === y ? { ...item, order: null, fieldItem: null } : item
    )
  )
  .on(editLevelItem, (store, { x, y, order }) =>
    store.map((item) => (item.x === x && item.y === y ? { ...item, order } : item))
  )
  .reset(resetLevel)

export const $menuAnchor = domain
  .createStore<HTMLElement | null>(null)
  .on(setMenuAnchor, (_, item) => item)

export const $levels = domain
  .createStore<SimpleLevel[]>([])
  .on(getLevelsFx.doneData, (_, { data }) => data)
  .on(createNewLevelFx.doneData, (store, { data }) => [...store, data])

// Errors
export const $levelError = domain
  .createStore<string | null>(null)
  .on(getLevelFx.failData, (_, error) => 'Ошибка при загрузке данных')
  .reset([getLevelFx.done])

export const $levelsError = domain
  .createStore<string | null>(null)
  .on(getLevelsFx.failData, (_, error) => 'Ошибка при загрузке данных')
  .reset([getLevelsFx.done])

// Pending
export const $levelPending = getLevelFx.pending
export const $levelsPending = getLevelsFx.pending

// combine
export const $levelCombine = combine({
  level: $level,
  items: $items,
  levelError: $levelError,
  levelPending: $levelPending,
})

export const $levelsCombine = combine({
  levelsData: $levels,
  levelsError: $levelsError,
  levelsPending: $levelsPending,
})
