import { BASE_STORAGE } from '@/shared/consts/bucket'

import { FortuneSector, ProductCode } from '../../types'

const BASE_MUSIC = `${BASE_STORAGE}/music/fortune`

export const audioUrls = {
  background: `${BASE_MUSIC}/fortune24.mp3`,
  negativePrize: `${BASE_MUSIC}/winner--1.mp3`,
  zeroPrize: `${BASE_MUSIC}/winner-0.mp3`,
  usualPrize: `${BASE_MUSIC}/winner-1.mp3`,
  goodPrize: `${BASE_MUSIC}/winner-10.mp3`,
  veryGoodPrize: `${BASE_MUSIC}/winner-50.mp3`,
  perfectPrize: `${BASE_MUSIC}/winner-100.mp3`,
  incrediblePrize: `${BASE_MUSIC}/winner-500.mp3`,
}

export const backgroundAudio = new Audio(audioUrls.background)

export const playAudioResult = (sector: FortuneSector) => {
  if (sector.productCode === ProductCode.WADDLES_COIN) {
    if (sector.count === -1) {
      new Audio(`${BASE_MUSIC}/winner--1.mp3`).play()
    } else if (sector.count === 0) {
      new Audio(`${BASE_MUSIC}/winner-0.mp3`).play()
    } else if (sector.count >= 1 && sector.count <= 5) {
      new Audio(`${BASE_MUSIC}/winner-1.mp3`).play()
    } else if (sector.count === 10) {
      new Audio(`${BASE_MUSIC}/winner-10.mp3`).play()
    } else if (sector.count === 50) {
      new Audio(`${BASE_MUSIC}/winner-50.mp3`).play()
    } else if (sector.count === 100) {
      new Audio(`${BASE_MUSIC}/winner-100.mp3`).play()
    } else if (sector.count === 500 || sector.count === 1000) {
      new Audio(`${BASE_MUSIC}/winner-500.mp3`).play()
    }
  } else if (sector.productCode === ProductCode.FORTUNE_NAME) {
    if (sector.count < 5) {
      new Audio(`${BASE_MUSIC}/winner-1.mp3`).play()
    } else {
      new Audio(`${BASE_MUSIC}/winner-10.mp3`).play()
    }
  } else if (sector.productCode === ProductCode.XP) {
    if (sector.count < 5) {
      new Audio(`${BASE_MUSIC}/winner-10.mp3`).play()
    } else {
      new Audio(`${BASE_MUSIC}/winner-50.mp3`).play()
    }
  } else if (sector.productCode === ProductCode.DETAIL_BOARD_GAME) {
    new Audio(`${BASE_MUSIC}/winner-10.mp3`).play()
  } else {
    new Audio(`${BASE_MUSIC}/winner-50.mp3`).play()
  }
}
