import { Add as AddIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { useStore } from 'effector-react'

import { If } from '@/shared/utils/if'

import { toggleInviteDialog } from '../../model/dialog/events'
import { $players, $userProfile } from '../../model/game'
import { setEnemyUsername } from '../../model/game/events'
import { BattleConfirmDialog } from '../battle-confirm-dialog'
import { BattleInviteDialog } from '../battle-invite-dialog'
import * as Styled from './styled'

export const EnGameMain = () => {
  // const battle = useStore($battle)
  const profile = useStore($userProfile)
  const players = useStore($players)

  return (
    <Styled.Root>
      <Box component="h1" textAlign="center" fontSize="40px">
        Engame
      </Box>
      <Box
        sx={{ position: 'absolute', right: 20, top: 20 }}
        onClick={() => window.location.reload()}
      >
        <Chip
          label={profile ? 'Online' : 'Offline'}
          color={profile ? 'success' : 'error'}
          size="small"
        />
      </Box>
      <Button
        size="large"
        color="success"
        sx={{ mt: 1, maxWidth: '300px' }}
        variant="contained"
        disabled={!profile}
        onClick={() => toggleInviteDialog(true)}
      >
        В бой
      </Button>
      <If condition={players.length > 0}>
        <List
          sx={{
            width: '100%',
            mt: 3,
            maxWidth: 360,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}
          subheader={<li />}
        >
          <Typography variant="h5" sx={{ p: 2 }}>
            Игроки
          </Typography>
          <Divider />
          {players.map((player) => (
            <ListItem key={player.id}>
              <ListItemText primary={player.username} />
              <Chip
                label={!!player.channel ? 'Online' : 'Offline'}
                color={!!player.channel ? 'success' : 'error'}
                size="small"
              />
              <IconButton sx={{ ml: 2 }} disabled={!player.channel}>
                <AddIcon
                  onClick={() => {
                    setEnemyUsername(player.username)
                    toggleInviteDialog(true)
                  }}
                />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </If>
      <BattleInviteDialog />
      <BattleConfirmDialog />
    </Styled.Root>
  )
}
