import { combine } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { Message } from '../../type'
import { resetActiveChatId } from '../chat/events'
import { $chatSocketOnline } from '../chat/socket/init'
import { domain } from '../domain'
import {
  addMessageInStore,
  changeDialogImage,
  deleteMessageFx,
  deleteMessageInStore,
  editMessageFx,
  editMessageInStore,
  fileChanged,
  getMessageForStore,
  getMessagesRoomFx,
  resetDeleteMessageId,
  resetEditMessage,
  resetMenuMessage,
  resetScrollChatConteiner,
  resetScrollHeightMessageContainer,
  sendMessageFx,
  setDeleteMessageId,
  setEditMessage,
  setEndChatElement,
  setMenuMessage,
  setScrollChatContainer,
  setScrollHeightMessageContainer,
  setSendInput,
} from './events'
import { $messagesSocketOnline } from './socket/init'
import { ChatMessageStore, MenuMessageType } from './types'

export const initMessages = Storage.get<ChatMessageStore>(STORAGE_NAMES.MESSAGES) || {}

export const $messages = domain
  .createStore<ChatMessageStore>(initMessages)
  .on(getMessagesRoomFx.doneData, (store, { data }) => ({
    ...store,
    [data.chatId]: data.results.reverse(),
  }))
  .on(getMessageForStore, (store, { chatId, messages }) => ({
    ...store,
    [chatId]: messages.reverse(),
  }))
  .on(deleteMessageInStore, (store, { chatId, messageId }) => ({
    ...store,
    [chatId]: store[chatId].filter((item) => item.id !== messageId),
  }))
  .on(editMessageInStore, (store, { chatId, messageId, text }) => ({
    ...store,
    [chatId]: store[chatId].map((item) => (item.id !== messageId ? item : { ...item, text })),
  }))
  .on(addMessageInStore, (store, { chatId, message }) => ({
    ...store,
    [chatId]: [...store[chatId], message],
  }))
$messages.watch((data) => {
  Storage.set(STORAGE_NAMES.MESSAGES, data)
})

export const $maxMessagesCount = domain
  .createStore<number | null>(null)
  .on(getMessagesRoomFx.doneData, (store, { data }) => data.count)

export const $scrollHeightMessageContainer = domain
  .createStore<number | null>(null)
  .on(setScrollHeightMessageContainer, (_, height) => height)
  .reset(resetScrollHeightMessageContainer, resetActiveChatId)

export const $menuMessage = domain
  .createStore<MenuMessageType>({ message: null, anchor: null })
  .on(setMenuMessage, (_, message) => message)
  .reset(resetMenuMessage)

export const $editMessage = domain
  .createStore<Message | null>(null)
  .on(setEditMessage, (_, message) => message)
  .reset(resetEditMessage)

export const $deleteMessageId = domain
  .createStore<number | null>(null)
  .on(setDeleteMessageId, (_, id) => id)
  .reset(resetDeleteMessageId)

export const $endChatElement = domain
  .createStore<HTMLDivElement | null>(null)
  .on(setEndChatElement, (_, id) => id)
  .reset(resetScrollChatConteiner)

export const $scrollChatContainer = domain
  .createStore<HTMLDivElement | null>(null)
  .on(setScrollChatContainer, (_, id) => id)
  .reset(resetScrollChatConteiner)

export const $messagesPending = getMessagesRoomFx.pending
export const $sendMessagePending = sendMessageFx.pending

export const $editMessagePending = editMessageFx.pending
export const $deleteMessagePending = deleteMessageFx.pending

// Создаем хранилище для файла
export const $imageFile = domain.createStore<File | null>(null).on(fileChanged, (_, file) => file)

// Создаем хранилище для файла
export const $dialogImage = domain
  .createStore<string | null>(null)
  .on(changeDialogImage, (_, isActive) => isActive)

// Создаем хранилище для файла
export const $inputSend = domain
  .createStore<HTMLInputElement | null>(null)
  .on(setSendInput, (_, input) => input)

export const $socketsIsOnline = combine({
  chatSocket: $chatSocketOnline,
  messageSocket: $messagesSocketOnline,
}).map(({ chatSocket, messageSocket }) => chatSocket && messageSocket)
