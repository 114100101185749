import { DefaultError, EmptyParams } from '@/shared/types/api'

import {
  BuyGameResponse,
  FinishSingleGameParams,
  FinishSingleGameResponse,
  GetProfileResponse,
  GetSnakeSingleGameParams,
  GetSnakeSingleGameResponse,
  StatsResponse,
  UpdateParamRequest,
  UpdateParamResponse,
  UpgradeParamRequest,
  UpgradeParamResponse,
} from '../../types'
import { GameSnakeSingle } from '../../ui/single-game/data'
import { END_REASON, GAME_STATUS } from '../../ui/single-game/types'
import { domain } from '../domain'

export const finishSingleGame = domain.event<FinishSingleGameParams>('finishSingleGame')

export const finishSingleGameFx = domain.effect<
  FinishSingleGameParams,
  FinishSingleGameResponse,
  DefaultError
>('finishSingleGameFx')

export const getProfile = domain.event<EmptyParams>('getProfile')
export const getProfileFx = domain.effect<EmptyParams, GetProfileResponse, DefaultError>(
  'getProfileFx'
)
export const buyGame = domain.event<EmptyParams>('buyGame')
export const buyGameFx = domain.effect<EmptyParams, BuyGameResponse, DefaultError>('buyGameFx')

export const getStats = domain.event<EmptyParams>('getStats')
export const getStatsFx = domain.effect<EmptyParams, StatsResponse, DefaultError>('getStatsFx')

export const upgradeParam = domain.event<UpgradeParamRequest>('upgradeParam')
export const upgradeParamFx = domain.effect<
  UpgradeParamRequest,
  UpgradeParamResponse,
  DefaultError
>('upgradeParamFx')

export const updateParam = domain.event<UpdateParamRequest>('updateParam')
export const updateParamFx = domain.effect<UpdateParamRequest, UpdateParamResponse, DefaultError>(
  'updateParamFx'
)

export const getSingleGame = domain.event<GetSnakeSingleGameParams>('getSingleGame')
export const getSingleGameFx = domain.effect<
  GetSnakeSingleGameParams,
  GetSnakeSingleGameResponse,
  DefaultError
>('getSingleGameFx')

export const setScore = domain.event<number>('setScore')

export const changeGameStatus = domain.event<GAME_STATUS>('changeGameStatus')
export const resetGame = domain.event<void>('resetGame')

export const setGameRecord = domain.event<number>('setGameRecord')
export const changeGameSnakeCount = domain.event<number>('changeGameSnakeCount')

export const setEndReason = domain.event<END_REASON>('setEndReason')

export const setGameSnakeSingle = domain.event<GameSnakeSingle>('setGameSnakeSingle')

export const hideSnackbarShop = domain.event<void>('hideSnackbarShop')

export const changeSingleGameModalVisible = domain.event<boolean>('changeSingleGameModalVisible')

export const setSnackbarSingleGame = domain.event<boolean>('setSnackbarSingleGame')

export const changeVisibleColorModal = domain.event<boolean>('changeVisibleColorModal')

export const changeSnakeColor = domain.event<string>('changeVisibleColorModal')
