import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { If } from '@/shared/utils/if'

import { $level } from '../../../../model/admin'
import { clearFieldCell, fillFieldCell, setMenuAnchor } from '../../../../model/admin/events'
import { ItemDataType, ItemType } from '../../../../model/admin/types'
import * as Styled from './styled'

export interface FieldCellProps {
  fieldItem: ItemDataType | null
  cellId: number
  order: number | null
  x: number
  y: number
}

export const FieldCell: React.FC<FieldCellProps> = memo(({ fieldItem, cellId, x, y, order }) => {
  const level = useStore($level)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: Object.values(ItemType),
    drop: (item: ItemDataType) => {
      return fillFieldCell({
        id: cellId,
        fieldItem: item,
        x,
        y,
        order: order || item.order || null,
      })
    },
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
  })

  const [{ opacity }, drag] = useDrag({
    type: fieldItem?.itemType || '',
    item: { ...fieldItem, order },
    end: (item, monitor) => {
      return monitor.didDrop() && clearFieldCell({ x, y })
    },
    collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.4 : 1 }),
  })

  return (
    <Styled.Root
      ref={drop}
      sx={{
        width: `${100 / (level?.fieldSize || 1)}%`,
        height: `${100 / (level?.fieldSize || 1)}%`,
        backgroundColor: isOver && canDrop ? 'darkgreen' : '#64DD17',
      }}
    >
      {fieldItem && (
        <Box
          ref={drag}
          data-cell-order={order}
          data-cell-id={fieldItem.id}
          data-cell-x={x}
          data-cell-y={y}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          sx={{ opacity, cursor: 'pointer', position: 'relative' }}
        >
          <img src={fieldItem.image} width={'100%'} height={'100%'} alt={fieldItem.name} />
          <If condition={order}>
            <Typography
              color="white"
              sx={{ position: 'absolute', top: -10, left: 0, right: 0, bottom: 0, margin: 'auto' }}
            >
              {order}
            </Typography>
          </If>
        </Box>
      )}
    </Styled.Root>
  )
})
