import { forward, guard } from 'effector'

import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'

import * as api from './api'
import { buyPack, buyPackFx, closeBuyModal, getPacks, getPacksFx } from './events'

getPacksFx.use(api.getPacksApi)

guard({
  source: getPacks,
  filter: getPacksFx.pending.map((state) => !state),
  target: getPacksFx,
})

buyPackFx.use(api.buyPackApi)

guard({
  source: buyPack,
  filter: buyPackFx.pending.map((state) => !state),
  target: buyPackFx,
})

forward({
  from: buyPackFx.done,
  to: [closeBuyModal, getPacks],
})

buyPackFx.done.watch(() => {
  router.navigate(ROUTE_PATH.SHOP_PACK)
})
