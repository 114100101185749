import { Button, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertApi } from '@/shared/components/alert'
import { Delayed } from '@/shared/components/delayed'
import { RouterLink } from '@/shared/components/router-link'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'
import { pluralize, PLURALIZE_WORD } from '@/shared/utils/pluralize'

import { LevelData } from '../../model/admin/types'
import { $levelGameCombined, $score } from '../../model/level'
import { resetLevelGame } from '../../model/level/events'
import { HomeButton } from '../home-button'
import { GameSnakeLevel } from '../level-number/data'
import { RewardBlock } from '../level-number/ui/reward-block'
import * as Styled from './styled'

type Props = {
  gameSnake: GameSnakeLevel
  level: LevelData
}

export const ResultLevelBlock = ({ gameSnake, level }: Props) => {
  const score = useStore($score)
  const { levelGameResult, levelGameResultError, levelGameResultPending } =
    useStore($levelGameCombined)

  return (
    <Styled.Root>
      <AlertApi
        errorText={levelGameResultError}
        pending={levelGameResultPending}
        loadingText={'Обрабатывается результат игры'}
      />
      <If condition={levelGameResult}>
        <Typography variant="h6" fontWeight="bold" sx={{ mt: 1 }}>
          <Choose>
            <When condition={levelGameResult?.isOpenNewLevel}>
              Ура!!! Открыт новый уровень!
              <br />
              <RewardBlock levelGame={level} title="Ваша награда:" />
            </When>
            <When condition={levelGameResult?.isPurpose}>Ура! Уровень пройден!</When>
            <Otherwise>{`Набрано ${score} из ${pluralize(
              level.purpose,
              PLURALIZE_WORD.SCORE,
              true
            )}`}</Otherwise>
          </Choose>
        </Typography>
      </If>

      <Delayed time={2000}>
        <>
          <RouterLink to={ROUTE_PATH.GAME_SNAKE_LEVELS} sx={{ mt: 2 }}>
            <Button color="secondary" sx={{ width: 300 }}>
              К уровням
            </Button>
          </RouterLink>
          <HomeButton
            onClick={() => {
              gameSnake.clear()
              resetLevelGame()
            }}
          />
        </>
      </Delayed>
    </Styled.Root>
  )
}
