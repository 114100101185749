import { sortBy } from 'lodash'

import { chooseMany } from '@/shared/utils/random'

import { getAppPath } from '../../../../shared/utils/getPath'
import { FieldCellType } from '../../ui/admin/types'
import { createDefaultField } from '../../ui/admin/utils'
import { ItemDataType, LevelData } from './types'

type Args = {
  x: number
  y: number
}

export const getXYbyId = (id: number, fieldSize: number): Args => {
  const x = id % fieldSize
  const y = Math.floor(id / fieldSize)
  return { x, y }
}

export const mapFieldCell = (cells: FieldCellType[], level: LevelData): FieldCellType[] => {
  const newCells = cells.length > 0 ? cells : createDefaultField(level.fieldSize)

  return newCells.map((cell) => {
    const newItem = level.items.find((item) => item.x === cell.x && item.y === cell.y)
    if (newItem) {
      const { x, y, item, order } = newItem
      return { ...cell, x, y, order, fieldItem: { ...item, image: getAppPath(item.image) } }
    }

    return cell
  })
}

export const getLevelId = (): number => {
  const paths = window.location.pathname.split('/')
  const levelId = Number(paths[paths.length - 1])
  return levelId
}

export const calculateManyNewItems = (
  fieldCells: FieldCellType[],
  count: number,
  hasOrder: boolean,
  product?: ItemDataType
): Array<{
  fieldItem?: ItemDataType
  itemId: number
  x: number
  y: number
}> => {
  const emptyFieldCells = fieldCells.filter((item) => item.fieldItem === null)
  const newFields = chooseMany(emptyFieldCells, count)
  if (!product || newFields.length === 0) return []
  let maxOrder: number | null = null
  const filteredOrder = fieldCells.filter((item) => item.order !== null)
  if (hasOrder) {
    const value = sortBy(filteredOrder, ['order'])[filteredOrder.length - 1]
    maxOrder = value?.order || 0
  }

  const newItems = newFields.map((item) => {
    if (hasOrder && maxOrder !== null) maxOrder++
    return {
      fieldItem: product,
      itemId: product.id,
      order: maxOrder,
      x: item.x,
      y: item.y,
    }
  })

  return newItems
}
