import { Alert, Button, Snackbar } from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'

import { MainGameSnakeGate } from '../../gate'
import { $gameSnakeProfile, $snackbarSingleGameVisible } from '../../model/game-snake'
import { changeSingleGameModalVisible, setSnackbarSingleGame } from '../../model/game-snake/events'
import { GameWrapper } from '../game-wrapper'
import { ModalSingleGame } from '../modal-single-game'
import { SnakeColor } from '../snake-color'
import * as Styled from './styled'

export const GameSnakeMain = () => {
  useGate(MainGameSnakeGate)
  const singleGameSnackbarVisible = useStore($snackbarSingleGameVisible)
  const profile = useStore($gameSnakeProfile)

  return (
    <GameWrapper title="Змейка" withHomeButton={false}>
      <Styled.SnakeWrapper>
        <SnakeColor sx={{ mt: 1, mb: 2 }} size={30} withSpeed />
      </Styled.SnakeWrapper>
      <Button
        variant="contained"
        sx={{ width: '100%', mb: 1 }}
        onClick={() =>
          Number(profile?.gameCount) > 0
            ? changeSingleGameModalVisible(true)
            : setSnackbarSingleGame(true)
        }
      >
        Одиночная игра
      </Button>
      <RouterLink to={ROUTE_PATH.GAME_SNAKE_LEVELS} sx={{ width: '100%', mb: 1 }}>
        <Button variant="contained" sx={{ width: '100%' }}>
          Режим кампании
        </Button>
      </RouterLink>
      <RouterLink to={ROUTE_PATH.GAME_SNAKE_SHOP} sx={{ width: '100%', mb: 1 }}>
        <Button variant="contained" sx={{ width: '100%' }}>
          Змейкашоп
        </Button>
      </RouterLink>
      <RouterLink to={ROUTE_PATH.GAME_SNAKE_STATS} sx={{ width: '100%', mb: 1 }}>
        <Button variant="contained" sx={{ width: '100%' }}>
          Статистика и рекорды
        </Button>
      </RouterLink>
      <RouterLink to={ROUTE_PATH.GAME_SNAKE_PARAMS} sx={{ width: '100%', mb: 1 }}>
        <Button variant="contained" sx={{ width: '100%' }}>
          Параметры
        </Button>
      </RouterLink>
      <ModalSingleGame />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={singleGameSnackbarVisible}
        autoHideDuration={6000}
        onClose={() => setSnackbarSingleGame(false)}
      >
        <Alert
          severity="error"
          sx={{ marginTop: 20, whiteSpace: 'pre-wrap' }}
          variant="filled"
          onClose={() => setSnackbarSingleGame(false)}
        >
          {'У вас нет доступных игр!\n Игры можно купить в змейкашопе!'}
        </Alert>
      </Snackbar>
    </GameWrapper>
  )
}
