import { ChatRoom } from '../../../type'

export enum SocketType {
  UPDATE_CHAT_LIST = 'update_chat_list',
}

interface UpdateChatRoomEvent {
  type: SocketType.UPDATE_CHAT_LIST
  chats: ChatRoom[]
}

export type WebSocketEvent = UpdateChatRoomEvent
