import { api } from '@/services/api'

import {
  JoinChatParams,
  JoinChatResponse,
  UpdateChatRoomParams,
  UpdateChatRoomResponse,
} from './types'

export const updateChatApi = async (
  params: UpdateChatRoomParams
): Promise<UpdateChatRoomResponse> => {
  const { chatId, title, avatar } = params
  const formData = new FormData()

  formData.append('title', title)
  if (avatar) {
    formData.append('avatar', avatar)
  }

  return api.patch(`chat/${chatId}/update/`, formData)
}

export const joinChatApi = async (params: JoinChatParams): Promise<JoinChatResponse> => {
  return api.post(`/chat/join/${params.chatId}/`)
}
