import '../../model/single-timer-process/init'

import { Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material'
import { Alert, Box, IconButton, List, TextField, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useRef } from 'react'

import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { TimerStatus } from '../../enums'
import { $isEditRoundTime, $roundTime } from '../../model/multi-timer'
import {
  changeIsEditRoundTime,
  setAudioEndTimer,
  setAudioTickTimer,
  setRoundTime,
  updateRound,
  updateStatus,
} from '../../model/multi-timer/events'
import { Timer } from '../../types'
import { MultiUserCard } from '../multi-user-card'
import * as Styled from './styled'
import { getAlertInfo } from './utils'

type MultiUserProcessProps = {
  timer: Timer
}

export const MultiUserProcess = ({ timer }: MultiUserProcessProps) => {
  const user = useStore($user)
  const isEditRoundTime = useStore($isEditRoundTime)
  const roundTime = useStore($roundTime)
  const refTick = useRef<HTMLAudioElement>(null)
  const refEnd = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    refTick.current && setAudioTickTimer(refTick.current)
    refEnd.current && setAudioEndTimer(refEnd.current)
  }, [refEnd, refTick])

  if (!user) throw new Error('User not found')

  const isAdmin = user.id === timer.admin.id
  const alertInfo = getAlertInfo(timer)

  return (
    <>
      <Typography sx={{ textAlign: 'center', mb: 2 }} variant="h3" color="initial">
        Таймер
      </Typography>
      <Alert severity="info">
        {alertInfo.text}
        <br />
        Длинна раунда:{' '}
        {isEditRoundTime ? (
          <TextField
            sx={{ maxWidth: 50 }}
            value={roundTime || ''}
            size="small"
            type="number"
            variant="standard"
            onChange={(e) => setRoundTime(Number(e.target.value))}
          />
        ) : (
          <span>{timer.roundSeconds}</span>
        )}{' '}
        сек.
        <If condition={isAdmin}>
          <IconButton size="small" onClick={() => changeIsEditRoundTime(true)}>
            <If condition={!isEditRoundTime}>
              <EditIcon fontSize="small" color="info" />
            </If>
          </IconButton>
          <If condition={isEditRoundTime}>
            <IconButton
              disabled={!roundTime}
              size="small"
              onClick={() =>
                timer && roundTime && updateRound({ id: timer.id, roundSeconds: roundTime })
              }
            >
              <DoneIcon fontSize="small" color="info" />
            </IconButton>
          </If>
        </If>
      </Alert>
      <audio ref={refTick} src="https://b.avodom.ru/media/files/audio/tick.mp3" />
      <audio ref={refEnd} src="https://b.avodom.ru/media/files/audio/end.mp3" />
      <List>
        {timer.participants.map((user, number) => {
          const isLastUser = timer.participants.length - 1 === number
          const nextUserId: number = isLastUser
            ? timer.participants[0].id
            : timer.participants[number + 1].id
          return <MultiUserCard key={user.id} userCard={user} nextUserId={nextUserId} />
        })}
      </List>
      <If condition={isAdmin}>
        <Box mt={2}>
          <Choose>
            <When condition={[TimerStatus.RUNNING, TimerStatus.PAUSE].includes(timer.status)}>
              <Styled.Button
                variant="contained"
                onClick={() => updateStatus({ id: timer.id, status: TimerStatus.WAIT })}
              >
                Приостановить
              </Styled.Button>
            </When>
            <When condition={[TimerStatus.WAIT].includes(timer.status)}>
              <Styled.Button
                variant="contained"
                sx={{ marginTop: 1 }}
                onClick={() => updateStatus({ id: timer.id, status: TimerStatus.STOP })}
              >
                Завершить таймер
              </Styled.Button>
            </When>
          </Choose>
        </Box>
      </If>
    </>
  )
}
