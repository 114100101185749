import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WheelOfFortune = styled('div')`
  display: inline-flex;
  position: relative;
  overflow: hidden;
`

export const Wheel = styled('div')`
  display: block;
`

export const SpinFortune = styled('span')`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 20%;
  height: 20%;
  color: #fff;
  box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;
  font-size: 30px;
  &:after {
    content: '';
    position: absolute;
    top: -30px;
    border: 24px solid transparent;
    border-bottom-color: currentColor;
    border-top: none;
    border-width: 0 10px 30px 10px;
  }
`

export const Result = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ResultText = styled(Typography)`
  white-space: pre-line;
  margin-top: 16px;
  max-width: 280px;
  font-weight: bold;
`
