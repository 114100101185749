import './init'

import { forward } from 'effector'

import { ShopCardItem, ShopCardKey } from '../../type'
import { domain } from '../domain'
import {
  buyPackFx,
  clearActiveCard,
  closeBuyModal,
  getPacksFx,
  openBuyModal,
  startOpenPack,
} from './events'
import { PackPrize } from './types'

export const $activeBuyModal = domain
  .createStore<ShopCardKey | null>(null)
  .on(openBuyModal, (_, item) => item)
  .reset(closeBuyModal)

export const $activeCard = domain
  .createStore<ShopCardItem | null>(null)
  .on(startOpenPack, (_, item) => item)
  .reset(clearActiveCard)

export const $packs = domain
  .createStore<ShopCardItem[]>([])
  .on(getPacksFx.doneData, (_, { data }) => data)

export const $packsPending = getPacksFx.pending

export const $buyPackPending = buyPackFx.pending

export const $packPrize = domain
  .createStore<PackPrize | null>(null)
  .on(buyPackFx.doneData, (_, { data }) => data)
  .reset(clearActiveCard)

forward({
  from: buyPackFx.doneData,
  to: startOpenPack.prepend(({ data }) => {
    const packs = $packs.getState()
    const activeCardKey = $activeBuyModal.getState()
    return packs.find((item) => item.type === activeCardKey) || null
  }),
})
