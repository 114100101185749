import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { UserXpResponse } from '../../types'
import { domain } from '../domain'
import { getUserXpFx } from './events'

export const initUserXp = Storage.get<UserXpResponse>(STORAGE_NAMES.USER_XP)

export const $userXp = domain
  .createStore<UserXpResponse | null>(initUserXp)
  .on(getUserXpFx.doneData, (_, { data }) => data)

export const $userXpError = domain
  .createStore<string | null>(null)
  .on(getUserXpFx.failData, (_, error) => 'Ошибка при загрузке опыта пользователя')

export const $getUserXpPending = getUserXpFx.pending
