import { DefaultError, EmptyParams } from '@/shared/types/api'

import { ActivateNumber, GetNumbers } from '../../types'
import { domain } from '../domain'

export const getNumbers = domain.event<EmptyParams>('getNotes')

export const getNumbersFx = domain.effect<EmptyParams, GetNumbers, DefaultError>('getNotesFx')

export const activateNumber = domain.event<number>('actavateNumber')

export const hideNumber = domain.event<number>('hideNumber')
export const hideNumbers = domain.event<number[]>('hideNumbers')
export const resetHideNumber = domain.event<number>('resetHideNumber')

export const disableButton = domain.event<boolean>('disableButton')

export const activateNumberFx = domain.effect<{ number: number }, ActivateNumber, DefaultError>(
  'activateNumberFx'
)

export const resetActiveNumber = domain.event<void>('resetActiveNumber')
