import { PhotoDayTabName } from '../types'

export const getTextByPhotoDayKey = (key: string): string => {
  if (key === PhotoDayTabName.WEEK) return 'Неделя'
  else if (key === PhotoDayTabName.MONTH) return 'Месяц'
  else if (key === PhotoDayTabName.QUARTER) return 'Квартал'
  else if (key === PhotoDayTabName.YEAR) return 'Год'
  else if (key === PhotoDayTabName.YEAR2) return '2 года'
  else return 'День'
}
