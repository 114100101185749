import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'

import { changeOpenChatsMenu } from '../../../../../../model/chat/events'

export const MenuButton = () => {
  return (
    <IconButton onClick={() => changeOpenChatsMenu(true)}>
      <MenuIcon />
    </IconButton>
  )
}
