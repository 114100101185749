import { guard, sample } from 'effector'

import { changeRuble, changeWaddlesCoin } from '../../../auth/models/user/events'
import { changeGameSnakeCount } from '../../../game-snake/model/game-snake/events'
import * as api from '../../api'
import { Gift, GiftType } from '../../types'
import { changeFortuneCount } from '../fortune/events'
import { getUserXp } from '../xp/events'
import { $giftList } from '.'
import { activateGift, activateGiftFx, getGifts, getGiftsFx } from './events'

getGiftsFx.use(api.getGiftsApi)
activateGiftFx.use(api.activateGiftApi)

guard({
  source: getGifts,
  filter: getGiftsFx.pending.map((state) => !state),
  target: getGiftsFx,
})

guard({
  source: activateGift,
  filter: activateGiftFx.pending.map((state) => !state),
  target: activateGiftFx,
})

const activedGift = sample({
  source: $giftList,
  clock: activateGiftFx.doneData,
  fn: (gifts, { data: { id } }) => gifts.find((item) => item.id === id),
})

guard({
  source: activedGift,
  filter: (gift) => gift?.product?.code === GiftType.FORTUNE,
  target: changeFortuneCount.prepend((item?: Gift) => (item ? item.count : 0)),
})

guard({
  source: activedGift,
  filter: (gift) => gift?.giftType === GiftType.WADDLES_COIN,
  target: changeWaddlesCoin.prepend((item?: Gift) => (item ? item.count : 0)),
})
guard({
  source: activedGift,
  filter: (gift) => gift?.giftType === GiftType.RUBBLE,
  target: changeRuble.prepend((item?: Gift) => (item ? item.count : 0)),
})

guard({
  source: activedGift,
  filter: (gift) => gift?.giftType === GiftType.XP,
  target: getUserXp,
})

guard({
  source: activedGift,
  filter: (gift) => gift?.product?.code === GiftType.GAME_SNAKE,
  target: changeGameSnakeCount.prepend((item?: Gift) => (item ? item.count : 0)),
})
