import { guard } from 'effector'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import * as api from '../../api'
import {
  clearBackgroundUrl,
  clearHeaderColor,
  clearLogoUrl,
  clearWaddlesUrl,
  getTheme,
  getThemeFx,
} from './events'
import { mapBackgroundUrl, mapHeaderColor, mapLogoUrl, mapWaddlesUrl } from './utils'

getThemeFx.use(api.getThemeApi)

guard({
  source: getTheme,
  filter: getThemeFx.pending.map((state) => !state),
  target: getThemeFx,
})

getThemeFx.doneData.watch(({ data }) => {
  if (data.backgroundUrl) {
    Storage.set(STORAGE_NAMES.BACKGROUND_URL, mapBackgroundUrl(data.backgroundUrl))
  } else {
    Storage.remove(STORAGE_NAMES.BACKGROUND_URL)
    clearBackgroundUrl()
  }

  if (data.logoUrl) {
    Storage.set(STORAGE_NAMES.LOGO_URL, mapLogoUrl(data.logoUrl))
  } else {
    Storage.remove(STORAGE_NAMES.LOGO_URL)
    clearLogoUrl()
  }

  if (data.waddlesUrl) {
    Storage.set(STORAGE_NAMES.WADDLES_URL, mapWaddlesUrl(data.waddlesUrl))
  } else {
    Storage.remove(STORAGE_NAMES.WADDLES_URL)
    clearWaddlesUrl()
  }

  if (data.headerColor) {
    Storage.set(STORAGE_NAMES.HEADER_COLOR, mapHeaderColor(data.headerColor))
  } else {
    Storage.remove(STORAGE_NAMES.HEADER_COLOR)
    clearHeaderColor()
  }
})
