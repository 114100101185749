import { combine } from 'effector'

import { FortuneInfo, FortuneSector, FortuneStats, ModalMode, ProductCode } from '../../types'
// import { Storage, STORAGE_NAMES } from '@/services/local-storage'
import { domain } from '../domain'
import {
  buyFortuneFx,
  changeFortuneCount,
  changeFortuneStatsTab,
  changeModalFortuneVisible,
  changeModalMode,
  expiredFortune,
  expiredFortuneFx,
  getFortune,
  getFortuneFx,
  getFortuneStats,
  getFortuneStatsFx,
  reloadFortune,
  saveFortuneFx,
  setActivePrizeCount,
  startFortune,
  startFortuneFx,
  updateFortune,
  updateFortuneFx,
} from './events'

// export const initFortune = Storage.get<Fortune>(STORAGE_NAMES.FORTUNE)

export const $fortuneCount = domain
  .createStore<number>(0)
  .on(
    [getFortuneFx.doneData, updateFortuneFx.doneData, expiredFortuneFx.doneData],
    (_, { data }) => data.fortuneCount
  )
  .on(changeFortuneCount, (data, count) => data + count)
  .on(buyFortuneFx.done, (data) => data + 1)

export const $fortuneSectors = domain
  .createStore<FortuneSector[]>([])
  .on(
    [getFortuneFx.doneData, updateFortuneFx.doneData, expiredFortuneFx.doneData],
    (_, { data }) => data.sectors
  )

export const $fortuneInfo = domain
  .createStore<FortuneInfo | null>(null)
  .on(
    [getFortuneFx.doneData, updateFortuneFx.doneData, expiredFortuneFx.doneData],
    (_, { data: { sectors, fortuneCount, ...otherData } }) => otherData
  )
  .on(startFortuneFx.doneData, (state, { data = {} }) => (state ? { ...state, ...data } : null))

export const $fortuneError = domain
  .createStore<string | null>(null)
  .on(getFortuneFx.failData, (_, error) => 'Ошибка при загрузке колеса фортуны')
  .on(
    [updateFortuneFx.failData, expiredFortuneFx.failData],
    (_, error) => 'Ошибка при обновлении колеса фортуны'
  )
  .reset([reloadFortune, getFortune, updateFortune, expiredFortune])

export const $buyFortuneError = domain
  .createStore<string | null>(null)
  .on(buyFortuneFx.failData, (_, error) => 'Ошибка при покупке колеса фортуны')
  .reset([reloadFortune, getFortune, updateFortune, expiredFortune])

export const $startFortuneError = domain
  .createStore<string | null>(null)
  .on(startFortuneFx.failData, (_, error) => 'Ошибка при запуске колеса фортуны')
  .reset([reloadFortune, getFortune, updateFortune, expiredFortune, startFortune])

export const $modalVisible = domain
  .createStore<boolean>(false)
  .on(changeModalFortuneVisible, (_, isVisible) => isVisible)

export const $activePrizeCount = domain
  .createStore<number | null>(null)
  .on(setActivePrizeCount, (_, value) => value)
  .reset(reloadFortune)

export const $fortuneResult = domain
  .createStore<FortuneSector | null>(null)
  .on(saveFortuneFx.doneData, (_, { data }) => data)
  .reset(reloadFortune)

export const $fortuneInProgress = domain
  .createStore<boolean>(false)
  .on(startFortuneFx.doneData, () => true)
  .reset([reloadFortune, saveFortuneFx.doneData])

export const $saveFortunePending = saveFortuneFx.pending
export const $startFortunePending = startFortuneFx.pending
export const $getFortunePending = combine({
  getPending: getFortuneFx.pending,
  updatePending: updateFortuneFx.pending,
  expiredPending: expiredFortuneFx.pending,
}).map(
  ({ getPending, updatePending, expiredPending }) => getPending || updatePending || expiredPending
)
export const $buyFortunePending = buyFortuneFx.pending
export const $getFortuneStatsPending = getFortuneStatsFx.pending

export const $saveFortuneError = domain
  .createStore<string | null>(null)
  .on(saveFortuneFx.failData, (_, error) => {
    if (error.response?.data[0]) {
      return error.response?.data[0]
    }
    return 'Ошибка при сохранение колеса фортуны'
  })
  .reset(reloadFortune)

export const $fortuneStats = domain
  .createStore<FortuneStats | null>(null)
  .on(getFortuneStatsFx.doneData, (_, { data }) => data)

export const $getFortuneStatsError = domain
  .createStore<string | null>(null)
  .on(getFortuneStatsFx.failData, (_, error) => 'Ошибка при получении статистики')
  .reset([reloadFortune, getFortuneStats])

export const $modalMode = domain
  .createStore<ModalMode>(ModalMode.MAIN)
  .on(changeModalMode, (_, modalMode) => modalMode)

export const $fortuneStatsTab = domain
  .createStore<ProductCode>(ProductCode.WADDLES_COIN)
  .on(changeFortuneStatsTab, (_, tabName) => tabName)
