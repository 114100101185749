import { Message } from '../../../../type'

export const drawSecondaryMessge = (message: Message | null): string => {
  if (!message) return 'Сообщений нет'

  const { author, text, images } = message
  const hasOnlyImages = !text && images.length === 1

  return `${author.username}: ${hasOnlyImages ? 'Изображение' : text}`
}
