import { Box, Skeleton, Stack, Typography } from '@mui/material'

import { RouterLink } from '@/shared/components/router-link'

import { NavItem } from '../../types'

interface Props {
  navItems: NavItem[] | null
}

export const PcMenu = ({ navItems }: Props) => {
  if (!navItems) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: 180, height: 60 }} />
      </Stack>
    )
  }
  return (
    <Box display="flex">
      {navItems.map((item) => (
        <Box
          key={item.link}
          sx={{
            display: { xs: 'none', sm: 'flex', marginRight: 20 },
            alignItems: 'center',
          }}
        >
          <RouterLink to={item.link}>
            <Typography variant="h6" component="div" color="grey.900">
              {item.name}
            </Typography>
          </RouterLink>
        </Box>
      ))}
    </Box>
  )
}
