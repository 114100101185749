import { Box, Chip, CircularProgress, Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { foodSrc, snakeSrc } from '@/shared/assets'
import { AlertError } from '@/shared/components/alert'
import { WaddlesCoin } from '@/shared/components/waddles-coin'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $waddlesCoins } from '../../../auth/models/user'
import { $gameSnakeProfile, $getProfileError, $getProfilePending } from '../../model/game-snake'

export const CoinsHeader = () => {
  const profile = useStore($gameSnakeProfile)
  const profilePending = useStore($getProfilePending)
  const profileError = useStore($getProfileError)
  const waddlesCoin = useStore($waddlesCoins)

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Choose>
        <When condition={profileError}>
          <AlertError text="Ошибка при загрузке" />
        </When>
        <When condition={profile}>
          <Chip
            label={`${profile?.foodCount}`}
            avatar={
              profilePending ? (
                <CircularProgress size={24} />
              ) : (
                <img src={foodSrc} alt="Еда" width={20} />
              )
            }
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 2,
              background: 'rgba(0, 0, 0, 0.08)',
              borderRadius: 16,
            }}
          >
            <Typography variant="body1" sx={{ mr: 1 }}>
              {profile?.gameCount}
            </Typography>
            <img src={snakeSrc} alt="Еда" width={18} />
          </Box>
        </When>
        <When condition={profilePending}>
          <Skeleton variant="rounded" width={240} height={32} />
        </When>
      </Choose>
      <If condition={waddlesCoin !== null}>
        <Box sx={{ background: 'rgba(0, 0, 0, 0.08)', borderRadius: 16, display: 'inline-flex' }}>
          <WaddlesCoin
            count={waddlesCoin as number}
            TypographyProps={{ color: 'text.secondary', fontSize: 16 }}
          />
        </Box>
      </If>
    </Box>
  )
}
