import { combine,forward, sample } from 'effector'

import { GameStatus, TimerStatus } from '../../enums'
import { changeGameStatus } from '../single-game-status/events'
import { $players } from '../single-player'
import { $activePlayerNum, $activeTimer, $audioEndTimer, $audioTickTimer, $timeForStep, $timerStatus } from '.'
import { changeTimerStatus, continueTimer, setActivePlayerNum, setNextPlayerId, setTimer, startGame } from './events'

forward({
  from: setNextPlayerId,
  to: [changeTimerStatus.prepend(() => TimerStatus.WAIT)],
})

sample({
  source: $timeForStep,
  clock: startGame,
  fn: (timeForStep) => timeForStep,
  target: [setTimer, changeTimerStatus.prepend(() => TimerStatus.RUNNING)],
})

let timeout: any

$activeTimer.watch((timer) => {
  const audioTick = $audioTickTimer.getState()
  if (audioTick && timer && timer <= 10) {
    audioTick.play()
  }

  const audioEnd = $audioEndTimer.getState()
  if (audioEnd && timer === 0) {
    audioEnd.play()
  }

  timeout = setTimeout(() => {
    timer && setTimer(timer - 1)
  }, 1000)
})

$timerStatus.watch((status) => {
  const timer = $activeTimer.getState()
  const timeForStep = $timeForStep.getState()
  const audioTick = $audioTickTimer.getState()

  if (status === TimerStatus.PAUSE) {
    clearTimeout(timeout)
    audioTick && audioTick.pause()
  }
  if (status === TimerStatus.RUN) {
    changeTimerStatus(TimerStatus.RUNNING)
    continueTimer()
  }

  if (status === TimerStatus.WAIT) {
    if (timer !== timeForStep) {
      clearTimeout(timeout)
    }

    changeTimerStatus(TimerStatus.RUNNING)
    setTimer(timeForStep)
  }
  if (status === TimerStatus.STOP) {
    clearTimeout(timeout)
    setTimer(null)
  }
})

sample({
  source: combine({ players: $players, activePlayerNum: $activePlayerNum }),
  clock: setNextPlayerId,
  fn: ({ players, activePlayerNum }, selectedPlayerNum) => {
    if (selectedPlayerNum) return selectedPlayerNum

    return activePlayerNum === players.length ? 1 : activePlayerNum + 1
  },
  target: setActivePlayerNum,
})

sample({
  clock: changeTimerStatus,
  filter: (timerStatus) => timerStatus === TimerStatus.STOP,
  target: changeGameStatus.prepend(() => GameStatus.FINISH),
})
