import { Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useState } from 'react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'

import { $reports, $reportsLoading } from '../../model'
import { deleteReport } from '../../model/events' // Импортируем событие удаления
import { ReportGate } from '../../model/gates'

export const DailyNotesList = () => {
  const reports = useStore($reports)
  const reportsLoading = useStore($reportsLoading)

  useGate(ReportGate)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null)

  const handleDelete = (id: number) => {
    setSelectedReportId(id)
    setOpenDeleteDialog(true)
  }

  const confirmDelete = () => {
    if (selectedReportId !== null) {
      deleteReport(selectedReportId)
      setOpenDeleteDialog(false)
    }
  }

  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Box>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 700 }}>
          Список заметок
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <RouterLink to={ROUTE_PATH.DAILY_NOTES}>
            <Button variant="outlined" color="primary">
              На главную
            </Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.DAILY_NOTES_NEW}>
            <Button variant="outlined" color="primary">
              Добавить
            </Button>
          </RouterLink>
        </Box>
      </Box>
      {reportsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Дата
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Фото?
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}></TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <TableRow key={report.id}>
                  <TableCell align="center">{report.date}</TableCell>
                  <TableCell align="center">{report.photoOfDayUrl ? '+' : '-'}</TableCell>
                  <TableCell align="center">
                    <RouterLink to={`${ROUTE_PATH.DAILY_NOTES_EDIT}/${report.id}`}>
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </RouterLink>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton color="error" onClick={() => handleDelete(report.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Удалить заметку</DialogTitle>
        <DialogContent>
          <Typography>Вы уверены, что хотите удалить эту заметку?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Отмена</Button>
          <Button color="error" variant="contained" onClick={confirmDelete}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
