import { cyan } from '@mui/material/colors'
import isUrl from 'validator/lib/isURL'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'
import { getAppPath } from '@/shared/utils/getPath'

import backgroundSrc from '../../assets/bg.jpg'
import waddlesSrc from '../../assets/waddles.png'
import { Theme } from '../../types'

export const defaultTheme: Theme = {
  backgroundUrl: backgroundSrc,
  backgroundMobileUrl: backgroundSrc,
  headerColor: cyan['A400'],
  waddlesUrl: waddlesSrc,
  logoUrl: '/logo.svg',
}

const checkUrlOrGetApp = (url: string | null): string => {
  if (!url) return ''

  if (isUrl(url)) return url

  return getAppPath(url)
}

export const mapBackgroundUrl = (url?: string): string => {
  return (
    checkUrlOrGetApp(url || Storage.get<string>(STORAGE_NAMES.BACKGROUND_URL)) ||
    defaultTheme.backgroundUrl
  )
}

export const mapHeaderColor = (url?: string): string => {
  return url || Storage.get<string>(STORAGE_NAMES.HEADER_COLOR) || defaultTheme.headerColor
}

export const mapLogoUrl = (url?: string): string => {
  return (
    checkUrlOrGetApp(url || Storage.get<string>(STORAGE_NAMES.LOGO_URL)) || defaultTheme.logoUrl
  )
}

export const mapWaddlesUrl = (url?: string): string => {
  return (
    checkUrlOrGetApp(url || Storage.get<string>(STORAGE_NAMES.WADDLES_URL)) ||
    defaultTheme.waddlesUrl
  )
}
