import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { orange, pink } from '@mui/material/colors'
import { useLocation } from 'react-router-dom'

import { CopyableText } from '@/shared/components/copyable-text'

import { User } from '../../../../../auth/types'

type Props = {
  members: User[]
  chatId: number
}
export const SettingsMembers = ({ members, chatId }: Props) => {
  const location = useLocation()
  const copyPath = `${window.location.origin}${location.pathname}/join/`

  return (
    <Box>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography fontSize={20} fontWeight={700}>
          Участники
        </Typography>
      </Box>
      <List
        sx={{
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '240px',
          overflowY: 'auto',
        }}
      >
        {members.map((member) => (
          <ListItem key={member.id} sx={{ bgcolor: orange[100], borderRadius: 3 }}>
            <ListItemAvatar>
              <Avatar src={member.avatar || ''} />
            </ListItemAvatar>
            <ListItemText primary={member.username} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Typography sx={{ mr: 1 }}>Пригласить:</Typography>
        <Box sx={{ p: 1, bgcolor: pink[50], borderRadius: 2 }}>
          <CopyableText viewText="Скопировать ссылку" copyText={copyPath} showIcon withSnackbar />
        </Box>
      </Box>
    </Box>
  )
}
