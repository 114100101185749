export function containsOnlyEmoji(text: string) {
  // Регулярное выражение для совпадения с большинством эмодзи
  const emojiRegex =
    // eslint-disable-next-line max-len
    /(?:\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD00-\uDDFF])[\uFE0E\uFE0F]?|\uD83D[\uDC00-\uDE4F](?:\u200D\uD83D[\uDC00-\uDE4F])*\uFE0F?|\uD83C[\uDDE6-\uDDFF]\uD83C[\uDDE6-\uDDFF](?:\u200D(?:\uD83C[\uDDE6-\uDDFF]\uD83C[\uDDE6-\uDDFF]))*\uFE0F?/g

  // Удаляем все эмодзи из текста
  const cleanText = text.replace(emojiRegex, '')

  // Проверяем, остался ли непустой текст без пробельных символов
  return cleanText.trim().length === 0
}
