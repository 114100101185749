import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RouterLink } from '@/shared/components/router-link'

import { ROUTE_PATH } from '../../../../packages/paths'
import { $sessionCreating, $sessions } from '../../model'
import { deleteSession, updateSession, updateSessionFx } from '../../model/events'
import { sessionForm } from '../../model/forms'

export const EditGameSession = () => {
  const sessionEditing = useStore($sessionCreating)
  const sessions = useStore($sessions)
  const { fields, error } = useForm(sessionForm)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const { id: sessionId } = useParams<{ id: string }>()

  useEffect(() => {
    if (sessionId) {
      const session = sessions.find((s) => s.id === Number(sessionId))
      if (!session) {
        return
      }
      fields.game.onChange(session.game)
      fields.date.onChange(session.date)
      fields.duration.onChange(session.duration_minutes.toString())
      fields.participants.onChange(session.participants)
      fields.winner.onChange(session.winner || '')
    }

    const unsubscribeUpdate = updateSessionFx.done.watch(() => {
      setIsSubmitted(true)
      setSubmitError(null)
    })

    const unsubscribeUpdateError = updateSessionFx.fail.watch((error) => {
      setSubmitError(error?.params?.data?.[0] || 'Произошла ошибка при обновлении игровой сессии')
    })

    return () => {
      unsubscribeUpdate()
      unsubscribeUpdateError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, sessions])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (sessionId) {
      updateSession({
        id: Number(sessionId),
        data: {
          game: fields.game.value,
          date: fields.date.value,
          duration: fields.duration.value,
          participants: fields.participants.value,
          winner: fields.winner.value,
        },
      })
    }
  }

  const handleDelete = () => {
    if (sessionId) {
      deleteSession(Number(sessionId))
    }
  }

  return (
    <Container>
      <Box sx={{ mt: 3, width: '100%' }}>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 700 }}>
          Редактировать игровую сессию
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <RouterLink to={ROUTE_PATH.BOARDGAMES}>
            <Button variant="outlined" color="primary">
              На главную
            </Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.BOARDGAMES_LIST}>
            <Button variant="outlined" color="primary">
              К списку сессий
            </Button>
          </RouterLink>
        </Box>
        {isSubmitted ? (
          <Box>
            <Alert severity="success" sx={{ mb: 2 }}>
              Игровая сессия успешно обновлена!
            </Alert>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Название игры"
              name="game"
              value={fields.game.value}
              onChange={(e) => fields.game.onChange(e.target.value)}
              error={!!error('game')}
              helperText={error('game')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Дата"
              name="date"
              type="date"
              value={fields.date.value}
              onChange={(e) => fields.date.onChange(e.target.value)}
              error={!!error('date')}
              helperText={error('date')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Длительность (в минутах)"
              name="duration"
              type="number"
              value={fields.duration.value}
              onChange={(e) => fields.duration.onChange(e.target.value)}
              error={!!error('duration')}
              helperText={error('duration')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Участники (через запятую)"
              name="participants"
              value={fields.participants.value}
              onChange={(e) => fields.participants.onChange(e.target.value.split(','))}
              error={!!error('participants')}
              helperText={error('participants')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Победитель"
              name="winner"
              value={fields.winner.value}
              onChange={(e) => fields.winner.onChange(e.target.value)}
              error={!!error('winner')}
              helperText={error('winner')?.errorText}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%' }}
              color="primary"
              disabled={sessionEditing}
              startIcon={sessionEditing ? <CircularProgress size={20} /> : null}
            >
              {sessionEditing ? 'Отправка...' : 'Сохранить изменения'}
            </Button>
            <Button
              onClick={handleDelete}
              variant="outlined"
              sx={{ width: '100%', mt: 2 }}
              color="error"
            >
              Удалить сессию
            </Button>
          </form>
        )}
      </Box>
    </Container>
  )
}
