import { Box, CardContent as CardConentMui } from '@mui/material'
import { grey, yellow } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

export const Root = styled('div')`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardConent = styled(CardConentMui)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ImagePrizeWrapper = styled(Box)`
  background: ${grey[200]};
  border-radius: 50%;
  width: 200px;
  height: 200px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MultipliersWrapper = styled(motion.div)`
  display: flex;
  width: 320px;
  justify-content: center;
  margin-top: 12px;
  align-items: center;
`

export const MultiplierWrapper = styled(Box)`
  background: ${yellow[900]};
  margin: 0 8px;
  border-radius: 20px;
  height: 60px;
  width: 60px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
