import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { $levelCombine } from '../../../../model/admin'
import { clearItemsFx } from '../../../../model/admin/events'
import { $fillManyItemsForm } from '../../../../model/admin/forms'

export const FieldSettings = () => {
  const params = useParams()
  const levelNumber = Number(params.number)
  const { items } = useStore($levelCombine)
  const { fields, submit } = useForm($fillManyItemsForm)
  const productFillMany = items.find((item) => item.id === fields.itemId.value)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            labelPlacement="bottom"
            sx={{ width: 80, ml: 0, mr: 1 }}
            componentsProps={{
              typography: { sx: { fontSize: 12 } },
            }}
            control={
              <Checkbox
                sx={{ m: 0, p: 0 }}
                value={fields.hasOrder.value}
                onChange={(e, value) => fields.hasOrder.onChange(value)}
              />
            }
            label="С очередью?"
          />
        </FormGroup>
        <Select
          sx={{ width: 80, mr: 1 }}
          variant="outlined"
          error={fields.itemId.hasError()}
          size="small"
          renderValue={() => (
            <img width={14} height={14} src={productFillMany?.image} alt={productFillMany?.name} />
          )}
          value={fields.itemId.value || ''}
          onChange={(e) => fields.itemId.onChange(Number(e.target.value) || 0)}
        >
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <img width={20} height={20} src={item.image} alt={item.name} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <TextField
          value={fields.count.value || ''}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          variant="outlined"
          error={fields.count.hasError()}
          size="small"
          onChange={(e) => fields.count.onChange(Number(e.target.value) || 0)}
          sx={{ mr: 1, width: 80 }}
        />
        <IconButton
          sx={{ mr: 1 }}
          color="primary"
          onClick={() => {
            submit()
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <IconButton color="error" onDoubleClick={() => clearItemsFx(levelNumber)}>
          <HighlightOffIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
