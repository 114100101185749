import { IconButton as IconButtonMui,Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PlayerCard = styled(Paper)<{ $isActive: boolean; $activeTimer: number | null }>`
  word-break: break-all;
  margin-top: 10px;
  padding: 10px 20px;
  user-select: none;
  min-width: 300px;
  transition: all 0.3s;
  && {
    background: ${({ $isActive, $activeTimer = 0, theme }) => {
      return $isActive && $activeTimer === 0
        ? theme.palette.error.main
        : $isActive && Number($activeTimer) > 10
        ? theme.palette.info.main
        : $isActive && Number($activeTimer) <= 10
        ? theme.palette.warning.main
        : theme.palette.common.white
    }};
    color: ${({ $isActive, $activeTimer, theme }) =>
      $isActive && $activeTimer === 0 ? theme.palette.common.white : $isActive ? theme.palette.common.white : ''};
  }
`

export const IconButton = styled(IconButtonMui)<{ $isActive: boolean }>`
  svg {
    transition: all 0.3s;
    font-size: 80px;
    opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  }
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.common.white : '')};
`
