export const songs = [
  "MC HAMMER — U Can't Touch This",
  'Depeche Mode — Enjoy the Silence',
  'Michael Jackson — Black or White',
  'КИНО (ЦОЙ) — Кончится лето',
  'Браво — Вася',
  'Avicii — Wake Me Up',
  'Vaya Con Dios — Nah Neh Nah',
  'Мираж — Музыка нас связала',
  'Игорь Тальков — Я вернусь',
  'Nirvana — Smells Like Teen Spirit',
  'Queen — The Show Must Go On',
  'Наташа Королева — Желтые тюльпаны',
  'Scorpions — Wind of Change',
  'Scorpions — Still Loving You',
  'The White Stripes — Seven Nation Army',
  'Лесоповал — Я куплю тебе дом',
  "Dr. Alban — It's My Life",
  'На-На — Фаина',
  'Земфира — Ариведерчи',
  'Whitney Houston — I Will Always Love You',
  'Валерий Леонтьев — Маргарита',
  'Сектор газа — Гуляй мужик',
  'Алла Пугачева — Озеро надежды',
  'Любэ — Давай, наяривай',
  'Los Del Rio — Macarena',
  'Мальчишник — В последний раз',
  'Mika — Relax, Take It Easy',
  'Ленинград — WWW',
  'Ed Sheeran — Shape of You',
  'DJ Smash — Moscow Never Sleeps',
  'Shawn Mendes — Señorita',
  'Gorky Park — Moscow Calling',
  'Pharrell Williams — Happy',
  'Ирина Аллегрова — Младший лейтенант',
  'Sting — Shape of My Heart',
  'Haddaway — What Is Love',
  'Celine Dion — My Heart Will Go On',
  'Ace of Base — Happy Nation',
  'Комбинация — Два кусочека колбаски',
  'Crazy Town — Butterfly',
  'Radiohead — Creep',
  'ДДТ, Юрий Шевчук — Это всё',
  'E-Type — Set the World on Fire',
  'Scooter — How Much Is the Fish?',
  "Basshunter — Now You're Gone",
  'System of a Down — Chop Suey!',
  'Леонид Агутин — Хоп Хей Лала Лей',
  'Кай Метов — Position 2',
  'Филипп Киркоров — Зайка моя',
  'Snap! — The Power',
  'Queen — We Are the Champions',
  'Adele — Rolling in the Deep',
  'Михаил Шуфутинский — Третье сентября',
  'Aerosmith — Crazy',
  'Агата Кристи — Опиум для никого',
  "Coolio feat. L.V. — Gangsta's Paradise",
  'Ирина Салтыкова — Серые глаза',
  'Нэнси — Дым сигарет с ментолом',
  'The Cranberries — Zombie',
  'Scatman John — Scatman',
  'Вячеслав Быков — Любимая моя',
  "Lara Fabian — Je T'aime",
  'Кабаре дуэт Академия — Зараза',
  'Фристайл — Ах, какая женщина',
  'Lana Del Rey — Summertime Sadness',
  'Michael Jackson — Earth Song',
  'Группа Лицей — Осень',
  'Celine Dion — My Heart Will Go On',
  'Сектор газа — Пора домой',
  'Мурат Насыров — Мальчик хочет в Тамбов',
  'Король и Шут — Лесник',
  'Влад Сташевский — Позови меня в ночи',
  'Несчастный случай — Генералы песчаных карьеров',
  'Алена Апина — Электричка',
  'Анжелика Варум и Леонид Агутин — Королева',
  'Gloria Gaynor & Лариса Долина — I Will Survive',
  'Натали — Ветер с моря дул',
  'Иванушки International — Тополиный пух',
  'Eiffel 65 — Blue',
  'Руки Вверх — Крошка моя',
  'Шура — Ты не верь слезам',
  "No Doubt — Don't Speak",
  'Aqua — Barbie Girl',
  'Rammstein — Du Hast',
  "The Monkees — I'm a Believer",
  'Madonna — Frozen',
  'Отпетые Мошенники — Люби меня',
  'Britney Spears — Oops!... I Did It Again',
  'Жуки — Батарейка',
  'Cher — Believe',
  'Ляпис Трубецкой — Ау',
  'Стрелки — Ты бросил меня',
  'Demo — Солнышко',
  "Ricky Martin — Livin' la Vida Loca",
  'Алсу — Solo',
  "Bomfunk MC's — Freestyler",
  'Децл — Вечеринка',
  "Bon Jovi — It's My Life",
  'ATC — All Around The World',
  'Би-2 — Полковнику никто не пишет',
  'Giorgos Mazanakis — To Gucci Forema',
  'Цой — Пачка сигарет',
  'Muse — Supermassive Black Hole',
  'Eminem — The Real Slim Shady',
  "Christina Aguilera, Lil' Kim, Mya, Pink — Lady Marmalade",
  'Ария — Осколок льда',
  'Алексин — Ну что ж ты страшная такая',
  "The Black Eyed Peas — Let's Get It Started",
  'Blur — Song 2',
  'Дискотека Авария — Disco Superstar',
  'Оксана Почепа (Акула) — Убегаю',
  'Света — Что мне делать',
  "Kylie Minogue — Can't Get You Out of My Head",
  'Группа Пропаганда — Мелом',
  'Би-2 — Мой рок-н-ролл',
  'Avril Lavigne — Sk8er Boi',
  'Las Ketchup — The Ketchup Song',
  '3 Doors Down — Here Without You',
  'Сплин — Мое сердце',
  'AC/DC — Highway to Hell',
  'Shakira — Whenever, Wherever',
  'Краски — Сегодня к маме я приехала домой',
  'Премьер Министр — Девочка с севера',
  'Vitas — Opera #2',
  'Группа Блестящие — За четыре моря',
  'Linkin Park — Numb',
  'Глюк’oZa — Невеста',
  'Тату — Не верь, не бойся, не проси',
  'Корни — Плакала берёза',
  'Олег Газманов — Мои ясные дни',
  'Чай вдвоем — Ласковая моя',
  '50 Cent — In Da Club',
  'DJ Azik — Chuchuka',
  'The Rasmus — In the Shadows',
  'Звери — Напитки покрепче',
  'O-Zone — Dragostea Din Tei',
  'Юрий Шатунов — Не бойся',
  'Jay-Z — Empire State of Mind',
  'Joan Osborne — One of Us',
  'Ангина — Твоя девочка ушла',
  'Panjabi MC — Jogi',
  'Валерий Меладзе и ВИА Гра — Притяженья больше нет',
  'Usher ft. Lil Jon, Ludacris — Yeah!',
  'Вячеслав Бутусов — Песня идущего домой',
  'Фактор 2 — Красавица',
  'Akon — Lonely',
  'Серега — Черный бумер',
  'Eurythmics — Sweet Dreams',
  'Gorillaz — Feel Good Inc.',
  'Green Day — Boulevard of Broken Dreams',
  'Nelly Furtado ft. Timbaland — Promiscuous',
  "Eminem — You Don't Know",
  'Город 312 — Вне зоны доступа',
  'Лолита — Пошлю его на...',
  'Земфира — Прогулка',
  'The Black Eyed Peas — Pump It',
  'Morandi — Angels',
  'Eminem & Dido — Stan',
  "Банд'Эрос — Про красивую жизнь",
  'Alex Gaudino feat. Crystal Waters — Destination Calabria',
  'Evanescence — Bring Me to Life',
  'Инфинити — Где ты',
  'Kanye West — Stronger',
  'Евгения Отрадная — Уходи и дверь закрой',
  'МакSим — Научусь летать',
  'МакSим — Знаешь ли ты',
  'Akcent — Stay With Me',
  'Винтаж — Плохая девочка',
  'Вика Дайнеко — Я просто сразу от тебя уйду',
  'Rihanna ft. Jay-Z — Umbrella',
  'Alexander Rybak — Fairytale',
  'The Black Eyed Peas — Boom Boom Pow',
  'Arash feat. Helena — Pure Love',
  'Inna — Hot',
  'The Pussycat Dolls — Hush Hush; Hush Hush',
  'Eminem — Not Afraid',
  'Митя Фомин — Все будет хорошо',
  'Stromae — Alors on danse',
  'Lady Gaga — Alejandro',
  'Kesha — Tik Tok',
  'Adele — Skyfall',
  '50 Cent — Candy Shop',
  'Eminem — Love the Way You Lie',
  'Adele — Someone Like You',
  'Ёлка — На воздушном шаре',
  'LMFAO ft. Lauren Bennett, GoonRock — Party Rock Anthem',
  'Серебро — Мама Люба',
  'Потап и Настя — Чумачечая весна',
  'Michel Teló — Ai Se Eu Te Pego',
  'PSY — Gangnam Style',
  'Elvira T — Все решено',
  'Flo Rida — Whistle',
  'Lana Del Rey — Blue Jeans',
  'Pitbull feat. Chris Brown — International Love',
  'Jay-Z & Kanye West — Ni**as in Paris',
  'Katy Perry ft. Juicy J — Dark Horse',
  'Григорий Лепс и Ани Лорак — Зеркала',
  'Daft Punk feat. Pharrell Williams — Get Lucky',
  'OneRepublic — Counting Stars',
  'Sia — Chandelier',
  'Enrique Iglesias — Bailando',
  'Ariana Grande ft. Zedd — Break Free',
  'Kiesza — Hideaway',
  'Руки Вверх! — А он тебя целует',
  'Beyoncé — Single Ladies',
  'Arctic Monkeys — Arabella',
  'P!nk — Try',
  'Calvin Harris & Disciples — How Deep Is Your Love',
  'Время и Стекло — Имя 505',
  'Oxxxymiron — Где нас нет',
  'Pharaoh — Black Siemens',
  'Тимати — Баклажан',
  "Rag'n'Bone Man — Human",
  'Ленинград — Экспонат',
  'Егор Крид — Будильник',
  'Макс Корж — Малый повзрослел',
  'Тимати и Егор Крид — Где ты, где я',
  'Toby King — Loli Mou',
  'Мияги и Эндшпиль — I Got Love',
  'Luis Fonsi feat. Daddy Yankee — Despacito',
  'Toni Braxton — Un-Break My Heart',
  'Грибы — Тает лёд',
  'Элджей и Feduk — Розовое вино',
  'Imagine Dragons — Thunder',
  'LOBODA — Твои глаза',
  'ЛСП, Feduk, Егор Крид — Холостяк',
  'Little Big — Skibidi',
  "No Doubt — Don't Speak",
  'Miley Cyrus — Wrecking Ball',
  'Алла Пугачева — Миллион алых роз',
  'HammAli & Navai — Пусти меня на танцпол',
  'Big Baby Tape — Gimme the Loot',
  'Кипелов — Я свободен',
  'Мумий Тролль — Владивосток 2000',
  'Juice WRLD — Lucid Dreams',
  'Nelly Furtado — Say It Right',
  'Звери — Просто такая сильная любовь',
  'Maroon 5 — Maps',
  'Тима Белорусских — Мокрые кроссы',
  'NILETTO — Любимка',
  'RASA — Пчеловод',
  'Billie Eilish — Bad Guy',
  'Lil Nas X — Old Town Road',
  'Imagine Dragons — Believer',
  'Тату — Нас не догонят',
  'Maroon 5 feat. Christina Aguilera — Moves Like Jagger',
  'Eminem — Godzilla',
  'Roxette — Listen to Your Heart',
  'David Guetta feat. Sia — Titanium',
  'Руки Вверх! — 18 мне уже',
  'Taylor Swift — Shake It Off',
]
