import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useStore } from 'effector-react'

import { AlertError, AlertInfo, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'

import {
  $activeTab,
  $getPhotoDayPending,
  $modalVisible,
  $noPhotoDay,
  $photoDayError,
  $photoDayList,
} from '../../model/photo_days'
import { changeModalPhotoDayVisible } from '../../model/photo_days/events'
import { ModalPhotoDayTabs } from '../modal-photo-day-tabs'

const handleClose = () => changeModalPhotoDayVisible(false)

export const ModalPhotoDay = () => {
  const photoDayList = useStore($photoDayList)
  const modalVisible = useStore($modalVisible)
  const noPhotoDay = useStore($noPhotoDay)
  const photoDayError = useStore($photoDayError)
  const photoDayPending = useStore($getPhotoDayPending)
  const activeTab = useStore($activeTab)

  return (
    <Dialog maxWidth="lg" fullWidth open={modalVisible} keepMounted onClose={handleClose}>
      <DialogTitle>Ваши фото дня</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Choose>
          <When condition={noPhotoDay}>
            <AlertInfo
              text={
                'У вас нету фото дня за последний день, неделю, месяц, квартал или год. ' +
                'Или вы не заполнили фото дня вчера'
              }
              TypographyProps={{ variant: 'body1' }}
            />
          </When>
          <When condition={photoDayPending}>
            <AlertLoading text="Фото дня загружаются" />
          </When>
          <When condition={photoDayError}>
            <AlertError text={photoDayError} TypographyProps={{ variant: 'body1' }} />
          </When>
          <When condition={photoDayList.length !== 0}>
            <ModalPhotoDayTabs activeTab={activeTab} photoDayList={photoDayList} />
          </When>
        </Choose>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
