import './init'

import { toBaseDate } from '@/shared/utils/moment'

import { domain } from './domain'
import {
  createReportFx,
  getCalendarDataFx,
  getHappinessLevelFx,
  getLastDaysReportFx,
  getReportByIdFx,
  getReportsFx,
} from './events'
import { GetCalendarDataResponse, LastDaysReportResponse, Report } from './types'

export const $reports = domain
  .createStore<Report[]>([])
  .on(getReportsFx.doneData, (_, { data }) =>
    data.map((item) => ({ ...item, date: toBaseDate(item.date) }))
  )

export const $reportsLoading = getReportsFx.pending
export const $reportCreating = createReportFx.pending

export const $reportError = domain
  .createStore<string | null>(null)
  .on(createReportFx.failData, (_, error) => error.message || 'Ошибка при создании отчета')
  .reset(createReportFx.done)

// Новое состояние для уровня счастья
export const $happinessLevel = domain
  .createStore<number | null>(null)
  .on(getHappinessLevelFx.doneData, (_, { happinessLevel }) => happinessLevel)

export const $happinessLoading = getHappinessLevelFx.pending

// Стор для данных календаря
export const $calendarData = domain
  .createStore<GetCalendarDataResponse | null>(null)
  .on(getCalendarDataFx.doneData, (_, data) => data)

export const $calendarLoading = getCalendarDataFx.pending

export const $currentReport = domain
  .createStore<Report | null>(null)
  .on(getReportByIdFx.doneData, (_, report) => report)

// last report
export const $lastDaysReport = domain
  .createStore<LastDaysReportResponse | null>(null)
  .on(getLastDaysReportFx.doneData, (_, data) => data)

export const $lastDaysReportLoading = getLastDaysReportFx.pending
