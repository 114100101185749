import { guard, split } from 'effector'

import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'
import { removeToken } from '@/services/api'
import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import * as api from '../../api'
import { $rubles, $user, $waddlesCoins } from '.'
import { getUser, getUserFx, logout, resetUser, setUserError } from './events'

getUserFx.use(api.getUserApi)

guard({
  source: getUser,
  filter: getUserFx.pending.map((state) => !state),
  target: getUserFx,
})

split({
  source: getUserFx.failData,
  match: {
    unauthorizedError: (data) => data.response?.status === 401,
  },
  cases: {
    unauthorizedError: logout,
    __: setUserError,
  },
})

getUserFx.doneData.watch(() => {
  if (router.state.location.pathname === ROUTE_PATH.SIGN_IN) {
    router.navigate(ROUTE_PATH.HOME)
  }
})

$user.watch((data) => {
  if (data) {
    Storage.set(STORAGE_NAMES.USER, data)
  }
})

$waddlesCoins.watch((data) => {
  Storage.set(STORAGE_NAMES.WADDLES_COIN, data)
})
$rubles.watch((data) => {
  Storage.set(STORAGE_NAMES.RUBLES, data)
})

resetUser.watch(() => {
  removeToken()
  Storage.remove(STORAGE_NAMES.USER)
})

logout.watch(() => {
  resetUser()
  router.navigate(ROUTE_PATH.SIGN_IN)
})
