import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Alert, Snackbar, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { CopyableText } from '../../../../../../shared/components/copyable-text'
import { If } from '../../../../../../shared/utils/if'
import { $user } from '../../../../../auth'
import { User } from '../../../../../auth/types'
import {
  resetMenuMessage,
  setDeleteMessageId,
  setEditMessage,
} from '../../../../model/messages/events'
import { $menuMessage } from '../../../../model/messages/store'

export const MessageMenu = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { anchor, message } = useStore($menuMessage)
  const user = useStore($user) as User
  const isOwner = user.username === message?.author.username
  const isEmptyMessage = message && message.text === ''
  const hasMenu = Boolean(message && (isOwner || !isEmptyMessage))

  return (
    <>
      <Menu
        anchorEl={anchor}
        id="account-menu"
        open={hasMenu}
        onClose={() => resetMenuMessage()}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        <If condition={message && message.text !== ''}>
          <MenuItem>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <CopyableText
              viewText="Скопировать"
              copyText={message?.text}
              onAfterCopy={() => {
                setOpenSnackbar(true)
                resetMenuMessage()
              }}
            />
          </MenuItem>
        </If>
        <If condition={isOwner}>
          <MenuItem
            onClick={() => {
              if (message) {
                setEditMessage(message)
                resetMenuMessage()
              }
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Редактировать
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (message) {
                setDeleteMessageId(message.id)
                resetMenuMessage()
              }
            }}
          >
            <ListItemIcon>
              <DeleteIcon sx={{ color: red[500] }} fontSize="small" />
            </ListItemIcon>
            <Typography color={red[500]}>Удалить</Typography>
          </MenuItem>
        </If>
      </Menu>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: '100%', marginBottom: '80px' }}
        >
          Скопировано
        </Alert>
      </Snackbar>
    </>
  )
}
