import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useGate, useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { If } from '@/shared/utils/if'

import { TransactionGate } from '../../model/gate'
import { $packTransactionPending, $packTransactions } from '../../model/transactions'
import * as Styled from './styled'

export const PackTransactions = () => {
  const packTransactions = useStore($packTransactions)
  const packTransactionPending = useStore($packTransactionPending)
  useGate(TransactionGate)

  return (
    <Styled.Root>
      <If condition={packTransactionPending}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <CircularProgress />
        </Box>
      </If>
      <If condition={!packTransactionPending && packTransactions.length === 0}>
        <Typography>У вас ещё нет транзакций</Typography>
      </If>
      <If condition={packTransactions.length > 0}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Тип</TableCell>
                <TableCell align="center">Приз</TableCell>
                <TableCell align="center">Сколько</TableCell>
                <TableCell align="center">Дата</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packTransactions.map((transaction) => (
                <TableRow
                  key={transaction.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{transaction.type}</TableCell>
                  <TableCell align="center">{transaction.prize}</TableCell>
                  <TableCell align="center">{transaction.count}</TableCell>
                  <TableCell align="center" sx={{ fontSize: 12 }}>
                    {transaction.date}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </If>
      <If condition={!packTransactionPending}>
        <Box sx={{ my: 3 }}>
          <RouterLink to={ROUTE_PATH.SHOP}>
            <Button variant="contained">
              <Typography>К наборам</Typography>
            </Button>
          </RouterLink>
        </Box>
      </If>
    </Styled.Root>
  )
}
