import { DefaultError, EmptyParams, EmptyResponse, NumberParam } from '@/shared/types/api'

import {
  CreateEditNotesResponse,
  CreateNoteParams,
  EditNoteParams,
  GetNotesResponse,
} from '../../types'
import { domain } from '../domain'

export const getNotes = domain.event<EmptyParams>('getNotes')

export const getNotesFx = domain.effect<EmptyParams, GetNotesResponse, DefaultError>('getNotesFx')

export const createNote = domain.event<CreateNoteParams>('createNote')

export const createNoteFx = domain.effect<CreateNoteParams, CreateEditNotesResponse, DefaultError>(
  'createNoteFx'
)

export const editNote = domain.event<EditNoteParams>('editNote')

export const editNoteFx = domain.effect<EditNoteParams, CreateEditNotesResponse, DefaultError>(
  'editNoteFx'
)

export const deleteNote = domain.event<NumberParam>('deleteNote')

export const deleteNoteFx = domain.effect<NumberParam, EmptyResponse, DefaultError>('deleteNoteFx')

// Для смены табов
export const changeTagTab = domain.event<number>('changeTagTab')

// Для редактирования формы формы
export const setActiveNoteId = domain.event<number | null>('setActiveNoteId')

// Для редактирования формы формы
export const setAnchorDelete = domain.event<HTMLElement | null>('setAnchorDelete')
