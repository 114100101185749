import '../../model/holiday/init'
import '../../model/gift/init'

import { Box, Link, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import giftSrc from '../../assets/gift.gif'
import { $giftList, $notUserGiftCount } from '../../model/gift'
import { changeModalGiftVisible } from '../../model/gift/events'
import { $holiday } from '../../model/holiday'
import { $lastReport } from '../../model/last_report'
import { $waddlesUrl } from '../../model/theme'
import { ModalGift } from '../modal-gift'
import * as Styled from './styled'

export const CenterMain = () => {
  const holiday = useStore($holiday)
  const giftList = useStore($giftList)
  const waddlesUrl = useStore($waddlesUrl)
  const notUsedGiftCount = useStore($notUserGiftCount)
  const lastReport = useStore($lastReport)

  return (
    <Styled.Root>
      <If condition={lastReport?.needReport}>
        <Box mb={2}>
          <RouterLink to={ROUTE_PATH.DAILY_NOTES}>
            <Styled.BottomMessage>
              <Typography>{'Заполнить дневник!'}</Typography>
            </Styled.BottomMessage>
          </RouterLink>
        </Box>
      </If>
      <If condition={holiday}>
        <Styled.Message>
          <Typography>{holiday?.mainText}</Typography>
        </Styled.Message>
      </If>
      <Styled.WaddlesWrapper>
        <Link underline="none" href={getAppPath('/waddles')}>
          <Styled.WaddlesImage src={waddlesUrl} alt="Пухля" />
        </Link>
        <If condition={notUsedGiftCount > 0}>
          <Styled.Gift onClick={() => changeModalGiftVisible(true)} src={giftSrc} alt="Подарок" />
        </If>
      </Styled.WaddlesWrapper>
      <If condition={giftList.length > 0}>
        <ModalGift gifts={giftList} />
      </If>
    </Styled.Root>
  )
}
