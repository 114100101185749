// eslint-disable-next-line simple-import-sort/imports
import { AuthPage } from '@/packages/pages/auth'
import { ErrorPage } from '@/packages/pages/error'
import { MainPage } from '@/packages/pages/main'
import { TimerPage } from '@/packages/pages/timer'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import { NotePage } from './pages/note'
import { TimerPageType } from './pages/timer/types'
import { AuthRoute } from '@/features/auth/ui/protected-route'
import { GameSnakePage } from './pages/game-snake'
import { GameSnakePageType } from './pages/game-snake/types'
import { ROUTE_PATH } from './paths'
import { PWAPage } from './pages/pwa'
import { AdminPage } from './pages/admin'
import { EnGamePage } from './pages/game-engame'
import { EnGamePageType } from './pages/game-engame/types'
import { GameNumberPage } from './pages/game-number'
import { BlogPage } from './pages/blog'
import { ShopPackPage, ShopPage, ShopTransactionPage } from './pages/shop'
import { ChatListPage, ChatRoomPage, ChatJoinPage } from './pages/chat'
import { RoadToHousePage } from './pages/road-to-house'
import { AppsPage } from './pages/apps'
import { CrutilsPage } from './pages/crutils'
import {
  NewDailyNotesPage,
  EditDailyNotesPage,
  DailyNotesListPage,
  DailyNotesMainPage,
  DailyNotesCalendarPage,
} from './pages/daily-notes'
import {
  BoardGamesListPage,
  BoardGamesReviewPage,
  BoardGamesEditPage,
  BoardGamesMainMenuPage,
  BoardGamesNewPage,
} from './pages/boardgames'
import { SoundsPage } from './pages/sounds'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTE_PATH.HOME} errorElement={<ErrorPage />}>
      <Route index element={<AuthRoute element={<MainPage />} />} />
      <Route path={ROUTE_PATH.SIGN_IN} element={<AuthPage />} />
      <Route
        path={ROUTE_PATH.NOTES}
        element={<AuthRoute isDisableNight element={<NotePage />} />}
      />
      <Route path={ROUTE_PATH.PWA} element={<AuthRoute element={<PWAPage />} />} />
      <Route path={ROUTE_PATH.ADMIN} element={<AuthRoute element={<AdminPage />} />} />
      <Route path={ROUTE_PATH.BLOG_POST} element={<BlogPage />} />
      <Route path={ROUTE_PATH.APPS} element={<AuthRoute element={<AppsPage />} />} />
      <Route path={ROUTE_PATH.CRUTILS} element={<AuthRoute element={<CrutilsPage />} />} />
      <Route
        path={ROUTE_PATH.ROAD_TO_HOUSE}
        element={<AuthRoute element={<RoadToHousePage />} />}
      />
      <Route path={ROUTE_PATH.GAME_NUMBER} element={<AuthRoute element={<GameNumberPage />} />} />
      <Route path={ROUTE_PATH.CHAT}>
        <Route index element={<AuthRoute isDisableNight element={<ChatListPage />} />} />
        <Route
          path={ROUTE_PATH.CHAT_ROOM}
          element={<AuthRoute isDisableNight element={<ChatRoomPage />} />}
        />
        <Route
          path={ROUTE_PATH.CHAT_JOIN}
          element={<AuthRoute isDisableNight element={<ChatJoinPage />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.SHOP}>
        <Route index element={<AuthRoute element={<ShopPage />} />} />
        <Route path={ROUTE_PATH.SHOP_PACK} element={<AuthRoute element={<ShopPackPage />} />} />
        <Route
          path={ROUTE_PATH.SHOP_TRANSACTIONS}
          element={<AuthRoute element={<ShopTransactionPage />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.DAILY_NOTES}>
        <Route index element={<AuthRoute isDisableNight element={<DailyNotesMainPage />} />} />
        <Route
          path={ROUTE_PATH.DAILY_NOTES_LIST}
          element={<AuthRoute isDisableNight element={<DailyNotesListPage />} />}
        />
        <Route
          path={ROUTE_PATH.DAILY_NOTES_CALENDAR}
          element={<AuthRoute isDisableNight element={<DailyNotesCalendarPage />} />}
        />
        <Route
          path={ROUTE_PATH.DAILY_NOTES_NEW}
          element={<AuthRoute isDisableNight element={<NewDailyNotesPage />} />}
        />
        <Route
          path={ROUTE_PATH.DAILY_NOTES_EDIT_WITH_ID}
          element={<AuthRoute isDisableNight element={<EditDailyNotesPage />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.BOARDGAMES}>
        <Route index element={<AuthRoute element={<BoardGamesMainMenuPage />} />} />
        <Route
          path={ROUTE_PATH.BOARDGAMES_NEW}
          element={<AuthRoute element={<BoardGamesNewPage />} />}
        />
        <Route
          path={ROUTE_PATH.BOARDGAMES_REVIEW}
          element={<AuthRoute element={<BoardGamesReviewPage />} />}
        />
        <Route
          path={ROUTE_PATH.BOARDGAMES_LIST}
          element={<AuthRoute element={<BoardGamesListPage />} />}
        />
        <Route
          path={ROUTE_PATH.BOARDGAMES_EDIT}
          element={<AuthRoute element={<BoardGamesEditPage />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.GAME_ENGAME}>
        <Route
          path={ROUTE_PATH.GAME_ENGAME}
          element={<AuthRoute element={<EnGamePage type={EnGamePageType.MAIN} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_BATTLE}
          element={<AuthRoute element={<EnGamePage type={EnGamePageType.BATTLE} />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.GAME_SNAKE}>
        <Route
          index
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.MAIN} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_SHOP}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.SHOP} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_SINGLE_GAME}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.SINGLE_GAME} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_PARAMS}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.PARAMS} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_STATS}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.STATS} />} />}
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_ADMIN}
          element={<AuthRoute isAdmin element={<GameSnakePage type={GameSnakePageType.ADMIN} />} />}
        />
        <Route
          path={`${ROUTE_PATH.GAME_SNAKE_ADMIN}/:number`}
          element={
            <AuthRoute isAdmin element={<GameSnakePage type={GameSnakePageType.ADMIN_NUMBER} />} />
          }
        />
        <Route
          path={ROUTE_PATH.GAME_SNAKE_LEVELS}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.LEVELS} />} />}
        />
        <Route
          path={`${ROUTE_PATH.GAME_SNAKE_LEVEL_NUMBER}/:number`}
          element={<AuthRoute element={<GameSnakePage type={GameSnakePageType.LEVEL_NUMBER} />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.TIMER}>
        <Route index element={<AuthRoute element={<TimerPage type={TimerPageType.LIST} />} />} />
        <Route
          path={ROUTE_PATH.TIMER_MULTI}
          element={<AuthRoute element={<TimerPage type={TimerPageType.MULTI_USER} />} />}
        />
        <Route
          path={ROUTE_PATH.TIMER_SINGLE}
          element={<AuthRoute element={<TimerPage type={TimerPageType.SINGLE} />} />}
        />
      </Route>
      <Route path={ROUTE_PATH.SOUNDS}>
        <Route index element={<AuthRoute isAdmin element={<SoundsPage />} />} />
      </Route>
    </Route>
  )
)
