import { combine } from 'effector'

import { Note, Tag } from '../../types'
import { domain } from '../domain'
import {
  changeTagTab,
  createNote,
  createNoteFx,
  deleteNote,
  deleteNoteFx,
  editNote,
  editNoteFx,
  getNotes,
  getNotesFx,
  setActiveNoteId,
  setAnchorDelete,
} from './events'
import { editNoteForm, newNoteForm } from './forms'
import { getAllTagsFromNotes, mapNoteTags } from './utils'

export const $notes = domain
  .createStore<Note[]>([])
  .on(getNotesFx.doneData, (_, { data }) => data)
  .on(createNoteFx.doneData, (notes, { data: newNote }) => [newNote, ...notes])
  .on(deleteNoteFx.done, (notes, data) => notes.filter((note) => note.id !== data.params))
  .on(editNoteFx.doneData, (notes, { data: editedNote }) =>
    notes.map((note) => (note.id === editedNote.id ? editedNote : note))
  )

export const $tags = domain
  .createStore<Tag[]>([])
  .on($notes, (_, notes) => getAllTagsFromNotes(notes))

export const $noteTags = combine({
  notes: $notes,
  tags: $tags,
}).map(({ notes, tags }) => mapNoteTags(notes, tags))

export const $tagList = $noteTags.map((tags) => tags.map((item) => item.tag.name))

export const $getNotesError = domain
  .createStore<string | null>(null)
  .on(getNotesFx.failData, (_, data) => data.response?.data[0] || 'Ошибка при загрузке заметок')
  .reset(getNotes)

export const $createNoteError = domain
  .createStore<string | null>(null)
  .on(createNoteFx.failData, (_, data) => data.response?.data[0] || 'Ошибка при создании заметки')
  .reset([newNoteForm.$values.updates, createNote])

export const $editNoteError = domain
  .createStore<string | null>(null)
  .on(
    editNoteFx.failData,
    (_, data) => data.response?.data[0] || 'Ошибка при редактировании заметки'
  )
  .on(deleteNoteFx.failData, (_, data) => data.response?.data[0] || 'Ошибка при удалении заметки')
  .reset([editNote, deleteNote, editNoteForm.$values.updates])

export const $activeTagTab = domain
  .createStore<number | null>(null)
  .on(getNotesFx.doneData, (_, { data }) => data[0]?.tag.id || null)
  .on(createNoteFx.doneData, (_, { data }) => data.tag.id)
  .on(editNoteFx.doneData, (_, { data }) => data.tag.id)
  .on(changeTagTab, (_, value) => value)

export const $activeEditNoteId = domain
  .createStore<number | null>(null)
  .on(setActiveNoteId, (_, id) => id)
  .reset([deleteNoteFx.done, editNoteFx.done])

export const $anchorDelete = domain
  .createStore<HTMLElement | null>(null)
  .on(setAnchorDelete, (_, value) => value)

export const $getNotesFxPending = getNotesFx.pending
export const $createNoteFxPending = createNoteFx.pending
export const $editNoteFxPending = editNoteFx.pending
export const $deleteNoteFxPending = deleteNoteFx.pending
