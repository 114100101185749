import 'features/timer/model/multi-timer/init'

import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { arrayMove, List as MovableList } from 'react-movable'
import { useParams } from 'react-router-dom'

import { $multiTimer, $roundTime } from '@/features/timer/model/multi-timer'
import {
  addUser,
  deleteTimer,
  deleteUser,
  getTimer,
  setRoundTime,
  startTimer,
  updateParticipants,
} from '@/features/timer/model/multi-timer/events'
import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { TimerStatus } from '../../enums'
import { Finish } from '../multi-user-finish'
import { MultiUserProcess } from '../multi-user-process'
import { checkIsActiveUser } from './utils'

export const MultiUser = () => {
  const timer = useStore($multiTimer)
  const user = useStore($user)
  const roundTime = useStore($roundTime)
  const { id } = useParams()

  const isActiveUser = checkIsActiveUser(timer, user)
  const isAdmin = user?.id === timer?.admin.id
  const isDisableStart = (!!timer && timer.participants.length < 2) || !roundTime

  useEffect(() => {
    getTimer(Number(id))
    const interval = setInterval(() => {
      getTimer(Number(id))
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (timer) {
    const multiStatuses = [
      TimerStatus.READY,
      TimerStatus.PAUSE,
      TimerStatus.RUNNING,
      TimerStatus.WAIT,
    ]
    if (multiStatuses.includes(timer.status)) {
      return <MultiUserProcess timer={timer} />
    } else if (timer.status === TimerStatus.STOP) {
      return <Finish />
    }
  }

  return (
    <Box>
      <Choose>
        <When condition={timer}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4" color="initial">
              {timer?.title}
            </Typography>
            <If condition={isAdmin}>
              <Tooltip title="Удалить таймер">
                <IconButton sx={{ ml: 2 }} onClick={() => timer && deleteTimer(timer.id)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            </If>
          </Box>
          <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 2 }} variant="h5" component="div">
              {timer && timer.participants.length > 0 ? 'Участники таймера:' : 'Нет участников'}
            </Typography>
            <Choose>
              <When condition={isAdmin}>
                <MovableList
                  values={timer?.participants || []}
                  onChange={({ oldIndex, newIndex }) =>
                    timer &&
                    updateParticipants({
                      id: timer.id,
                      participants: arrayMove(timer?.participants || [], oldIndex, newIndex),
                    })
                  }
                  renderList={({ children, props }) => <List {...props}>{children}</List>}
                  renderItem={({ value: user, props }) => (
                    <ListItem
                      {...props}
                      key={user.id}
                      secondaryAction={
                        isAdmin && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              timer && deleteUser({ timerId: timer.id, userId: user.id })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )
                      }
                    >
                      <ListItemIcon>
                        <Avatar alt={user.username || ''} src={getAppPath(user?.avatar)} />
                      </ListItemIcon>
                      <ListItemText primary={user.username} secondary={user.email} />
                    </ListItem>
                  )}
                />
              </When>
              <Otherwise>
                <List>
                  {timer?.participants.map((user) => (
                    <ListItem
                      key={user.id}
                      secondaryAction={
                        isAdmin && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              timer && deleteUser({ timerId: timer.id, userId: user.id })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )
                      }
                    >
                      <ListItemIcon>
                        <Avatar alt={user.username || ''} src={getAppPath(user?.avatar)} />
                      </ListItemIcon>
                      <ListItemText primary={user.username} secondary={user.email} />
                    </ListItem>
                  ))}
                </List>
              </Otherwise>
            </Choose>
            <If condition={isAdmin}>
              <TextField
                label="Время раунда"
                type="number"
                variant="standard"
                value={roundTime}
                sx={{ mb: 3, width: '100%' }}
                onChange={(e) => setRoundTime(Number(e.target.value))}
              />
            </If>
          </Grid>

          <If condition={!isActiveUser}>
            <Button
              variant="contained"
              sx={{ mb: 2, width: '100%' }}
              color="primary"
              onClick={() => timer && addUser(timer.id)}
            >
              Вступить
            </Button>
          </If>
          <RouterLink to={ROUTE_PATH.TIMER}>
            <Button
              variant="outlined"
              sx={{ mb: 2, display: 'block', width: '100%' }}
              color="primary"
            >
              Выйти на главную
            </Button>
          </RouterLink>
          <If condition={isAdmin}>
            <Button
              variant="contained"
              sx={{ mb: 2, display: 'block', width: '100%' }}
              disabled={isDisableStart}
              color="primary"
              onClick={() =>
                timer && roundTime && startTimer({ id: timer.id, roundSeconds: roundTime })
              }
            >
              Начать таймер
            </Button>
          </If>
          <Button
            variant="outlined"
            sx={{ mb: 2, display: 'block', width: '100%' }}
            color="error"
            onClick={() => timer && user && deleteUser({ timerId: timer.id, userId: user.id })}
          >
            Выйти из таймера
          </Button>
          <If condition={isAdmin}>
            <Button
              variant="contained"
              sx={{ mb: 2, display: 'block', width: '100%' }}
              color="error"
              onClick={() => timer && deleteTimer(timer.id)}
            >
              Удалить таймер
            </Button>
          </If>
        </When>
        <When condition={!timer}>
          <Typography variant="h3" color="black" sx={{ textAlign: 'center' }}>
            Таймер не найден
          </Typography>
          <RouterLink to={ROUTE_PATH.TIMER}>
            <Button variant="contained" color="primary" sx={{ width: '100%', mt: 3 }}>
              К списку таймеров
            </Button>
          </RouterLink>
        </When>
      </Choose>
    </Box>
  )
}
