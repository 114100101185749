import { Shop } from '@/features/shop'
import { PackTransactions } from '@/features/shop/ui/pack-transactions'
import { ShopPack } from '@/features/shop/ui/shop-pack'

import { Template } from '../templates/template'

export const ShopPage = () => {
  return (
    <Template>
      <Shop />
    </Template>
  )
}

export const ShopPackPage = () => {
  return (
    <Template>
      <ShopPack />
    </Template>
  )
}

export const ShopTransactionPage = () => {
  return (
    <Template>
      <PackTransactions />
    </Template>
  )
}
