import { pluralizeWord } from './consts'
import { PLURALIZE_WORD } from './types'

/* возвращает строку со склоненным существительным */
const pluralize = (value: number, word: PLURALIZE_WORD, withValue: boolean = false): string => {
  const quantitative = pluralizeWord[word]
  let result = quantitative[2]

  if ([11, 12, 13, 14].includes(value % 100)) {
    result = quantitative[2]
  }
  if (value % 10 === 1) {
    result = quantitative[0]
  }
  if ([2, 3, 4].includes(value % 10)) {
    result = quantitative[1]
  }

  if (withValue) {
    return `${value} ${result}`
  }

  return result
}

export { pluralize, PLURALIZE_WORD }
