import { TimerStatus } from '../../enums';
import { domain } from '../domain';
import {
  changeTimeForStep,
  changeTimerStatus,
  continueTimer,
  setActivePlayerNum,
  setAudioEndTimer,
  setAudioTickTimer,
  setTimer,
} from './events';

export const $timeForStep = domain
  .createStore<number>(60)
  .on(changeTimeForStep, (_, time) => time);

export const $activeTimer = domain
  .createStore<number | null>(null)
  .on(setTimer, (_, time) => time)
  .on(continueTimer, (time) => (time ? time - 1 : time));

export const $timerStatus = domain
  .createStore<TimerStatus>(TimerStatus.STOP)
  .on(changeTimerStatus, (_, status) => status);

export const $activePlayerNum = domain
  .createStore<number>(1)
  .on(setActivePlayerNum, (_, num) => num);

export const $audioTickTimer = domain
  .createStore<HTMLAudioElement | null>(null)
  .on(setAudioTickTimer, (_, timer) => timer);

export const $audioEndTimer = domain
  .createStore<HTMLAudioElement | null>(null)
  .on(setAudioEndTimer, (_, timer) => timer);
