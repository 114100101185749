import { Container as ContainerMui } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('footer')`
  width: 100%;
  background: ${({ theme }) => theme.palette.grey[900]};
  color: ${({ theme }) => theme.palette.common.white}; ;
`

export const TopRow = styled('div')`
  display: flex;
  a:not(:last-child) {
    margin-right: 20px;
  }
`

export const Container = styled(ContainerMui)`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
