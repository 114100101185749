import { domain } from '../domain'
import {
  GetPackTransactionsError,
  GetPackTransactionsParams,
  GetPackTransactionsResponse,
} from './types'

export const getPackTransactions = domain.event<GetPackTransactionsParams>('getPackTransactions')
export const getPackTransactionsFx = domain.effect<
  GetPackTransactionsParams,
  GetPackTransactionsResponse,
  GetPackTransactionsError
>('getPackTransactionFx')
