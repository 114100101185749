import { combine } from 'effector'

import { GameSnakeLevel } from '../../ui/level-number/data'
import { END_REASON, GAME_STATUS } from '../../ui/level-number/types'
import { domain } from '../domain'
import {
  changeGameStatus,
  finishLevelGameFx,
  resetLevelGame,
  setEndReason,
  setGameSnakeLevel,
  setScore,
} from './events'
import { LevelResult } from './types'

export const $gameSnakeLevel = domain
  .createStore<GameSnakeLevel | null>(null)
  .on(setGameSnakeLevel, (_, data) => data)
  .reset(resetLevelGame)

export const $gameStatus = domain
  .createStore<GAME_STATUS>(GAME_STATUS.START)
  .on(changeGameStatus, (_, status) => status)
  .reset(resetLevelGame)

export const $score = domain
  .createStore<number>(0)
  .on(setScore, (_, score) => score)
  .reset(resetLevelGame)

export const $endReason = domain
  .createStore<END_REASON | null>(null)
  .on(setEndReason, (_, reason) => reason)
  .reset(resetLevelGame)

export const $levelGameResult = domain
  .createStore<LevelResult | null>(null)
  .on(finishLevelGameFx.doneData, (_, { data }) => data)
  .reset(resetLevelGame)

export const $levelGameResultPending = finishLevelGameFx.pending

export const $levelGameResultError = domain
  .createStore<string | null>(null)
  .on(finishLevelGameFx.failData, (_, error) => 'Ошибка при сохранении игры')
  .reset([resetLevelGame, finishLevelGameFx.done])

export const $levelGameCombined = combine({
  levelGameResult: $levelGameResult,
  levelGameResultPending: $levelGameResultPending,
  levelGameResultError: $levelGameResultError,
})
