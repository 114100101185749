import React from 'react'

type IfProps = {
  condition: any
  children: React.ReactNode
}

/*
      <Choose>
        <When condition={hideButtons}>{children}</When>
        <Otherwise>
*/

export const Choose = ({ children }: { children: React.ReactNode }) => {
  if (!children) {
    throw new Error('Empty choose')
  }

  const childs: JSX.Element[] = Array.isArray(children) ? children : [children]

  let result = null

  for (let i = 0; i < childs.length; i++) {
    const current = childs[i]
    if (current.props.condition) {
      result = current
      break
    }
  }
  let otherwise: React.ReactNode | JSX.Element | null = childs[childs.length - 1]

  if (!result) {
    return otherwise
  }

  return result
}

export const When = ({ children, condition }: IfProps) => {
  if (Boolean(condition)) {
    return <>{children}</>
  }

  return <div />
}

export const Otherwise = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>
}
