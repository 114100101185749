import '../../model/level/init'

import { Edit as EditIcon } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertApi } from '@/shared/components/alert'
import { RouterLink } from '@/shared/components/router-link'
import { If } from '@/shared/utils/if'
import { pluralize, PLURALIZE_WORD } from '@/shared/utils/pluralize'

import { $user } from '../../../auth'
import { $levelCombine } from '../../model/admin'
import { getLevel } from '../../model/admin/events'
import { LevelData } from '../../model/admin/types'
import { $gameSnakeProfile } from '../../model/game-snake'
import { $gameSnakeLevel, $gameStatus } from '../../model/level'
import { LevelPageGate } from '../../model/level/gate'
import { GameWrapper } from '../game-wrapper'
import { ControlButtons } from '../result-block'
import { ResultLevelBlock } from '../result-level-block'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './consts'
import { GameSnakeLevel } from './data'
import { GAME_STATUS } from './types'
import { RewardBlock } from './ui/reward-block'

export const GameSnakeLevelNumber = () => {
  const params = useParams()
  const canvas = useRef<HTMLCanvasElement | null>(null)
  const navigate = useNavigate()
  const gameStatus = useStore($gameStatus)
  const profile = useStore($gameSnakeProfile)
  const user = useStore($user)
  const gameSnake = useStore($gameSnakeLevel)
  const { level: levelGame, levelError, levelPending } = useStore($levelCombine)
  const levelNumber = Number(params.number)
  const isLevelDisabled = profile && profile.snakeParams.level !== levelNumber && !user?.isSuperuser

  useGate(LevelPageGate, { number: levelNumber, canvas, levelGame, gameSnake, profile })

  useEffect(() => {
    isLevelDisabled && navigate(ROUTE_PATH.GAME_SNAKE_LEVELS)
  }, [isLevelDisabled, navigate])

  return (
    <GameWrapper title={levelGame?.name} withHeader={false} withHomeButton={false}>
      {user?.isSuperuser && (
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <RouterLink to={`${ROUTE_PATH.GAME_SNAKE_ADMIN}/${levelNumber}`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </RouterLink>
        </Box>
      )}
      <canvas
        ref={canvas}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        style={{ borderRadius: '4px' }}
      ></canvas>
      <AlertApi
        sx={{ my: 1 }}
        errorText={levelError}
        pending={levelPending}
        loadingText={'Загружается'}
        onReload={() => getLevel(Number(params.number))}
      />
      <If condition={gameSnake && profile && gameStatus !== GAME_STATUS.END}>
        <ControlButtons gameSnake={gameSnake as GameSnakeLevel} gameStatus={gameStatus} />
      </If>
      <If condition={GAME_STATUS.START === gameStatus}>
        {profile && levelGame && (
          <Typography variant="h5">
            Цель: набрать {pluralize(levelGame.purpose, PLURALIZE_WORD.SCORE, true)}
            <br />
            <If condition={profile.snakeParams.level <= levelGame.number}>
              <RewardBlock levelGame={levelGame} />
            </If>
          </Typography>
        )}
      </If>
      <If condition={GAME_STATUS.END === gameStatus}>
        <ResultLevelBlock level={levelGame as LevelData} gameSnake={gameSnake as GameSnakeLevel} />
      </If>
      <If condition={GAME_STATUS.START === gameStatus}>
        <RouterLink to={ROUTE_PATH.GAME_SNAKE_LEVELS} sx={{ mt: 2 }}>
          <Button color="secondary" sx={{ width: 300 }}>
            К уровням
          </Button>
        </RouterLink>
      </If>
    </GameWrapper>
  )
}
