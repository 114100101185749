import { TimerStatus } from '../../enums'
import { Timer } from '../../types'

export const getAlertInfo = (timer: Timer) => {
  const { status, activeUser } = timer
  if (timer.roundSecondsLeft === 0) {
    return {
      text: `Время вышло для ${activeUser?.username}`,
    }
  } else if (status === TimerStatus.READY) {
    return { text: 'Ожидаем начала таймера' }
  } else if (status === TimerStatus.WAIT) {
    return { text: 'Таймер приостановлен' }
  } else if (status === TimerStatus.PAUSE && activeUser) {
    return { text: `На паузе (${activeUser.username})` }
  } else if (timer.status === TimerStatus.RUNNING && activeUser) {
    return { text: `Таймер работает (${activeUser.username})` }
  } else {
    return {}
  }
}
