import { domain } from '../domain'
import { changeChatSettingsModal, resetSettingsUpdate, updateChatRoomFx } from './events'

export const $openChatSettings = domain
  .createStore<boolean>(false)
  .on(changeChatSettingsModal, (_, open) => open)

export const $updateChatPending = updateChatRoomFx.pending

export const $settingsUpdated = domain
  .createStore<boolean>(false)
  .on(updateChatRoomFx.done, () => true)
  .reset(resetSettingsUpdate)
