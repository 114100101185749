import { NumberGameMain } from '../../../features/number-game'
import { Template } from '../templates/template'

export const GameNumberPage = () => {
  return (
    <Template hasPadding={false}>
      <NumberGameMain />
    </Template>
  )
}
