import { createGate } from 'effector-react'

import { getLevel, getLevels, resetLevel } from './events'

type LevelEditGateArgs = {
  activeLevelNumber: number | null
}

export const LevelEditGate = createGate<LevelEditGateArgs>('LevelEditGate')

export const MainAdminGate = createGate('MainAdminGate')

let intervalLevel: NodeJS.Timer | null = null

LevelEditGate.open.watch(({ activeLevelNumber }) => {
  activeLevelNumber && getLevel(activeLevelNumber)
  intervalLevel = setInterval(() => {
    activeLevelNumber && getLevel(activeLevelNumber)
  }, 30000)
})

LevelEditGate.close.watch(() => {
  intervalLevel && clearInterval(intervalLevel)
  resetLevel()
})

MainAdminGate.open.watch(() => {
  getLevels()
})
