import { useStore } from 'effector-react'

import { Main } from '@/features/main'
import { $backgroundMobileUrl, $backgroundUrl } from '@/features/main/model/theme'

import { useMedia } from '../../../shared/utils/media'
import { Template } from '../templates/template'

export const MainPage = () => {
  const backgroundUrl = useStore($backgroundUrl)
  const backgroundMobileUrl = useStore($backgroundMobileUrl)

  const { isMobile } = useMedia()

  const url = isMobile ? backgroundMobileUrl : backgroundUrl

  return (
    <Template backgroundUrl={url}>
      <Main />
    </Template>
  )
}
