import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'
// import { getToken } from '@/services/api'
import { isProduction } from '@/shared/libs/env'
import { deepCamelCase } from '@/shared/libs/string/camelize'

import { signInFx } from '../../auth/models/auth/events'
import { toggleConfirmDialog } from './dialog/events'
import { $userProfile } from './game'
import {
  resetAnswerId,
  resetLastDamage,
  resetQuestion,
  setBattle,
  setBattleError,
  setPlayers,
} from './game/events'
import { setProfile } from './game/events'
import { Profile } from './game/types'
import { WsAction } from './types'

// const token = getToken()

export const connectWs = (token: string) => {
  const link = isProduction()
    ? `wss://b.avodom.ru/ws/engame/?token=${token}`
    : `ws://localhost:8012/ws/engame/?token=${token}`

  const ws = new WebSocket(link)

  ws.onmessage = function (event) {
    const data = deepCamelCase(JSON.parse(event.data))
    const user = $userProfile.getState()
    switch (data.action) {
      case WsAction.CREATED:
        setProfile(data.profile)
        break
      case WsAction.UPDATE_PLAYERS:
        setPlayers(data.players.filter((item: Profile) => item.username !== user?.username))
        break
      case WsAction.INVITE:
        setBattle(data.battle)
        if (data.invitedPlayer === user?.username) {
          toggleConfirmDialog(true)
        }
        break
      case WsAction.USER_NOT_FOUND:
        setBattle(data.battle)
        setBattleError('Противник не найден')
        break
      case WsAction.ACCEPT:
        setBattle(data.battle)
        toggleConfirmDialog(false)
        router.navigate(ROUTE_PATH.GAME_BATTLE)
        break
      case WsAction.RESET:
        resetQuestion()
        resetLastDamage()
        resetAnswerId()
        break
      case WsAction.REJECT:
        setBattle(data.battle)
        setBattleError('Противник отказался от боя')
        break
      case WsAction.FIGHT_END:
        setBattle(data.battle)
        break
      case WsAction.GAME_START:
      case WsAction.ROUND_FIGHT:
      case WsAction.ROUND_PROGRESS:
      case WsAction.ROUND_END:
        setBattle(data.battle)
        break
      case WsAction.ROUND_START:
        resetQuestion()
        resetLastDamage()
        resetAnswerId()
        setBattle(data.battle)
        break
    }
  }

  return ws
}

export let chatSocket: WebSocket | null = null // token ? connectWs(token) : null

signInFx.doneData.watch(({ data }: any) => {
  // connectWs(data.authToken)
})
