import '../../model/photo_days/init'

import { Badge, Link } from '@mui/material'
import { useStore } from 'effector-react'

import { fortuneSrc } from '@/shared/assets'
import { getAppPath } from '@/shared/utils/getPath'

import blogSrc from '../../assets/blog.png'
import calendarSrc from '../../assets/calendar.png'
import cameraSrc from '../../assets/camera.png'
import taskSrc from '../../assets/tasks.png'
import { $fortuneCount } from '../../model/fortune'
import { changeModalFortuneVisible } from '../../model/fortune/events'
import { changeModalPhotoDayVisible } from '../../model/photo_days/events'
import { MenuItem } from '../menu-item'
import { ModalFortune } from '../modal-fortune'
import { ModalPhotoDay } from '../modal-photo-day'
import * as Styled from './styled'

type RightMainProps = {
  newTaskCount: number
  newPostsCount: number
}

export const RightMain = ({ newTaskCount, newPostsCount }: RightMainProps) => {
  const fortuneCount = useStore($fortuneCount)

  return (
    <>
      <Styled.Root>
        <Styled.ButtonWrapper onClick={() => changeModalFortuneVisible(true)}>
          <Badge badgeContent={fortuneCount} color="primary">
            <MenuItem text="Фортуна">
              <Styled.IconImage src={fortuneSrc} alt="Колесо фортуны" />
            </MenuItem>
          </Badge>
        </Styled.ButtonWrapper>
        <Link underline="none" href={getAppPath('/shop/tasks')} mb={2}>
          <Badge badgeContent={newTaskCount} color="primary">
            <MenuItem text="Задачи">
              <Styled.IconImage src={taskSrc} alt="Задачи" />
            </MenuItem>
          </Badge>
        </Link>
        <Styled.ButtonWrapper onClick={() => changeModalPhotoDayVisible(true)}>
          <MenuItem text="Фото дня">
            <Styled.IconImage src={cameraSrc} alt="Фото дня" />
          </MenuItem>
        </Styled.ButtonWrapper>
        <Link underline="none" href={getAppPath('/calendar')} mb={2}>
          <MenuItem text="Календарь">
            <Styled.IconImage src={calendarSrc} alt="Календарь" />
          </MenuItem>
        </Link>
        <Link underline="none" href={getAppPath('/blog')} mb={2}>
          <Badge badgeContent={newPostsCount} color="primary">
            <MenuItem text="Блог">
              <Styled.IconImage src={blogSrc} alt="Блог" />
            </MenuItem>
          </Badge>
        </Link>
      </Styled.Root>
      <ModalFortune fortuneCount={fortuneCount} />
      <ModalPhotoDay />
    </>
  )
}
