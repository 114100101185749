import MailIcon from '@mui/icons-material/Mail'
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { grey, orange, pink, purple } from '@mui/material/colors'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $activeChatId } from '../../../../model/chat/store'
import { ChatRoom } from '../../../../type'
import * as Styled from './styled'
import { drawSecondaryMessge } from './utils'

type Props = {
  chat: ChatRoom
}

export const ChatItem = ({ chat }: Props) => {
  const activeChatId = useStore($activeChatId)
  const isActiveChat = activeChatId === chat.id
  return (
    <RouterLink key={chat.id} to={`${ROUTE_PATH.CHAT}/${chat.id}`}>
      <ListItem
        sx={{
          width: '100%',
          bgcolor: isActiveChat ? purple[400] : pink[50],
          border: `1px solid ${pink[100]}`,
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{ background: !chat.avatar ? orange[300] : '' }}
            src={getAppPath(chat.avatar)}
            alt={chat.title}
          ></Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            fontSize: 20,
            fontWeight: 700,
            mr: 4,
          }}
          primaryTypographyProps={{ color: isActiveChat ? grey[100] : grey[900] }}
          secondaryTypographyProps={{
            color: isActiveChat ? grey[300] : grey[600],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          primary={chat.title}
          secondary={drawSecondaryMessge(chat.lastMessage)}
        />
        <If condition={chat.lastMessage}>
          <Typography
            color={isActiveChat ? grey[300] : grey[600]}
            fontSize={12}
            whiteSpace="nowrap"
            sx={{ position: 'absolute', right: 16, top: 8 }}
          >
            {chat.lastMessage?.createdDate}
          </Typography>
        </If>
        <Box flex={1} />
        <If condition={chat.unreadMessagesCount > 0}>
          <Styled.BadgeMessage
            badgeContent={chat.unreadMessagesCount}
            color={isActiveChat ? 'success' : 'secondary'}
          >
            <MailIcon sx={{ fontSize: 16 }} color={isActiveChat ? 'warning' : 'info'} />
          </Styled.BadgeMessage>
        </If>
      </ListItem>
    </RouterLink>
  )
}
