import { sample } from 'effector'

import { getToken } from '@/services/api'
import { deepCamelCase } from '@/shared/libs/string/camelize'

import { domain } from '../../domain'
import { updateUserChatInStore } from '../events'
import {
  changeChatSocketOnline,
  closeSocket,
  initiateWebSocketChatConnection,
  webSocketConnect,
} from './events'
import { SocketType, WebSocketEvent } from './types'

webSocketConnect.use(() => {
  const path = `${process.env.REACT_APP_WS_URL}/ws/chat_list/`
  const ws = new WebSocket(path)

  const token = getToken()

  ws.onopen = () => {
    ws.send(JSON.stringify({ action: 'authenticate', token }))
    changeChatSocketOnline(true)
  }
  ws.onclose = () => {
    changeChatSocketOnline(false)
  }

  return ws
})

// Подписываемся на событие подключения и вызываем эффект подключения
sample({
  clock: initiateWebSocketChatConnection,
  target: webSocketConnect,
})

let socket: WebSocket | null = null

// Обработка установленного соединения
webSocketConnect.done.watch(({ result }) => {
  socket = result

  socket.onmessage = (event) => {
    const data: WebSocketEvent = deepCamelCase(JSON.parse(event.data))

    if (data.type === SocketType.UPDATE_CHAT_LIST) {
      updateUserChatInStore(data.chats)
    }
  }
})

closeSocket.watch(() => {
  if (socket) {
    socket.close()
  }
})

export const $chatSocketOnline = domain
  .createStore<boolean>(false)
  .on(changeChatSocketOnline, (_, isOnline) => isOnline)
