import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useStore } from 'effector-react'
import { GithubPicker } from 'react-color'

import { foodSrc } from '@/shared/assets'

import {
  $getProfilePending,
  $isShowColorModal,
  $isShowSnackbarShop,
  $snakeColorChange,
  $upgradeParamPending,
} from '../../../../model/game-snake'
import {
  changeSnakeColor,
  changeVisibleColorModal,
  upgradeParam,
} from '../../../../model/game-snake/events'
import { SnakeColor } from '../../../snake-color'
import { COLORS } from '../../consts'

type Props = {
  foodCount?: number
}

const COLOR_CHANGE_PRICE = 50

export const DialogChangeColor = ({ foodCount = 0 }: Props) => {
  const isShowColorModal = useStore($isShowColorModal)
  const profilePending = useStore($getProfilePending)
  const isShowSnackbarShop = useStore($isShowSnackbarShop)
  const upgradeParamPending = useStore($upgradeParamPending)
  const snakeColorChange = useStore($snakeColorChange)

  return (
    <Dialog
      open={isShowColorModal}
      onClose={() => changeVisibleColorModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Изменить цвет змейки'}</DialogTitle>
      <DialogContent sx={{ maxWidth: 300 }}>
        <GithubPicker
          triangle="hide"
          width="200"
          colors={COLORS}
          onChange={(item) => changeSnakeColor(item.hex)}
        />
        {snakeColorChange && <SnakeColor sx={{ mt: 2 }} color={snakeColorChange} />}
        <LoadingButton
          variant="contained"
          loading={upgradeParamPending}
          disabled={
            foodCount < COLOR_CHANGE_PRICE ||
            profilePending ||
            isShowSnackbarShop ||
            !snakeColorChange
          }
          sx={{ width: '100%', mt: 3 }}
          onClick={() =>
            snakeColorChange && upgradeParam({ param: 'snake_color', value: snakeColorChange })
          }
        >
          Изменить цвет за {COLOR_CHANGE_PRICE}
          <Box component={'img'} sx={{ ms: 1 }} src={foodSrc} alt="Яблоки" width={20} />
        </LoadingButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => changeVisibleColorModal(false)}>Отменить</Button>
      </DialogActions>
    </Dialog>
  )
}
