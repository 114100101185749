import { domain } from '../domain'
import { toggleConfirmDialog, toggleInviteDialog } from './events'

export const $openInviteDialog = domain
  .createStore<boolean>(false)
  .on(toggleInviteDialog, (_, toggle) => toggle)

export const $openConfirmDialog = domain
  .createStore<boolean>(false)
  .on(toggleConfirmDialog, (_, toggle) => toggle)
