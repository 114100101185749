import { guard } from 'effector'

import * as api from '../../api'
import { getLastReport, getLastReportFx } from './events'

getLastReportFx.use(api.getLastReportApi)

guard({
  source: getLastReport,
  filter: getLastReportFx.pending.map((state) => !state),
  target: getLastReportFx,
})
