import {
  Done as DoneIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  TimerTwoTone as TimerTwoToneIcon,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { User } from '../../../auth/types'
import { TimerStatus } from '../../enums'
import { $multiTimer } from '../../model/multi-timer'
import { updateStatus } from '../../model/multi-timer/events'
import { Timer } from '../../types'
import * as Styled from './styled'

type PlayerCardProps = {
  userCard: User
  nextUserId: number
}

export const MultiUserCard = ({ userCard, nextUserId }: PlayerCardProps) => {
  const timer = useStore($multiTimer) as Timer
  const user = useStore($user)
  if (!user) throw new Error('User not found')
  const timerStatus = timer.status
  const isActive = userCard.id === timer.activeUser?.id
  const timeLeft = timer.roundSecondsLeft

  const isExpired = timeLeft === 0
  const isAdmin = user.id === timer.admin.id
  const isHost = user.id === userCard.id || isAdmin

  return (
    <Styled.PlayerCard $active={isActive} $timeLeft={timeLeft}>
      <Typography gutterBottom variant="h5" component="div">
        {userCard.username}{' '}
        {isActive &&
          (timerStatus === TimerStatus.PAUSE ? '(пауза)' : isExpired ? '(время вышло)' : '')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Choose>
          <When condition={isActive}>
            <Typography
              gutterBottom
              variant="h2"
              component="div"
              style={{ marginBottom: 0, marginRight: 0, fontWeight: 600 }}
            >
              {timeLeft}
            </Typography>
          </When>
          <Otherwise>
            <TimerTwoToneIcon style={{ fontSize: 40, color: 'orange' }} />
          </Otherwise>
        </Choose>
        <Box sx={{ display: 'flex' }}>
          <If condition={isActive}>
            <Styled.IconButton
              $active
              disabled={isExpired || !isHost}
              size="large"
              onClick={() =>
                updateStatus({
                  id: timer.id,
                  status: TimerStatus.RUNNING,
                  activeUserId: nextUserId,
                })
              }
            >
              <DoneIcon />
            </Styled.IconButton>
          </If>
          <Choose>
            <When condition={isActive && timerStatus === TimerStatus.PAUSE}>
              <Styled.IconButton
                $active
                disabled={!isHost}
                size="large"
                onClick={() => updateStatus({ id: timer.id, status: TimerStatus.RUNNING })}
              >
                <PlayArrowIcon />
              </Styled.IconButton>
            </When>
            <When condition={isActive}>
              <Styled.IconButton
                $active
                disabled={isExpired || !isAdmin}
                size="large"
                onClick={() => updateStatus({ id: timer.id, status: TimerStatus.PAUSE })}
              >
                <PauseIcon />
              </Styled.IconButton>
            </When>
            <Otherwise>
              <Styled.IconButton
                $active={isActive}
                disabled={!isAdmin}
                size="large"
                onClick={() => {
                  return updateStatus({
                    id: timer.id,
                    status: TimerStatus.RUNNING,
                    activeUserId: userCard.id,
                  })
                }}
              >
                <PlayArrowIcon />
              </Styled.IconButton>
            </Otherwise>
          </Choose>
        </Box>
      </Box>
    </Styled.PlayerCard>
  )
}
