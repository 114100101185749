import { domain } from '../../domain'

export const closeSocket = domain.createEvent<void>('closeSocket')

// Эффект для подключения к вебсокету
export const webSocketConnect = domain.createEffect<void, WebSocket>('webSocketConnect')

// Событие, инициирующее подключение
export const initiateWebSocketChatConnection = domain.createEvent<void>(
  'initiateWebSocketChatConnection'
)

// Ивент на чаты вебсокеты
export const changeChatSocketOnline = domain.createEvent<boolean>('changeChatSocketOnline')
