import { createGate } from 'effector-react'
import { v4 as uuidv4 } from 'uuid'

import { getAppPath } from '../../../../shared/utils/getPath'
import { ItemPosition, Profile } from '../../types'
import { GameSnakeLevel } from '../../ui/level-number/data'
import { loadImage, preventArrows } from '../../utils'
import { getLevel, getLevels } from '../admin/events'
import { ItemType, LevelData } from '../admin/types'
import { getProfile } from '../game-snake/events'
import { resetLevelGame, setGameSnakeLevel } from './events'

export const MainLevelGate = createGate('MainLevelGate')
export type GameSnakeLevelGateArgs = {
  canvas?: React.MutableRefObject<HTMLCanvasElement | null>
  gameSnake: GameSnakeLevel | null
  number: number
  profile: Profile | null
  levelGame: LevelData | null
}
export const LevelPageGate = createGate<GameSnakeLevelGateArgs>('LevelPageGate')

MainLevelGate.open.watch(() => {
  getProfile()
  getLevels()
})

export let keyHandler: (e: KeyboardEvent) => void

LevelPageGate.open.watch(({ number }) => {
  getProfile()
  getLevel(number)
})

LevelPageGate.state.watch(({ levelGame, canvas, gameSnake, profile }) => {
  if (canvas && canvas.current && !gameSnake) {
    const ctx = canvas.current.getContext(`2d`)
    if (ctx && levelGame && profile) {
      const initSnakeGame = new GameSnakeLevel(ctx, levelGame, profile)
      setGameSnakeLevel(initSnakeGame)
      keyHandler = (event: KeyboardEvent) => preventArrows(event, initSnakeGame, true)
      document.addEventListener('keydown', keyHandler, false)
    }
  }
  if (levelGame && gameSnake) {
    const prizes: ItemPosition[] = []
    const obstacles: ItemPosition[] = []
    levelGame.items.forEach(({ item, x, y }) => {
      const result: ItemPosition = {
        x,
        y,
        isActive: false,
        isEaten: false,
        id: uuidv4(),
        item: {
          ...item,
          audio: new Audio(getAppPath(item.audio)),
          image: loadImage(getAppPath(item.image)),
        },
      }
      if (item.itemType === ItemType.OBSTACLE) obstacles.push(result)
      else prizes.push(result)
    })
    gameSnake.drawNav()
    gameSnake.setFood(prizes)
    gameSnake.setLevel(levelGame.number)
    gameSnake.setPurpose(levelGame.purpose)
    gameSnake.setObstacle(obstacles)
    gameSnake.setFieldSize(levelGame.fieldSize)

    // Акселерометр, временно отключен, возможно будет сделан как отдельная платная фича
    // deviceMotionHandler = (e: DeviceMotionEvent) => changeSnakeSideByMotion(e, gameSnake)
    // window.addEventListener('devicemotion', deviceMotionHandler, true)
  }
})

LevelPageGate.close.watch(({ gameSnake }) => {
  gameSnake && gameSnake.pauseGame()
  resetLevelGame()
  if (Boolean(keyHandler)) {
    document.removeEventListener('keydown', keyHandler)
  }
})
