import { DefaultError, EmptyParams } from '@/shared/types/api'

import { GetThemeResponse } from '../../types'
import { domain } from '../domain'

export const getTheme = domain.event<EmptyParams>('getTheme')

export const getThemeFx = domain.effect<EmptyParams, GetThemeResponse, DefaultError>('getThemeFx')

export const clearBackgroundUrl = domain.event<void>('clearBackgroundUrl')
export const clearHeaderColor = domain.event<void>('clearHeaderColor')
export const clearLogoUrl = domain.event<void>('clearLogoUrl')
export const clearWaddlesUrl = domain.event<void>('clearWaddlesUrl')
