import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AlertLoading } from '../../../../shared/components/alert'
import { joinChat } from '../../model/chat-settings'

export const ChatJoin = () => {
  const { chatId } = useParams()

  useEffect(() => {
    joinChat({ chatId: Number(chatId) })
  }, [chatId])

  return (
    <Box>
      <AlertLoading text="Приглашение..." />
    </Box>
  )
}
