import { AxiosResponse } from 'axios'

import { SIDE } from '../../ui/single-game/types'

export enum ItemType {
  FORTUNE = 'FORTUNE',
  WADDLESCOIN = 'WADDLESCOIN',
  FOOD_1 = 'FOOD_1',
  OBSTACLE = 'OBSTACLE',
}

export type ItemDataType = {
  id: number
  name: string
  itemType: ItemType
  audio: string | null
  image: string
  order?: number
}

export type ItemPositionData = {
  x: number
  y: number
  order: number
  level: number
  item: ItemDataType
}

export type LevelData = {
  fieldSize: number
  isPublished: boolean
  name: string
  audioBg: string
  audioEndLose: string
  audioEndTime: string
  audioEndWin: string
  fieldBg: string
  brokenMyselfAccuracy: number
  defaultStep: number
  obstacleAccuracy: number
  prizeAccuracy: number
  startSide: SIDE
  roundTime: number
  startX: number
  startY: number
  id: number
  number: number
  rewardFood: number
  rewardFortune: number
  rewardGameCount: number
  rewardWaddlescoin: number
  purpose: number
  items: ItemPositionData[]
}

export type SimpleLevel = {
  id: number
  isPublished: boolean
  number: number
}

export type LevelEditData = {
  level: LevelData
  items: ItemDataType[]
}

export type GetLevelResponse = AxiosResponse<LevelEditData>

export type GetLevelsResponse = AxiosResponse<SimpleLevel[]>

export type CreateLevelResponse = AxiosResponse<SimpleLevel>

export type EditItem = {
  order?: number
  id: number
  x: number
  y: number
}

export type EditLevel = {
  number: number
  items?: EditItem[]
}

export type CreateLevelItemParams = {
  number: number
  itemType: string
  itemId: number
  order?: number
  x: number
  y: number
}

export type CreateLevelManyItemParams = {
  number: number
  items: Array<{
    fieldItem?: ItemDataType
    itemId: number
    x: number
    y: number
  }>
}

export type DeleteLevelItemParams = {
  number: number
  x: number
  y: number
}

export type EditLevelItemParams = {
  x: number
  y: number
  number: number
  order: number | null
}

export type EditLevelResponse = AxiosResponse<LevelData>
