import { brown } from '@mui/material/colors'

import { foodSrc } from '@/shared/assets'

import { foodAudioSrc } from '../../assets'
import { loadImage } from '../../utils'

// Неизменяемые данные
export const CANVAS_WIDTH = 360
export const NAV_SIZE = 28
export const CANVAS_HEIGHT = CANVAS_WIDTH + NAV_SIZE
export const NAV_COLOR = brown[500] // #795548
export const SEC_IN_MIN = 1000 // Простой секундый таймер

export const gameImages = {
  food: loadImage(foodSrc),
}

export const gameAudios = {
  food: new Audio(foodAudioSrc),
}
