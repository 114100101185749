import { green } from '@mui/material/colors'

import { Profile } from '../../types'

type Args = {
  borderColor?: string
  background?: string
}

export const getLevelColor = (
  profile: Profile | null,
  level: number,
  isPublished: boolean
): Args => {
  if (!isPublished || !profile) return {}
  if (profile.snakeParams.level > level) {
    return { borderColor: green[600], background: 'rgba(0, 255, 0, 0.5)' }
  }
  return {}
}
