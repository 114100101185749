import { createForm } from 'effector-forms'

import { RULES } from '@/shared/libs/effector-forms'

export const createChatForm = createForm({
  fields: {
    title: {
      init: '',
      rules: [RULES.required()],
    },
  },
  validateOn: ['submit'],
})
