import { useEffect } from 'react'

import { getAppPath } from '@/shared/utils/getPath'

export const BlogPage = () => {
  useEffect(() => {
    window.location.href = getAppPath(window.location.pathname)
  }, [])

  return null
}
