import '../../model/timer/init'

import { Delete as DeleteIcon, Input as InputIcon } from '@mui/icons-material'
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import { changeCreateMultiDialog } from '../../model/dialogs/events'
import { deleteTimer } from '../../model/multi-timer/events'
import { $timers } from '../../model/timer'
import { getTimers } from '../../model/timer/events'
import { DialogMultiUser } from '../dialog-multi-create'
import * as Styled from './styled'

export const TimerList = () => {
  const timers = useStore($timers)
  const user = useStore($user)

  if (!user) throw new Error('user not found')

  useEffect(() => {
    getTimers()
  }, [])

  return (
    <Styled.Root>
      <Typography variant="h3" color="inherit">
        Таймеры
      </Typography>
      <RouterLink style={{ width: '300px' }} to={ROUTE_PATH.TIMER_SINGLE}>
        <Button variant="contained" color="primary" sx={{ mt: 3, width: '300px' }}>
          Режим с одного устройства
        </Button>
      </RouterLink>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3, width: '300px' }}
        onClick={() => changeCreateMultiDialog(true)}
      >
        Создать новый
      </Button>
      <If condition={timers.length > 0}>
        <Box sx={{ width: '100%', maxWidth: 600 }}>
          <Typography sx={{ mt: 4, mb: 2, textAlign: 'center' }} variant="h5" component="div">
            Свежие таймеры:
          </Typography>
          <List>
            {timers.map((timer) => (
              <ListItem
                key={timer.id}
                button
                secondaryAction={
                  <>
                    <RouterLink to={`${ROUTE_PATH.TIMER}/${timer.id}`}>
                      <IconButton color="success" edge="end" aria-label="input">
                        <InputIcon />
                      </IconButton>
                    </RouterLink>
                    <If condition={user.id === timer.admin.id}>
                      <IconButton
                        sx={{ ml: 1 }}
                        color="error"
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteTimer(timer.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </If>
                  </>
                }
              >
                <ListItemText
                  primary={timer.title}
                  secondary={`Участники: ${timer.participants
                    .map(({ username }) => username)
                    .join(', ')}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </If>

      <DialogMultiUser />
    </Styled.Root>
  )
}
