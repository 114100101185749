import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { useStore } from 'effector-react'

import { changeDialogImage } from '../../../../model/messages/events'
import { $dialogImage } from '../../../../model/messages/store'

export const DialogImage = () => {
  const dialogImage = useStore($dialogImage)

  if (!dialogImage) return null

  return (
    <Dialog
      fullScreen
      open={Boolean(dialogImage)}
      onClose={() => changeDialogImage(null)}
      sx={{
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(1px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
        '& .MuiPaper-root': {
          backdropFilter: 'blur(1px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          height: '100%', // Использование 100% высоты для DialogContent
          width: '100%', // Использование 100% ширины для DialogContent
          padding: 0, // Убираем padding у DialogContent, если он есть
          backdropFilter: 'blur(1px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <Box
          component="img"
          src={dialogImage}
          alt="Preview"
          sx={{
            p: 1,
            maxHeight: '100vh', // Ограничиваем максимальную высоту изображения высотой видимой части экрана
            maxWidth: '100%', // Ограничиваем максимальную ширину изображения шириной DialogContent
            objectFit: 'contain', // Масштабируем содержимое, чтобы сохранять пропорции, помещаясь полностью в контейнер
            objectPosition: 'center', // Центрируем изображение
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          backdropFilter: 'blur(1px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <Button sx={{ width: '100%' }} onClick={() => changeDialogImage(null)}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
