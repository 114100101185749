import { Typography } from '@mui/material'
import { yellow } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  background-color: ${yellow[100]};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey['500']};
  padding: 6px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100px;
  height: 100px;
  img {
    width: 60px;
    height: 60px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      width: 50px;
      height: 50px;
    }
  }
`

export const Text = styled(Typography)`
  text-align: center;
  font-size: 14px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 12px;
  }
`
