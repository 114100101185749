import { SxProps, Typography, TypographyProps } from '@mui/material'
import { useEffect, useState } from 'react'

import { durationSeconds, secondsToTime } from '@/shared/utils/moment'

import { expiredFortune } from '../../model/fortune/events'
import * as Styled from './styled'

type Props = {
  time: string
  size?: number
  sx?: SxProps
  TypographyProps?: TypographyProps
}

export const TimeExpired = ({ time, TypographyProps = {}, size = 20, sx }: Props) => {
  const secondsLeft = durationSeconds(time)
  const timeLeft = secondsToTime(secondsLeft)
  const [newTime, setNewTime] = useState(timeLeft)

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = durationSeconds(time)

      if (duration < 0) {
        expiredFortune()
        clearInterval(interval)
        return
      }
      setNewTime(secondsToTime(duration))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time])

  return (
    <Styled.Root sx={sx}>
      <Typography fontSize={size} fontWeight={'bold'} {...TypographyProps}>
        Обновится через: {secondsLeft < 0 ? secondsToTime(0) : newTime}
      </Typography>
    </Styled.Root>
  )
}
