import { guard } from 'effector'

import * as api from './api'
import { getPackTransactions, getPackTransactionsFx } from './events'

getPackTransactionsFx.use(api.getPackTransactionsApi)

guard({
  source: getPackTransactions,
  filter: getPackTransactionsFx.pending.map((state) => !state),
  target: getPackTransactionsFx,
})
