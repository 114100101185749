import { v4 as uuidv4 } from 'uuid'

import { getAppPath } from '@/shared/utils/getPath'

import { GetSnakeSingleGameResponse, ItemPosition, ItemType, SingleGame } from '../../types'
import { gameAudios } from '../../ui/single-game/consts'
import { loadImage } from '../../utils'

export const mapSingleGameResponse = ({ data }: GetSnakeSingleGameResponse): SingleGame => {
  const itemsData = data.game.items.map(({ item }) => ({
    ...item,
    image: loadImage(getAppPath(item.image)),
    audio: item.audio ? new Audio(getAppPath(item.audio)) : gameAudios.food,
  }))

  const prizes: ItemPosition[] = []
  const obstacles: ItemPosition[] = []

  data.game.items.forEach((gameItem) => {
    const item = itemsData.find((item) => gameItem.item.id === item.id)
    if (!item) return
    const result: ItemPosition = {
      ...gameItem,
      id: uuidv4(),
      item,
      isActive: false,
      isEaten: false,
    }

    if (gameItem.item.itemType === ItemType.OBSTACLE) obstacles.push(result)
    else prizes.push(result)
  })

  return {
    ...data.game,
    record: data.record,
    difficulty: data.difficulty,
    params: data.gameParams,
    obstacles,
    prizes,
  }
}
