import { useEffect, useState } from 'react'

type Props = {
  children: JSX.Element
  time?: number
  isHide?: boolean
  showTime?: boolean
  waitingData?: JSX.Element | null
  onDelayed?: () => void
}

export const Delayed = ({
  children,
  time = 500,
  isHide = false,
  waitingData = null,
  onDelayed,
}: Props): JSX.Element | null => {
  const [isShown, setIsShown] = useState<boolean>(isHide)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(!isHide)
      onDelayed && onDelayed()
    }, time)
    return () => clearTimeout(timer)
  }, [time, isHide, onDelayed])

  return isShown ? children : waitingData
}
