// ./model/forms.ts
import { createForm } from 'effector-forms'

import { createReport } from './events'

export const reportForm = createForm({
  fields: {
    date: {
      init: '',
      rules: [
        {
          name: 'required',
          validator: (value: string) => value.trim().length > 0,
          errorText: 'Дата обязательна',
        },
      ],
    },
    about: {
      init: '',
      rules: [],
    },
    photoOfDay: {
      init: null as File | null,
      rules: [
        {
          name: 'required',
          validator: (value: File | null) => value !== null,
          errorText: 'Фото дня обязательно',
        },
      ],
    },
  },
  validateOn: ['submit'],
})

// Вызов эффекта при валидации формы
reportForm.formValidated.watch((formValues) => {
  const formData = new FormData()
  formData.append('date', formValues.date)
  formData.append('about', formValues.about)
  if (formValues.photoOfDay) {
    formData.append('photo_of_day', formValues.photoOfDay)
  }
  createReport(formData) // Отправляем данные на сервер
})
