import { Box, Button, PaginationItem, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { AlertApi } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { $levelsCombine } from '../../../../model/admin'
import { createNewLevel, getLevels } from '../../../../model/admin/events'
import { MainAdminGate } from '../../../../model/admin/gate'
import { GameWrapper } from '../../../game-wrapper'
import * as Styled from './styled'

export const LevelAdminMain = () => {
  useGate(MainAdminGate)
  const navigator = useNavigate()
  const { levelsData, levelsError, levelsPending } = useStore($levelsCombine)
  return (
    <GameWrapper withHeader={false}>
      <Styled.Root>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Админка змейки
        </Typography>
        <AlertApi
          errorText={levelsError}
          pending={levelsPending}
          loadingText={'Загружается'}
          onReload={() => getLevels()}
        />
        <If condition={!levelsPending}>
          <Box
            sx={{
              display: 'flex',
              widht: '100%',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              mb: 2,
            }}
          >
            {levelsData.map(({ id, number }) => (
              <PaginationItem
                sx={{ mb: 1 }}
                size="small"
                key={id}
                page={number}
                color="primary"
                variant="outlined"
                onClick={() => navigator(`${ROUTE_PATH.GAME_SNAKE_ADMIN}/${number}`)}
              />
            ))}
          </Box>
          <Button variant="contained" onClick={() => createNewLevel()}>
            Создать новый уровень
          </Button>
        </If>
      </Styled.Root>
    </GameWrapper>
  )
}
