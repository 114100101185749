import { SxProps } from '@mui/system'
import { LinkProps } from 'react-router-dom'

import * as Styled from './styled'

type RouterLinkProps = LinkProps & {
  color?: string
  sx?: SxProps
}

export const RouterLink = (props: RouterLinkProps) => {
  const { to, children, color, sx = {}, ...otherProps } = props
  return (
    <Styled.Root sx={{ color: color, ...sx }} to={to} {...otherProps}>
      {children}
    </Styled.Root>
  )
}
