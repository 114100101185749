import { Box, Button, Container, Typography } from '@mui/material'

import { RouterLink } from '@/shared/components/router-link'

import { ROUTE_PATH } from '../../../../packages/paths'

export const BoardGamesMainMenu = () => {
  return (
    <Container>
      <Box
        sx={{
          my: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ mb: 4 }}>
          Меню Настольных Игр
        </Typography>
        <Box sx={{ mb: 2, width: '100%' }}>
          <RouterLink to={ROUTE_PATH.BOARDGAMES_NEW}>
            <Button variant="contained" color="success" sx={{ width: '100%' }}>
              Добавить новую сессию
            </Button>
          </RouterLink>
        </Box>
        <Box sx={{ mb: 2, width: '100%' }}>
          <RouterLink to={ROUTE_PATH.BOARDGAMES_LIST}>
            <Button variant="contained" color="primary" sx={{ width: '100%' }}>
              Список сессий
            </Button>
          </RouterLink>
        </Box>
      </Box>
    </Container>
  )
}
