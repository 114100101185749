import { api } from '@/services/api'

import {
  CreateRatingResponse,
  CreateSessionResponse,
  GetRatingsResponse,
  GetSessionsResponse,
  Session,
} from './types'

export const getSessionsApi = async (): Promise<GetSessionsResponse> => {
  return api.get('/api/sessions/')
}

export const createSessionApi = async (sessionData: any): Promise<CreateSessionResponse> => {
  const response = await api.post('/api/sessions/', sessionData)
  return response.data
}

export const updateSessionApi = async ({
  id,
  data,
}: {
  id: number
  data: any
}): Promise<Session> => {
  const response = await api.put(`/api/sessions/${id}/`, data)
  return response.data
}

export const deleteSessionApi = async (id: number): Promise<void> => {
  await api.delete(`/api/sessions/${id}/`)
}

export const getRatingsApi = async (): Promise<GetRatingsResponse> => {
  return api.get('/api/ratings/')
}

export const createRatingApi = async (ratingData: any): Promise<CreateRatingResponse> => {
  const response = await api.post('/api/ratings/', ratingData)
  return response.data
}
