import moment from 'moment'

export const toBaseDateTime = (date: string) => moment(date).format('DD.MM.YYYY HH:mm:ss')
export const toBaseDate = (date: string) => moment(date).format('DD.MM.YYYY')

export const currentDate = () => moment().format('DD.MM.YYYY')

export const durationSeconds = (date: string) => {
  const now = moment(new Date()) //todays date
  const end = moment(date) // another date
  return moment.duration(end.diff(now)).asSeconds()
}

export const secondsToTime = (seconds: number) => {
  return moment.utc(seconds * 1000).format('HH:mm:ss')
}

export const checkTimeBetween = (before: string, after: string): boolean => {
  const TIME_FORMAT = 'hh:mm:ss'
  const time = moment()
  const beforeTime = moment(before, TIME_FORMAT)
  const afterTime = moment(after, TIME_FORMAT)
  return time.isBetween(beforeTime, afterTime)
}

export const checkTimeBetweenNight = (before: string, after: string): boolean => {
  const isPrevDay = checkTimeBetween(before, '24:00:00')
  const isNextDay = checkTimeBetween('00:00:00', after)
  return isPrevDay || isNextDay
}
