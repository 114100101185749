import { createForm } from 'effector-forms'

import { RULES } from '@/shared/libs/effector-forms'

export const $fillManyItemsForm = createForm({
  fields: {
    hasOrder: {
      init: false,
    },
    itemId: {
      init: 0,
      rules: [RULES.minValue(1)],
    },
    count: {
      init: 0,
      rules: [RULES.minValue(1)],
    },
  },
  validateOn: ['submit'],
})
