import gfm from 'remark-gfm'

import * as Styled from './styled'

type Props = {
  children: string
}

export const Markdown = ({ children }: Props) => {
  return (
    <Styled.Root
      remarkPlugins={[gfm]}
      components={{
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        a: ({ node, ...props }) => <a {...props} target="_blank" />,
        ul: ({ node, ...props }) => (
          <ul
            style={{ margin: '0 0 0 16px', padding: 0, lineHeight: 1.2, display: 'grid' }}
            {...props}
          />
        ),
        // Настройка стилей для нумерованных списков
        ol: ({ node, ...props }) => (
          <ol
            style={{ margin: '0 0 0 16px', padding: 0, lineHeight: 1.2, display: 'grid' }}
            {...props}
          />
        ),
        // Настройка стилей для элементов списка
        li: ({ node, ...props }) => (
          <li style={{ margin: 0, padding: '4px 0', lineHeight: 1.2 }} {...props} />
        ),
        p: ({ node, ...props }) => <p style={{ display: 'grid', lineHeight: 1.2 }} {...props} />,
      }}
    >
      {children}
    </Styled.Root>
  )
}
