import { combine, guard, sample } from 'effector'

import { $user } from '../../../auth'
import * as api from '../../api'
import { $titleNewTimer } from '../dialogs'
import {
  addUser,
  addUserFx,
  changeIsEditRoundTime,
  createTimer,
  createTimerFx,
  deleteTimer,
  deleteTimerFx,
  deleteUser,
  deleteUserFx,
  getTimer,
  getTimerFx,
  resetMultiTimer,
  setTimerData,
  startTimer,
  startTimerFx,
  updateParticipants,
  updateParticipantsFx,
  updateRound,
  updateRoundFx,
  updateStatus,
  updateStatusFx,
} from './events'

getTimerFx.use(api.getTimerApi)
createTimerFx.use(api.createTimerApi)
deleteTimerFx.use(api.deleteTimerApi)
addUserFx.use(api.addUserApi)
deleteUserFx.use(api.deleteUserApi)
startTimerFx.use(api.startTimerApi)
updateRoundFx.use(api.updateRoundApi)
updateStatusFx.use(api.updateStatusApi)
updateParticipantsFx.use(api.updateParticipantsApi)

const createNewTimer = sample({
  clock: createTimer,
  source: combine({ admin: $user, title: $titleNewTimer }),
  fn: (data) => ({ title: data.title, admin: data.admin?.id || 0 }),
})

guard({
  source: getTimer,
  filter: getTimerFx.pending.map((state) => !state),
  target: getTimerFx,
})

guard({
  source: createNewTimer,
  filter: createTimerFx.pending.map((state) => !state),
  target: createTimerFx,
})

guard({
  source: deleteTimer,
  filter: deleteTimerFx.pending.map((state) => !state),
  target: deleteTimerFx,
})

guard({
  source: addUser,
  filter: addUserFx.pending.map((state) => !state),
  target: addUserFx,
})

guard({
  source: deleteUser,
  filter: deleteUserFx.pending.map((state) => !state),
  target: deleteUserFx,
})

guard({
  source: startTimer,
  filter: startTimerFx.pending.map((state) => !state),
  target: startTimerFx,
})

guard({
  source: updateRound,
  filter: updateRoundFx.pending.map((state) => !state),
  target: updateRoundFx,
})

guard({
  source: updateStatus,
  filter: updateStatusFx.pending.map((state) => !state),
  target: updateStatusFx,
})

guard({
  source: updateParticipants,
  filter: updateParticipantsFx.pending.map((state) => !state),
  target: updateParticipantsFx,
})

createTimerFx.doneData.watch((data) => {
  setTimerData(data.data)
})

deleteTimerFx.doneData.watch((data) => {
  resetMultiTimer()
})

getTimerFx.doneData.watch((data) => {
  setTimerData(data.data)
})

updateRoundFx.doneData.watch(() => {
  changeIsEditRoundTime(false)
})
