import { sample } from 'effector'

import { getToken } from '@/services/api'
import { deepCamelCase } from '@/shared/libs/string/camelize'

import { $user } from '../../../../auth'
import { updateChatRoomForStore } from '../../chat-settings/events'
import { domain } from '../../domain'
import {
  addMessageInStore,
  deleteMessageInStore,
  editMessageInStore,
  fileChanged,
  resetDeleteMessageId,
  resetEditMessage,
  resetScrollHeightMessageContainer,
  setSendInputFocus,
} from '../events'
import { sendMessageForm } from '../forms'
import {
  changeMessagesSocketOnline,
  closeSocket,
  initiateWebSocketMessagesConnection,
  sendUserIsTyping,
  setUserIsTyping,
  setUserIsTypingStore,
  throttleUserTyping,
  webSocketConnect,
} from './events'
import { SocketType, WebSocketEvent } from './types'

webSocketConnect.use((chatId: number) => {
  const path = `${process.env.REACT_APP_WS_URL}/ws/chat/${chatId}/`
  const ws = new WebSocket(path)

  const token = getToken()

  ws.onopen = () => {
    ws.send(JSON.stringify({ action: 'authenticate', token }))
    changeMessagesSocketOnline(true)
  }
  ws.onclose = () => {
    changeMessagesSocketOnline(false)
  }

  return ws
})

// Подписываемся на событие подключения и вызываем эффект подключения
sample({
  clock: initiateWebSocketMessagesConnection,
  target: webSocketConnect,
})

let socket: WebSocket | null = null

// Обработка установленного соединения
webSocketConnect.done.watch(({ result }) => {
  socket = result

  socket.onmessage = (event) => {
    const data: WebSocketEvent = deepCamelCase(JSON.parse(event.data))

    if (data.type === SocketType.SEND) {
      addMessageInStore({ chatId: data.chatId, message: data.message })
    } else if (data.type === SocketType.EDIT) {
      editMessageInStore({ chatId: data.chatId, messageId: data.messageId, text: data.text })
    } else if (data.type === SocketType.DELETE) {
      deleteMessageInStore({ chatId: data.chatId, messageId: data.messageId })
    } else if (data.type === SocketType.UPDATE_CHAT) {
      updateChatRoomForStore(data.chat)
    } else if (data.type === SocketType.TYPING) {
      setUserIsTyping(data.username)
    }
  }
})

// Подписываемся на событие, которое сообщает, что пользователь начал печатать
let timeout: NodeJS.Timeout | null = null

addMessageInStore.watch(({ message }) => {
  const isAuthor = message.author.username === $user.getState()?.username
  setUserIsTypingStore(null)
  if (isAuthor) {
    throttleUserTyping.cancel()
    setSendInputFocus()
    sendMessageForm.reset()
    resetScrollHeightMessageContainer()
    fileChanged(null)
  }
})

deleteMessageInStore.watch(() => {
  resetDeleteMessageId()
})
editMessageInStore.watch(() => {
  resetEditMessage()
})

setUserIsTyping.watch((username) => {
  timeout && clearTimeout(timeout)
  setUserIsTypingStore(username)

  // Скрываем индикатор через некоторое время, если сообщение о печати не обновлялось
  timeout = setTimeout(() => setUserIsTypingStore(null), 3000)
})

sendUserIsTyping.watch((item) => {
  if (!socket) return
  socket.send(JSON.stringify({ action: 'typing' }))
})

closeSocket.watch(() => {
  if (socket) {
    socket.close()
  }
})

export const $messagesSocketOnline = domain
  .createStore<boolean>(false)
  .on(changeMessagesSocketOnline, (_, isOnline) => isOnline)
