import { Box } from '@mui/material'
import { memo } from 'react'
import { useDrag } from 'react-dnd'

import { ItemDataType } from '../../../../model/admin/types'

export interface FieldItemProps {
  fieldItem: ItemDataType
}

export const FieldItem: React.FC<FieldItemProps> = memo(({ fieldItem }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: fieldItem.itemType,
      item: fieldItem,
      collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.4 : 1 }),
    }),
    [fieldItem]
  )

  return (
    <Box ref={drag} sx={{ cursor: 'move', opacity, mr: 1 }}>
      <img src={fieldItem?.image} width={'100%'} height={'100%'} alt={fieldItem?.name} />
    </Box>
  )
})
