import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'

import { RouterLink } from '@/shared/components/router-link'

import { ROUTE_PATH } from '../../../../packages/paths'
import { $sessionCreating } from '../../model'
import { createSessionFx } from '../../model/events'
import { sessionForm } from '../../model/forms'

export const NewGameSession = () => {
  const sessionCreating = useStore($sessionCreating)
  const { fields, submit, error } = useForm(sessionForm)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  useEffect(() => {
    fields.game.onChange('')
    fields.date.onChange('')
    fields.duration.onChange('')
    fields.participants.onChange([])
    fields.winner.onChange('')

    const unsubscribeCreate = createSessionFx.doneData.watch(() => {
      setIsSubmitted(true)
      setSubmitError(null)
    })

    const unsubscribeCreateError = createSessionFx.failData.watch((error) => {
      setSubmitError(error?.response?.data[0] || 'Произошла ошибка при создании игровой сессии')
    })

    return () => {
      unsubscribeCreate()
      unsubscribeCreateError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Container>
      <Box sx={{ mt: 3, width: '100%' }}>
        <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', fontWeight: 700 }}>
          Новая игровая сессия
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <RouterLink to={ROUTE_PATH.BOARDGAMES}>
            <Button variant="outlined" color="primary">
              На главную
            </Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.BOARDGAMES_LIST}>
            <Button variant="outlined" color="primary">
              К списку сессий
            </Button>
          </RouterLink>
        </Box>
        {isSubmitted ? (
          <Box>
            <Alert severity="success" sx={{ mb: 2 }}>
              Игровая сессия успешно создана!
            </Alert>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Название игры"
              name="game"
              value={fields.game.value}
              onChange={(e) => fields.game.onChange(e.target.value)}
              error={!!error('game')}
              helperText={error('game')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Дата"
              name="date"
              type="date"
              value={fields.date.value}
              onChange={(e) => fields.date.onChange(e.target.value)}
              error={!!error('date')}
              helperText={error('date')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Длительность (в минутах)"
              name="duration"
              type="number"
              value={fields.duration.value}
              onChange={(e) => fields.duration.onChange(e.target.value)}
              error={!!error('duration')}
              helperText={error('duration')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Участники (через запятую)"
              name="participants"
              value={fields.participants.value}
              onChange={(e) => fields.participants.onChange(e.target.value.split(','))}
              error={!!error('participants')}
              helperText={error('participants')?.errorText}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Победитель"
              name="winner"
              value={fields.winner.value}
              onChange={(e) => fields.winner.onChange(e.target.value)}
              error={!!error('winner')}
              helperText={error('winner')?.errorText}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%' }}
              color="primary"
              disabled={sessionCreating}
              startIcon={sessionCreating ? <CircularProgress size={20} /> : null}
            >
              {sessionCreating ? 'Отправка...' : 'Создать сессию'}
            </Button>
          </form>
        )}
      </Box>
    </Container>
  )
}
