import { Gift } from '../../types'
import { domain } from '../domain'
import { activateGiftFx, changeAccordionId, changeModalGiftVisible, getGiftsFx } from './events'

export const $giftList = domain
  .createStore<Gift[]>([])
  .on(getGiftsFx.doneData, (_, { data }) => data)
  .on(activateGiftFx.doneData, (gifts, { data }) =>
    gifts.map((item) => (item.id === data.id ? { ...item, isUsed: true } : item))
  )

export const $notUserGiftCount = $giftList.map(
  (gifts) => gifts.filter((item) => !item.isUsed).length
)

export const $giftError = domain
  .createStore<string | null>(null)
  .on(getGiftsFx.failData, (_, error) => 'Ошибка при загрузке подарков')

export const $activateGiftError = domain
  .createStore<string | null>(null)
  .on(activateGiftFx.doneData, () => null)
  .on(activateGiftFx.failData, (_, error) => 'Ошибка при активации подарка')

export const $getGitsPending = getGiftsFx.pending

export const $activateGiftsPending = activateGiftFx.pending

// Отображение модального окна
export const $modalVisible = domain
  .createStore<boolean>(false)
  .on(changeModalGiftVisible, (_, isVisible) => isVisible)

export const $accordionId = domain
  .createStore<number | null>(null)
  .on(changeAccordionId, (activeId, id) => (activeId === id ? null : id))
