type IfProps = {
  condition: any
  children: React.ReactNode
}

export const If = ({ children, condition }: IfProps) => {
  if (Boolean(condition)) {
    return <>{children}</>
  }

  return null
}
