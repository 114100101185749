import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WifiIcon from '@mui/icons-material/Wifi'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { useStore } from 'effector-react'
import { useNavigate } from 'react-router-dom'

import { ROUTE_PATH } from '@/packages/paths'
import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { useMedia } from '../../../../../../shared/utils/media'
import { resetActiveChatId } from '../../../../model/chat/events'
import { initializeSockets } from '../../../../model/messages/events'
import { $socketsIsOnline } from '../../../../model/messages/store'
import { ChatSettings } from '../../../chat-settings'
import { SettingsButton } from './ui/settings-button'

type Props = {
  title: string
  avatar: string | null
}

export const ChatHeader = ({ title, avatar }: Props) => {
  const navigate = useNavigate()
  const { isDesktop } = useMedia()
  const chatSocketIsOnline = useStore($socketsIsOnline)
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        alignItems: 'center',
        height: isDesktop ? 40 : 30,
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <If condition={!isDesktop}>
            <IconButton
              onClick={() => {
                navigate(ROUTE_PATH.CHAT, { replace: true })
                resetActiveChatId()
              }}
              sx={{ p: 0 }}
            >
              <ArrowBackIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </If>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <If condition={chatSocketIsOnline}>
              <WifiIcon sx={{ fontSize: 32, color: green[700] }} />
            </If>
            <If condition={!chatSocketIsOnline}>
              <WifiOffIcon
                sx={{ fontSize: 32, color: red[700] }}
                onClick={() => initializeSockets()}
              />
            </If>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <If condition={avatar}>
          <Avatar alt={title} src={getAppPath(avatar)} sx={{ mr: 1, height: 30, width: 30 }} />
        </If>

        <Typography fontSize={20} fontWeight={700}>
          {title}
        </Typography>
      </Box>
      <Box>
        <SettingsButton />
      </Box>
      <ChatSettings />
    </Box>
  )
}
