import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { AlertLoading } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import { $filteredPhotoDayList, $loadedImages } from '../../model/photo_days'
import { changeActiveTab, setLoadedImage } from '../../model/photo_days/events'
import { PhotoDayItem, PhotoDayTabName } from '../../types'
import * as Styled from './styled'

type ModalPhotoDayProps = {
  photoDayList: PhotoDayItem[]
  activeTab: PhotoDayTabName
}

export const ModalPhotoDayTabs = ({ photoDayList, activeTab }: ModalPhotoDayProps) => {
  const filteredPhotoDayList = useStore($filteredPhotoDayList)
  const loadedImage = useStore($loadedImages)

  return (
    <Box>
      <Styled.TabWrapper>
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons
          onChange={(_, newValue) => changeActiveTab(newValue)}
        >
          {photoDayList.map((item) => (
            <Tab key={item.key} label={item.text} value={item.key} disabled={!item.url} />
          ))}
        </Tabs>
      </Styled.TabWrapper>
      {filteredPhotoDayList.map((item) => (
        <If key={item.key} condition={item.key === activeTab}>
          <Box>
            <If condition={!loadedImage[item.key]}>
              <AlertLoading text="Фотогравия загружается" sx={{ my: 2 }} />
            </If>
            <Styled.Image
              sx={{ display: loadedImage[item.key] ? '' : 'none' }}
              src={item.url as string}
              alt={item.text}
              onLoad={() => setLoadedImage(item.key)}
            />
            <Typography sx={{ mt: 2, textAlign: 'left' }}>{item.about}</Typography>
          </Box>
        </If>
      ))}
    </Box>
  )
}
