import { api } from '@/services/api'

import {
  CreateLevelItemParams,
  CreateLevelManyItemParams,
  CreateLevelResponse,
  DeleteLevelItemParams,
  EditLevelItemParams,
  EditLevelResponse,
  GetLevelResponse,
  GetLevelsResponse,
} from './model/admin/types'
import { FinishLevelParams, FinishLevelResponse } from './model/level/types'
import {
  BuyGameResponse,
  FinishSingleGameParams,
  FinishSingleGameResponse,
  GetProfileResponse,
  GetSnakeSingleGameParams,
  GetSnakeSingleGameResponse,
  StatsResponse,
  UpdateParamRequest,
  UpdateParamResponse,
  UpgradeParamRequest,
  UpgradeParamResponse,
} from './types'

export const getProfileApi = (): Promise<GetProfileResponse> => {
  return api.get('/game_snake/profile/')
}

export const getSnakeSingeGameApi = (
  params: GetSnakeSingleGameParams
): Promise<GetSnakeSingleGameResponse> => {
  return api.post('/game_snake/single_game/', params)
}

export const buyGameApi = (): Promise<BuyGameResponse> => {
  return api.post('/game_snake/shop/buy_game')
}

export const finishSingleGameApi = (
  params: FinishSingleGameParams
): Promise<FinishSingleGameResponse> => {
  return api.post('/game_snake/single_game/finish', params)
}

export const upgradeParamApi = (params: UpgradeParamRequest): Promise<UpgradeParamResponse> => {
  return api.post('/game_snake/shop/upgrade_param', params)
}

export const updateParamApi = (params: UpdateParamRequest): Promise<UpdateParamResponse> => {
  return api.patch('/game_snake/params/update', params)
}

export const getStatsApi = (): Promise<StatsResponse> => {
  return api.get('/game_snake/stats/')
}

export const getLevelsApi = (): Promise<GetLevelsResponse> => {
  return api.get(`/game_snake/admin/level/`)
}

export const getLevelApi = (number: number): Promise<GetLevelResponse> => {
  return api.get(`/game_snake/admin/level/${number}/`)
}

export const createLevelItemApi = (params: CreateLevelItemParams): Promise<EditLevelResponse> => {
  const { number, ...otherParams } = params
  return api.post(`/game_snake/admin/level/${number}/edit_item/`, otherParams)
}

export const deleteLevelItemApi = (params: DeleteLevelItemParams): Promise<EditLevelResponse> => {
  const { number, x, y } = params
  return api.delete(`/game_snake/admin/level/${number}/edit_item/?x=${x}&y=${y}`)
}

export const editLevelItemApi = (params: EditLevelItemParams): Promise<EditLevelResponse> => {
  const { number, ...otherParams } = params
  return api.patch(`/game_snake/admin/level/${number}/edit_item/`, otherParams)
}

export const clearItemsApi = (number: number): Promise<EditLevelResponse> => {
  return api.patch(`/game_snake/admin/level/${number}/clear/`)
}

export const fillManyItemsApi = (params: CreateLevelManyItemParams): Promise<EditLevelResponse> => {
  const { number, items } = params
  return api.post(`/game_snake/admin/level/${number}/fill_many_items/`, items)
}

export const createNewLevelApi = (): Promise<CreateLevelResponse> => {
  return api.post('/game_snake/admin/level/create/')
}

export const finishLevelGameApi = (params: FinishLevelParams): Promise<FinishLevelResponse> => {
  const { number, ...otherParams } = params
  return api.post(`/game_snake/level/finish/${number}/`, otherParams)
}
