import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Alert, IconButton, Snackbar, Typography } from '@mui/material'
import React from 'react'

import { If } from '@/shared/utils/if'

type Props = {
  viewText: string
  copyText?: string
  onAfterCopy?: () => void
  showIcon?: boolean
  withSnackbar?: boolean
}

export const CopyableText = ({
  viewText,
  copyText,
  onAfterCopy,
  showIcon = false,
  withSnackbar = false,
}: Props) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    navigator.clipboard.writeText(copyText || viewText).then(() => {
      withSnackbar && setOpenSnackbar(true)
      onAfterCopy && onAfterCopy()
    })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <Typography onClick={handleClick} style={{ cursor: 'pointer' }}>
        {viewText}
        <If condition={showIcon}>
          <IconButton size="small" onClick={handleClick}>
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </If>
      </Typography>
      <If condition={withSnackbar}>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: '100%' }}
          >
            Скопировано
          </Alert>
        </Snackbar>
      </If>
    </>
  )
}
