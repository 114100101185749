import {
  BoardGamesMainMenu,
  EditGameSession,
  GameSessionsList,
  NewGameSession,
  ReviewGameSession,
} from '@/features/boardgames'

import { Template } from '../templates/template'

export const BoardGamesMainMenuPage = () => {
  return (
    <Template withFooter={false} hasContainer={false}>
      <BoardGamesMainMenu />
    </Template>
  )
}

export const BoardGamesNewPage = () => {
  return (
    <Template withFooter={false} hasContainer={false}>
      <NewGameSession />
    </Template>
  )
}

export const BoardGamesReviewPage = () => {
  return (
    <Template withFooter={false}>
      <ReviewGameSession />
    </Template>
  )
}

export const BoardGamesListPage = () => {
  return (
    <Template withFooter={false}>
      <GameSessionsList />
    </Template>
  )
}

export const BoardGamesEditPage = () => {
  return (
    <Template withFooter={false}>
      <EditGameSession />
    </Template>
  )
}
