import { createGate } from 'effector-react'

import { getUserChatsRoom } from './events'
import { closeSocket, initiateWebSocketChatConnection } from './socket'

export const ChatListGate = createGate('ChatListGate')

ChatListGate.open.watch(() => {
  getUserChatsRoom()
  initiateWebSocketChatConnection()
})

ChatListGate.close.watch(() => {
  closeSocket()
})
