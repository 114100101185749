import { createForm } from 'effector-forms'

import { createRuleError, RULES } from '@/shared/libs/effector-forms'

export const loginForm = createForm({
  fields: {
    username: {
      init: '',
      rules: [
        createRuleError({
          rule: RULES.minLength(2),
          errorText: 'Логин должен быть минимум 2 символа',
        }),
      ],
    },
    password: {
      init: '',
      rules: [
        createRuleError({
          rule: RULES.required(),
          errorText: 'Поле пароль обязательное',
        }),
      ],
    },
  },
  validateOn: ['submit'],
})
