import { Player } from '../../types';
import { createPlayer } from '../../utils';
import { domain } from '../domain';
import {
  addPlayer,
  changePlayer,
  removeAllPlayers,
  removePlayer,
} from './events';

// remove before prod
const users = [createPlayer(1, ''), createPlayer(2, ''), createPlayer(3, '')];

export const $players = domain
  .createStore<Player[]>(users)
  .on(addPlayer, (items, name) => [
    ...items,
    createPlayer(items.length + 1, name),
  ])
  .on(changePlayer, (items, player) =>
    items.map((item) =>
      item.id === player.id ? { ...item, name: player.name } : item
    )
  )
  .on(removePlayer, (items, id) =>
    items
      .filter((item) => item.id !== id)
      .map((item, i) => ({ ...item, number: i + 1 }))
  )
  .on(removeAllPlayers, () => []);
