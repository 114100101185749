import { Clear as ClearIcon, Delete as DeleteIcon, Done as DoneIcon } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, TextField } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { $menuAnchor } from '../../../../model/admin'
import { clearFieldCell, editLevelItem, setMenuAnchor } from '../../../../model/admin/events'
import { getLevelId } from '../../../../model/admin/utils'

export const FieldMenu = () => {
  const menuAnchor = useStore($menuAnchor)
  const [order, setOrder] = useState<number>(Number(menuAnchor?.dataset.cellOrder))

  useEffect(() => {
    setOrder(Number(menuAnchor?.dataset.cellOrder))
  }, [menuAnchor])

  const clearCell = () => {
    clearFieldCell({
      x: Number(menuAnchor?.dataset.cellX),
      y: Number(menuAnchor?.dataset.cellY),
    })
    setMenuAnchor(null)
  }
  const saveOrder = (isClear: boolean = false) => {
    const x = Number(menuAnchor?.dataset.cellX)
    const y = Number(menuAnchor?.dataset.cellY)
    editLevelItem({ x, y, order: isClear ? null : order, number: getLevelId() })
    setOrder(0)
    setMenuAnchor(null)
  }

  return (
    <Menu
      id="basic-menu"
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      sx={{ minWidth: 120 }}
      onClose={() => setMenuAnchor(null)}
    >
      <Box sx={{ px: 2 }}>{`x: ${menuAnchor?.dataset.cellX}, y: ${menuAnchor?.dataset.cellY}`}</Box>
      <Box sx={{ display: 'flex', p: 2 }}>
        <TextField
          type="number"
          value={order}
          autoFocus
          label="Очередь"
          variant="standard"
          onKeyDown={(e) => e.key === 'Enter' && saveOrder()}
          onChange={(e) => setOrder(Number(e.target.value))}
        />
        <Box sx={{ display: 'flex', ml: 1 }}>
          <IconButton onClick={() => saveOrder()}>
            <DoneIcon color="success" />
          </IconButton>
          <IconButton onClick={() => saveOrder(true)}>
            <ClearIcon color="error" />
          </IconButton>
        </Box>
      </Box>
      <MenuItem sx={{ color: 'error.main' }} onClick={clearCell}>
        <DeleteIcon />
        Удалить
      </MenuItem>
    </Menu>
  )
}
