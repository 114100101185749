import { Rule } from 'effector-forms'

import { RULES } from './rules'

type RuleStringErrorAttrs = {
  rule: Rule<string>
  errorText: string
}

type RuleNumberErrorAttrs = {
  rule: Rule<number>
  errorText: string
}

const createRuleError = ({ rule, errorText }: RuleStringErrorAttrs): Rule<string> => ({
  ...rule,
  errorText,
})

const createRuleNumError = ({ rule, errorText }: RuleNumberErrorAttrs): Rule<number> => ({
  ...rule,
  errorText,
})

export { createRuleError, createRuleNumError, RULES }
