// ./ui/encrypt/index.tsx
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { If } from '@/shared/utils/if'

import { $encryptedData, $encryptPending } from '../../model'
import { encryptForm } from '../../model/forms'
import { $encryptError } from '../../model/store'
import { FixedBox } from '../main/styled'

export const Encrypt = () => {
  const pending = useStore($encryptPending)
  const encryptedData = useStore($encryptedData)
  const encryptError = useStore($encryptError)
  const { fields, submit, error } = useForm(encryptForm)

  // State для управления видимостью текста в полях
  const [showSecretPhrase, setShowSecretPhrase] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  // Функция для копирования текста в буфер обмена
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Текст скопирован в буфер обмена')
    })
  }

  return (
    <FixedBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      p={2}
      boxSizing="border-box"
    >
      <Typography variant="h5" gutterBottom>
        Шифрование
      </Typography>
      <TextField
        label="Секретная фраза"
        variant="outlined"
        type={showSecretPhrase ? 'text' : 'password'}
        value={fields.secretPhrase.value}
        onChange={(e) => fields.secretPhrase.onChange(e.target.value)}
        disabled={pending}
        fullWidth
        margin="normal"
        error={!!error('secretPhrase')}
        helperText={error('secretPhrase')?.errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle secret phrase visibility"
                onClick={() => setShowSecretPhrase(!showSecretPhrase)}
                edge="end"
                tabIndex={-1} // Исключаем из цикла табуляции
              >
                {showSecretPhrase ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Сообщение"
        variant="outlined"
        type={showMessage ? 'text' : 'password'}
        value={fields.message.value}
        onChange={(e) => fields.message.onChange(e.target.value)}
        disabled={pending}
        fullWidth
        margin="normal"
        error={!!error('message')}
        helperText={error('message')?.errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle message visibility"
                onClick={() => setShowMessage(!showMessage)}
                edge="end"
                tabIndex={-1} // Исключаем из цикла табуляции
              >
                {showMessage ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {encryptError && (
        <Typography variant="body2" color="error" mt={1}>
          {encryptError}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={() => submit()}
        disabled={pending}
        fullWidth
        sx={{ mt: 2 }}
      >
        Зашифровать
      </Button>
      <If condition={!!encryptedData}>
        <Box mt={2} width="100%">
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              overflow: 'hidden', // Скрываем переполнение
            }}
          >
            <Box flexGrow={1} overflow="auto">
              <Typography variant="h6" gutterBottom>
                Зашифрованное сообщение:
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                {encryptedData?.encryptedMessage}
              </Typography>
            </Box>
            <IconButton onClick={() => handleCopy(encryptedData?.encryptedMessage || '')}>
              <ContentCopyIcon />
            </IconButton>
          </Paper>
          <Box mt={2} textAlign="center">
            <Typography variant="h6">QR код:</Typography>
            <img
              src={`data:image/png;base64,${encryptedData?.qrCode}`}
              alt="QR Code"
              style={{ maxWidth: '100%', maxHeight: '250px' }}
            />
          </Box>
        </Box>
      </If>
    </FixedBox>
  )
}
