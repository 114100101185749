import { IconButton as IconButtonMui,Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PlayerCard = styled(Paper)<{ $active: boolean; $timeLeft: number | null }>`
  word-break: break-all;
  margin-top: 10px;
  padding: 10px 20px;
  user-select: none;
  min-width: 300px;
  transition: all 0.3s;
  && {
    background: ${({ $active, $timeLeft = 0, theme }) => {
      return $active && $timeLeft === 0
        ? theme.palette.error.main
        : $active && Number($timeLeft) > 10
        ? theme.palette.info.main
        : $active && Number($timeLeft) <= 10
        ? theme.palette.warning.main
        : theme.palette.common.white
    }};
    color: ${({ $active, $timeLeft, theme }) =>
      $active && $timeLeft === 0 ? theme.palette.common.white : $active ? theme.palette.common.white : undefined};
  }
`

export const IconButton = styled(IconButtonMui)<{ $active: boolean }>`
  svg {
    transition: all 0.3s;
    font-size: 80px;
    opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  }
  color: ${({ $active, theme }) => ($active ? theme.palette.common.white : undefined)};
`
