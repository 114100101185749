import { AudioService } from '@/services/audio'

import audioBgSrc from '../../assets/bg.mp3'
import audioFailSrc from '../../assets/fail.mp3'
import audioWinSrc from '../../assets/win.mp3'
import { NumberItem } from '../../types'
import { domain } from '../domain'
import {
  activateNumberFx,
  disableButton,
  getNumbersFx,
  hideNumber,
  hideNumbers,
  resetActiveNumber,
} from './events'

export const $numbers = domain
  .createStore<NumberItem[]>([])
  .on(getNumbersFx.doneData, (_, { data }) => data)
  .on(hideNumber, (data, num) =>
    data.map((item) => (item.number !== num ? item : { ...item, hideNumber: true }))
  )
  .on(hideNumbers, (data, nums) =>
    data.map((item) => (!nums.includes(item.number) ? item : { ...item, hideNumber: true }))
  )

export const $activeNumber = domain
  .createStore<number | null>(null)
  .on(activateNumberFx.doneData, (_, { data }) => data.number)
  .reset(resetActiveNumber)

export const $disableButton = domain
  .createStore<boolean>(false)
  .on(disableButton, (_, isDisabled) => isDisabled)
  .reset(resetActiveNumber)

export const audioBg = new AudioService(audioBgSrc)
export const audioWin = new AudioService(audioWinSrc)
export const audioFail = new AudioService(audioFailSrc)
