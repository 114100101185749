import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { yellow } from '@mui/material/colors'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { AlertSuccess } from '../../../../shared/components/alert'
import { If } from '../../../../shared/utils/if'
import { $user } from '../../../auth'
import { User } from '../../../auth/types'
import { $activeChat } from '../../model/chat/store'
import { $openChatSettings, updateChatForm } from '../../model/chat-settings'
import { changeChatSettingsModal } from '../../model/chat-settings'
import { resetSettingsUpdate } from '../../model/chat-settings/events'
import { $settingsUpdated, $updateChatPending } from '../../model/chat-settings/store'
import { SettingsMembers } from './ui/members'

export const ChatSettings = () => {
  const isOpen = useStore($openChatSettings)
  const pending = useStore($updateChatPending)
  const activeChat = useStore($activeChat)
  const settingsUpdated = useStore($settingsUpdated)
  const user = useStore($user) as User

  const { fields, submit, errorText } = useForm(updateChatForm)

  useEffect(() => {
    if (activeChat) {
      fields.title.set(activeChat.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat])

  if (!activeChat) return null

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => changeChatSettingsModal(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Настройки чата</DialogTitle>
      <DialogContent>
        <If condition={user.username === activeChat.owner.username}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              bgcolor: yellow[200],
              p: 1,
              borderRadius: 2,
            }}
          >
            <TextField
              sx={{ mb: 1, width: '100%' }}
              multiline
              disabled={pending}
              value={fields.title.value}
              error={fields.title.hasError()}
              helperText={errorText('title')}
              variant="standard"
              label="Название"
              onChange={(e) => {
                resetSettingsUpdate()
                fields.title.onChange(e.target.value)
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 1 }}>Аватар</Typography>
              <TextField
                type="file"
                sx={{ width: '100%' }}
                disabled={pending}
                error={fields.avatar.hasError()}
                helperText={errorText('title')}
                onChange={(e) => {
                  resetSettingsUpdate()
                  // @ts-ignore
                  const file = e.target?.files[0]
                  if (file) {
                    fields.avatar.onChange(file)
                  }
                }}
              />
            </Box>
            <LoadingButton
              variant="contained"
              loading={pending}
              sx={{ my: 1, width: '100%' }}
              onClick={() => submit()}
            >
              Сохранить изменения
            </LoadingButton>
            <If condition={settingsUpdated}>
              <AlertSuccess text="Упешно сохранено!" />
            </If>
          </Box>
        </If>
        <Box component="hr" height="1px" sx={{ my: 1 }} />
        <Box sx={{ mb: 1 }}>
          <SettingsMembers members={activeChat.members} chatId={activeChat.id} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button sx={{ width: '100%' }} onClick={() => changeChatSettingsModal(false)}>
          Закрыть настройки
        </Button>
      </DialogActions>
    </Dialog>
  )
}
