import { User } from '../../../auth/types'
import { addPlayer, removeAllPlayers } from '../../model/single-player/events'
import { Timer } from '../../types'

export const fillBasePlayers = () => {
  removeAllPlayers()
  addPlayer('Лена')
  addPlayer('Витя')
  addPlayer('Мила')
}

export const checkIsActiveUser = (timer: Timer | null, user: User | null): boolean => {
  if (!timer || !user) return false
  return !!timer.participants.find((item) => item.username === user.username)
}
