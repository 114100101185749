import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { ROUTE_PATH } from '@/packages/paths'
import { router } from '@/packages/routes'
import { Timer } from '@/shared/components/timer'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import { $battle, $battleProfiles } from '../../model/game'
import { BattleStatus } from '../../model/game/types'
import { chatSocket } from '../../model/ws'
import bgSounds from './assets/bg.mp3'
import * as Styled from './styled'
import { Question } from './ui/question'

const close = () => {
  router.navigate(ROUTE_PATH.GAME_ENGAME)
  if (!chatSocket) return
  chatSocket.send(
    JSON.stringify({
      type: 'close',
    })
  )
}

const audioBg = new Audio(bgSounds)

export const EnGameBatte = () => {
  const battle = useStore($battle)
  const battleProfiles = useStore($battleProfiles)

  useEffect(() => {
    if (battle) {
      audioBg.play()
      audioBg.loop = true
    }
    return () => {
      audioBg.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!battle || !battleProfiles)
    return (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Button
          sx={{ width: '70%', mt: 3 }}
          variant="contained"
          onClick={() => router.navigate(ROUTE_PATH.GAME_ENGAME)}
        >
          Go home
        </Button>
      </Box>
    )

  const { user, enemy } = battleProfiles

  const userWin = enemy.health <= 0
  const enemyWin = user.health <= 0

  const hasWin = userWin || enemyWin
  const userAnswer = battle.activeQuestion?.answers.find((item) => item.id === user.answerId)
  const enemyAnswer = battle.activeQuestion?.answers.find((item) => item.id === enemy.answerId)

  return (
    <Styled.Root>
      <Box component="h1" textAlign="center" fontSize="40px" sx={{ my: 2 }}>
        Бой
      </Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box textAlign="center" width="140px">
          <Typography fontSize="24px" component="h4" color="mediumseagreen">
            {user.profile.username}
          </Typography>
          <List>
            <ListItem divider>
              <ListItemIcon sx={{ mr: 3 }}>Атака</ListItemIcon>
              <ListItemText primary={user.profile.attack} />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 3 }}>Здоровье</ListItemIcon>
              <ListItemText primary={user.profile.health} />
            </ListItem>
          </List>
          <Typography fontSize="24px" component="h4">
            {user.health}
            <If condition={user.lastDamage !== null}>
              <Box component="span" fontSize="30px" sx={{ color: 'red' }}>
                &nbsp;- {user.lastDamage}
              </Box>
            </If>
            <If condition={userAnswer && battle.status === BattleStatus.ROUND_END}>
              <br />
              <Box
                component="span"
                fontSize="14px"
                sx={{
                  color:
                    userAnswer?.id === battle.activeQuestion?.correctAnswer.id ? 'green' : 'red',
                }}
              >
                Ответ: {userAnswer?.answer}
              </Box>
            </If>
          </Typography>
        </Box>
        <Box textAlign="center" width="140px">
          <Typography fontSize="24px" component="h4" color="orangered">
            {enemy.profile.username}
          </Typography>
          <List>
            <ListItem divider>
              <ListItemIcon sx={{ mr: 3 }}>Атака</ListItemIcon>
              <ListItemText primary={user.profile.attack} />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 3 }}>Здоровье</ListItemIcon>
              <ListItemText primary={user.profile.health} />
            </ListItem>
          </List>
          <Typography fontSize="24px" component="h4">
            {enemy.health}
            <If condition={enemy.lastDamage !== null}>
              <Box component="span" fontSize="30px" sx={{ color: 'red' }}>
                &nbsp;- {enemy.lastDamage}
              </Box>
            </If>
            <If condition={enemyAnswer && battle.status === BattleStatus.ROUND_END}>
              <br />
              <Box
                component="span"
                fontSize="14px"
                sx={{
                  color:
                    enemyAnswer?.id === battle.activeQuestion?.correctAnswer.id ? 'green' : 'red',
                }}
              >
                Ответ: {enemyAnswer?.answer}
              </Box>
            </If>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Choose>
          <When condition={[BattleStatus.ACCEPT, BattleStatus.ROUND_START].includes(battle.status)}>
            <Timer count={3} />
          </When>
          <When condition={userWin}>
            <Typography color="lightGreen" fontSize="32px" component="h4">
              Вы победили
            </Typography>
          </When>
          <When condition={enemyWin}>
            <Typography color="error" fontSize="32px" component="h4">
              Вы проиграли!
            </Typography>
          </When>
          <When condition={battle.activeQuestion}>
            {battle.activeQuestion && (
              <Question
                status={battle.status}
                question={battle.activeQuestion}
                battleId={battle.id}
              />
            )}
          </When>
        </Choose>
      </Box>
      <If condition={hasWin}>
        <Button
          size="large"
          sx={{ width: '100%', mt: 4 }}
          variant="contained"
          onClick={() => close()}
        >
          Играть ещё раз
        </Button>
      </If>
    </Styled.Root>
  )
}
