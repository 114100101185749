import '../../model/number/init'

import { Box, Button } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { random, range, shuffle } from 'lodash'
import { useEffect, useState } from 'react'

import { Delayed } from '../../../../shared/components/delayed'
import { Choose, Otherwise, When } from '../../../../shared/utils/choose'
import { If } from '../../../../shared/utils/if'
import { NumberGameGate } from '../../model/gate'
import { $activeNumber, $disableButton, $numbers, audioBg } from '../../model/number'
import {
  activateNumber,
  disableButton,
  hideNumbers,
  resetActiveNumber,
} from '../../model/number/events'
import * as Styled from './styled'
import { getChanceSuccess, getRemovedCount, getVolumeByChance, getWinCount } from './utils'

export const NumberGameMain = () => {
  useGate(NumberGameGate)
  const activeNumber = useStore($activeNumber)
  const numbers = useStore($numbers)
  const isDisabled = useStore($disableButton)
  const [chance, setChance] = useState<number | null>(null)

  audioBg.setVolume(getVolumeByChance(chance))
  const winCount = getWinCount(numbers)

  const onActivateNumber = () => {
    disableButton(true)
    audioBg.play()
    const numbersData = shuffle(numbers)
    if (numbersData.length === 1) {
      activateNumber(numbersData[0].number)
      return
    }

    const checkNum = () => {
      const removeCount = getRemovedCount(numbersData)
      const removedNumbers: number[] = []
      const mockeRemoveArr = range(removeCount)

      mockeRemoveArr.forEach((item) => {
        const removedNumber = numbersData.shift()
        if (removedNumber) {
          removedNumbers.push(removedNumber.number)
        }
      })

      hideNumbers(removedNumbers)

      if (numbersData.length === 1) {
        const randomIndex = random(0, numbersData.length - 1)
        const winNumber = numbersData[randomIndex].number
        audioBg.stop()
        activateNumber(winNumber)
        return
      }

      const winCount = getWinCount(numbersData)
      const timeout = winCount === 0 ? 2000 : 5000

      audioBg.setVolume(getVolumeByChance(chance))

      setTimeout(() => {
        checkNum()
      }, timeout)
    }
    setTimeout(() => {
      checkNum()
    }, 2000)
  }
  useEffect(() => {
    setTimeout(() => {
      setChance(getChanceSuccess(numbers))
    }, 1000)
  }, [numbers])

  return (
    <Styled.Root>
      <Box component="h2" sx={{ marginY: 0 }} textAlign="center" fontSize={30}>
        Number game
      </Box>
      <Box component="p" fontSize={22} mt={2} mb={1}>
        Доступно чисел: {numbers.length}
      </Box>
      <Box component="p" fontSize={22} mt={0} mb={2}>
        Вероятность:&nbsp;
        <Choose>
          <When condition={numbers.length > 0 && chance !== null && !Number.isNaN(chance)}>
            {chance}%
          </When>
          <Otherwise>-</Otherwise>
        </Choose>
      </Box>
      <Box
        component="div"
        textAlign="center"
        fontSize="14px"
        sx={{ maxWidth: 400, display: 'flex', flexWrap: 'wrap', mb: 2, gap: '2px' }}
      >
        {numbers.map((item, i) => (
          <Styled.NumberItem key={i} $isWin={item.isWin} $isHide={item.hideNumber}>
            {item.number}
          </Styled.NumberItem>
        ))}
      </Box>
      <If condition={numbers.length > 0 && winCount === 0}>
        <Box sx={{ fontSize: 18, mb: 2 }}>Больше нет победных чисел</Box>
      </If>
      <Button
        onClick={onActivateNumber}
        disabled={isDisabled || winCount === 0}
        variant="contained"
        sx={{ width: '200px' }}
      >
        Запустить!
      </Button>
      <If condition={activeNumber !== null}>
        <Delayed isHide={activateNumber === null} time={2000}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ fontSize: 80, mt: 2 }}>{activeNumber}</Box>
            <Button
              onClick={() => resetActiveNumber()}
              color="info"
              variant="contained"
              sx={{ width: '140px' }}
            >
              Очистить
            </Button>
          </Box>
        </Delayed>
      </If>
    </Styled.Root>
  )
}
