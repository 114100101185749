import { Container as ContainerMui } from '@mui/material'
import { amber } from '@mui/material/colors'
import { css, styled } from '@mui/material/styles'

export const Root = styled('div')<{ $backgroundUrl?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${({ $backgroundUrl }) =>
    $backgroundUrl
      ? css`
          background: url(${$backgroundUrl}) 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          background: ${amber[50]};
        `}
`

export const Container = styled(ContainerMui)`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`
