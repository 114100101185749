// ./ui/main/index.tsx
import { Box, Typography } from '@mui/material'
import { useState } from 'react'

import {
  resetDecryptedData,
  resetDecryptForm,
  resetEncryptedData,
  resetEncryptForm,
} from '../../model/events'
import { DecryptQR } from '../decrypt-qr'
import { DecryptText } from '../decrypt-text'
import { Encrypt } from '../encrypt'
import * as Styled from './styled'

export const Crutils = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue)
    // Очищаем данные при смене таба
    if (newValue === 0) {
      resetDecryptForm()
      resetDecryptedData()
    } else {
      resetEncryptForm()
      resetEncryptedData()
    }
  }

  return (
    <Styled.Root>
      <Styled.Container>
        <Typography sx={{ my: 3 }} fontSize={30} fontWeight="bold" lineHeight={1.1}>
          Crutils
        </Typography>
        <Box width="100%" mb={3} display="flex" justifyContent="center">
          <Styled.TabsContainer
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            <Styled.Tab label="Шифрование" />
            <Styled.Tab label="Дешифрование по тексту" />
            <Styled.Tab label="Дешифрование по QR-коду" />
          </Styled.TabsContainer>
        </Box>
        <Styled.UtilsWrapper>
          {activeTab === 0 && <Encrypt />}
          {activeTab === 1 && <DecryptText />}
          {activeTab === 2 && <DecryptQR />}
        </Styled.UtilsWrapper>
      </Styled.Container>
    </Styled.Root>
  )
}
