import { Timer } from '../../types'
import { domain } from '../domain'
import {
  CreateTimerParams,
  DeleteTimerResponse,
  DeleteUserParams,
  StartTimerParams,
  TimerError,
  TimerResponse,
  UpdateParticipantsParams,
  UpdateRoundParams,
  UpdateStatusParams,
} from './types'

export const setTimerData = domain.createEvent<Timer>('setTimerData')
export const resetMultiTimer = domain.createEvent<void>('resetMultiTimer')

export const getTimer = domain.createEvent<number>('getTimer')
export const getTimerFx = domain.createEffect<number, TimerResponse, TimerError>('getTimerFx')

export const updateParticipants = domain.createEvent<UpdateParticipantsParams>('updateParticipant')
export const updateParticipantsFx = domain.createEffect<UpdateParticipantsParams, TimerResponse, TimerError>(
  'updateParticipantFx'
)

export const createTimer = domain.createEvent<void>('createTimer')
export const createTimerFx = domain.createEffect<CreateTimerParams, TimerResponse, TimerError>('createTimerFx')

export const deleteTimer = domain.createEvent<number>('deleteTimer')
export const deleteTimerFx = domain.createEffect<number, DeleteTimerResponse, TimerError>('deleteTimerFx')

export const addUser = domain.createEvent<number>('addUser')
export const addUserFx = domain.createEffect<number, TimerResponse, TimerError>('addUserFx')

export const deleteUser = domain.createEvent<DeleteUserParams>('deleteUser')
export const deleteUserFx = domain.createEffect<DeleteUserParams, TimerResponse, TimerError>('deleteUserFx')

export const startTimer = domain.createEvent<StartTimerParams>('startTimer')
export const startTimerFx = domain.createEffect<StartTimerParams, TimerResponse, TimerError>('startTimerFx')

export const updateStatus = domain.createEvent<UpdateStatusParams>('updateStatus')
export const updateStatusFx = domain.createEffect<UpdateStatusParams, TimerResponse, TimerError>('updateStatusFx')

export const updateRound = domain.createEvent<UpdateRoundParams>('updateRound')
export const updateRoundFx = domain.createEffect<UpdateRoundParams, TimerResponse, TimerError>('updateRoundFx')

export const setRoundTime = domain.createEvent<number>('createRoundTimer')

export const setAudioTickTimer = domain.createEvent<HTMLAudioElement>('setAudioTickTimer')

export const setAudioEndTimer = domain.createEvent<HTMLAudioElement>('setAudioEndTimer')

export const changeIsEditRoundTime = domain.createEvent<boolean>('changeIsEditRoundTime')
