import { ChatJoin, ChatWrapper } from '@/features/chat'

import { Template } from '../templates/template'

export const ChatListPage = () => {
  return (
    <Template withFooter={false} hasContainer={false}>
      <ChatWrapper />
    </Template>
  )
}

export const ChatRoomPage = () => {
  return (
    <Template withFooter={false} hasContainer={false}>
      <ChatWrapper isChatPage />
    </Template>
  )
}

export const ChatJoinPage = () => {
  return (
    <Template withFooter={false}>
      <ChatJoin />
    </Template>
  )
}
