import { Delete as DeleteIcon } from '@mui/icons-material'
import { Box, Button, Collapse,IconButton, TextField, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { TransitionGroup } from 'react-transition-group'

import { GameStatus } from '../../enums'
import { $isDisabledButton } from '../../model/single-game-status'
import { changeGameStatus } from '../../model/single-game-status/events'
import { $players } from '../../model/single-player'
import { addPlayer, changePlayer, removePlayer } from '../../model/single-player/events'
import { $timeForStep } from '../../model/single-timer-process'
import { changeTimeForStep } from '../../model/single-timer-process/events'
import { fillBasePlayers } from './utils'

export const Settings = () => {
  const players = useStore($players)
  const isDisabledButton = useStore($isDisabledButton)
  const timeForStep = useStore($timeForStep)

  return (
    <Box>
      <Typography variant="h3" color="initial">
        Настройки таймера
      </Typography>
      <Box mt={2}>
        <Typography sx={{ mb: 2 }} variant="h6" color="initial">
          Список участников
        </Typography>
        <TransitionGroup>
          {players.map((player) => (
            <Collapse key={player.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="standard-basic"
                  label="Имя"
                  variant="standard"
                  value={player.name}
                  onChange={(e) => changePlayer({ ...player, name: e.target.value })}
                />
                <IconButton
                  sx={{ ml: 2 }}
                  aria-label="delete"
                  onClick={() => removePlayer(player.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>
      </Box>
      <Box mt={2}>
        <Button variant="outlined" onClick={() => addPlayer('')}>
          Добавить
        </Button>
        <Button sx={{ ml: 2 }} variant="outlined" onClick={fillBasePlayers}>
          Предзаполнить
        </Button>
      </Box>
      <Box mt={2}>
        <TextField
          id="standard-basic"
          type="number"
          label="Время на раунд"
          variant="standard"
          onChange={(e) => changeTimeForStep(Number(e.target.value))}
          value={timeForStep || ''}
        />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          disabled={isDisabledButton}
          onClick={() => changeGameStatus(GameStatus.PROCESS)}
          sx={{ width: '100%' }}
        >
          Начать
        </Button>
      </Box>
    </Box>
  )
}
