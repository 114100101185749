// reports/model/api.ts

import { api } from '@/services/api'

import { CreateReportResponse, GetReportsResponse, LastDaysReportResponse, Report } from './types'

export const getCalendarDataApi = async (params: { month: number; year: number }) => {
  const response = await api.get('/daily-notes/calendar/', { params })
  return response.data
}

export const getReportsApi = async (): Promise<GetReportsResponse> => {
  return api.get('/daily-notes/list/')
}

export const createReportApi = async (reportData: FormData): Promise<CreateReportResponse> => {
  const response = await api.post('/daily-notes/daily/', reportData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Ensure correct content type
    },
  })

  // Return only the data part of the AxiosResponse
  return response.data
}

export const updateReportApi = async ({
  id,
  data,
}: {
  id: number
  data: FormData
}): Promise<Report> => {
  const response = await api.put(`/daily-notes/${id}/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export const deleteReportApi = async (id: number): Promise<void> => {
  await api.delete(`/daily-notes/${id}/`)
}

export const getHappinessLevelApi = async (): Promise<{ happinessLevel: number }> => {
  const response = await api.get('/daily-notes/happiness/')
  return response.data
}

export const getReportByIdApi = async (id: number): Promise<Report> => {
  const response = await api.get(`/daily-notes/${id}/`)
  return response.data
}

export const getLastDaysReportApi = async (): Promise<LastDaysReportResponse> => {
  const response = await api.get('/daily-notes/last-days-report/')
  return response.data
}
