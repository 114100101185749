import { createForm } from 'effector-forms'

export const sendMessageForm = createForm({
  fields: {
    text: {
      init: '',
      rules: [],
    },
  },
  validateOn: ['submit'],
})

export const editMessageForm = createForm({
  fields: {
    text: {
      init: '',
      rules: [],
    },
  },
  validateOn: ['submit'],
})
