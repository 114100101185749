import { Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { Choose, When } from '@/shared/utils/choose'

import { $user } from '../../../auth'
import { $openInviteDialog } from '../../model/dialog'
import { toggleInviteDialog } from '../../model/dialog/events'
import { $battle, $battleError, $enemyUsername } from '../../model/game'
import { setBattleError, setBattleStatus, setEnemyUsername } from '../../model/game/events'
import { BattleStatus } from '../../model/game/types'
import { WsAction } from '../../model/types'
import { chatSocket } from '../../model/ws'
import * as Styled from './styled'

const invite = (enemyUsername: string | null) => {
  if (!enemyUsername || !chatSocket) {
    setBattleError('Произошла непредвиденная ошибка')
    return
  }
  if ($user.getState()?.username === enemyUsername) {
    setBattleError('Нельзя пригласить самого себя')
    return
  }
  setBattleStatus(BattleStatus.INVITE)
  chatSocket.send(
    JSON.stringify({
      username: enemyUsername,
      type: WsAction.INVITE,
    })
  )
}

export const BattleInviteDialog = () => {
  const modalOpen = useStore($openInviteDialog)
  const user = useStore($user)
  const enemyUsername = useStore($enemyUsername)
  const battle = useStore($battle)
  const battleError = useStore($battleError)

  useEffect(() => {
    // if (!player1 && user) setPlayer1(user.username)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Dialog
      open={modalOpen}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        toggleInviteDialog(false)
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Найти противника</Typography>
          <IconButton onClick={() => toggleInviteDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Styled.Root>
          <TextField
            sx={{ mb: 1 }}
            disabled={battle?.status === BattleStatus.INVITE}
            autoFocus
            value={enemyUsername}
            variant="standard"
            label="Ник пользователя"
            onKeyDown={(e) => e.key === 'Enter' && invite(enemyUsername)}
            onChange={(e) => setEnemyUsername(e.target.value)}
          />
          <Choose>
            <When condition={battleError}>
              <Typography color="error" variant="body1">
                {battleError}
              </Typography>
            </When>
            <When condition={battle?.status === BattleStatus.INVITE}>
              <Typography variant="body1">Ожидаем ответ противника...</Typography>
            </When>
          </Choose>
        </Styled.Root>
      </DialogContent>
      <Styled.Actions>
        <LoadingButton
          loading={battle?.status === BattleStatus.INVITE}
          disabled={!enemyUsername}
          tabIndex={3}
          variant="contained"
          onClick={() => invite(enemyUsername)}
        >
          Пригласить
        </LoadingButton>
      </Styled.Actions>
    </Dialog>
  )
}
