import { createForm } from 'effector-forms'

import { createRuleNumError, RULES } from '@/shared/libs/effector-forms'

export const $singleGameForm = createForm({
  fields: {
    difficulty: {
      init: 0,
      rules: [
        createRuleNumError({
          rule: RULES.minValue(0),
          errorText: 'Минимальный уровень - 0',
        }),
        createRuleNumError({
          rule: RULES.maxValue(20),
          errorText: 'Максимальный уровень - 20',
        }),
      ],
    },
  },
  validateOn: ['submit'],
})
