import { Box, Link, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { getAppPath } from '@/shared/utils/getPath'
import { If } from '@/shared/utils/if'

import { $user } from '../../../auth'
import * as Styled from './styled'

export const Footer = () => {
  const user = useStore($user)
  const currentYear = new Date().getFullYear()

  return (
    <Styled.Root>
      <Styled.Container>
        <Styled.TopRow>
          <If condition={user?.isSuperuser}>
            <Link color={'white'} href={getAppPath('/admin')} underline="none">
              <Typography variant="h6" component="div">
                Админка
              </Typography>
            </Link>
          </If>
        </Styled.TopRow>
        <Typography component="div" mt={1}>
          © {currentYear} Copyright
        </Typography>
        <Typography component="div">
          Для{' '}
          <Box component="span" sx={{ color: 'error.main' }}>
            Лучшей семьи!
          </Box>
        </Typography>
        <Typography component="div" sx={{ mt: 1 }}>
          Version: <span id="app-versiont">{user?.version}</span>
        </Typography>
      </Styled.Container>
    </Styled.Root>
  )
}
