import { Button, CircularProgress, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useRef } from 'react'

import { $waddlesCoins } from '@/features/auth/models/user'
import {
  AlertError,
  AlertInfo,
  AlertLoading,
  AlertSuccess,
  AlertWarning,
} from '@/shared/components/alert'
import { WaddlesCoin } from '@/shared/components/waddles-coin'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'
import { durationSeconds } from '@/shared/utils/moment'

import {
  $activePrizeCount,
  $buyFortuneError,
  $buyFortunePending,
  $fortuneError,
  $fortuneInProgress,
  $fortuneResult,
  $fortuneSectors,
  $getFortunePending,
  $saveFortuneError,
  $saveFortunePending,
  $startFortuneError,
  $startFortunePending,
} from '../../model/fortune'
import {
  buyFortune,
  getFortune,
  reloadFortune,
  startFortune,
  updateFortune,
} from '../../model/fortune/events'
import { FortuneInfo, FortunePrizeType } from '../../types'
import { TimeExpired } from '../fortune-time-expired'
import { backgroundAudio } from './audio'
import { FORTUNE_SIZE } from './consts'
import { FortuneData } from './data'
import * as Styled from './styled'
import { getResultText } from './utils'

type ModalFortuneBodyProps = {
  fortuneCount: number
  fortuneInfo: FortuneInfo
}

export let fortuneData = new FortuneData()

export const ModalFortuneBody = ({ fortuneCount, fortuneInfo }: ModalFortuneBodyProps) => {
  const activePrizeCount = useStore($activePrizeCount)
  const fortuneResult = useStore($fortuneResult)
  const fortuneInProgress = useStore($fortuneInProgress)
  const fortuneSectors = useStore($fortuneSectors)
  const waddlesCoins = useStore($waddlesCoins)

  const buyFortuneError = useStore($buyFortuneError)
  const saveFortuneError = useStore($saveFortuneError)
  const startFortuneError = useStore($startFortuneError)
  const fortuneError = useStore($fortuneError)

  const saveFortunePending = useStore($saveFortunePending)
  const buyFortunePending = useStore($buyFortunePending)
  const getFortunePending = useStore($getFortunePending)
  const startFortunePending = useStore($startFortunePending)

  const canvas = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext(`2d`)
      if (!ctx || !fortuneInfo) return

      fortuneData = new FortuneData()

      // Отрисовка секторов
      fortuneData.drawSectors(ctx, fortuneSectors)
      fortuneData.drawCircle(ctx, 'darkorange')

      // Установка колеса в начальную позицию
      fortuneData.rotate(ctx, 0)
      // Запуск движка
      fortuneData.engine({ fortuneInfo, fortuneSectors, ctx })
    }
  }, [canvas, fortuneInfo, fortuneSectors])

  useEffect(() => {
    if (fortuneInProgress) {
      backgroundAudio.loop = true
      backgroundAudio.play()
      fortuneData.setIsSpinning(true)
      fortuneData.setIsAccelerating(true)
    }
  }, [fortuneInProgress])

  const handleStartFortune = () => {
    if (fortuneData.isSpinning || fortuneInProgress || fortuneResult || fortuneCount === 0) return
    startFortune()
  }

  return (
    <Styled.Root sx={{ minHeight: FORTUNE_SIZE }}>
      <Styled.WheelOfFortune id="wheelOfFortune">
        <canvas ref={canvas} id="wheel" width={FORTUNE_SIZE} height={FORTUNE_SIZE}></canvas>
        <Styled.SpinFortune
          onClick={handleStartFortune}
          sx={{ cursor: fortuneCount === 0 || fortuneInProgress || fortuneResult ? '' : 'pointer' }}
        >
          <Choose>
            <When
              condition={
                (fortuneCount === 0 && !fortuneResult) ||
                (fortuneInfo.expired && durationSeconds(fortuneInfo.expired) < 0)
              }
            >
              <Typography fontWeight="bold" variant="h4">
                X
              </Typography>
            </When>
            <When condition={startFortunePending}>
              <CircularProgress sx={{ color: 'common.white' }} size={24} />
            </When>
            <When condition={activePrizeCount === null && !fortuneInProgress}>
              <Typography fontWeight="bold" variant="h4">
                Go
              </Typography>
            </When>
            <Otherwise>
              <Typography fontWeight="bold" variant="h4">
                {activePrizeCount}
              </Typography>
            </Otherwise>
          </Choose>
        </Styled.SpinFortune>
      </Styled.WheelOfFortune>
      <Styled.Result>
        <If
          condition={
            !fortuneInProgress && !fortuneInfo.isStarted && fortuneInfo.expired && !fortuneResult
          }
        >
          <TimeExpired sx={{ mt: 2 }} time={fortuneInfo.expired as string} />
        </If>
        <Choose>
          <When condition={fortuneError}>
            <AlertError text={fortuneError} onReload={() => getFortune()} />
          </When>
          <When condition={saveFortuneError}>
            <AlertError text={saveFortuneError} sx={{ my: 1 }} />
          </When>
          <When condition={startFortuneError}>
            <AlertError text={startFortuneError} sx={{ my: 1 }} onReload={() => startFortune()} />
          </When>
          <When condition={saveFortunePending}>
            <AlertLoading text="Результат сохраняется" sx={{ mt: 2 }} />
          </When>
          <When condition={getFortunePending}>
            <AlertLoading text="Колесо фортуны загружается" sx={{ my: 2 }} />
          </When>
          <When condition={fortuneResult}>
            <Choose>
              <When
                condition={
                  fortuneResult?.prizeType === FortunePrizeType.NEGATIVE ||
                  fortuneResult?.prizeType === FortunePrizeType.ZERO
                }
              >
                <AlertWarning
                  sx={{ my: 2 }}
                  text={getResultText(fortuneResult)}
                  TypographyProps={{ variant: 'body1' }}
                />
              </When>
              <When condition={fortuneResult?.prizeType === FortunePrizeType.NORMAL}>
                <AlertInfo
                  sx={{ my: 2 }}
                  text={getResultText(fortuneResult)}
                  TypographyProps={{ variant: 'body1' }}
                />
              </When>
              <Otherwise>
                <AlertSuccess
                  sx={{ my: 2 }}
                  text={getResultText(fortuneResult)}
                  TypographyProps={{ variant: 'body1' }}
                />
              </Otherwise>
            </Choose>
          </When>
        </Choose>
        <Choose>
          <When condition={fortuneResult}>
            <Button sx={{ mt: 1 }} variant="contained" onClick={() => reloadFortune()}>
              Крутить ещё
            </Button>
          </When>
          <When condition={fortuneInfo.expired && durationSeconds(fortuneInfo.expired) < 0}>
            <AlertLoading text="Колесо обновляется" sx={{ mt: 2 }} />
          </When>
          <When condition={startFortunePending}>
            <AlertLoading text="Колесо запускается" sx={{ mt: 2 }} />
          </When>
          <When condition={buyFortuneError}>
            <AlertError text={saveFortuneError} onReload={() => buyFortune()} />
          </When>
          <When condition={buyFortunePending}>
            <AlertLoading text="Покупаем" sx={{ mt: 2 }} />
          </When>
          <When condition={fortuneCount === 0 && fortuneInfo.price !== null}>
            <Button
              sx={{ mt: 2 }}
              disabled={Number(fortuneInfo.price) > Number(waddlesCoins)}
              color="success"
              variant="contained"
              onClick={() => buyFortune()}
            >
              <WaddlesCoin text="Купить за " count={fortuneInfo.price as number} />
            </Button>
            <If condition={Number(fortuneInfo.price) > Number(waddlesCoins)}>
              <Typography fontSize={12} sx={{ my: 1, color: 'warning.dark' }}>
                У вас недостаточно пухлекоинов для покупки
              </Typography>
            </If>
          </When>
          <When condition={!fortuneInProgress && !fortuneInfo.isStarted}>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              disabled={Number(fortuneInfo.priceUpdated) > Number(waddlesCoins)}
              onClick={() => updateFortune()}
            >
              <If condition={fortuneInfo.priceUpdated === 0}>Обновить колесо бесплатно</If>
              <If condition={fortuneInfo.priceUpdated > 0}>
                <WaddlesCoin text="Обновить колесо за" count={fortuneInfo.priceUpdated} />
              </If>
            </Button>
            <If condition={Number(fortuneInfo.priceUpdated) > Number(waddlesCoins)}>
              <Typography fontSize={12} sx={{ my: 1, color: 'warning.dark' }}>
                У вас недостаточно пухлекоинов для покупки
              </Typography>
            </If>
          </When>
        </Choose>
      </Styled.Result>
    </Styled.Root>
  )
}
