import './init'

import { PackTransaction } from '../../type'
import { domain } from '../domain'
import { getPackTransactionsFx } from './events'

export const $packTransactions = domain
  .createStore<PackTransaction[]>([])
  .on(getPackTransactionsFx.doneData, (_, { data }) => data)

export const $packTransactionPending = getPackTransactionsFx.pending
