import { createGate } from 'effector-react'

import { getProfile as getGameSnakeProfile } from '../../game-snake/model/game-snake/events'
import { hasUnreadMessage } from './chat/events'
import { getFortune, getFortuneStats } from './fortune/events'
import { getGifts } from './gift/events'
import { getHoliday } from './holiday/events'
import { getLastReport } from './last_report/events'
import { getNotifications } from './notifications/events'
import { getPhotoDay } from './photo_days/events'
import { getTheme } from './theme/events'
import { getUserXp } from './xp/events'

export const MainGate = createGate('Main')
export const FortuneStatsGate = createGate('FortuneStats')

MainGate.open.watch(() => {
  getUserXp()
  getTheme()

  getPhotoDay()

  // TODO check for actual from storage
  getHoliday()

  getNotifications()

  getFortune()

  getGifts()
  getGameSnakeProfile()
  getLastReport()
  hasUnreadMessage()
})

FortuneStatsGate.open.watch(() => {
  getFortuneStats()
})
