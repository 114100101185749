import { ShopCardItem, ShopCardKey } from '../../type'
import { domain } from '../domain'
import {
  BuyPackError,
  BuyPackParams,
  BuyPackResponse,
  GetPacksError,
  GetPacksParams,
  GetPacksResponse,
} from './types'

export const startOpenPack = domain.event<ShopCardItem | null>('startOpenPack')
export const clearActiveCard = domain.event<void>('startOpenPack')

export const getPacks = domain.event<GetPacksParams>('getPacks')
export const getPacksFx = domain.effect<GetPacksParams, GetPacksResponse, GetPacksError>(
  'getPacksFx'
)

export const buyPack = domain.event<BuyPackParams>('buyPack')
export const buyPackFx = domain.effect<BuyPackParams, BuyPackResponse, BuyPackError>('buyPackFx')

export const openBuyModal = domain.event<ShopCardKey>('openBuyModal')
export const closeBuyModal = domain.event<void>('closeBuyModal')
