import '../../model/last_report/init'

import { Badge, Box, Link } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { getAppPath } from '@/shared/utils/getPath'

import appsSrc from '../../assets/apps.png'
import chatSrc from '../../assets/chat.png'
import homeSrc from '../../assets/house.png'
import ideaSrc from '../../assets/idea.png'
import reportSrc from '../../assets/report.png'
import shopSrc from '../../assets/waddles_shop.svg'
import { $hasUnreadMessage } from '../../model/chat'
import { MenuItem } from '../menu-item'
import * as Styled from './styled'

type LeftMainProps = {
  updatedProductsCount: number
}

export const LeftMain = ({ updatedProductsCount }: LeftMainProps) => {
  const hasUnreadMessage = useStore($hasUnreadMessage)
  return (
    <Styled.Root>
      <Link underline="none" href={getAppPath('/shop')} mb={2}>
        <Badge badgeContent={updatedProductsCount} color="primary">
          <MenuItem text="Пухляшоп">
            <Styled.IconImage src={shopSrc} alt="Пухляшоп" />
          </MenuItem>
        </Badge>
      </Link>
      <Box mb={2}>
        <RouterLink to={ROUTE_PATH.ROAD_TO_HOUSE}>
          <MenuItem text="Дом">
            <Styled.IconImage src={homeSrc} alt="Дом" />
          </MenuItem>
        </RouterLink>
      </Box>
      <Box mb={2}>
        <RouterLink to={ROUTE_PATH.DAILY_NOTES}>
          <MenuItem text="Дневник">
            <Styled.IconImage src={reportSrc} alt="Дневник" />
          </MenuItem>
        </RouterLink>
      </Box>
      <Box mb={2}>
        <RouterLink to={ROUTE_PATH.NOTES}>
          <MenuItem text="Заметки">
            <Styled.IconImage src={ideaSrc} alt="Идея" />
          </MenuItem>
        </RouterLink>
      </Box>
      <Box mb={2}>
        <RouterLink to={ROUTE_PATH.CHAT}>
          <Badge invisible={!hasUnreadMessage} color="secondary" variant="dot">
            <MenuItem text="Чаты">
              <Styled.IconImage src={chatSrc} alt="Чат" />
            </MenuItem>
          </Badge>
        </RouterLink>
      </Box>
      <Box mb={2}>
        <RouterLink to={ROUTE_PATH.APPS}>
          <MenuItem text="Приложения">
            <Styled.IconImage src={appsSrc} alt="Приложения" />
          </MenuItem>
        </RouterLink>
      </Box>
    </Styled.Root>
  )
}
