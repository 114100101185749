import '../../model/fortune/init'

import {
  Close as CloseIcon,
  QueryStats as QueryStatsIcon,
  RotateLeft as RotateLeftIcon,
} from '@mui/icons-material'
import { Button, DialogActions } from '@mui/material'
import { useStore } from 'effector-react'

import { AlertError, AlertLoading } from '@/shared/components/alert'
import { Choose, When } from '@/shared/utils/choose'
import { If } from '@/shared/utils/if'

import {
  $fortuneError,
  $fortuneInfo,
  $fortuneResult,
  $getFortunePending,
  $modalMode,
  $modalVisible,
} from '../../model/fortune'
import {
  changeModalFortuneVisible,
  changeModalMode,
  getFortune,
  reloadFortune,
} from '../../model/fortune/events'
import { FortuneInfo, ModalMode } from '../../types'
import { ModalFortuneBody } from '../modal-fortune-body'
import { ModalFortuneStats } from '../modal-fortune-stats'
import * as Styled from './styled'
type ModalFortuneProps = {
  fortuneCount: number
}
export const ModalFortune = ({ fortuneCount }: ModalFortuneProps) => {
  const modalVisible = useStore($modalVisible)
  const getFortunePending = useStore($getFortunePending)
  const fortuneResult = useStore($fortuneResult)
  const fortuneError = useStore($fortuneError)
  const fortuneInfo = useStore($fortuneInfo)
  const modalMode = useStore($modalMode)

  const handleClose = () => {
    if (fortuneResult) {
      reloadFortune()
    }
    changeModalFortuneVisible(false)
  }

  return (
    <Styled.Dialog
      disableEscapeKeyDown
      maxWidth="lg"
      open={modalVisible}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        handleClose()
      }}
    >
      <Styled.DialogTitle>
        Колесо фортуны ({fortuneCount})
        <If condition={modalMode === ModalMode.MAIN}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => changeModalMode(ModalMode.STATS)}
          >
            <QueryStatsIcon />
          </Button>
        </If>
        <If condition={modalMode === ModalMode.STATS}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => changeModalMode(ModalMode.MAIN)}
          >
            <RotateLeftIcon />
          </Button>
        </If>
        <Styled.CloseButton onClick={handleClose}>
          <CloseIcon />
        </Styled.CloseButton>
      </Styled.DialogTitle>
      <Styled.DialogContent>
        <Choose>
          <When condition={fortuneError}>
            <Styled.BoxCenter>
              <AlertError text={fortuneError} onReload={() => getFortune()} />
            </Styled.BoxCenter>
          </When>
          <When condition={getFortunePending}>
            <Styled.BoxCenter>
              <AlertLoading text="Колесо фортуны загружается" />
            </Styled.BoxCenter>
          </When>
          <When condition={fortuneInfo}>
            <If condition={modalMode === ModalMode.MAIN}>
              <ModalFortuneBody
                fortuneInfo={fortuneInfo as FortuneInfo}
                fortuneCount={fortuneCount}
              />
            </If>
            <If condition={modalMode === ModalMode.STATS}>
              <ModalFortuneStats />
            </If>
          </When>
        </Choose>
      </Styled.DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Styled.Dialog>
  )
}
