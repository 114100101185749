// ./ui/decrypt-qr/index.tsx
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import React, { ChangeEvent, useState } from 'react'

import { If } from '@/shared/utils/if'

import { $decryptedData, $decryptPending } from '../../model'
import { decryptQRForm } from '../../model/forms'
import { $decryptError } from '../../model/store'
import { FixedBox } from '../main/styled'

export const DecryptQR = () => {
  const pending = useStore($decryptPending)
  const decryptedData = useStore($decryptedData)
  const decryptError = useStore($decryptError)
  const { fields, submit, error } = useForm(decryptQRForm)

  // State для управления видимостью текста в поле
  const [showSecretPhrase, setShowSecretPhrase] = useState(false)

  // State для хранения сообщений
  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'success' | 'error' | 'info'>('info')

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        fields.qrCode.onChange(reader.result as string)
        setMessage('Изображение загружено успешно!')
        setMessageColor('success')
      }
      reader.readAsDataURL(file)
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const items = e.clipboardData.items
    let imageFound = false
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        const blob = items[i].getAsFile()
        if (blob) {
          const reader = new FileReader()
          reader.onloadend = () => {
            fields.qrCode.onChange(reader.result as string)
            setMessage('Изображение вставлено из буфера обмена!')
            setMessageColor('success')
          }
          reader.readAsDataURL(blob)
          imageFound = true
        }
      }
    }
    if (!imageFound) {
      setMessage('Не удалось вставить изображение из буфера обмена.')
      setMessageColor('error')
    }
  }

  const checkClipboardForImage = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read()
      const hasImage = clipboardItems.some((item) => item.types.includes('image/png'))

      if (hasImage) {
        const item = clipboardItems.find((item) => item.types.includes('image/png'))
        const blob = await item?.getType('image/png')

        if (blob) {
          const reader = new FileReader()
          reader.onloadend = () => {
            fields.qrCode.onChange(reader.result as string)
            setMessage('Изображение вставлено из буфера обмена!')
            setMessageColor('success')
          }
          reader.readAsDataURL(blob)
        }
      } else {
        setMessage('В буфере обмена нет изображения.')
        setMessageColor('info')
      }
    } catch (error) {
      console.error('Ошибка чтения буфера обмена:', error)
      setMessage('Ошибка при попытке чтения из буфера обмена.')
      setMessageColor('error')
    }
  }

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Текст скопирован в буфер обмена')
    })
  }

  return (
    <FixedBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      p={2}
      boxSizing="border-box"
      onPaste={handlePaste}
    >
      <Typography variant="h5" gutterBottom>
        Дешифрование по QR
      </Typography>
      <TextField
        label="Секретная фраза"
        variant="outlined"
        type={showSecretPhrase ? 'text' : 'password'}
        value={fields.secretPhrase.value}
        onChange={(e) => fields.secretPhrase.onChange(e.target.value)}
        disabled={pending}
        fullWidth
        margin="normal"
        error={!!error('secretPhrase')}
        helperText={error('secretPhrase')?.errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle secret phrase visibility"
                onClick={() => setShowSecretPhrase(!showSecretPhrase)}
                edge="end"
                tabIndex={-1} // Исключаем из цикла табуляции
              >
                {showSecretPhrase ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box mt={2} width="100%" display="flex" justifyContent="space-between">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="upload-button"
        />
        <label htmlFor="upload-button">
          <Button variant="outlined" component="span" disabled={pending}>
            Загрузить файл
          </Button>
        </label>
        <Button
          variant="outlined"
          onClick={checkClipboardForImage}
          sx={{ ml: 2 }}
          disabled={pending}
        >
          Вставить из буфера обмена
        </Button>
      </Box>
      {/* Отображение сообщений об успехе или ошибке */}
      {message && (
        <Typography variant="body2" color={`${messageColor}.main`} mt={1}>
          {message}
        </Typography>
      )}
      {decryptError && (
        <Typography variant="body2" color="error" mt={1}>
          {decryptError}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={() => submit()}
        disabled={pending}
        fullWidth
        sx={{ mt: 2 }}
      >
        Расшифровать
      </Button>
      <If condition={!!decryptedData}>
        <Box mt={2} width="100%">
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              overflow: 'hidden',
            }}
          >
            <Box flexGrow={1} overflow="auto">
              <Typography variant="h6" gutterBottom>
                Расшифрованное сообщение:
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                {decryptedData}
              </Typography>
            </Box>
            <IconButton onClick={() => handleCopy(decryptedData || '')}>
              <ContentCopyIcon />
            </IconButton>
          </Paper>
        </Box>
      </If>
    </FixedBox>
  )
}
