import { $gameStatus as $gameStatusSingle } from './model/game-snake'
import { $gameStatus as $gameStatusLevel } from './model/level'
import { GameSnakeLevel } from './ui/level-number/data'
import { GameSnakeSingle } from './ui/single-game/data'
import { GAME_STATUS, Position, SIDE } from './ui/single-game/types'

export const preventArrows = (
  event: KeyboardEvent,
  gameSnake: GameSnakeSingle | GameSnakeLevel | null,
  isLevel: boolean = false
) => {
  if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(event.code) > -1) {
    event.preventDefault()
  }

  const gameStatus = isLevel ? $gameStatusLevel.getState() : $gameStatusSingle.getState()
  if (gameStatus === GAME_STATUS.PROGRESS && gameSnake) {
    if (event.code === 'ArrowLeft') gameSnake.changeSide(SIDE.LEFT)
    else if (event.code === 'ArrowUp') gameSnake.changeSide(SIDE.UP)
    else if (event.code === 'ArrowRight') gameSnake.changeSide(SIDE.RIGHT)
    else if (event.code === 'ArrowDown') gameSnake.changeSide(SIDE.DOWN)
  }
  if (event.code === 'Space' && gameSnake) {
    if ([GAME_STATUS.PAUSE, GAME_STATUS.START].includes(gameStatus)) {
      if (gameSnake.food.length === 0) return
      gameSnake.runGame()
    } else {
      gameSnake.pauseGame()
    }
  }
}

export const changeSnakeSideByMotion = (
  event: DeviceMotionEvent,
  gameSnake: GameSnakeSingle | GameSnakeLevel
) => {
  const ev = event.accelerationIncludingGravity
  const x = ev?.x?.toFixed(2) || ''
  const y = ev?.y?.toFixed(2) || ''
  if (Number(x) > 2) gameSnake.changeSide(SIDE.LEFT)
  else if (Number(x) < -2) gameSnake.changeSide(SIDE.RIGHT)
  else if (Number(y) > 2) gameSnake.changeSide(SIDE.DOWN)
  else if (Number(y) < -2) gameSnake.changeSide(SIDE.UP)
}

export const getRotateBySide = (side: SIDE): number => {
  if (side === SIDE.RIGHT) return 90
  if (side === SIDE.DOWN) return 180
  if (side === SIDE.LEFT) return 270
  return 0
}

export const loadImage = (src: string): HTMLImageElement => {
  const image = new Image()
  image.src = src
  return image
}

export const checkApprox = (num1: number, num2: number, epsilon = 0.1) => {
  return Math.abs(num1 - num2) < epsilon
}

export const checkIntersection = (position1: Position, position2: Position, epsilon: number) => {
  const intersectionX = checkApprox(position1.x, position2.x, epsilon)
  const intersectionY = checkApprox(position1.y, position2.y, epsilon)

  return intersectionX && intersectionY
}
