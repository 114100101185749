import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import { If } from '@/shared/utils/if'

import { FortuneStatSection } from '../../types'
import * as Styled from './styled'

type ModalFortuneStatsItemProps = {
  item: FortuneStatSection
}

export const ModalFortuneStatsItem = ({ item }: ModalFortuneStatsItemProps) => {
  return (
    <Styled.Root>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <Styled.Bold>Всего выпадало раз: </Styled.Bold>
        {item.count}
      </Typography>
      <If condition={item.sumWin}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <Styled.Bold>Суммарно выиграно: </Styled.Bold>
          {item.sumWin}
        </Typography>
      </If>
      <If condition={item.avg}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <Styled.Bold>Средний выигрыш: </Styled.Bold>
          {item.avg}
        </Typography>
      </If>
      <If condition={item.max}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <Styled.Bold>Максимальный выигрыш: </Styled.Bold>
          {item.max}
        </Typography>
      </If>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Выигрыш</TableCell>
            <TableCell align="center">Сколько раз</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.stats.map((item, i) => (
            <TableRow key={i}>
              <TableCell align="center">{item.count}</TableCell>
              <TableCell align="center">{item.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Styled.Root>
  )
}
