import { Box, Button, Typography } from '@mui/material'

import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { useMedia } from '@/shared/utils/media'

import { DailyNotesCalendar } from '../calendar'
import { HappinessLevel } from '../happiness'
import { Progress } from '../progress'

export const DailyNotes = () => {
  const { isMobile } = useMedia()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: 0 }}>
        Дневник
      </Typography>
      <Box sx={{ mb: 2, width: '100%' }}>
        <Progress />
      </Box>
      <Box sx={{ mb: 2, width: '100%' }}>
        <RouterLink to={ROUTE_PATH.DAILY_NOTES_NEW}>
          <Button variant="contained" color="success" sx={{ width: '100%' }}>
            Добавить новую запись
          </Button>
        </RouterLink>
      </Box>
      <Box
        sx={{
          width: '100%',
          mt: isMobile ? 0 : 3,
          mb: 3,
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        <DailyNotesCalendar />
        <HappinessLevel />
      </Box>
      <Box sx={{ mb: 2, width: '100%' }}>
        <RouterLink to={ROUTE_PATH.DAILY_NOTES_LIST}>
          <Button variant="contained" color="primary" sx={{ width: '100%' }}>
            Дневник
          </Button>
        </RouterLink>
      </Box>
    </Box>
  )
}
