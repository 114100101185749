const DEBUG_VALUE = 1

const animNumber = {
  start: { scale: 0, opacity: 0, display: 'none' },
  end: { scale: 1, opacity: 1, display: 'block' },
}

export const animCard = {
  container: {
    variants: {
      start: {
        scale: 0.5,
        opacity: 0,
        y: 50,
      },
      end: {
        scale: 1,
        opacity: 1,
        y: 0,
      },
    },
    transition: { delay: 1 * DEBUG_VALUE, duration: 2, ease: 'easeIn' },
  },
  imagePrizeAnim: {
    transition: { delay: 6 * DEBUG_VALUE, duration: 3, ease: 'easeOut' },
    variants: {
      start: { scale: 0.5, opacity: 0, y: 50 },
      end: { scale: 1, opacity: 1, y: 0 },
    },
  },
  anim1: {
    transition: { delay: 10 * DEBUG_VALUE, duration: 5, ease: 'easeOut' },
    variants: animNumber,
  },
  anim2: {
    transition: { delay: 15 * DEBUG_VALUE, duration: 5, ease: 'easeOut' },
    variants: animNumber,
  },
  anim3: {
    transition: { delay: 20 * DEBUG_VALUE, duration: 5, ease: 'easeOut' },
    variants: animNumber,
  },
  anim4: {
    transition: { delay: 25 * DEBUG_VALUE, duration: 5, ease: 'easeOut' },
    variants: animNumber,
  },
  prizeAnim: {
    transition: { delay: 25 * DEBUG_VALUE, duration: 1, ease: 'easeOut' },
    variants: {
      start: { x: 0 },
      end: { x: 0 },
    },
  },
  buttonAnim: {
    transition: { delay: 25 * DEBUG_VALUE, duration: 1, ease: 'easeOut' },
    variants: {
      start: { y: 60, opacity: 0 },
      end: { y: 0, opacity: 1 },
    },
  },
}
