// ./api.ts
import { api } from '@/services/api'

import { DecryptRequest, DecryptResponse, EncryptRequest, EncryptResponse } from '../types'

export const encryptMessageApi = async (params: EncryptRequest): Promise<EncryptResponse> => {
  return api.post('/crutils/encrypt/', params)
}

export const decryptMessageApi = async (params: DecryptRequest): Promise<DecryptResponse> => {
  return api.post('/crutils/decrypt/', params)
}
