// reports/model/gates.ts

import { createGate } from 'effector-react'

import { getReports } from './events'

export const ReportGate = createGate('ReportGate')

ReportGate.open.watch(() => {
  getReports()
})
