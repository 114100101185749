import { domain } from '../domain'
import { signInFx } from './events'
import { loginForm } from './forms'

export const $signInError = domain
  .createStore<string | null>(null)
  .on(signInFx.failData, (_, data) => {
    const error = data.response?.data.nonFieldErrors[0]
    if (error) return error

    return `Что-то пошло не так! Ошибка: ${data.message}`
  })
  .reset([signInFx.done, loginForm.$values.updates])

export const $signInPending = signInFx.pending
