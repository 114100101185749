import { Box, CircularProgress } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useGate, useStore } from 'effector-react'

import { useLockBodyScroll } from '@/shared/hooks'
import { If } from '@/shared/utils/if'

import { useMedia } from '../../../../shared/utils/media'
import { HEADER_HEIGHT } from '../../../header'
import { ChatListGate } from '../../model/chat'
import { $userChats, $userChatsPending } from '../../model/chat/store'
import { ChatRoomsMenu } from '../chat-rooms-menu'
import * as Styled from './styled'
import { ChatItem } from './ui/chat-item'
import { ChatsHeader } from './ui/chats-header'

export const ChatList = () => {
  const userChats = useStore($userChats)
  const pending = useStore($userChatsPending)
  const { isDesktop } = useMedia()
  useGate(ChatListGate)

  useLockBodyScroll()

  return (
    <Box
      sx={{
        width: isDesktop ? 280 : '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'column',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        borderRight: `1px solid ${grey[500]}`,
      }}
    >
      <Box sx={{ width: '100%', borderBottom: `1px solid ${grey[400]}`, p: 1 }}>
        <ChatsHeader />
      </Box>
      <If condition={pending}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: '0 auto',
            my: 1,
            textAlign: 'center',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      </If>

      <If condition={userChats.length > 0}>
        <Styled.ChatLists>
          {userChats.map((chat) => (
            <ChatItem key={chat.id} chat={chat} />
          ))}
        </Styled.ChatLists>
      </If>
      <ChatRoomsMenu />
    </Box>
  )
}
