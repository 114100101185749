import _ from 'lodash'

import { getAppPath } from '@/shared/utils/getPath'

import { keyHandler } from '../../gate'
import { LevelData } from '../../model/admin/types'
import { $gameStatus } from '../../model/level'
import { changeGameStatus, finishLevelGame, setEndReason, setScore } from '../../model/level/events'
import { ItemPosition, Profile } from '../../types'
import { checkIntersection, getRotateBySide, loadImage } from '../../utils'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  // gameAudios,
  gameImages,
  NAV_COLOR,
  NAV_SIZE,
  SEC_IN_MIN,
} from './consts'
import { END_REASON, GAME_STATUS, GameAudios, Position, SIDE } from './types'

export class GameSnakeLevel {
  ctx: CanvasRenderingContext2D

  snakeHeader: HTMLImageElement // Голова змейки
  snakeColor: string // Цвет хвоста змейки
  fieldBg: HTMLImageElement // цвет поля
  fieldSize: number // размер поля
  cellSize: number // размер ячейки
  levelNumber: number // Уровень
  purpose: number // сколько нужно набрать

  audios: GameAudios

  defaultStep: number // Шаг по умолчанию
  timerGame: number // Таймаут, чем он ниже, тем игра быстрее
  defaultTailLength: number // Длина змейки по умолчанию

  brokenMyselfAccuracy: number // Точность врезания в себя
  obstacleAccuracy: number // Точность врезания в препятствие
  prizeAccuracy: number // Точность сбора приза

  startXY: Position // Стартовая позиция змейки

  timer: number // время игры
  score: number // результат текущий

  intervalGame: NodeJS.Timer | null
  intervalTimer: NodeJS.Timer | null

  snakeTrail: Array<Position> // координаты змейки
  tail: number // длинна змейки

  obstacles: Array<ItemPosition> // координаты препятствий
  food: Array<ItemPosition> // координаты еды

  // Направление движения змейки
  moveSide: SIDE

  nextPosition: Position

  constructor(ctx: CanvasRenderingContext2D, levelData: LevelData, profile: Profile) {
    this.ctx = ctx

    this.audios = {
      bg: new Audio(getAppPath(levelData.audioBg)),
      endLose: new Audio(getAppPath(levelData.audioEndLose)),
      endTime: new Audio(getAppPath(levelData.audioEndTime)),
      endWin: new Audio(getAppPath(levelData.audioEndWin)),
    }

    this.brokenMyselfAccuracy = levelData.brokenMyselfAccuracy
    this.obstacleAccuracy = levelData.obstacleAccuracy
    this.prizeAccuracy = levelData.prizeAccuracy

    this.levelNumber = levelData.number
    this.intervalGame = null
    this.intervalTimer = null
    this.snakeTrail = []
    this.purpose = levelData.purpose
    this.defaultStep = levelData.defaultStep
    this.startXY = { x: levelData.startX, y: levelData.startY }
    this.moveSide = levelData.startSide
    this.snakeColor = profile.snakeParams.snakeColor
    this.timerGame = (250 - profile.snakeParams.speed) * this.defaultStep
    this.snakeHeader = loadImage(getAppPath(profile.snakeParams.headImage))
    this.defaultTailLength = (1 / this.defaultStep) * 2
    this.tail = this.defaultTailLength
    this.fieldBg = loadImage(getAppPath(levelData.fieldBg))
    this.fieldSize = levelData.fieldSize
    this.cellSize = (CANVAS_HEIGHT - NAV_SIZE) / this.fieldSize
    this.obstacles = []
    this.food = []
    this.timer = levelData.roundTime
    this.score = 0
    this.nextPosition = this.getNextSnakePosition()
    this.clearFood()
    this.initCtx()
  }

  game() {
    if (this.timer === 0) {
      this.endGame(END_REASON.TIME_OVER)
    }
    this.nextPosition = this.getNextSnakePosition()

    this.drawField()

    this.checkSnakeSubject()

    this.checkPrize()

    this.drawFieldData()

    this.drawNav()
  }

  clear() {
    if (Boolean(keyHandler)) {
      document.removeEventListener('keydown', keyHandler)
    }
    this.clearIntervalGame()
    this.clearIntervalTimer()
  }

  initCtx() {
    this.drawField()
    this.drawNav()
  }

  drawSnakeHead(cell: Position) {
    this.ctx.beginPath()
    this.ctx.fillStyle = this.snakeColor
    // Отрисовка тела шапки
    if (this.ctx.roundRect) {
      const placeX = cell.x * this.cellSize
      const placeY = cell.y * this.cellSize + NAV_SIZE
      const round = this.cellSize / 2
      this.ctx.roundRect(placeX, placeY, this.cellSize, this.cellSize, [
        [SIDE.UP, SIDE.LEFT].includes(this.moveSide) ? round : 0,
        [SIDE.UP, SIDE.RIGHT].includes(this.moveSide) ? round : 0,
        [SIDE.RIGHT, SIDE.DOWN].includes(this.moveSide) ? round : 0,
        [SIDE.DOWN, SIDE.LEFT].includes(this.moveSide) ? round : 0,
      ])
    }
    this.ctx.fill()
    // Дорисовка глаз и прочих украшений
    this.drawRotatedImage(cell, this.snakeHeader, getRotateBySide(this.moveSide))
  }

  createIntervalTimer() {
    return setInterval(() => this.decrementTimer(), SEC_IN_MIN)
  }
  createIntervalGame() {
    return setInterval(() => this.game(), this.timerGame)
  }

  setIntervalGame() {
    this.intervalGame = this.createIntervalGame()
  }
  setIntervalTimer() {
    this.intervalTimer = this.createIntervalTimer()
  }

  setFood(food: ItemPosition[]) {
    this.food = food
    this.setFoodActive()
  }
  setLevel(level: number) {
    this.levelNumber = level
  }
  setPurpose(purpose: number) {
    this.purpose = purpose
  }
  setObstacle(obstacles: ItemPosition[]) {
    this.obstacles = obstacles
    this.setFoodActive()
  }

  setFieldSize(size: number) {
    this.fieldSize = size
    this.cellSize = (CANVAS_HEIGHT - NAV_SIZE) / this.fieldSize
  }

  clearIntervalGame() {
    this.intervalGame && clearInterval(this.intervalGame)
  }
  clearIntervalTimer() {
    this.intervalTimer && clearInterval(this.intervalTimer)
  }

  decrementTimer() {
    this.timer = this.timer - 1
  }

  setCtx(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
  }

  setTimer(timer: number) {
    this.timer = timer
  }

  setTail(tail: number) {
    this.tail = tail
  }

  setMoveSide(side: SIDE) {
    this.moveSide = side
  }

  drawFieldCell(cell: Position, color: string) {
    const placeX = cell.x * this.cellSize
    const placeY = cell.y * this.cellSize + NAV_SIZE
    this.ctx.fillStyle = color
    this.ctx.fillRect(placeX, placeY, this.cellSize, this.cellSize)
  }

  drawFieldImage(cell: Position, image: HTMLImageElement | CanvasImageSource) {
    const placeX = cell.x * this.cellSize
    const placeY = cell.y * this.cellSize + NAV_SIZE
    this.ctx.drawImage(image, placeX, placeY, this.cellSize, this.cellSize)
  }

  drawRotatedImage(cell: Position, image: HTMLImageElement | CanvasImageSource, angle: number) {
    const TO_RADIANS = Math.PI / 180
    const placeX = cell.x * this.cellSize
    const placeY = cell.y * this.cellSize + NAV_SIZE
    this.ctx.save()
    this.ctx.translate(placeX + this.cellSize / 2, placeY + this.cellSize / 2)

    this.ctx.rotate(angle * TO_RADIANS)
    this.ctx.drawImage(
      image,
      -(this.cellSize / 2),
      -(this.cellSize / 2),
      this.cellSize,
      this.cellSize
    )

    this.ctx.restore()
  }

  getNextSnakePosition() {
    const lastTrail = this.snakeTrail[this.snakeTrail.length - 1]
    if (!lastTrail) return this.startXY
    const x = _.round(lastTrail.x, 1)
    const y = _.round(lastTrail.y, 1)

    if (this.moveSide === SIDE.UP) {
      return {
        x: x,
        y: y === this.defaultStep - 1 ? this.fieldSize - this.defaultStep : y - this.defaultStep,
      }
    } else if (this.moveSide === SIDE.DOWN) {
      return {
        x: x,
        y: y + this.defaultStep >= this.fieldSize ? this.defaultStep - 1 : y + this.defaultStep,
      }
    } else if (this.moveSide === SIDE.RIGHT) {
      return {
        x: x + this.defaultStep >= this.fieldSize ? this.defaultStep - 1 : x + this.defaultStep,
        y: y,
      }
    } else if (this.moveSide === SIDE.LEFT) {
      return {
        x: x === this.defaultStep - 1 ? this.fieldSize - this.defaultStep : x - this.defaultStep,
        y: y,
      }
    }
    return lastTrail
  }

  drawSnake() {
    const newCell = this.nextPosition

    this.snakeTrail = [...this.snakeTrail, newCell]

    // Перерисовка хвоста
    this.snakeTrail.slice(0, this.defaultTailLength >= 5 ? -4 : -1).forEach((item) => {
      this.drawFieldCell(item, this.snakeColor)
    })

    // Перерисовка шапки
    this.drawSnakeHead(newCell)

    while (this.snakeTrail.length > this.tail) {
      this.trailShift()
    }
  }

  trailShift() {
    // Очистка старого конца змейки
    this.snakeTrail = this.snakeTrail.slice(1)
  }

  setFoodActive() {
    const activeFoodId = this.food.findIndex((item) => !item.isEaten)
    if ((this.food.length && activeFoodId === -1) || this.score === this.purpose) {
      this.endGame(END_REASON.FOOD_COLLECTED)
      return
    }
    this.food = this.food.map((item, index) => ({
      ...item,
      isActive: activeFoodId === index || (index === 0 && !item.isEaten),
    }))
  }

  setFoodEaten(id: string) {
    this.food = this.food.map((item) => ({
      ...item,
      isEaten: item.isEaten || item.id === id,
      isActive: item.isActive && item.id !== id,
    }))
    this.incrementScore()
    this.setTail(this.tail + 1 / this.defaultStep)
    this.setFoodActive()
  }

  clearFood() {
    this.food = []
  }

  deactivateFood() {
    this.food = this.food.map((item) => ({ ...item, isActive: false }))
  }

  incrementScore() {
    this.score = this.score + 1
  }

  changeSide(side: SIDE) {
    const nowCell = this.snakeTrail[this.snakeTrail.length - 1]
    const prevCell = this.snakeTrail[this.snakeTrail.length - 2]

    if (side === SIDE.LEFT && this.moveSide !== SIDE.RIGHT) {
      if (!prevCell || prevCell.x !== nowCell.x - 1) {
        this.setMoveSide(SIDE.LEFT)
      }
    } else if (side === SIDE.RIGHT && this.moveSide !== SIDE.LEFT) {
      if (!prevCell || prevCell.x !== nowCell.x + 1) {
        this.setMoveSide(SIDE.RIGHT)
      }
    } else if (side === SIDE.UP && this.moveSide !== SIDE.DOWN) {
      if (!prevCell || prevCell.y !== nowCell.y - 1) {
        this.setMoveSide(SIDE.UP)
      }
    } else if (side === SIDE.DOWN && this.moveSide !== SIDE.UP) {
      if (!prevCell || prevCell.y !== nowCell.y + 1) {
        this.setMoveSide(SIDE.DOWN)
      }
    }
  }

  pauseGame() {
    const gameStatus = $gameStatus.getState()
    if (gameStatus !== GAME_STATUS.PROGRESS) return
    this.clearIntervalGame()
    this.clearIntervalTimer()
    this.audios.bg.pause()
    changeGameStatus(GAME_STATUS.PAUSE)
  }

  runGame() {
    if (this.food.length === 0) {
      this.setFoodActive()
    }
    this.decrementTimer()
    this.setIntervalGame()
    this.setIntervalTimer()
    this.audios.bg.play()
    changeGameStatus(GAME_STATUS.PROGRESS)
  }

  endGame(reason: END_REASON) {
    if (reason === END_REASON.FOOD_COLLECTED) {
      this.audios.endWin.play()
    } else if (reason === END_REASON.BROKEN) {
      this.audios.endLose.play()
    } else {
      this.audios.endTime.play()
    }

    this.clearIntervalGame()
    this.clearIntervalTimer()
    this.pauseGame()

    changeGameStatus(GAME_STATUS.END)
    setScore(this.score)
    setEndReason(reason)
    finishLevelGame({ score: this.score, number: this.levelNumber })
  }

  drawField() {
    this.ctx.drawImage(gameImages.bg, 0, 0, CANVAS_HEIGHT, CANVAS_HEIGHT)
  }

  drawNav() {
    this.ctx.fillStyle = NAV_COLOR
    this.ctx.fillRect(0, 0, CANVAS_WIDTH, NAV_SIZE)
    this.ctx.fillStyle = 'white'
    this.ctx.font = '18px Arial'
    this.ctx.fillText(`Время: ${this.timer}`, CANVAS_WIDTH - 100, 20)
    this.ctx.drawImage(gameImages.food, 8, 4, 20, 20)
    this.ctx.fillText(`${this.score}`, 32, 20)
  }

  drawPrize(x?: number, y?: number) {
    this.food
      .filter((item) => item.isActive)
      .forEach((food) => {
        this.drawFieldImage({ x: x || food.x, y: y || food.y }, food.item.image)
      })
  }

  drawObstacles() {
    // Отрисовка препятствий
    this.ctx.fillStyle = 'gray'
    this.obstacles.forEach(({ x, y, item }) => {
      this.drawFieldImage({ x, y }, item.image)
    })
  }

  checkAteMyself(cell: Position) {
    const isAteMysellf =
      this.snakeTrail.length > 1 &&
      checkIntersection(cell, this.nextPosition, this.brokenMyselfAccuracy)

    if (isAteMysellf) {
      this.endGame(END_REASON.BROKEN)
    }
  }

  checkObstacle() {
    const isBroken = this.obstacles.some((item) =>
      checkIntersection(item, this.nextPosition, this.obstacleAccuracy)
    )

    if (isBroken) {
      this.endGame(END_REASON.BROKEN)
    }
  }

  checkSnakeSubject() {
    this.snakeTrail.slice(0, -((1 / this.defaultStep) * 2 + 1)).forEach((activeTrail) => {
      this.checkAteMyself(activeTrail)
    })
    this.checkObstacle()
  }

  checkPrize() {
    this.food
      .filter((item) => item.isActive)
      .forEach((item) => {
        const isAte = checkIntersection(item, this.nextPosition, this.prizeAccuracy)

        if (!isAte) return false

        item.item.audio.currentTime = 0
        item.item.audio.play()

        this.setFoodEaten(item.id)
        return true
      })
  }

  drawFieldData() {
    const gameStatus = $gameStatus.getState()
    if (gameStatus !== GAME_STATUS.START) {
      this.drawSnake()
      this.drawObstacles()
      this.drawPrize()
    }
  }
}
