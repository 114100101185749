import { combine } from 'effector'

import { GameStatus } from '../../enums'
import { domain } from '../domain'
import { $players } from '../single-player'
import { $timeForStep } from '../single-timer-process'
import { changeGameStatus } from './events'

// Game status

export const $gameStatus = domain
  .createStore<GameStatus>(GameStatus.SETTINGS)
  .on(changeGameStatus, (_, status) => status)

export const $isDisabledButton = combine({
  players: $players,
  timeForStep: $timeForStep,
}).map(({ players, timeForStep }) => {
  const isPlayersNotFiled = players.length && players.some((player) => player.name === '')
  const isTimeForSleepNotField = timeForStep === 0

  return isPlayersNotFiled || isTimeForSleepNotField
})
