import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 300;
`
