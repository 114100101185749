import { ChatRoom, Message } from '../../../type'

export enum SocketType {
  SEND = 'send',
  EDIT = 'edit',
  DELETE = 'delete',
  UPDATE_CHAT = 'update_chat',
  TYPING = 'typing',
}

interface BaseMessageEvent {
  chatId: number
  type: SocketType
}

interface SendMessageEvent extends BaseMessageEvent {
  type: SocketType.SEND
  message: Message
}

interface EditMessageEvent extends BaseMessageEvent {
  type: SocketType.EDIT
  messageId: number
  text: string
}

interface TypingEvent extends BaseMessageEvent {
  type: SocketType.TYPING
  chatId: number
  username: string
}

interface DeleteMessageEvent extends BaseMessageEvent {
  type: SocketType.DELETE
  messageId: number
}

interface UpdateChatEvent extends BaseMessageEvent {
  type: SocketType.UPDATE_CHAT
  chat: ChatRoom
}

export type WebSocketEvent =
  | SendMessageEvent
  | EditMessageEvent
  | DeleteMessageEvent
  | UpdateChatEvent
  | TypingEvent
