// reports/model/events.ts

import { domain } from './domain'
import {
  CreateReportError,
  CreateReportResponse,
  GetCalendarDataResponse,
  GetReportsError,
  GetReportsResponse,
  LastDaysReportResponse,
  Report,
} from './types'

export const getReports = domain.event<void>('getReports')
export const getReportsFx = domain.effect<void, GetReportsResponse, GetReportsError>('getReportsFx')

export const getReportById = domain.event<number>('getReportById')
export const getReportByIdFx = domain.effect<number, Report, Error>('getReportByIdFx')

export const createReport = domain.event<FormData>('createReport')
export const createReportFx = domain.effect<FormData, CreateReportResponse, CreateReportError>(
  'createReportFx'
)

export const updateReport = domain.event<{ id: number; data: FormData }>('updateReport')
export const updateReportFx = domain.effect<{ id: number; data: FormData }, Report, Error>(
  'updateReportFx'
)

export const deleteReport = domain.event<number>('deleteReport')
export const deleteReportFx = domain.effect<number, void, Error>('deleteReportFx')

export const getHappinessLevel = domain.event<void>('getHappinessLevel')
export const getHappinessLevelFx = domain.effect<void, { happinessLevel: number }, Error>(
  'getHappinessLevelFx'
)

export const getCalendarData = domain.event<{ month: number; year: number }>('getCalendarData')
export const getCalendarDataFx = domain.effect<
  { month: number; year: number },
  GetCalendarDataResponse,
  Error
>('getCalendarDataFx')

export const getLastDaysReport = domain.event<void>('getLastDaysReport')
export const getLastDaysReportFx = domain.effect<void, LastDaysReportResponse, Error>(
  'getLastDaysReportFx'
)
