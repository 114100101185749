import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton } from '@mui/material'

import { useMedia } from '../../../../../../shared/utils/media'
import { changeModalCreateChat } from '../../../../model/chat/events'
import { MenuButton } from '../../../chat-window/ui/bottom-block/ui/menu-button'

export const ChatsHeader = () => {
  const { isDesktop } = useMedia()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: isDesktop ? 40 : 30,
      }}
    >
      <Box>
        <MenuButton />
      </Box>
      <Box>
        <IconButton onClick={() => changeModalCreateChat(true)}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
