import '../../model/xp/init'

import { Link } from '@mui/material'
import { useTheme } from '@mui/material'
import { useStore } from 'effector-react'

import { $getUserPending, $rubles, $userError, $waddlesCoins } from '@/features/auth/models/user'
import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'
import { Ruble } from '@/shared/components/ruble'
import { WaddlesCoin } from '@/shared/components/waddles-coin'
import { getAppPath } from '@/shared/utils/getPath'

import { Time } from '../time'
import { TopXpMain } from '../top-xp'
import * as Styled from './styled'

export const TopMain = () => {
  const theme = useTheme()
  const waddlesCoins = useStore($waddlesCoins)
  const rubles = useStore($rubles)
  const userError = useStore($userError)
  const coinsLoading = useStore($getUserPending)

  return (
    <Styled.Root>
      <Styled.XpWrapper>
        <Link underline="none" href={getAppPath('/xp')}>
          <TopXpMain />
        </Link>
      </Styled.XpWrapper>
      <Time />
      <Styled.WaddlesCoinWrapper>
        <RouterLink to={ROUTE_PATH.SHOP}>
          <Ruble
            sx={{ background: theme.palette.info.dark, mb: 1 }}
            count={rubles as number}
            hasError={Boolean(userError)}
            pending={coinsLoading}
            text=""
            TypographyProps={{ fontWeight: 'bold', fontSize: 14 }}
          />
        </RouterLink>
        <Link underline="none" href={getAppPath('/shop/purchases')}>
          <WaddlesCoin
            sx={{ background: theme.palette.primary.main }}
            count={waddlesCoins as number}
            hasError={Boolean(userError)}
            pending={coinsLoading}
            text=""
            TypographyProps={{ fontWeight: 'bold' }}
          />
        </Link>
      </Styled.WaddlesCoinWrapper>
    </Styled.Root>
  )
}
