import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const Root = styled('div')`
  display: flex;
  cursor: pointer;
`

export const Timer = styled('div')`
  padding: 12px;
  background: rgba(0, 47, 85, 0.6);
  border-radius: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 8px;
  }
`

export const DateText = styled(Typography)`
  font-size: 28px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 20px;
  }
`

export const TimerText = styled(Typography)`
  font-size: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 16px;
  }
`

export const DialogWrapper = styled('div')`
  padding: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    transform: rotate(90deg);
  }
`

export const Close = styled(CloseIcon)`
  font-size: 40px;
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    top: auto;
    bottom: 20px;
  }
`

export const TimerBig = styled('div')`
  padding: 12px;
  border-radius: 16px;
  color: ${grey[900]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TimerTextBig = styled(Typography)`
  font-size: 200px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 140px;
  }
`

export const DateTextBig = styled(Typography)`
  font-size: 120px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 80px;
  }
`
