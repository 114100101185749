import { FieldCellType } from '../../types'
import { FieldCell } from '../field-cell'
import * as Styled from './styled'

export type Props = {
  fieldCells: FieldCellType[]
}

export const FieldCells = ({ fieldCells }: Props) => {
  return (
    <Styled.Root>
      {fieldCells.map(({ fieldItem, id, x, y, order }) => (
        <FieldCell key={id} fieldItem={fieldItem} cellId={id} x={x} y={y} order={order} />
      ))}
    </Styled.Root>
  )
}
