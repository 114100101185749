import { domain } from '../domain'
import { changeDrawerMenu, setAnchorDesktopMenu } from './events'

export const $openDrawerMenu = domain
  .createStore<boolean>(false)
  .on(changeDrawerMenu, (data, value) => (value !== undefined ? value : !data))

export const $anchorDesktopMenu = domain
  .createStore<HTMLElement | null>(null)
  .on(setAnchorDesktopMenu, (_, value) => value)
