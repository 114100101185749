import { Box } from '@mui/system'

import { useMedia } from './utils'

type Props = {
  desktop?: boolean
  mobile?: boolean
  tablet?: boolean
  children: React.ReactNode
}
/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
*/

const Media = ({ children, desktop = false, mobile = false, tablet = false }: Props) => {
  return (
    <Box
      sx={{
        display: {
          xs: mobile ? 'block' : 'none',
          sm: tablet ? 'block' : 'none',
          md: desktop ? 'block' : 'none',
          lg: desktop ? 'block' : 'none',
          xl: desktop ? 'block' : 'none',
        },
      }}
    >
      {children}
    </Box>
  )
}
export { Media, useMedia }
