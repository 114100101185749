import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { useStore } from 'effector-react'

import { AlertError, AlertSuccess } from '@/shared/components/alert'
import { If } from '@/shared/utils/if'

import {
  $accordionId,
  $activateGiftError,
  $activateGiftsPending,
  $modalVisible,
} from '../../model/gift'
import { activateGift, changeAccordionId, changeModalGiftVisible } from '../../model/gift/events'
import { Gift } from '../../types'

const handleClose = () => changeModalGiftVisible(false)

type Props = {
  gifts: Gift[]
}

export const ModalGift = ({ gifts }: Props) => {
  const modalVisible = useStore($modalVisible)
  const accordionId = useStore($accordionId)
  const activateGiftError = useStore($activateGiftError)
  const activateGiftPending = useStore($activateGiftsPending)

  return (
    <Dialog fullWidth maxWidth="sm" open={modalVisible} keepMounted onClose={handleClose}>
      <DialogTitle>Подарки уже ждут вас!</DialogTitle>
      <DialogContent>
        {gifts.map((gift) => (
          <Accordion
            key={gift.id}
            expanded={accordionId === gift.id}
            onChange={() => changeAccordionId(gift.id)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{gift.title}</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography sx={{ mb: 2 }}>{gift.description}</Typography>
              <If condition={!gift.isUsed}>
                <LoadingButton
                  color="success"
                  variant="contained"
                  disabled={activateGiftPending}
                  loading={activateGiftPending}
                  onClick={() => activateGift({ code: gift.code })}
                >
                  Активировать
                </LoadingButton>
              </If>
              <If condition={activateGiftError && !activateGiftPending}>
                <AlertError text={activateGiftError} sx={{ mt: 2 }} />
              </If>
              <If condition={gift.isUsed}>
                <AlertSuccess text={gift.giftText} />
              </If>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
