import '../../model/single-timer-process/init'

import { Box, List, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useRef } from 'react'

import { Choose, When } from '@/shared/utils/choose'

import { TimerStatus } from '../../enums'
import { $players } from '../../model/single-player'
import { $timerStatus } from '../../model/single-timer-process'
import {
  changeTimerStatus,
  setAudioEndTimer,
  setAudioTickTimer,
  startGame,
} from '../../model/single-timer-process/events'
import { PlayerCard } from '../single-game-card'
import * as Styled from './styled'

export const TimerProcess = () => {
  const players = useStore($players)
  const timerStatus = useStore($timerStatus)
  const refTick = useRef<HTMLAudioElement>(null)
  const refEnd = useRef<HTMLAudioElement>(null)
  const isGameActive = [TimerStatus.RUNNING, TimerStatus.PAUSE].includes(timerStatus)

  // TODO change to useGate
  useEffect(() => {
    refTick.current && setAudioTickTimer(refTick.current)
    refEnd.current && setAudioEndTimer(refEnd.current)
  }, [refTick])

  return (
    <>
      <Typography sx={{ textAlign: 'center' }} variant="h3" color="initial">
        Таймер
      </Typography>
      <audio ref={refTick} src="https://b.avodom.ru/media/files/audio/tick.mp3" />
      <audio ref={refEnd} src="https://b.avodom.ru/media/files/audio/end.mp3" />
      <List>
        {players.map((player) => (
          <PlayerCard key={player.id} player={player} />
        ))}
      </List>
      <Box mt={2}>
        <Choose>
          <When condition={timerStatus === TimerStatus.STOP}>
            <Styled.Button variant="contained" onClick={() => startGame()}>
              Начать
            </Styled.Button>
          </When>
          <When condition={isGameActive}>
            <Styled.Button
              variant="contained"
              onClick={() => changeTimerStatus(TimerStatus.STOP)}
              sx={{ marginTop: 1 }}
            >
              Завершить таймер
            </Styled.Button>
          </When>
        </Choose>
      </Box>
    </>
  )
}
