import { DefaultError } from '../../../../shared/types/api'
import { GameSnakeLevel } from '../../ui/level-number/data'
import { END_REASON, GAME_STATUS } from '../../ui/level-number/types'
import { domain } from '../domain'
import { FinishLevelParams, FinishLevelResponse } from './types'

export const finishLevelGame = domain.event<FinishLevelParams>('finishLevelGame')

export const finishLevelGameFx = domain.effect<
  FinishLevelParams,
  FinishLevelResponse,
  DefaultError
>('finishLevelGameFx')

export const setGameSnakeLevel = domain.event<GameSnakeLevel>('setGameSnakeLevel')

export const resetLevelGame = domain.event<void>('resetLevelGame')
export const setScore = domain.event<number>('setScore')

export const changeGameStatus = domain.event<GAME_STATUS>('changeGameStatus')

export const setEndReason = domain.event<END_REASON>('setEndReason')
