import { useStore } from 'effector-react'

import {
  GameSnakeLevelNumber,
  GameSnakeLevels,
  GameSnakeMain,
  GameSnakeParams,
  GameSnakeShop,
  GameSnakeSingleGame,
  GameSnakeStats,
  LevelAdminMain,
  LevelEditor,
} from '@/features/game-snake'
import { $backgroundUrl } from '@/features/main/model/theme'
import { Choose, When } from '@/shared/utils/choose'

import { Template } from '../templates/template'
import { GameSnakePageType } from './types'

type Props = {
  type: GameSnakePageType
}

export const GameSnakePage = ({ type }: Props) => {
  const backgroundUrl = useStore($backgroundUrl)
  return (
    <Template hasPadding={false} backgroundUrl={backgroundUrl}>
      <Choose>
        <When condition={type === GameSnakePageType.MAIN}>
          <GameSnakeMain />
        </When>
        <When condition={type === GameSnakePageType.SINGLE_GAME}>
          <GameSnakeSingleGame />
        </When>
        <When condition={type === GameSnakePageType.SHOP}>
          <GameSnakeShop />
        </When>
        <When condition={type === GameSnakePageType.PARAMS}>
          <GameSnakeParams />
        </When>
        <When condition={type === GameSnakePageType.STATS}>
          <GameSnakeStats />
        </When>
        <When condition={type === GameSnakePageType.LEVELS}>
          <GameSnakeLevels />
        </When>
        <When condition={type === GameSnakePageType.LEVEL_NUMBER}>
          <GameSnakeLevelNumber />
        </When>
        <When condition={type === GameSnakePageType.ADMIN}>
          <LevelAdminMain />
        </When>
        <When condition={type === GameSnakePageType.ADMIN_NUMBER}>
          <LevelEditor />
        </When>
      </Choose>
    </Template>
  )
}
