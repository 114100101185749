import { uniqBy } from 'lodash'

import { Note, NoteTag, Tag } from '../../types'

export const mapNoteTags = (notes: Note[], tags: Tag[]): NoteTag[] => {
  return tags.map((tag) => ({
    tag: tag,
    notes: notes.filter((note) => note.tag.id === tag.id),
  }))
}
export const getAllTagsFromNotes = (notes: Note[]): Tag[] => {
  return uniqBy(
    notes.map((item) => item.tag),
    'id'
  )
}
