import { Container as ContainerMui } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('section')`
  width: 100%;
`

export const TopRow = styled('div')`
  display: flex;
  a:not(:last-child) {
    margin-right: 20px;
  }
`

export const AppWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;
`

export const Container = styled(ContainerMui)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ImageIcon = styled('img')`
  object-fit: contain;
  width: 100%;
  height: 100%;
`
