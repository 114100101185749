import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useStore } from 'effector-react'

import { WaddlesCoin } from '@/shared/components/waddles-coin'

import { $buyPackPending } from '../../../model/shop'
import { buyPack, closeBuyModal } from '../../../model/shop/events'
import { ShopCardItem } from '../../../type'

type Props = {
  pack: ShopCardItem | null
}

export const ConfrimBuy = ({ pack }: Props) => {
  const buyPackPending = useStore($buyPackPending)
  if (!pack) return null

  return (
    <Dialog open={pack !== null} onClose={() => closeBuyModal()}>
      <DialogTitle>Подтверждение покупки</DialogTitle>
      <DialogContent>
        <Typography fontSize="18px" sx={{ textAlign: 'center' }}>
          {pack.name}
        </Typography>
        <WaddlesCoin
          sx={{ color: 'text.primary', mt: 2 }}
          TypographyProps={{ fontSize: 20 }}
          text={`Купить за`}
          count={pack.price}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={buyPackPending}
          variant="contained"
          color="error"
          onClick={() => closeBuyModal()}
        >
          Отмена
        </Button>
        <LoadingButton
          variant="contained"
          loading={buyPackPending}
          color="success"
          onClick={() => {
            buyPack({ type: pack.type })
          }}
          autoFocus
        >
          Купить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
