import { Box, CircularProgress, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useGate, useStore } from 'effector-react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useLockBodyScroll } from '@/shared/hooks'
import { If } from '@/shared/utils/if'

import { HEADER_HEIGHT } from '../../../header'
import { changeActiveChat, setActiveChatId } from '../../model/chat/events'
import { $activeChat } from '../../model/chat/store'
import { ChatWindowGate } from '../../model/messages/gate'
import { closeSocket } from '../../model/messages/socket'
import { $messagesPending } from '../../model/messages/store'
import { BottomBlock } from './ui/bottom-block'
import { ChatHeader } from './ui/chat-header'
import { DialogImage } from './ui/dialog-image'
import { MessageList } from './ui/message-list'

export const ChatWindow = () => {
  const { chatId } = useParams()
  useGate(ChatWindowGate)
  const pending = useStore($messagesPending)

  useEffect(() => {
    const activeChatId = Number(chatId)
    setActiveChatId(activeChatId)
    function handleFocus() {
      changeActiveChat({ chatId: activeChatId, isActive: true })
    }

    function handleBlur() {
      changeActiveChat({ chatId: activeChatId, isActive: false })
    }

    // Добавляем обработчики событий при монтировании компонента
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    // Удаляем обработчики событий при размонтировании компонента
    return () => {
      closeSocket()
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [chatId])

  const activeChat = useStore($activeChat)
  useLockBodyScroll()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        width: '100%',
        flexGrow: 1,
      }}
    >
      <If condition={activeChat}>
        <Box sx={{ width: '100%', borderBottom: `1px solid ${grey[400]}`, p: 1 }}>
          <ChatHeader title={activeChat?.title || ''} avatar={activeChat?.avatar || null} />
        </Box>
        <MessageList />
        <Box>
          <BottomBlock />
        </Box>
      </If>
      <If condition={!activeChat}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <If condition={pending}>
            <CircularProgress />
          </If>
          <If condition={!pending}>
            <Typography>Выберите чат</Typography>
          </If>
        </Box>
      </If>
      <DialogImage />
    </Box>
  )
}
