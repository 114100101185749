import { Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { Dialog } from '../../../../../main/ui/modal-fortune/styled'
import { changeModalCreateChat } from '../../../../model/chat/events'
import { createChatForm } from '../../../../model/chat/forms'
import { $createChatPending, $showModalCreateChat } from '../../../../model/chat/store'

export const CreateChat = () => {
  const open = useStore($showModalCreateChat)
  const pending = useStore($createChatPending)

  const { fields, submit, errorText } = useForm(createChatForm)

  return (
    <Box>
      <Button variant="contained" onClick={() => changeModalCreateChat(true)}>
        Создать чат
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') return
          changeModalCreateChat(false)
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Создать чат</Typography>
            <IconButton onClick={() => changeModalCreateChat(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 1, width: '100%' }}
            multiline
            disabled={pending}
            value={fields.title.value}
            error={fields.title.hasError()}
            helperText={errorText('title')}
            variant="standard"
            label="Название"
            onChange={(e) => fields.title.onChange(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={pending}
            sx={{ width: '100%' }}
            tabIndex={3}
            variant="contained"
            onClick={() => submit()}
          >
            Создать
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
