import * as Styled from './styled'

type MenuItemProps = {
  children: React.ReactNode
  text: string
}

export const MenuItem = ({ children, text = 'hello' }: MenuItemProps) => {
  return (
    <Styled.Root>
      <div>{children}</div>
      <Styled.Text color="text.primary">{text}</Styled.Text>
    </Styled.Root>
  )
}
