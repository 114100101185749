import { Box, LinearProgress, Typography } from '@mui/material'

import houseSrc from './assets/house.png'
import * as Styled from './styled'

export const RoadToHouse = () => {
  const progress = 1

  return (
    <Styled.Root>
      <Box
        component="h1"
        textAlign="center"
        marginTop="0"
        fontSize="34px"
        marginBottom="12px"
        fontWeight="bold"
      >
        Дорога к домику!
      </Box>
      <Styled.HouseContainer>
        <img src={houseSrc} alt="Домик" style={{ width: '100%', objectFit: 'contain' }} />
      </Styled.HouseContainer>
      <Styled.ProgressContainer>
        <Typography variant="body1" align="center" marginBottom="10px" fontWeight="bold">
          Ваш прогресс: {progress}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: '10px',
            borderRadius: '5px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#4caf50',
            },
          }}
        />
      </Styled.ProgressContainer>
      <Styled.MotivationalText>
        <Typography variant="body1" align="center" fontWeight="bold" sx={{ mb: 2 }}>
          Выполняйте задания и домик будет ближе!
        </Typography>
        <Typography variant="body2" align="center">
          Каждое выполненное задание приближает вас к цели. Прогресс растет вместе с вашими
          усилиями!
        </Typography>
      </Styled.MotivationalText>
    </Styled.Root>
  )
}
