type GenericObject = {
  [key: string]: any
}

// Вспомогательная функция для преобразования строки из snake_case в camelCase
function toCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

// Рекурсивная функция для преобразования ключей объекта или массива объектов в camelCase
export function deepCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    // Если это массив, применяем функцию ко всем его элементам
    return obj.map(deepCamelCase)
  } else if (obj !== null && typeof obj === 'object') {
    // Если это объект, применяем функцию ко всем его ключам
    return Object.keys(obj).reduce<GenericObject>((acc, key) => {
      const camelKey = toCamelCase(key) // Преобразование ключа в camelCase
      acc[camelKey] = deepCamelCase(obj[key]) // Рекурсивное применение к значениям
      return acc
    }, {})
  }
  // Возвращаем неизменённое значение, если это не объект/массив
  return obj
}
