export const choose = <T>(choices: T[]): T => {
  const index = Math.floor(Math.random() * choices.length)
  return choices[index]
}

export const chooseMany = <T>(choices: T[], count: number): T[] => {
  if (choices.length < count) return []

  const copyChoises = [...choices]

  const results: T[] = []
  for (let i = 0; i < count; i++) {
    const index = Math.floor(Math.random() * copyChoises.length)
    results.push(copyChoises[index])
    copyChoises.splice(index, 1)
  }

  return results
}

export const chooses = <T>(items: T[], weights: number[]) => {
  let i

  for (i = 1; i < weights.length; i++) weights[i] += weights[i - 1]

  let random = Math.random() * weights[weights.length - 1]

  for (i = 0; i < weights.length; i++) if (weights[i] > random) break

  return items[i]
}

export const getRandomWeightedNumber = (min: number, max: number) => {
  // Генерация случайного числа с нормальным распределением в диапазоне [0, 1)
  let u = 0,
    v = 0
  while (u === 0) u = Math.random() // Избегаем нуля
  while (v === 0) v = Math.random()
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

  // Преобразование в нормальное распределение с центром в среднем значении диапазона
  let mean = (max + min) / 2
  let sigma = (max - min) / 6 // 99.7% значений будут в диапазоне [min, max]
  num = num * sigma + mean

  // Ограничение результата диапазоном [min, max]
  return Math.min(Math.max(num, min), max)
}
