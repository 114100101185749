import { STORAGE_NAMES } from './consts'

export class Storage {
  static get<T>(key: string): T | null {
    const storageData = localStorage.getItem(key)

    if (!storageData) return null
    try {
      return JSON.parse(storageData)
    } catch (err) {
      console.error(err)
    }

    return null
  }

  static remove(key: string): void {
    localStorage.removeItem(key)
  }

  static set<T>(key: string, value: T): void {
    try {
      const jsonValue = JSON.stringify(value)
      localStorage.setItem(key, jsonValue)
    } catch (err) {
      console.error(err)
    }
  }

  static clear(): void {
    localStorage.clear()
  }
}

export { STORAGE_NAMES }
