import { domain } from '../domain'
import { HasUnreadMessageError, HasUnreadMessageParams, HasUnreadMessageResponse } from './types'

export const hasUnreadMessage = domain.event<HasUnreadMessageParams>('hasUnreadMessage')

export const hasUnreadMessageFx = domain.effect<
  HasUnreadMessageParams,
  HasUnreadMessageResponse,
  HasUnreadMessageError
>('hasUnreadMessageFx')
