import '../../models/user/init'

import { Box, Button } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Template } from '@/packages/pages/templates/template'
import { ROUTE_PATH } from '@/packages/paths'
import { getToken } from '@/services/api'
import { AlertError, AlertLoading } from '@/shared/components/alert'
import { RouterLink } from '@/shared/components/router-link'
import { checkTimeBetweenNight } from '@/shared/utils/moment'

import { $getUserPending, $user, initUser } from '../../models/user'
import { getUser } from '../../models/user/events'
import { Night } from './ui/night'

export type ProtectedRouteProps = {
  element: JSX.Element
  isAdmin?: boolean
  isDisableNight?: boolean
}

export const AuthRoute = ({
  element,
  isAdmin = false,
  isDisableNight = false,
}: ProtectedRouteProps) => {
  const user = useStore($user)
  const getUserPending = useStore($getUserPending)
  const token = getToken()
  const location = useLocation()

  useEffect(() => {
    if (!user || initUser) {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if ((token || getUserPending) && !user) {
    return (
      <Template withHeader={false} withFooter={false}>
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '300px', textAlign: 'center' }} mt={5}>
            <AlertLoading text="Данные загружаются" />
          </Box>
        </Box>
      </Template>
    )
  }

  if (!user && !getUserPending && location.pathname !== ROUTE_PATH.SIGN_IN) {
    return <Navigate to={ROUTE_PATH.SIGN_IN} />
  }

  if (user && isAdmin && !user.isSuperuser) {
    return (
      <Template withHeader={false} withFooter={false}>
        <Box sx={{ width: '100%', textAlign: 'center' }} mt={5}>
          <AlertError text="Страница недоступна!" />
          <RouterLink to={ROUTE_PATH.HOME}>
            <Button sx={{ mt: 2 }}>На главную</Button>
          </RouterLink>
        </Box>
      </Template>
    )
  }

  const isNight =
    checkTimeBetweenNight('23:00:00', '06:00:00') && !isDisableNight && !user?.isSuperuser

  if (isNight) {
    return <Night />
  }

  return element
}
