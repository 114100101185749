import { indigo } from '@mui/material/colors'
import { css,styled } from '@mui/material/styles'

const baseBlock = css`
  width: 132px;
  min-height: 72px;
  border-radius: 8px;
`

export const EmptyRoot = styled('div')`
  ${baseBlock}
  background: ${({ theme }) => theme.palette.action.disabledBackground};
  & > p {
    padding: 4px 8px;
  }
  & > span {
    border-radius: 8px;
  }
`

export const Root = styled('div')`
  ${baseBlock}
  padding: 4px 8px;
  background: ${indigo[900]};
  color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey['500']};
  display: flex;
  justify-content: center;
  align-items: center;
`
