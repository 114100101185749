import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { Storage } from '../local-storage'

const API_URL = process.env.REACT_APP_API_URL + '/api/v1'
const TOKEN_NAME = 'token'

export const api = applyCaseMiddleware(axios.create({ baseURL: API_URL }))

export const setToken = (token: string): void => {
  Storage.set(TOKEN_NAME, token)
}

export const removeToken = (): void => {
  Storage.remove(TOKEN_NAME)
}

export const getToken = (): string | null => {
  return Storage.get(TOKEN_NAME)
}

api.interceptors.request.use((config) => {
  if (config.headers) {
    const token = getToken()
    if (token) {
      // @ts-ignore
      config.headers.authorization = `Token ${token}`
    }
  }
  return config
})
