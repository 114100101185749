import CloseIcon from '@mui/icons-material/CloseSharp'
import { Box } from '@mui/material'
import { useStore } from 'effector-react'

import { If } from '../../../../../../../../shared/utils/if'
import { changeDialogImage, fileChanged } from '../../../../../../model/messages/events'
import { $imageFile } from '../../../../../../model/messages/store'
import { DialogImage } from '../../../dialog-image'

export const ImageFile = () => {
  const imageFile = useStore($imageFile)

  if (!imageFile) return null
  const isImage = imageFile.type.startsWith('image/')

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 1,
        justifyContent: 'flex-end',
        mr: 1,
        mb: 1,
        alignItems: 'center',
      }}
    >
      <If condition={isImage}>
        <Box
          sx={{ position: 'relative' }}
          onClick={() => {
            changeDialogImage(URL.createObjectURL(imageFile))
          }}
        >
          <CloseIcon
            sx={{
              position: 'absolute',
              top: -8,
              right: -8,
              color: 'red',
              fontSize: 20,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation()
              fileChanged(null)
            }}
          />

          <Box
            component="img"
            src={URL.createObjectURL(imageFile)}
            alt="Preview"
            sx={{ height: 60 }}
          />
        </Box>
      </If>
      <DialogImage />
    </Box>
  )
}
