import { LoadingButton } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useEffect, useRef } from 'react'

import { Markdown } from '@/shared/components/markdown'
import { If } from '@/shared/utils/if'
import { isMobileDevice } from '@/shared/utils/media/isMobileDevice'

import { resetEditMessage, setSendInputFocus } from '../../../../../../model/messages/events'
import { editMessageForm } from '../../../../../../model/messages/forms'
import { $editMessage, $editMessagePending } from '../../../../../../model/messages/store'
import { Message } from '../../../../../../type'
import { containsOnlyEmoji } from './utils'

type Props = {
  message: Message
}

export const MessageTextField = ({ message }: Props) => {
  const onlyEmoji = containsOnlyEmoji(message.text)
  const pending = useStore($editMessagePending)
  const editMessage = useStore($editMessage)
  const isEditMessage = editMessage && editMessage.id === message.id
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { fields, submit, errorText } = useForm(editMessageForm)

  useEffect(() => {
    if (editMessage) {
      fields.text.set(editMessage.text)
      setEditInputFocus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage])

  const setEditInputFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        const length = inputRef.current.value.length
        inputRef.current.focus()
        inputRef.current.setSelectionRange(length, length)
      }
    }, 0)
  }

  return (
    <Box sx={{ width: '100%', pb: '4px' }}>
      <Typography
        sx={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          lineHeight: 1.2,
          pr: 4,
        }}
        fontSize={onlyEmoji ? 50 : 14}
      >
        <Markdown>{message.text}</Markdown>
      </Typography>
      <If condition={isEditMessage}>
        <Box sx={{ mt: 1 }}>
          <TextField
            inputRef={inputRef}
            sx={{
              mb: 1,
              width: '100%',
              p: 0,
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              '& .MuiOutlinedInput-input': {
                fontSize: '14px',
                lineHeight: 1.2,
              },
              '& .MuiOutlinedInput-root': {
                padding: 1,
              },
            }}
            autoFocus
            multiline
            disabled={pending}
            value={fields.text.value}
            error={fields.text.hasError()}
            helperText={errorText('text')}
            variant="outlined"
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                resetEditMessage()
                setSendInputFocus()
              }
              if (e.key === 'Enter' && !e.shiftKey && !isMobileDevice()) {
                e.preventDefault()
                submit()
              }
            }}
            onChange={(e) => fields.text.onChange(e.target.value)}
          />
          <Box sx={{ display: 'flex' }}>
            <LoadingButton
              loading={pending}
              sx={{ mr: 1, fontSize: 10, color: red[300] }}
              onClick={() => {
                resetEditMessage()
              }}
            >
              Отменить
            </LoadingButton>
            <LoadingButton
              loading={pending}
              sx={{ fontSize: 10 }}
              onClick={() => {
                submit()
              }}
            >
              Изменить
            </LoadingButton>
          </Box>
        </Box>
      </If>
    </Box>
  )
}
