export enum BattleStatus {
  CREATED = 'CREATED',
  INVITE = 'INVITE',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
  ROUND_START = 'ROUND_START',
  ROUND_PROGRESS = 'ROUND_PROGRESS',
  ROUND_END = 'ROUND_END',
  FIGHT_END = 'FIGHT_END',
  CLOSE = 'CLOSE',
}

export type Profile = {
  id: number
  attack: number
  health: number
  channel: string
  username: string
}

export type PlayerBattle = {
  profile: Profile
  health: number
  attack: number
  lastDamage: number | null
  answerId: number | null
}

export type TeamBattle = {
  name: string
  players: PlayerBattle[]
}

export type QuestionAnswer = {
  id: number
  answer: string
  is_correct: boolean
}

export type Question = {
  id: number
  complexity: number
  question: string
  translate: string
  correctAnswer: QuestionAnswer
  answers: QuestionAnswer[]
}

export type Battle = {
  id: number
  round: number | null
  status: BattleStatus
  activeQuestion: Question | null
  battleCreated: string
  roundCreated: string
  teams: TeamBattle[]
  ownerId: number
}
