import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton } from '@mui/material'

import { changeChatSettingsModal } from '../../../../../../model/chat-settings'

export const SettingsButton = () => {
  return (
    <IconButton onClick={() => changeChatSettingsModal(true)} sx={{ p: 0 }}>
      <SettingsIcon sx={{ fontSize: '28px' }} />
    </IconButton>
  )
}
