import { DefaultError, EmptyParams } from '@/shared/types/api'

import { ActivateGiftParams, ActivateGiftResponse, GetGiftsResponse } from '../../types'
import { domain } from '../domain'

export const getGifts = domain.event<void>('getGits')

export const getGiftsFx = domain.effect<EmptyParams, GetGiftsResponse, DefaultError>('getGitsFx')

export const activateGift = domain.event<ActivateGiftParams>('activateGift')

export const activateGiftFx = domain.effect<ActivateGiftParams, ActivateGiftResponse, DefaultError>(
  'activateGiftFx'
)

export const changeModalGiftVisible = domain.event<boolean>('changeModalGiftVisible')

export const changeAccordionId = domain.event<number | void>('changeAccordionId')
