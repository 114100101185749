// ./ui/decrypt-text/index.tsx
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { If } from '@/shared/utils/if'

import { $decryptedData, $decryptPending } from '../../model'
import { decryptTextForm } from '../../model/forms'
import { $decryptError } from '../../model/store'
import { FixedBox } from '../main/styled'

export const DecryptText = () => {
  const pending = useStore($decryptPending)
  const decryptedData = useStore($decryptedData)
  const decryptError = useStore($decryptError)
  const { fields, submit, error } = useForm(decryptTextForm)

  // State для управления видимостью текста в полях
  const [showSecretPhrase, setShowSecretPhrase] = useState(false)
  const [showEncryptedMessage, setShowEncryptedMessage] = useState(false)

  // Функция для копирования текста в буфер обмена
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Текст скопирован в буфер обмена')
    })
  }

  return (
    <FixedBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      p={2}
      boxSizing="border-box"
    >
      <Typography variant="h5" gutterBottom>
        Дешифрование по тексту
      </Typography>
      <TextField
        label="Секретная фраза"
        variant="outlined"
        type={showSecretPhrase ? 'text' : 'password'}
        value={fields.secretPhrase.value}
        onChange={(e) => fields.secretPhrase.onChange(e.target.value)}
        disabled={pending}
        fullWidth
        margin="normal"
        error={!!error('secretPhrase')}
        helperText={error('secretPhrase')?.errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle secret phrase visibility"
                onClick={() => setShowSecretPhrase(!showSecretPhrase)}
                edge="end"
                tabIndex={-1} // Исключаем из цикла табуляции
              >
                {showSecretPhrase ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Зашифрованное сообщение"
        variant="outlined"
        type={showEncryptedMessage ? 'text' : 'password'}
        value={fields.encryptedMessage.value}
        onChange={(e) => fields.encryptedMessage.onChange(e.target.value)}
        disabled={pending}
        fullWidth
        margin="normal"
        error={!!error('encryptedMessage')}
        helperText={error('encryptedMessage')?.errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle encrypted message visibility"
                onClick={() => setShowEncryptedMessage(!showEncryptedMessage)}
                edge="end"
                tabIndex={-1} // Исключаем из цикла табуляции
              >
                {showEncryptedMessage ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {decryptError && (
        <Typography variant="body2" color="error" mt={1}>
          {decryptError}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={() => submit()}
        disabled={pending}
        fullWidth
        sx={{ mt: 2 }}
      >
        Расшифровать
      </Button>
      <If condition={!!decryptedData}>
        <Box mt={2} width="100%">
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              overflow: 'hidden', // Скрываем переполнение
            }}
          >
            <Box flexGrow={1} overflow="auto">
              <Typography variant="h6" gutterBottom>
                Расшифрованное сообщение:
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                {decryptedData}
              </Typography>
            </Box>
            <IconButton onClick={() => handleCopy(decryptedData || '')}>
              <ContentCopyIcon />
            </IconButton>
          </Paper>
        </Box>
      </If>
    </FixedBox>
  )
}
