import { throttle } from 'lodash'

import { domain } from '../../domain'

export const closeSocket = domain.createEvent<void>('closeSocket')

// Эффект для подключения к вебсокету
export const webSocketConnect = domain.createEffect<number, WebSocket>('webSocketConnect')

// Событие, инициирующее подключение
export const initiateWebSocketMessagesConnection = domain.createEvent<number>(
  'initiateWebSocketMessagesConnection'
)

export const sendUserIsTyping = domain.createEvent<void>('sendUserIsTyping')

export const setUserIsTyping = domain.createEvent<string>('setUserIsTyping')

export const setUserIsTypingStore = domain.createEvent<string | null>('setUserIsTypingStore')

export const throttleUserTyping = throttle(() => {
  sendUserIsTyping()
}, 2000)

// Ивент на чаты вебсокеты
export const changeMessagesSocketOnline = domain.createEvent<boolean>('changeMessagesSocketOnline')
