import { AlertProps, TypographyProps } from '@mui/material'

import { AlertError } from '../error'
import { AlertInfo } from '../info'
import { AlertLoading } from '../loading'

type Props = AlertProps & {
  hasError?: boolean
  pending?: boolean
  loaded?: boolean
  loadedEmtpy?: boolean
  errorText?: string | null
  loadingText?: string | null
  successText?: string
  emptyText?: string
  TypographyProps?: TypographyProps
  onReload?: () => void
  size?: number
}
export const AlertApi = ({
  hasError,
  pending,
  loaded,
  loadedEmtpy,
  errorText = 'Произошла ошибка',
  loadingText,
  successText,
  emptyText,
  onReload,
  TypographyProps = {},
  ...otherProps
}: Props) => {
  if (hasError || Boolean(errorText)) {
    return (
      <AlertError
        text={errorText}
        TypographyProps={TypographyProps}
        onReload={onReload}
        {...otherProps}
      />
    )
  }

  if (pending || loaded) {
    return (
      <AlertLoading
        text={loadingText}
        pending={Boolean(pending)}
        showSuccess={Boolean(loaded)}
        successText={successText}
        TypographyProps={TypographyProps}
        {...otherProps}
      />
    )
  }

  if (loadedEmtpy && emptyText) {
    return <AlertInfo text={emptyText} TypographyProps={TypographyProps} {...otherProps} />
  }

  return null
}
