import { Card, CardContent as CardContentUi } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Card)`
  width: 100%;
`

export const CardContent = styled(CardContentUi)`
  cursor: pointer;
  && {
    padding: 12px 24px 12px 12px;
  }
`
