import { Typography } from '@mui/material'

import { toBaseDateTime } from '@/shared/utils/moment'

import { setActiveNoteId } from '../../model/note/events'
import { Note } from '../../types'
import * as Styled from './styled'

type Props = {
  note: Note
}

export const NoteTabItem = ({ note }: Props) => {
  return (
    <Styled.Root variant="outlined">
      <Styled.CardContent onClick={() => setActiveNoteId(note.id)}>
        <Typography color="text.secondary" fontSize={12} sx={{ mb: 1 }}>
          {toBaseDateTime(note.created)}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
          {note.text}
        </Typography>
      </Styled.CardContent>
    </Styled.Root>
  )
}
