import { Skeleton } from '@mui/material'
import { Box, SxProps } from '@mui/system'
import { useStore } from 'effector-react'
import { range } from 'lodash'

import { getAppPath } from '@/shared/utils/getPath'

import { $gameSnakeProfile } from '../../model/game-snake'
import * as Styled from './styled'

type Props = {
  color?: string
  size?: number
  snakeLength?: number
  withSpeed?: boolean
  sx?: SxProps
}

export const SnakeColor = ({ size = 20, color, snakeLength = 5, withSpeed, sx = {} }: Props) => {
  const snakeProfile = useStore($gameSnakeProfile)
  const snakeColor = color || snakeProfile?.snakeParams.snakeColor

  if (!snakeProfile) {
    return (
      <Skeleton
        animation="pulse"
        variant="rounded"
        sx={{ width: size * snakeLength, height: size + 4, ...sx }}
      />
    )
  }

  return (
    <Box sx={{ textAlign: 'center', width: '100%', ...sx }}>
      <Box sx={{ display: 'inline-flex', position: 'relative' }}>
        <Styled.ImageHead
          width={size}
          height={size}
          src={getAppPath(snakeProfile?.snakeParams.headImage)}
        />
        <Styled.SnakeHead width={size} height={size} sx={{ background: snakeColor }} />
        {range(snakeLength - 1).map((item) => (
          <Box key={item} sx={{ width: size, height: size, background: snakeColor }} />
        ))}
        {withSpeed && <Styled.Speed>{snakeProfile.snakeParams.speed}</Styled.Speed>}
      </Box>
    </Box>
  )
}
