import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom/client'

import { App } from '@/packages'
import { isProduction } from '@/shared/libs/env'
import { registerServiceWorker } from '@/shared/libs/service-worker'

if (isProduction()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)

// if (isProduction()) {
//   registerServiceWorker()
// }

registerServiceWorker()
