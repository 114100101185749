import { Box, Button, Typography } from '@mui/material'
import { useStore } from 'effector-react'

import { If } from '../../../../../shared/utils/if'
import { $answerId } from '../../../model/game'
import { setAnswerId } from '../../../model/game/events'
import { BattleStatus, Question as QuestionType } from '../../../model/game/types'
import { WsAction } from '../../../model/types'
import { chatSocket } from '../../../model/ws'

type Props = {
  question: QuestionType
  battleId: number
  status: string
}

const fight = (answerId: number | null, battleId?: number) => {
  if (!chatSocket || !answerId || !battleId) return

  setAnswerId(answerId)

  chatSocket.send(
    JSON.stringify({
      type: WsAction.ROUND_FIGHT,
      battle_id: battleId,
      answer_id: answerId,
    })
  )
}

export const Question = ({ question, battleId, status }: Props) => {
  const answerId = useStore($answerId)
  const statusIsRoundEnd = status === BattleStatus.ROUND_END

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography fontSize="32px" component="h4">
        {question.question}
      </Typography>
      <Box display="flex" sx={{ gap: 2, mt: 3, textAlign: 'center', justifyContent: 'center' }}>
        {question.answers.map((answer) => {
          const isCorrect = statusIsRoundEnd && answer.id === question.correctAnswer.id
          return (
            <Button
              key={answer.id}
              disabled={!!answerId && !statusIsRoundEnd}
              color={isCorrect ? 'success' : answer.id === answerId ? 'error' : undefined}
              variant={answer.id === answerId || isCorrect ? 'contained' : 'outlined'}
              onClick={() => !statusIsRoundEnd && fight(answer.id, battleId)}
            >
              {answer.answer}
            </Button>
          )
        })}
      </Box>
      <If condition={statusIsRoundEnd}>
        <Typography fontSize="24px" component="h4" sx={{ mt: 3 }}>
          {question.question.replace('.', question.correctAnswer.answer).toLowerCase()} -{' '}
          {question.translate.toLowerCase()}
        </Typography>
      </If>
    </Box>
  )
}
