import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { Storage, STORAGE_NAMES } from '@/services/local-storage'

import { $user } from '../../../../features/auth'

export const useUpdateVersion = () => {
  const user = useStore($user)
  useEffect(() => {
    const activeVersion = Storage.get(STORAGE_NAMES.ACTIVE_VERSION)

    if (activeVersion !== user?.version) {
      Storage.set(STORAGE_NAMES.ACTIVE_VERSION, user?.version)

      if (activeVersion) {
        window.location.reload()
      }
    }
  }, [user?.version])
}
