import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'

export const Fields = styled('div')`
  display: flex;
  flex-direction: column;
  & > input:not(:last-child) {
    margin-bottom: 440px;
  }
`

export const ButtonSend = styled(LoadingButton)``

export const Errors = styled('div')`
  margin: 0 8px 8px 8px;
`
