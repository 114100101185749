import { createGate } from 'effector-react'

import { getUserChatsRoom } from '../chat/events'
import { initiateWebSocketChatConnection } from '../chat/socket'
import { fileChanged } from './events'
import { sendMessageForm } from './forms'

export const ChatWindowGate = createGate('ChatWindowGate')

ChatWindowGate.open.watch(() => {
  getUserChatsRoom()
  initiateWebSocketChatConnection()
})

ChatWindowGate.close.watch(() => {
  sendMessageForm.reset()
  fileChanged(null)
})
