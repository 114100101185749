import { DefaultError } from '@/shared/types/api'

import { FieldCellType } from '../../ui/admin/types'
import { domain } from '../domain'
import {
  CreateLevelItemParams,
  CreateLevelManyItemParams,
  CreateLevelResponse,
  DeleteLevelItemParams,
  EditLevelItemParams,
  EditLevelResponse,
  GetLevelResponse,
  GetLevelsResponse,
} from './types'

export const getLevel = domain.event<number>('getLevel')
export const getLevelFx = domain.effect<number, GetLevelResponse, DefaultError>('getLevelFx')

export const getLevels = domain.event<void>('getLevels')
export const getLevelsFx = domain.effect<void, GetLevelsResponse, DefaultError>('getLevelsFx')

export const createLevelItem = domain.event<CreateLevelItemParams>('createLevelItem')
export const createLevelItemFx = domain.effect<
  CreateLevelItemParams,
  EditLevelResponse,
  DefaultError
>('createLevelItemFx')

export const deleteLevelItem = domain.event<DeleteLevelItemParams>('deleteLevelItem')
export const deleteLevelItemFx = domain.effect<
  DeleteLevelItemParams,
  EditLevelResponse,
  DefaultError
>('deleteLevelItemFx')

export const editLevelItem = domain.event<EditLevelItemParams>('editLevelItem')
export const editLevelItemFx = domain.effect<EditLevelItemParams, EditLevelResponse, DefaultError>(
  'editLevelItemFx'
)

export const clearItemsFx = domain.effect<number, EditLevelResponse, DefaultError>('clearItemsFx')

export const fillManyItemsFx = domain.effect<
  CreateLevelManyItemParams,
  EditLevelResponse,
  DefaultError
>('fillManyItemsFx')

export const createNewLevel = domain.event<void>('getLevels')
export const createNewLevelFx = domain.effect<void, CreateLevelResponse, DefaultError>(
  'getLevelsFx'
)

export const resetLevel = domain.event<void>('resetLevel')

export const fillFieldCell = domain.event<FieldCellType>('fillFieldCell')
export const clearFieldCell = domain.event<{ x: number; y: number }>('clearFieldCell')

export const setMenuAnchor = domain.event<HTMLElement | null>('setMenuAnchor')
