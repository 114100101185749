import { createGate } from 'effector-react'

import { $packs } from './shop'
import { clearActiveCard, getPacks } from './shop/events'
import { getPackTransactions } from './transactions/events'
import { startPackAudio, stopPackAudio } from './utils'

export const ShopPackGate = createGate('ShopPackGate')
export const MainShopGate = createGate('MainShopGate')
export const TransactionGate = createGate('TransactionGate')

ShopPackGate.close.watch(() => {
  clearActiveCard()
  stopPackAudio()
})

ShopPackGate.open.watch(() => {
  startPackAudio()
})

MainShopGate.open.watch(() => {
  if ($packs.getState().length === 0) {
    getPacks()
  }
})

TransactionGate.open.watch(() => {
  getPackTransactions()
})
