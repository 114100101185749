import { Badge } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BadgeMessage = styled(Badge)`
  position: absolute;
  bottom: 16px;
  right: 24px;
  & .MuiBadge-badge {
    right: -2px;
    top: 2px;
    border: none;
    padding: 0 4px;
    min-width: 12px;
    height: 12px;
    font-size: 10px;
  }
`
