import { Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useStore } from 'effector-react'

import { deleteMessage, resetDeleteMessageId } from '../../../../model/messages/events'
import { $deleteMessageId, $deleteMessagePending } from '../../../../model/messages/store'
import * as Styled from './styled'

export const DeleteMessageModal = () => {
  const pending = useStore($deleteMessagePending)
  const deleteMessageId = useStore($deleteMessageId)

  return (
    <Dialog open={deleteMessageId !== null} onClose={() => resetDeleteMessageId()}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Редактирование</Typography>
          <IconButton onClick={() => resetDeleteMessageId()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={16}>Вы уверены, что хотите удалить это сообщение?</Typography>
      </DialogContent>
      <Styled.Actions>
        <LoadingButton
          onClick={(event) => resetDeleteMessageId()}
          sx={{ mr: 3 }}
          tabIndex={2}
          loading={pending}
        >
          Отмена
        </LoadingButton>
        <LoadingButton
          color="error"
          loading={pending}
          tabIndex={1}
          variant="contained"
          onClick={() => {
            if (deleteMessageId) {
              deleteMessage({ messageId: deleteMessageId })
            }
          }}
        >
          Удалить
        </LoadingButton>
      </Styled.Actions>
    </Dialog>
  )
}
