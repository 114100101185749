import { Box, Button, Typography } from '@mui/material'

import { Template } from '@/packages/pages/templates/template'
import { ROUTE_PATH } from '@/packages/paths'
import { RouterLink } from '@/shared/components/router-link'

export const Night = () => {
  return (
    <Template withHeader={false} withFooter={false}>
      <Box sx={{ width: '100%', textAlign: 'center' }} mt={2}>
        <Typography variant="h2">Пухля спит!</Typography>
        <Box
          sx={{ my: 4 }}
          component="img"
          src="https://b.avodom.ru/media/files/waddles/waddles_sleep_hard.jpg"
          width="100%"
          alt="Пухля спит"
        />
        <Typography variant="h4">И вам советует!</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <RouterLink to={ROUTE_PATH.NOTES}>
            <Button sx={{ mt: 4 }}>Заметки</Button>
          </RouterLink>
          <RouterLink to={ROUTE_PATH.CHAT}>
            <Button sx={{ mt: 1 }}>Чат</Button>
          </RouterLink>
          <Box sx={{ mb: 2 }}>
            <RouterLink to={ROUTE_PATH.DAILY_NOTES}>
              <Button sx={{ mt: 1 }}>Дневник</Button>
            </RouterLink>
          </Box>
        </Box>
      </Box>
    </Template>
  )
}
